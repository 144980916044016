import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { FiRefreshCw } from "react-icons/fi";
import { activeRolloverIsMixed } from "store/selectors/user";

import IconHeader from "components/IconHeader";
import IconSubheader from "components/IconSubheader";
import Button from "components/Button";
import RolloverInstructionsModal from "./RolloverInstructionsModal";

import "./PriorYearContributionCard.scss";

class IndividualRolloverCard extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    subtext: PropTypes.string,
    onClick: PropTypes.bool,
  };
  constructor() {
    super();

    this.state = {
      showRolloverInstructionsModal: false,
    };
  }

  _onClick = () => {
    this.setState({
      showRolloverInstructionsModal: true,
    });
  };

  render() {
    return (
      <>
        <div className="rollover-banner">
          <RolloverInstructionsModal
            show={this.state.showRolloverInstructionsModal}
            onSuccess={() => {
              this.setState({ showRolloverInstructionsModal: false });
            }}
            onClose={() => {
              this.setState({ showRolloverInstructionsModal: false });
            }}
          />

          <div className="rollover-box">
            <div className="employerInfo">
              <div className="employerInfo-body">
                <div>
                  <div className="circle">
                    <span className="icon">
                      <FiRefreshCw color="white" stroke="#60A4BF" size={16} />
                    </span>
                  </div>
                </div>
                <div className="employerInfo-intro">
                  <IconHeader
                    variant="labelHeader"
                    headerText={this.props.title}
                  />
                  <IconSubheader subheader={this.props.subtext} />
                </div>
              </div>
              <div className="employerInfo-action">
                {this.props.onClick && (
                  <Button
                    size="sm"
                    color="action"
                    name="action"
                    btnLabel="View"
                    onClick={this._onClick}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const userState = state.user.userState.state;
  const accounts = state.accounts.iraAccounts;

  return {
    userState,
    accounts,
    isRolloverIsMixed: activeRolloverIsMixed(state),
  };
};

export default connect(mapStateToProps)(withApollo(IndividualRolloverCard));
