import { find, get, includes, isEmpty, some } from "lodash";

import {
  allPayrollIntegrations,
  autoApproveJoinRequestsId,
  daysBeforeDebitId,
  disableReferralsId,
  finchIntegrationId,
  payrollIntegrations180,
  payrollIntegrations360,
  statePlanId,
  uniqueInviteCodesId,
  virtualBankId,
} from "statics/featureIds";
import { auth0PlanCodeSelector } from "./auth0";
import { ENTERPRISE } from "statics/planCodes";
import {
  ConnectionActive,
  ConnectionAuthError,
  ConnectionInitialized,
  ConnectionSyncError,
  PendingAuthTokenValidation,
  PendingInitialRosterApproval,
  PendingInitialRosterSync,
  ProcessingInitialRosterApproval,
  RosterSyncError,
} from "statics/payrollIntegrationStates";
import {
  EmployerApplicationApproved,
  EmployerApplicationSubmittedKYB,
  EmployerBillingDetailsPending,
  EmployerBillingVerificationPending,
  EmployerRequiredUpdatesPending,
  EmployerSelfServeBillingDetailsPending,
  progressiveOnboardingStates as progessiveOnboardingStates,
} from "statics/states";
import { ISOLVED_WHOLESALE } from "statics/billingTypes";

export const hasInvitedAUserSelector = (state) => {
  return (
    !isEmpty(get(state, "employer.employeeInvitations")) ||
    !isEmpty(get(state, "employer.employerLinkRequests"))
  );
};

export const hasStatePlanSelector = (state) => {
  const planType = get(state, "employer.company.enabledProductFeatures");
  return some(planType, (key) => key === statePlanId);
};

export const hasExpiredInvites = (state) => {
  const enabledFeatures = get(
    state,
    "employer.company.enabledProductFeatures",
    []
  );
  return some(
    enabledFeatures,
    (featureId) => featureId === uniqueInviteCodesId
  );
};

export const hasEnabledDaysBeforeDebit = (state) => {
  const enabledFeatures = get(
    state,
    "employer.company.enabledProductFeatures",
    []
  );
  return some(enabledFeatures, (featureId) => featureId === daysBeforeDebitId);
};

export const hasVirtualBankEnabled = (state) => {
  const enabledFeatures = get(
    state,
    "employer.company.enabledProductFeatures",
    []
  );
  return some(
    enabledFeatures,
    (hasVirtualBank) => hasVirtualBank === virtualBankId
  );
};
// Quick fix for making selectors work with the new state machine for progressive onboarding
const progressiveOnboardingStateScoring = {
  [progessiveOnboardingStates.ProgressiveOnboardingStarted]: 0,
  [progessiveOnboardingStates.AddedBankAccount]: 1,
  [progessiveOnboardingStates.AddedGroups]: 2,
  [progessiveOnboardingStates.AddedDeductionCode]: 3,
  [progessiveOnboardingStates.AnnouncedIcon]: 4,
  [progessiveOnboardingStates.InvitedEmployees]: 5,
  [progessiveOnboardingStates.DeductionCodeAfterInviteEmployees]: 6,
  [progessiveOnboardingStates.LaunchedPlan]: 7,
  [progessiveOnboardingStates.DeductionCodeAfterLaunchPlan]: 8,
};

export const hasAddedBank = (state) => {
  const progState = get(
    state,
    "employer.company.progressiveOnboardingState",
    ""
  );
  const progScore = progressiveOnboardingStateScoring[progState];
  return progScore >= progressiveOnboardingStateScoring.AddedBankAccount;
};

export const hasAnnouncedIcon = (state) => {
  const progState = get(
    state,
    "employer.company.progressiveOnboardingState",
    ""
  );
  const progScore = progressiveOnboardingStateScoring[progState];
  return progScore >= progressiveOnboardingStateScoring.AnnouncedIcon;
};

export const hasInvitedEmployees = (state) => {
  const progState = get(
    state,
    "employer.company.progressiveOnboardingState",
    ""
  );
  const progScore = progressiveOnboardingStateScoring[progState];
  return progScore >= progressiveOnboardingStateScoring.InvitedEmployees;
};

export const hasLaunchedPlan = (state) => {
  const progState = get(
    state,
    "employer.company.progressiveOnboardingState",
    ""
  );
  const progScore = progressiveOnboardingStateScoring[progState];
  return progScore >= progressiveOnboardingStateScoring.LaunchedPlan;
};

export const hasAddedGroups = (state) => {
  const progState = get(
    state,
    "employer.company.progressiveOnboardingState",
    ""
  );
  const progScore = progressiveOnboardingStateScoring[progState];
  return progScore >= progressiveOnboardingStateScoring.AddedGroups;
};

export const hasAddedDeductionCode = (state) => {
  const has360PayrollIntegration = hasEnabled360PayrollIntegration(state);

  if (has360PayrollIntegration) {
    return true;
  }

  const progState = get(
    state,
    "employer.company.progressiveOnboardingState",
    ""
  );
  const progScore = progressiveOnboardingStateScoring[progState];

  const needsDeductionCodeState =
    progState === "DeductionCodeAfterInviteEmployees" ||
    progState === "DeductionCodeAfterLaunchPlan";

  return (
    progScore >= progressiveOnboardingStateScoring.AddedDeductionCode &&
    !needsDeductionCodeState
  );
};

export const hasFullyOnboarded = (state) => {
  const launchedPlan = hasLaunchedPlan(state);

  return launchedPlan;
};

export const planCodeSelector = (state) => {
  const storedPlanCode = get(state.employer, "company.planCode");
  const initialPlanCode = auth0PlanCodeSelector(state);
  const planCode = storedPlanCode ? storedPlanCode : ENTERPRISE;

  // If there was no initial plan code specified, then give back nothing here
  if (initialPlanCode === undefined || !initialPlanCode) {
    return null;
  }

  return planCode;
};

export const hasEnabled360PayrollIntegration = (state) => {
  const enabledFeatures = get(
    state,
    "employer.company.enabledProductFeatures",
    []
  );
  return some(enabledFeatures, (featureId) => {
    return includes(payrollIntegrations360, featureId);
  });
};

export const hasEnabled180PayrollIntegration = (state) => {
  const enabledFeatures = get(
    state,
    "employer.company.enabledProductFeatures",
    []
  );
  return some(enabledFeatures, (featureId) => {
    return includes(payrollIntegrations180, featureId);
  });
};

export const hasEnabledPayrollIntegration = (state) => {
  const enabledFeatures = get(
    state,
    "employer.company.enabledProductFeatures",
    []
  );

  return some(enabledFeatures, (featureId) =>
    includes(allPayrollIntegrations, featureId)
  );
};

export const hasEnabledFinchFeature = (state) => {
  const enabledFeatures = get(
    state,
    "employer.company.enabledProductFeatures",
    []
  );
  return includes(enabledFeatures, finchIntegrationId);
};

export const hasSuccessfullyConnectedFinch = (state) => {
  const { finchStatus } = state.employer;
  return finchStatus && finchStatus === ConnectionActive;
};

export const hasFinchConnectionAuthError = (state) => {
  const authErrorStates = [ConnectionAuthError, PendingAuthTokenValidation];

  const { finchStatus } = state.employer;
  return finchStatus && includes(authErrorStates, finchStatus);
};

export const isValidatingFinchToken = (state) => {
  const { finchStatus } = state.employer;
  return finchStatus && finchStatus === PendingAuthTokenValidation;
};

export const finchHasSynced = (state) => {
  const { finchStatus } = state.employer;
  return (
    finchStatus === ConnectionActive ||
    finchStatus === PendingInitialRosterApproval
  );
};

export const awaitingFinchSync = (state) => {
  const { finchStatus } = state.employer;
  return [
    PendingInitialRosterSync,
    ConnectionInitialized,
    ConnectionSyncError,
  ].includes(finchStatus);
};

export const finchHasSyncError = (state) => {
  const { finchStatus } = state.employer;

  return finchStatus === RosterSyncError;
};

export const awaitingInitialRosterProcessing = (state) => {
  const { finchStatus } = state.employer;
  return finchStatus === ProcessingInitialRosterApproval;
};

export const canReferCompany = (state) => {
  const enabledFeatures = get(
    state,
    "employer.company.enabledProductFeatures",
    []
  );

  const disabledReferrals = includes(enabledFeatures, disableReferralsId);

  return hasRunFirstPayroll(state) && !disabledReferrals;
};

export const hasRunFirstPayroll = (state) => {
  const hasRanPayroll = get(
    state,
    "employer.company.hasRunFirstPayroll",
    false
  );
  return hasRanPayroll;
};

export const userContributionAbilities = (state) => {
  return get(
    employerContributionAbility(state),
    "userContributionAbilities",
    []
  );
};

export const employerContributionAbility = (state) => {
  const userContributionAbilities = get(
    state,
    "employer.contributionAbility",
    {}
  );
  return userContributionAbilities;
};

export const requiresBillingMicroDepositVerification = (state) => {
  const setupIntent = get(state, "employer.setupIntent");
  const requiresMicroDepositVerification =
    !isEmpty(setupIntent) && setupIntent.status === "requires_action";

  return requiresMicroDepositVerification;
};

export const requiresBillingPaymentUpdate = (state) => {
  const setupIntent = get(state, "employer.setupIntent");
  if (isEmpty(setupIntent)) return false;
  const requiresPaymentMethod =
    setupIntent.status === "requires_payment_method" &&
    !isEmpty(setupIntent.last_setup_error);

  return requiresPaymentMethod;
};

export const requiresBillingDetails = (state) => {
  const billingStates = [
    EmployerBillingDetailsPending,
    EmployerSelfServeBillingDetailsPending,
    EmployerApplicationSubmittedKYB,
    EmployerBillingVerificationPending,
  ];

  const companyState = get(state.user, "companyState.state");
  return includes(billingStates, companyState);
};

export const requiresAdministrativeUpdates = (state) => {
  const companyState = get(state.user, "companyState.state");
  return companyState === EmployerRequiredUpdatesPending;
};

export const pendingEmployerRequiredUpdates = (state) => {
  return get(state, "employer.requiredUpdates.pendingSteps", []);
};

export const isIsolvedWholesale = (state) => {
  const billingType = get(state, "employer.company.billingType", "");
  return billingType === ISOLVED_WHOLESALE;
};

export const employerHasFullyKybd = (state) => {
  const companyState = get(state.user, "companyState.state");
  return (
    companyState === EmployerRequiredUpdatesPending ||
    companyState === EmployerApplicationApproved
  );
};

export const getLimitByType = (state, limitKey) => {
  const limit = find(get(state, "employer.company.limits"), { key: limitKey });
  return get(limit, "value");
};

export const hasAutoApprovalLinkRequestsFeature = (state) => {
  const enabledFeatures = get(
    state,
    "employer.company.enabledProductFeatures",
    []
  );
  return includes(enabledFeatures, autoApproveJoinRequestsId);
};

export const getPayrollIntegrationStatus = (state) => {
  return get(state, "employer.payrollIntegrationStatus");
};

export const hasSuccessfullyConnectedPayroll = (state) => {
  const payrollIntegrationStatus = get(
    state,
    "employer.payrollIntegrationStatus"
  );
  return (
    payrollIntegrationStatus && payrollIntegrationStatus === ConnectionActive
  );
};
