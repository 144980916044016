import React from "react";
import CompanyProfile from "./employerDashboard/profile/CompanyProfile";
import IndividualProfile from "./individualDashboard/profile/IndividualProfile";
import UserManagement from "./employerDashboard/userManagement/UserManagement";
import ProcessContributions from "./employerDashboard/processContributions/ProcessContributions";
import ProcessOneTimeContributions from "./employerDashboard/processContributions/ProcessOneTimeContributions";
import PayrollWorkflow from "./employerDashboard/processContributions/payrollWorkflow/PayrollWorkflow";
import ApproveOneTimePayrollWorkflow from "./employerDashboard/processContributions/ApproveOneTimePayrollWorkflow";
import EmployeeLinkRequestWorkflow from "pages/dashboards/employerDashboard/profile/employeeLinkRequestWorkflow/EmployeeLinkRequestWorkflow";

import IndividualDashboard from "./individualDashboard/dashboard/IndividualDashboard";
import IndividualStatements from "./individualDashboard/documents/IndividualStatements";
import IndividualContributions from "./individualDashboard/contributions/IndividualContributions";
import Beneficiaries from "./individualDashboard/beneficiaries/Beneficiaries";
import Transactions from "./individualDashboard/transactions/Transactions";
import AdminSignup from "pages/signUp/iconAdmin/adminSignup";
import Employers from "./adminDashboard/employers/Employers";
import Employer from "./adminDashboard/employers/Employer";
import AdminTransactions from "./adminDashboard/transactions/AdminTransactions";
import AdminReports from "pages/dashboards/adminDashboard/reports/AdminReports";
import CustomReportForm from "pages/dashboards/adminDashboard/reports/CustomReportForm";
import Approval from "pages/admin/Approval";
import ApprovalTable from "pages/admin/ApprovalTable";
import PayrollHistory from "pages/dashboards/employerDashboard/processContributions/PayrollHistory";
import ZendeskSupportPage from "pages/dashboards/individualDashboard/support/ZendeskSupportPage";
import Originations from "./adminDashboard/originations/Originations";
import Origination from "./adminDashboard/originations/Origination";
import Users from "./adminDashboard/users/Users";
import User from "./adminDashboard/users/User";
import ReferralForm from "../../components/ReferralForm";
import SystemLimits from "./adminDashboard/SystemLimits";

import * as FiIcons from "react-icons/fi";
import DashboardContainer from "pages/dashboards/employerDashboard/dashboard/DashboardContainer";
import {
  employerAdminRole,
  regularAdminRole,
  superAdminRole,
} from "store/selectors/admin";
import { some } from "lodash";
import CustomReports from "pages/dashboards/adminDashboard/reports/CustomReports";
import EmployerDocuments from "./employerDashboard/documents/EmployerDocuments";
import Workflows from "pages/dashboards/adminDashboard/workflows/Workflows";

export const employerRoutes = [
  {
    path: "/dashboard",
    layout: "/employer",
    name: "Dashboard",
    icon: <FiIcons.FiHome stroke="#FFFFFF" ill="#30374e" color="#30374e" />,
    mini: "D",
    component: DashboardContainer,
    hiddenChildRoutes: true,
    views: [
      {
        path: "/dashboard",
        component: DashboardContainer,
        layout: "/employer",
        name: "Dashboard",
      },
      {
        path: "/dashboard/referral",
        component: ReferralForm,
        layout: "/employer",
        hidden: true,
      },
    ],
  },
  {
    path: "/dashboard/company",
    layout: "/employer",
    collapse: true,
    name: "Company",
    icon: <FiIcons.FiBookOpen />,
    mini: "P",
    state: "openCompProfile",
    component: CompanyProfile,
    views: [
      {
        path: "/dashboard/company/administrators",
        layout: "/employer",
        name: "Administrators",
        component: CompanyProfile,
      },
      {
        path: "/dashboard/company/bank",
        layout: "/employer",
        component: CompanyProfile,
        name: "Bank Info",
      },
      {
        path: "/dashboard/company/group",
        layout: "/employer",
        component: CompanyProfile,
        name: "Group Settings",
      },
      {
        path: "/dashboard/company/group/:id/review-employees",
        layout: "/employer",
        component: EmployeeLinkRequestWorkflow,
        hidden: true,
      },
    ],
  },
  {
    path: "/dashboard/users/employees",
    layout: "/employer",
    name: "Employees",
    icon: <FiIcons.FiUsers />,
    mini: "UM",
    collapse: true,
    component: UserManagement,
    views: [
      {
        path: "/dashboard/users/employees",
        component: UserManagement,
        layout: "/employer",
        name: "Employee Invitations",
      },
      {
        path: "/dashboard/users/employees/upload",
        component: UserManagement,
        layout: "/employer",
        name: "Upload Roster",
      },
      {
        path: "/dashboard/users/employees/upload-single",
        component: UserManagement,
        layout: "/employer",
        hidden: true,
        name: "Upload Single",
      },
    ],
  },
  {
    layout: "/employer",
    path: "/dashboard/contributions",
    name: "Contributions",
    icon: <FiIcons.FiRepeat />,
    mini: "PC",
    collapse: true,
    component: ProcessContributions,
    state: "openProcessContributions",
    views: [
      {
        path: "/dashboard/contributions",
        component: ProcessContributions,
        layout: "/employer",
        name: "Payrolls",
      },
      {
        path: "/dashboard/contributions/one-time",
        component: ProcessOneTimeContributions,
        layout: "/employer",
        name: "Process One-Time",
      },
      {
        path: "/dashboard/contributions/history",
        component: PayrollHistory,
        layout: "/employer",
        name: "History",
      },
      {
        path: "/dashboard/contributions/:id/approval",
        component: PayrollWorkflow,
        layout: "/employer",
        hidden: true,
      },
      {
        path: "/dashboard/contributions/:id/one-time",
        component: ApproveOneTimePayrollWorkflow,
        layout: "/employer",
        hidden: true,
      },
    ],
  },
  {
    path: "/dashboard/documents",
    name: "Documents",
    icon: <FiIcons.FiFileText />,
    hidden: true,
    mini: "S",
    layout: "/employer",
    component: EmployerDocuments,
  },
];

export const individualRoutes = [
  {
    path: "/dashboard",
    layout: "/individual",
    name: "Dashboard",
    component: IndividualDashboard,
  },
  {
    path: "/dashboard/contributions",
    layout: "/individual",
    name: "Contributions",
    component: IndividualContributions,
  },
  {
    path: "/dashboard/transactions",
    layout: "/individual",
    name: "Transactions",
    component: Transactions,
  },
  {
    path: "/dashboard/documents",
    name: "Documents",
    hidden: true,
    layout: "/individual",
    component: IndividualStatements,
  },

  {
    path: "/dashboard/beneficiaries",
    layout: "/individual",
    name: "Beneficiaries",
    component: Beneficiaries,
  },
  {
    path: "/dashboard/profile",
    collapse: true,
    layout: "/individual",
    name: "Profile",
    state: "openProfile",
    mini: "P",
    hidden: true,
    component: IndividualProfile,
    views: [
      {
        path: "/dashboard/profile/info",
        component: IndividualProfile,
        layout: "/individual",
        name: "User Info",
      },
      {
        path: "/dashboard/profile/investment-profile",
        component: IndividualProfile,
        layout: "/individual",
        name: "Investment Profile",
      },
      {
        path: "/dashboard/profile/login-info",
        component: IndividualProfile,
        layout: "/individual",
        name: "Login Info",
      },
      {
        path: "/dashboard/profile/close-account",
        component: IndividualProfile,
        layout: "/individual",
        name: "Close Account",
      },
    ],
  },
  {
    path: "/dashboard/support",
    name: "Support",
    mini: "S",
    layout: "/individual",
    hidden: true,
    component: ZendeskSupportPage,
  },
];

export function getResponsiveSideBarRoutes() {
  const accessibleRoutes = [
    "/dashboard",
    "/dashboard/contributions",
    "/dashboard/transactions",
    "/dashboard/beneficiaries",
    "/dashboard/documents",
    "/dashboard/profile",
    "/dashboard/support",
  ];
  return individualRoutes
    .filter((route) => {
      return accessibleRoutes.includes(route.path);
    })
    .map((route) => {
      return {
        ...route,
        hidden: false,
      };
    });
}

export function getAccountClosedRoutes() {
  // filter routes that are available to closed account user
  const accessibleRoutes = ["/dashboard", "/dashboard/documents"];
  return individualRoutes
    .filter((route) => {
      return accessibleRoutes.includes(route.path);
    })
    .map((route) => {
      return {
        ...route,
        hidden: false,
      };
    });
}

export function getOnboardingEmployerRoutes() {
  // filter routes that are available to closed account user
  const accessibleRoutes = ["/dashboard"];
  return employerRoutes.filter((route) => {
    return accessibleRoutes.includes(route.path);
  });
}

export function getEmployerRoutes({
  hasRanFirstPayroll,
  hasStatePlan,
  isAdministrator,
  hasPayrollIntegration,
  hasVirtualBank,
}) {
  let inaccessibleViews = [];
  if (!hasRanFirstPayroll) {
    inaccessibleViews.push("/dashboard/contributions/one-time");
  }

  if (!hasStatePlan || hasPayrollIntegration) {
    inaccessibleViews.push("/dashboard/users/employees/upload");
  }

  if (isAdministrator) {
    inaccessibleViews.push("/dashboard/company/administrators");
  }

  if (hasVirtualBank) {
    inaccessibleViews.push("/dashboard/company/bank");
  }

  return employerRoutes.map((route) => {
    if (!route.views) {
      return route;
    }
    const views = route.views.filter(
      (view) => !inaccessibleViews.includes(view.path)
    );
    return { ...route, views };
  });
}

export function getLockedUserRoutes() {
  const accessibleRoutes = ["/dashboard"];
  return individualRoutes.filter((route) =>
    accessibleRoutes.includes(route.path)
  );
}

export const adminRoutes = [
  {
    path: "/",
    layout: "/admin",
    name: "Admin Management",
    icon: <FiIcons.FiBookOpen />,
    mini: "A",
    component: AdminSignup,
  },
  {
    path: "/limits",
    layout: "/admin",
    name: "Limits",
    roleRestrictions: [superAdminRole],
    icon: <FiIcons.FiList />,
    mini: "R",
    component: SystemLimits,
  },
  {
    path: "/transactions",
    layout: "/admin",
    name: "Transactions",
    roleRestrictions: [superAdminRole, regularAdminRole, employerAdminRole],
    icon: <FiIcons.FiList />,
    mini: "T",
    component: AdminTransactions,
    hiddenChildRoutes: true,
    views: [
      {
        path: "/transactions",
        component: AdminTransactions,
        layout: "/admin",
      },
      {
        path: "/transactions/payrolls",
        component: AdminTransactions,
        layout: "/admin",
      },
      {
        path: "/transactions/missed_payrolls",
        component: AdminTransactions,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/employers",
    layout: "/admin",
    name: "Employers",
    roleRestrictions: [superAdminRole, regularAdminRole, employerAdminRole],
    icon: <FiIcons.FiBriefcase />,
    mini: "A",
    component: Employers,
    hiddenChildRoutes: true,
    views: [
      {
        path: "/employers/:id",
        component: Employer,
        layout: "/admin",
      },
      {
        path: "/employers/:id/info",
        component: Employer,
        layout: "/admin",
      },
      {
        path: "/employers/:id/documents",
        component: Employer,
        layout: "/admin",
      },
      {
        path: "/employers/:id/kyb/info",
        component: Employer,
        layout: "/admin",
      },
      {
        path: "/employers/:id/kyb/owners",
        component: Employer,
        layout: "/admin",
      },
      {
        path: "/employers/:id/kyb/summary",
        component: Employer,
        layout: "/admin",
      },
      {
        path: "/employers",
        component: Employers,
        layout: "/admin",
      },
      {
        path: "/employers/:id/groups",
        component: Employer,
        layout: "/admin",
      },
      {
        path: "/employers/:id/payrolls",
        component: Employer,
        layout: "/admin",
      },
      {
        path: "/employers/:id/missed_payrolls",
        component: Employer,
        layout: "/admin",
      },
      {
        path: "/employers/:id/roster",
        component: Employer,
        layout: "/admin",
      },
      {
        path: "/employers/:id/billing",
        component: Employer,
        layout: "/admin",
      },
      {
        path: "/employers/:id/organization",
        component: Employer,
        layout: "/admin",
      },
      {
        path: "/employers/:id/limits",
        component: Employer,
        layout: "/admin",
      },
      {
        path: "/employers/:id/crm",
        component: Employer,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/users",
    layout: "/admin",
    name: "Users",
    icon: <FiIcons.FiUsers />,
    roleRestrictions: [superAdminRole, regularAdminRole],
    mini: "A",
    component: Users,
    hiddenChildRoutes: true,
    views: [
      {
        path: "/users/:id",
        component: User,
        layout: "/admin",
      },
      {
        path: "/users/:id/info",
        component: User,
        layout: "/admin",
      },
      {
        path: "/users/:id/transactions",
        component: User,
        layout: "/admin",
      },
      {
        path: "/users/:id/employers",
        component: User,
        layout: "/admin",
      },
      {
        path: "/users/:id/accounts",
        component: User,
        layout: "/admin",
      },
      {
        path: "/users/:id/contribution_ability",
        component: User,
        layout: "/admin",
      },
      {
        path: "/users/:id/limits",
        component: User,
        layout: "/admin",
      },
      {
        path: "/users",
        component: Users,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/reports",
    layout: "/admin",
    name: "Reports",
    roleRestrictions: [superAdminRole],
    icon: <FiIcons.FiFolder />,
    mini: "R",
    collapse: true,

    component: AdminReports,
    views: [
      {
        path: "/dashboard/reports/compliance",
        component: AdminReports,
        layout: "/admin",
        name: "Compliance Reports",
      },
      {
        path: "/dashboard/reports/custom",
        component: CustomReports,
        layout: "/admin",
        name: "Custom Reports",
      },
      {
        path: "/dashboard/reports/custom/new",
        component: CustomReportForm,
        layout: "/admin",
        hidden: true,
        name: "Create Custom Report",
      },
    ],
  },
  {
    path: "/originations",
    layout: "/admin",
    name: "Originations",
    component: Originations,
    roleRestrictions: [superAdminRole, regularAdminRole],
    hiddenChildRoutes: true,
    icon: <FiIcons.FiList />,
    mini: "O",
    views: [
      {
        path: "/originations/:id",
        component: Origination,
        layout: "/admin",
      },
      {
        path: "/originations",
        component: Originations,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/workflows",
    layout: "/admin",
    name: "Workflows",
    component: Workflows,
    roleRestrictions: [superAdminRole, regularAdminRole],
    hiddenChildRoutes: true,
    icon: <FiIcons.FiList />,
    mini: "O",
    views: [
      {
        path: "/workflows",
        component: Workflows,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/approval",
    layout: "/admin",
    name: "Approvals",
    icon: <FiIcons.FiThumbsUp />,
    roleRestrictions: [superAdminRole, regularAdminRole],
    mini: "A",
    component: ApprovalTable,
    hiddenChildRoutes: true,
    views: [
      {
        path: "/approval",
        component: ApprovalTable,
        layout: "/admin",
        name: "Pending Approvals",
      },
      {
        path: "/approval/:type/:id",
        component: Approval,
        layout: "/admin",
      },
    ],
  },
];

export const filterAdminRoutesToRole = (roles) => {
  return adminRoutes.filter((route) => {
    if (route.roleRestrictions) {
      return some(roles, (role) => route.roleRestrictions.includes(role));
    }
    return true;
  });
};

export default {
  employerRoutes,
  individualRoutes,
  adminRoutes,
};
