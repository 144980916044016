import React, { useState } from "react";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import PropTypes from "prop-types";

import Button from "components/Button";

const StripeForm = ({ onSuccess }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);
  const [submittingPayment, setSubmittingPayment] = useState(false);

  const handleSubmit = async (event) => {
    setSubmittingPayment(true);
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const { error, setupIntent } = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      redirect: "if_required",
      confirmParams: {
        return_url: window.location.href,
      },
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
    } else {
      onSuccess(setupIntent);
    }

    setSubmittingPayment(false);
  };
  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />

      {/* Show error message to your customers */}
      {errorMessage && <div>{errorMessage}</div>}
      <Button
        name="submit"
        loading={submittingPayment}
        withArrow
        btnLabel={"Submit"}
      />
    </form>
  );
};

StripeForm.propTypes = {
  onSuccess: PropTypes.func,
};

export default StripeForm;
