export const ControllingOfficer = "ControllingOfficer";
export const BeneficialOwner = "BeneficialOwner";
export const ControllingOfficerAndBeneficialOwner =
  "ControllingOfficerAndBeneficialOwner";

export const companySizeValues = [
  { value: "1-10", description: "1-10 employees", min: 1, max: 10, key: "1" },
  {
    value: "11-50",
    description: "11-50 employees",
    min: 11,
    max: 50,
    key: "2",
  },
  {
    value: "51-200",
    description: "51-200 employees",
    min: 51,
    max: 200,
    key: "3",
  },
  {
    value: "201-500",
    description: "201-500 employees",
    min: 201,
    max: 500,
    key: "4",
  },
  {
    value: "501-1000",
    description: "501-1,000 employees",
    min: 501,
    max: 1000,
    key: "5",
  },
  {
    value: "1001-5000",
    description: "1,001-5,000 employees",
    min: 1001,
    max: 5000,
    key: "6",
  },
  {
    value: "5001-10000",
    description: "5,001-10,000 employees",
    min: 5001,
    max: 10000,
    key: "7",
  },
  {
    value: "10001+",
    description: "10,001+ employees",
    min: 10001,
    max: 50000,
    key: "8",
  },
];

export const kybRoles = [
  { value: ControllingOfficer, description: "Controller / Officer" },
  { value: BeneficialOwner, description: "Beneficial Owner >= 25%" },
  {
    value: ControllingOfficerAndBeneficialOwner,
    description: "Controller / Officer & Beneficial Owner >= 25%",
  },
];

export const naicsCategories = [
  {
    value: "Accommodation and Food Services",
    description: "Accommodation and Food Services",
  },
  {
    value: "Administrative and Support and Waste Management and Remediation",
    description:
      "Administrative and Support and Waste Management and Remediation",
  },
  {
    value: "Agriculture, Forestry, Fishing and Hunting",
    description: "Agriculture, Forestry, Fishing and Hunting",
  },
  {
    value: "Arts, Entertainment, and Recreation",
    description: "Arts, Entertainment, and Recreation",
  },
  {
    value: "Construction",
    description: "Construction",
  },
  {
    value: "Educational Services",
    description: "Educational Services",
  },
  {
    value: "Finance and Insurance",
    description: "Finance and Insurance",
  },
  {
    value: "Health Care and Social Assistance",
    description: "Health Care and Social Assistance",
  },
  {
    value: "Information",
    description: "Information",
  },
  {
    value: "Management of Companies and Enterprises",
    decription: "Management of Companies and Enterprises",
  },
  {
    value: "Manufacturing",
    description: "Manufacturing",
  },
  {
    value: "Mining, Quarrying, and Oil and Gas Extraction",
    description: "Mining, Quarrying, and Oil and Gas Extraction",
  },
  {
    value: "Other Services (except Public Administration)",
    description: "Other Services (except Public Administration)",
  },
  {
    value: "Professional, Scientific, and Technical Services",
    description: "Professional, Scientific, and Technical Services",
  },
  {
    value: "Public Administration",
    description: "Public Administration",
  },
  {
    value: "Real Estate and Rental and Leasing",
    description: "Real Estate and Rental and Leasing",
  },
  {
    value: "Retail Trade",
    description: "Retail Trade",
  },
  {
    value: "Transportation and Warehousing",
    description: "Transportation and Warehousing",
  },
  {
    value: "Wholesale Trade",
    description: "Wholesale Trade",
  },
];

export const naicsValues = [
  {
    value: "721",
    description: "Accommodation",
    category: "Accommodation and Food Services",
  },

  {
    value: "722",
    description: "Food Services and Drinking Places",
    category: "Accommodation and Food Services",
  },

  {
    value: "561",
    description: "Administrative and Support Services",
    category: "Administrative and Support and Waste Management and Remediation",
  },

  {
    value: "562",
    description: "Waste Management and Remediation Services",
    category: "Administrative and Support and Waste Management and Remediation",
  },

  {
    value: "111",
    description: "Crop Production",
    category: "Agriculture, Forestry, Fishing and Hunting",
  },

  {
    value: "112",
    description: "Animal Production",
    category: "Agriculture, Forestry, Fishing and Hunting",
  },

  {
    value: "113",
    description: "Forestry and Logging",
    category: "Agriculture, Forestry, Fishing and Hunting",
  },

  {
    value: "114",
    description: "Fishing, Hunting and Trapping",
    category: "Agriculture, Forestry, Fishing and Hunting",
  },

  {
    value: "115",
    description: "Support Activities for Agriculture and Forestry",
    category: "Agriculture, Forestry, Fishing and Hunting",
  },

  {
    value: "711",
    description: "Performing Arts, Spectator Sports, and Related Industries",
    category: "Arts, Entertainment, and Recreation",
  },

  {
    value: "712",
    description: "Museums, Historical Sites, and Similar Institutions",
    category: "Arts, Entertainment, and Recreation",
  },

  {
    value: "713",
    description: "Amusement, Gambling, and Recreation Industries",
    category: "Arts, Entertainment, and Recreation",
  },

  {
    value: "7131",
    description: "Amusement Parks and Arcades",
    category: "Arts, Entertainment, and Recreation",
  },

  {
    value: "7132",
    description: "Gambling Industries",
    category: "Arts, Entertainment, and Recreation",
  },

  {
    value: "7139",
    description: "Other Amusement and Recreation Industries",
    category: "Arts, Entertainment, and Recreation",
  },

  {
    value: "236",
    description: "Construction of Buildings",
    category: "Construction",
  },

  {
    value: "237",
    description: "Heavy and Civil Engineering Construction",
    category: "Construction",
  },

  {
    value: "238",
    description: "Specialty Trade Contractors",
    category: "Construction",
  },

  {
    value: "611",
    description: "Educational Services",
    category: "Educational Services",
  },

  {
    value: "521",
    description: "Monetary Authorities-Central Bank",
    category: "Finance and Insurance",
  },

  {
    value: "522",
    description: "Credit Intermediation and Related Activities",
    category: "Finance and Insurance",
  },

  {
    value: "523",
    description:
      "Securities, Commodity Contracts, and Other Financial Investments and Related Activities",
    category: "Finance and Insurance",
  },

  {
    value: "524",
    description: "Insurance Carriers and Related Activities",
    category: "Finance and Insurance",
  },

  {
    value: "525",
    description: "Funds, Trusts, and Other Financial Vehicles",
    category: "Finance and Insurance",
  },

  {
    value: "5221",
    description: "Depository Credit Intermediation",
    category: "Finance and Insurance",
  },

  {
    value: "5223",
    description: "Activities Related to Credit Intermediation",
    category: "Finance and Insurance",
  },

  {
    value: "5231",
    description:
      "Securities and Commodity Contracts Intermediation and Brokerage",
    category: "Finance and Insurance",
  },

  {
    value: "5232",
    description: "Securities and Commodity Exchanges",
    category: "Finance and Insurance",
  },

  {
    value: "5239",
    description: "Other Financial Investment Activities",
    category: "Finance and Insurance",
  },

  {
    value: "5241",
    description: "Insurance Carriers",
    category: "Finance and Insurance",
  },

  {
    value: "5242",
    description: "Agencies, Brokerages, and Other Insurance Related Activities",
    category: "Finance and Insurance",
  },

  {
    value: "5251",
    description: "Insurance and Employee Benefit Funds",
    category: "Finance and Insurance",
  },

  {
    value: "5259",
    description: "Other Investment Pools and Funds",
    category: "Finance and Insurance",
  },

  {
    value: "52231",
    description: "Mortgage and nonmortgage loan brokers",
    category: "Finance and Insurance",
  },

  {
    value: "621",
    description: "Ambulatory Health Care Services",
    category: "Health Care and Social Assistance",
  },

  {
    value: "622",
    description: "Hospitals",
    category: "Health Care and Social Assistance",
  },

  {
    value: "623",
    description: "Nursing and Residential Care Facilities",
    category: "Health Care and Social Assistance",
  },

  {
    value: "624",
    description: "Social Assistance",
    category: "Health Care and Social Assistance",
  },

  {
    value: "6211",
    description: "Offices of Physicians",
    category: "Health Care and Social Assistance",
  },

  {
    value: "6212",
    description: "Offices of Dentists",
    category: "Health Care and Social Assistance",
  },

  {
    value: "6213",
    description: "Offices of Other Health Practitioners",
    category: "Health Care and Social Assistance",
  },

  {
    value: "6214",
    description: "Outpatient Care Centers",
    category: "Health Care and Social Assistance",
  },

  {
    value: "6215",
    description: "Medical and Diagnostic Laboratories",
    category: "Health Care and Social Assistance",
  },

  {
    value: "6216",
    description: "Home Health Care Services",
    category: "Health Care and Social Assistance",
  },

  {
    value: "6219",
    description: "Other Ambulatory Health Care Services",
    category: "Health Care and Social Assistance",
  },

  {
    value: "6221",
    description: "General Medical and Surgical Hospitals",
    category: "Health Care and Social Assistance",
  },

  {
    value: "6222",
    description: "Psychiatric and Substance Abuse Hospitals",
    category: "Health Care and Social Assistance",
  },

  {
    value: "6223",
    description: "Specialty (except Psychiatric and Substance Abuse) Hospitals",
    category: "Health Care and Social Assistance",
  },

  {
    value: "6231",
    description: "Nursing Care Facilities",
    category: "Health Care and Social Assistance",
  },

  {
    value: "6232",
    description: "Residential Mental Health and Substance Abuse Facilities",
    category: "Health Care and Social Assistance",
  },

  {
    value: "6233",
    description: "Community Care Facilities for the Elderly",
    category: "Health Care and Social Assistance",
  },

  {
    value: "6239",
    description: "Other Residential Care Facilities",
    category: "Health Care and Social Assistance",
  },

  {
    value: "511",
    description: "Publishing Industries (except Internet)",
    category: "Information",
  },

  {
    value: "512",
    description: "Motion Picture and Sound Recording Industries",
    category: "Information",
  },

  {
    value: "515",
    description: "Broadcasting (except Internet)",
    category: "Information",
  },

  { value: "517", description: "Telecommunications", category: "Information" },

  {
    value: "518",
    description: "Data Processing, Hosting and Related Services",
    category: "Information",
  },

  {
    value: "519",
    description: "Other Information Services",
    category: "Information",
  },

  {
    value: "5171",
    description: "Wired Telecommunications Carriers",
    category: "Information",
  },

  {
    value: "5172",
    description: "Wireless Telecommunications Carriers (except Satellite)",
    category: "Information",
  },

  {
    value: "5174",
    description: "Satellite Telecommunications",
    category: "Information",
  },

  {
    value: "5179",
    description: "Other Telecommunications",
    category: "Information",
  },

  {
    value: "551",
    description: "Management of Companies and Enterprises",
    category: "Management of Companies and Enterprises",
  },

  {
    value: "311",
    description: "Food Manufacturing",
    category: "Manufacturing",
  },

  {
    value: "312",
    description: "Beverage and Tobacco Product Manufacturing",
    category: "Manufacturing",
  },

  { value: "313", description: "Textile Mills", category: "Manufacturing" },

  {
    value: "314",
    description: "Textile Product Mills",
    category: "Manufacturing",
  },

  {
    value: "315",
    description: "Apparel Manufacturing",
    category: "Manufacturing",
  },

  {
    value: "316",
    description: "Leather and Allied Product Manufacturing",
    category: "Manufacturing",
  },

  {
    value: "321",
    description: "Wood Product Manufacturing",
    category: "Manufacturing",
  },

  {
    value: "322",
    description: "Paper Manufacturing",
    category: "Manufacturing",
  },

  {
    value: "323",
    description: "Printing and Related Support Activities",
    category: "Manufacturing",
  },

  {
    value: "324",
    description: "Petroleum and Coal Products Manufacturing",
    category: "Manufacturing",
  },

  {
    value: "325",
    description: "Chemical Manufacturing",
    category: "Manufacturing",
  },

  {
    value: "326",
    description: "Plastics and Rubber Products Manufacturing",
    category: "Manufacturing",
  },

  {
    value: "327",
    description: "Nonmetallic Mineral Product Manufacturing",
    category: "Manufacturing",
  },

  {
    value: "331",
    description: "Primary Metal Manufacturing",
    category: "Manufacturing",
  },

  {
    value: "332",
    description: "Fabricated Metal Product Manufacturing",
    category: "Manufacturing",
  },

  {
    value: "333",
    description: "Machinery Manufacturing",
    category: "Manufacturing",
  },

  {
    value: "334",
    description: "Computer and Electronic Product Manufacturing",
    category: "Manufacturing",
  },

  {
    value: "335",
    description: "Electrical Equipment, Appliance, and Component Manufacturing",
    category: "Manufacturing",
  },

  {
    value: "336",
    description: "Transportation Equipment Manufacturing",
    category: "Manufacturing",
  },

  {
    value: "337",
    description: "Furniture and Related Product Manufacturing",
    category: "Manufacturing",
  },

  {
    value: "339",
    description: "Miscellaneous Manufacturing",
    category: "Manufacturing",
  },

  {
    value: "211",
    description: "Oil and Gas Extraction",
    category: "Mining, Quarrying, and Oil and Gas Extraction",
  },

  {
    value: "212",
    description: "Mining (except Oil and Gas)",
    category: "Mining, Quarrying, and Oil and Gas Extraction",
  },

  {
    value: "213",
    description: "Support Activities for Mining",
    category: "Mining, Quarrying, and Oil and Gas Extraction",
  },

  {
    value: "811",
    description: "Repair and Maintenance",
    category: "Other Services (except Public Administration)",
  },

  {
    value: "812",
    description: "Personal and Laundry Services",
    category: "Other Services (except Public Administration)",
  },

  {
    value: "813",
    description:
      "Religious, Grantmaking, Civic, Professional, and Similar Organizations",
    category: "Other Services (except Public Administration)",
  },

  {
    value: "814",
    description: "Private Households",
    category: "Other Services (except Public Administration)",
  },

  {
    value: "8131",
    description: "Religious Organizations",
    category: "Other Services (except Public Administration)",
  },

  {
    value: "8132",
    description: "Grantmaking and Giving Services",
    category: "Other Services (except Public Administration)",
  },

  {
    value: "8133",
    description: "Social Advocacy Organizations",
    category: "Other Services (except Public Administration)",
  },

  {
    value: "8134",
    description: "Civic and Social Organizations",
    category: "Other Services (except Public Administration)",
  },

  {
    value: "8139",
    description:
      "Business, Professional, Labor, Political, and Similar Organizations",
    category: "Other Services (except Public Administration)",
  },

  {
    value: "541",
    description: "Professional, Scientific, and Technical Services",
    category: "Professional, Scientific, and Technical Services",
  },

  {
    value: "5411",
    description: "Legal Services",
    category: "Professional, Scientific, and Technical Services",
  },

  {
    value: "5412",
    description:
      "Accounting, Tax Preparation, Bookkeeping, and Payroll Services",
    category: "Professional, Scientific, and Technical Services",
  },

  {
    value: "5413",
    description: "Architectural, Engineering, and Related Services",
    category: "Professional, Scientific, and Technical Services",
  },

  {
    value: "5414",
    description: "Specialized Design Services",
    category: "Professional, Scientific, and Technical Services",
  },

  {
    value: "5415",
    description: "Computer Systems Design and Related Services",
    category: "Professional, Scientific, and Technical Services",
  },

  {
    value: "5416",
    description: "Management, Scientific, and Technical Consulting Services",
    category: "Professional, Scientific, and Technical Services",
  },

  {
    value: "5417",
    description: "Scientific Research and Development Services",
    category: "Professional, Scientific, and Technical Services",
  },

  {
    value: "5418",
    description: "Advertising, Public Relations, and Related Services",
    category: "Professional, Scientific, and Technical Services",
  },

  {
    value: "5419",
    description: "Other Professional, Scientific, and Technical Services",
    category: "Professional, Scientific, and Technical Services",
  },

  {
    value: "921",
    description: "Executive, Legislative, and Other General Government Support",
    category: "Public Administration",
  },

  {
    value: "922",
    description: "Justice, Public Order, and Safety Activities",
    category: "Public Administration",
  },

  {
    value: "923",
    description: "Administration of Human Resource Programs",
    category: "Public Administration",
  },

  {
    value: "924",
    description: "Administration of Environmental Quality Programs",
    category: "Public Administration",
  },

  {
    value: "925",
    description:
      "Administration of Housing Programs, Urban Planning, and Community Development",
    category: "Public Administration",
  },

  {
    value: "926",
    description: "Administration of Economic Programs",
    category: "Public Administration",
  },

  {
    value: "927",
    description: "Space Research and Technology",
    category: "Public Administration",
  },

  {
    value: "928",
    description: "National Security and International Affairs",
    category: "Public Administration",
  },

  {
    value: "531",
    description: "Real Estate",
    category: "Real Estate and Rental and Leasing",
  },

  {
    value: "532",
    description: "Rental and Leasing Services",
    category: "Real Estate and Rental and Leasing",
  },

  {
    value: "533",
    description:
      "Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)",
    category: "Real Estate and Rental and Leasing",
  },

  {
    value: "5311",
    description: "Lessors of Real Estate",
    category: "Real Estate and Rental and Leasing",
  },

  {
    value: "5312",
    description: "Offices of Real Estate Agents and Brokers",
    category: "Real Estate and Rental and Leasing",
  },

  {
    value: "5313",
    description: "Activities Related to Real Estate",
    category: "Real Estate and Rental and Leasing",
  },

  {
    value: "5321",
    description: "Automotive Equipment Rental and Leasing",
    category: "Real Estate and Rental and Leasing",
  },

  {
    value: "5322",
    description: "Consumer Goods Rental",
    category: "Real Estate and Rental and Leasing",
  },

  {
    value: "5323",
    description: "General Rental Centers",
    category: "Real Estate and Rental and Leasing",
  },

  {
    value: "5324",
    description:
      "Commercial and Industrial Machinery and Equipment Rental and Leasing",
    category: "Real Estate and Rental and Leasing",
  },

  {
    value: "441",
    description: "Motor Vehicle and Parts Dealers",
    category: "Retail Trade",
  },

  {
    value: "442",
    description: "Furniture and Home Furnishings Stores",
    category: "Retail Trade",
  },

  {
    value: "443",
    description: "Electronics and Appliance Stores",
    category: "Retail Trade",
  },

  {
    value: "444",
    description: "Building Material and Garden Equipment and Supplies Dealers",
    category: "Retail Trade",
  },

  {
    value: "445",
    description: "Food and Beverage Stores",
    category: "Retail Trade",
  },

  {
    value: "446",
    description: "Health and Personal Care Stores",
    category: "Retail Trade",
  },

  { value: "447", description: "Gasoline Stations", category: "Retail Trade" },

  {
    value: "448",
    description: "Clothing and Clothing Accessories Stores",
    category: "Retail Trade",
  },

  {
    value: "451",
    description: "Sporting Goods, Hobby, Book, and Music Stores",
    category: "Retail Trade",
  },

  {
    value: "452",
    description: "General Merchandise Stores",
    category: "Retail Trade",
  },

  {
    value: "453",
    description: "Miscellaneous Store Retailers",
    category: "Retail Trade",
  },

  { value: "454", description: "Nonstore Retailers", category: "Retail Trade" },

  {
    value: "4411",
    description: "Automobile Dealers",
    category: "Retail Trade",
  },

  {
    value: "4412",
    description: "Other Motor Vehicle Dealers",
    category: "Retail Trade",
  },

  {
    value: "4413",
    description: "Automotive Parts, Accessories, and Tire Stores",
    category: "Retail Trade",
  },

  { value: "4481", description: "Clothing Stores", category: "Retail Trade" },

  { value: "4482", description: "Shoe Stores", category: "Retail Trade" },

  {
    value: "4483",
    description: "Jewelry, Luggage, and Leather Goods Stores",
    category: "Retail Trade",
  },

  {
    value: "4541",
    description: "Electronic Shopping and Mail-Order Houses",
    category: "Retail Trade",
  },

  {
    value: "4542",
    description: "Vending Machine Operators",
    category: "Retail Trade",
  },

  {
    value: "4543",
    description: "Direct Selling Establishments",
    category: "Retail Trade",
  },

  {
    value: "481",
    description: "Air Transportation",
    category: "Transportation and Warehousing",
  },

  {
    value: "482",
    description: "Rail Transportation",
    category: "Transportation and Warehousing",
  },

  {
    value: "483",
    description: "Water Transportation",
    category: "Transportation and Warehousing",
  },

  {
    value: "484",
    description: "Truck Transportation",
    category: "Transportation and Warehousing",
  },

  {
    value: "485",
    description: "Transit and Ground Passenger Transportation",
    category: "Transportation and Warehousing",
  },

  {
    value: "486",
    description: "Pipeline Transportation",
    category: "Transportation and Warehousing",
  },

  {
    value: "487",
    description: "Scenic and Sightseeing Transportation",
    category: "Transportation and Warehousing",
  },

  {
    value: "488",
    description: "Support Activities for Transportation",
    category: "Transportation and Warehousing",
  },

  {
    value: "491",
    description: "Postal Service",
    category: "Transportation and Warehousing",
  },

  {
    value: "492",
    description: "Couriers and Messengers",
    category: "Transportation and Warehousing",
  },

  {
    value: "493",
    description: "Warehousing and Storage",
    category: "Transportation and Warehousing",
  },

  {
    value: "4861",
    description: "Pipeline Transportation of Crude Oil",
    category: "Transportation and Warehousing",
  },

  {
    value: "4862",
    description: "Pipeline Transportation of Natural Gas",
    category: "Transportation and Warehousing",
  },

  {
    value: "4869",
    description: "Other Pipeline Transportation",
    category: "Transportation and Warehousing",
  },

  {
    value: "4921",
    description: "Couriers and Express Delivery Services",
    category: "Transportation and Warehousing",
  },

  {
    value: "4922",
    description: "Local Messengers and Local Delivery",
    category: "Transportation and Warehousing",
  },

  { value: "221", description: "Utilities", category: "Utilities" },

  {
    value: "423",
    description: "Merchant Wholesalers, Durable Goods",
    category: "Wholesale Trade",
  },

  {
    value: "424",
    description: "Merchant Wholesalers, Nondurable Goods",
    category: "Wholesale Trade",
  },

  {
    value: "425",
    description: "Wholesale Electronic Markets and Agents and Brokers",
    category: "Wholesale Trade",
  },

  {
    value: "4231",
    description:
      "Motor Vehicle and Motor Vehicle Parts and Supplies Merchant Wholesalers",
    category: "Wholesale Trade",
  },

  {
    value: "4232",
    description: "Furniture and Home Furnishing Merchant Wholesalers",
    category: "Wholesale Trade",
  },

  {
    value: "4233",
    description: "Lumber and Other Construction Materials Merchant Wholesalers",
    category: "Wholesale Trade",
  },

  {
    value: "4234",
    description:
      "Professional and Commercial Equipment and Supplies Merchant Wholesalers",
    category: "Wholesale Trade",
  },

  {
    value: "4235",
    description: "Metal and Mineral (except Petroleum) Merchant Wholesalers",
    category: "Wholesale Trade",
  },

  {
    value: "4236",
    description: "Electrical and Electronic Goods Merchant Wholesalers",
    category: "Wholesale Trade",
  },

  {
    value: "4237",
    description:
      "Hardware, and Plumbing and Heating Equipment and Supplies Merchant Wholesalers",
    category: "Wholesale Trade",
  },

  {
    value: "4238",
    description: "Machinery, Equipment, and Supplies Merchant Wholesalers",
    category: "Wholesale Trade",
  },

  {
    value: "4239",
    description: "Miscellaneous Durable Goods Merchant Wholesalers",
    category: "Wholesale Trade",
  },

  {
    value: "4241",
    description: "Paper and Paper Product Merchant Wholesalers",
    category: "Wholesale Trade",
  },

  {
    value: "4242",
    description: "Drugs and Druggists' Sundries Merchant Wholesalers",
    category: "Wholesale Trade",
  },

  {
    value: "4243",
    description: "Apparel, Piece Goods, and Notions Merchant Wholesalers",
    category: "Wholesale Trade",
  },

  {
    value: "4244",
    description: "Grocery and Related Product Merchant Wholesalers",
    category: "Wholesale Trade",
  },

  {
    value: "4245",
    description: "Farm Product Raw Material Merchant Wholesalers",
    category: "Wholesale Trade",
  },

  {
    value: "4246",
    description: "Chemical and Allied Products Merchant Wholesalers",
    category: "Wholesale Trade",
  },

  {
    value: "4247",
    description: "Petroleum and Petroleum Products Merchant Wholesalers",
    category: "Wholesale Trade",
  },

  {
    value: "4248",
    description:
      "Beer, Wine, and Distilled Alcoholic Beverage Merchant Wholesalers",
    category: "Wholesale Trade",
  },

  {
    value: "4249",
    description: "Miscellaneous Nondurable Goods Merchant Wholesalers",
    category: "Wholesale Trade",
  },
];

export const businessTypes = [
  { value: "corporation", description: "Corporation" },
  { value: "llc", description: "LLC" },
  { value: "llp", description: "LLP" },
  { value: "lp", description: "LP" },
  { value: "non_profit", description: "Non-Profit" },
  { value: "partnership", description: "Partnership" },
  { value: "public_corporation", description: "Public Corporation" },
  { value: "sole_proprietorship", description: "Sole Proprietorship" },
  { value: "trust", description: "Trust" },
  {
    value: "unincorporated_association",
    description: "Unincorporated Association",
  },
  { value: "S Corp", description: "S Corp" },
  { value: "C Corp", description: "C Corp" },
];

export const employeeRange = (val) => {
  if (val >= 10001) return "10001+";
  for (let i in companySizeValues) {
    if (companySizeValues[i].min <= val && companySizeValues[i].max >= val) {
      return companySizeValues[i].value;
    }
  }
};
