import React from "react";
import PropTypes from "prop-types";
import { withApollo } from "@apollo/client/react/hoc";
import moment from "moment";
import { each, groupBy, isEmpty, map, orderBy } from "lodash";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { Card, Col, Row, Tab, Tabs } from "react-bootstrap";
import { FiDownload, FiEye, FiList } from "react-icons/fi";
import { getIraAccountIdSelector } from "store/selectors/user";
import { getUrlForDocument } from "services/documentService";
import { getAllDocuments } from "actions/documentActions";
import { getAllAccounts } from "actions/accountActions";
import { simulateLinkClickNewTab } from "utils/dom";
import { statementType } from "statics/propTypes";
import {
  accountConstants,
  documentConstants,
  userConstants,
} from "actions/types";
import { createErrorSelector, createLoadingSelector } from "store/selectors";

import IconEmptyState from "components/IconEmptyState";
import IconTable from "components/IconTable";
import Alert from "components/Alert";
import IconSpinner from "components/IconSpinner";
import "./IndividualStatements.scss";
import TermsModal from "pages/signUp/TermsModal";
import { isProduction } from "utils/determineSite";
import { getUserTermsSubmissionDate } from "actions/userActions";

const tabs = [
  {
    label: "Statements",
    id: "statements",
  },
  {
    label: "Confirmations",
    id: "confirmations",
  },
  {
    label: "Agreements",
    id: "agreements",
  },
];

class IndividualStatements extends React.PureComponent {
  static propTypes = {
    routeName: PropTypes.string,
    getAllAccounts: PropTypes.func,
    getAllDocuments: PropTypes.func,
    getTransactions: PropTypes.func,
    getUserTermsSubmissionDate: PropTypes.func,
    client: PropTypes.object,
    iraAccountId: PropTypes.string,
    error: PropTypes.string,
    isFetching: PropTypes.bool,
    statements: PropTypes.shape({
      statements: statementType,
      trades: statementType,
    }),
  };
  _promises = [];

  constructor(props) {
    super(props);

    this.state = {
      tab: tabs[0].id,
      showEvolveTerms: false,
      fetching: false,
    };
  }

  async componentDidMount() {
    window.analytics.page("Individual Statements");
    const accountPromise = this.props
      .getAllAccounts(this.props.client)
      .then(() => {
        if (this.props.iraAccountId) {
          const docPromise = this.props.getAllDocuments(
            this.props.client,
            this.props.iraAccountId
          );
          this._promises.push(docPromise);
        }
      });

    const userStateHistoryPromise = await this.props
      .getUserTermsSubmissionDate(this.props.client)
      .then(({ data }) => {
        this.setState({
          termsAcceptanceDate: moment(data.createdAt).format("MMMM D, YYYY"),
        });
      });

    this._promises.push(userStateHistoryPromise);

    this._promises.push(accountPromise);
  }

  componentWillUnmount() {
    each(this._promises, (promise) => {
      promise && promise.cancel();
    });
  }

  _tabUpdate(value) {
    this.setState({ tab: value.k });
  }

  _downloadDoc = (docId) => {
    const docUrlPromise = getUrlForDocument(
      this.props.client,
      this.props.iraAccountId,
      docId
    ).then((result) => {
      simulateLinkClickNewTab(result.url, false);
    });

    this._promises.push(docUrlPromise);
  };

  _getIndividualStatementData = () => {
    let statementsToUse, statementType;
    switch (this.state.tab) {
      case "statements":
        statementsToUse = this.props.statements.statements;
        statementType = "dynamic";
        break;
      case "confirmations":
        statementsToUse = this.props.statements.trades;
        statementType = "dynamic";
        break;
      case "agreements":
        statementsToUse = this.getStaticStatements();
        statementType = "static";
        break;
    }

    return map(statementsToUse, (statement) => {
      const createdAt = moment(statement.createdAt).format("MMM Do YYYY");

      return {
        statement,
        id: statement.id,
        createdAt,
        name: statement.name,
        statementsToUse,
        statementType,
      };
    });
  };

  getStaticStatements = () => {
    return [
      {
        createdAt: "2020-12-18",
        name: "Icon ADV Part 2A",
        id: "icon-adv-part-2a",
        url: `https://iconsavingsplan-public.s3-us-west-2.amazonaws.com/${
          isProduction() ? "production" : "staging"
        }/documents/IFS_ADV_Part_2A.pdf`,
      },
      {
        createdAt: "2020-12-18",
        name: "Icon ADV Part 2B",
        id: "icon-adv-part-2b",
        url: `https://iconsavingsplan-public.s3-us-west-2.amazonaws.com/${
          isProduction() ? "production" : "staging"
        }/documents/IFS_ADV_Part_2B.pdf`,
      },
      {
        createdAt: "2020-08-25",
        name: "Icon Form CRS",
        id: "icon-crs",
        url: `https://iconsavingsplan-public.s3-us-west-2.amazonaws.com/${
          isProduction() ? "production" : "staging"
        }/documents/IFS_Form_CRS.pdf`,
      },
      {
        createdAt: "2020-08-25",
        name: "Icon Advisory Agreement",
        id: "icon-adv-agreement",
        url: `https://iconsavingsplan-public.s3-us-west-2.amazonaws.com/${
          isProduction() ? "production" : "staging"
        }/documents/IFS_Advisory_Agreement.pdf`,
      },
      {
        createdAt: "2021-01-01",
        name: "Icon IRA Disclosure Statement",
        id: "icon-disclosure-statement",
        url: `https://iconsavingsplan-public.s3-us-west-2.amazonaws.com/${
          isProduction() ? "production" : "staging"
        }/documents/ICON_IRA_DISCLOSURE_STATEMENT.pdf`,
      },
      {
        createdAt: this.state.termsAcceptanceDate,
        name: "Evolve Bank & Trust Customer Account Agreement",
        id: "evolve-terms",
        onClick: () => {
          this.setState({ showEvolveTerms: true });
        },
      },
    ];
  };

  _buildIndividualStatementColumns = () => {
    const columns = [
      {
        label: "Statement Date",
        key: "createdAt",
      },

      {
        label: "Name",
        key: "name",
      },

      {
        customComponent: (props) => {
          let action;

          if (props.statementType === "dynamic") {
            action = (
              <a
                className="link"
                onClick={() => {
                  this._downloadDoc(props.id);
                }}
              >
                <FiDownload
                  size={"20px"}
                  color={"#FFFFFF"}
                  fill={"#FFFFFF"}
                  stroke={"#635bff"}
                  strokeWidth={"1.5"}
                  className="icon"
                />
              </a>
            );
          } else if (props.statementType === "static") {
            if (props.statement.url) {
              action = (
                <a
                  className="link"
                  href={props.statement.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FiDownload
                    size={"20px"}
                    color={"#FFFFFF"}
                    fill={"#FFFFFF"}
                    stroke={"#635bff"}
                    strokeWidth={"1.5"}
                    className="icon"
                  />
                </a>
              );
            }

            if (props.statement.onClick) {
              action = (
                <a className="link" onClick={props.statement.onClick}>
                  <FiEye
                    size={"20px"}
                    color={"#FFFFFF"}
                    fill={"#FFFFFF"}
                    stroke={"#635bff"}
                    strokeWidth={"1.5"}
                    className="icon"
                  />
                </a>
              );
            }
          }

          return <div className="undo-button">{action}</div>;
        },
      },
    ];
    return columns;
  };

  _controlledTabs() {
    return (
      <Tabs
        activeKey={this.state.tab}
        id="tab-company"
        onSelect={(k) => this._tabUpdate({ k })}
      >
        {map(tabs, (tab) => {
          return <Tab key={tab.id} eventKey={tab.id} title={tab.label} />;
        })}
      </Tabs>
    );
  }

  render() {
    const columns = this._buildIndividualStatementColumns();
    const data = this._getIndividualStatementData();

    const showBlankState = isEmpty(data);

    return (
      <div className="mega-container dashboard-content" id="statements">
        <section className="page-title-wrap">
          <article className="text-cell">
            <h1 className="page-title">Statements and Account Documents</h1>
            <p className="page-subtext">
              See and download all your statements and documents
            </p>
            <div className="main-content">
              {this.state.showEvolveTerms && (
                <TermsModal
                  termsInfo={{ effectiveDate: this.state.termsAcceptanceDate }}
                  type={"evolveAgreement"}
                  show={true}
                  onClose={() => this.setState({ showEvolveTerms: false })}
                />
              )}
              {this.props.isFetching && <IconSpinner centered />}
              {!this.props.isFetching && (
                <Row>
                  <Col>
                    <div className="card-tab">{this._controlledTabs()}</div>
                    <Card className="dashboard-widget statements-table">
                      <Card.Body>
                        {showBlankState && !this.props.error && (
                          <IconEmptyState
                            header={`No ${this.state.tab} to show at this time`}
                            icon={
                              <FiList
                                color="white"
                                stroke="#60A4BF"
                                size={16}
                              />
                            }
                          />
                        )}
                        {this.props.error && (
                          <div className="alert-container">
                            <Alert type="error" msg={this.props.error} />
                          </div>
                        )}

                        {!showBlankState && !this.props.error && (
                          <>
                            <IconTable columns={columns} data={data} />
                          </>
                        )}
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              )}
            </div>
          </article>
        </section>
      </div>
    );
  }
}

const actions = [
  accountConstants.GET_ACCOUNTS,
  documentConstants.GET_DOCUMENTS,
  userConstants.GET_USER_TERMS_DATE,
];

const loadingSelector = createLoadingSelector(actions);
const errorSelector = createErrorSelector(actions);

const mapStateToProps = (state) => {
  const orderedStatements = orderBy(
    state.userDocuments.statements,
    "createdAt",
    "desc"
  );
  const groupedStatements = groupBy(orderedStatements, (statement) => {
    if (statement.type === "TradeConfirmation") {
      return "trades";
    }
    return "statements";
  });
  return {
    statements: groupedStatements,
    isFetching: loadingSelector(state),
    error: errorSelector(state),
    iraAccountId: getIraAccountIdSelector(state),
  };
};

const mapDispatchToProps = {
  push,
  getAllDocuments,
  getAllAccounts,
  getUserTermsSubmissionDate,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(IndividualStatements));
