import { adminConstants, employerConstants } from "./types";
import {
  approvePayrollIntegrationSetup,
  createCustomReport as createCustomReportRequest,
  createInvoiceForCompany,
  createOrganization,
  disableCompanyRequest,
  enableFeatureForCompany,
  enableFeaturesForCompany,
  getAllOriginations,
  getAllUnprocessedPayrollsRequest,
  getApprovalRequest as getApprovalById,
  getApprovalRequests,
  getBillingDetailsForCompany,
  getComplianceReports,
  getCustomReports,
  getEmployerCrmLinkRequest,
  getEmployerGroupsByCompanyIdRequest,
  getEntityLimitsRequest,
  getGroupsAndBanks,
  getKybStatus,
  getKybStatusForCompany,
  getLastWorkflowStep,
  getOrgForCompany,
  getPaginatedCompanies as getCompaniesSet,
  getPayrolls,
  getPendingAndLinkedEmployers,
  getPricingSheet,
  getProductFeatures,
  getReportsStatuses,
  getReportTypes,
  getUserDetails,
  getUserReviewDocuments,
  getUserTransactions,
  getWorkflows,
  linkBank,
  linkEmployerToCrmRequest,
  liquidateUserAccount,
  lockUser,
  migrateEmployeesForCompany,
  partiallyDisburseUserAccount,
  reopenUserAccount,
  requirePortfolioUpdateRequest,
  resetUserState,
  searchUsers,
  setApproval,
  submitKYB,
  submitKYBBusinessDocuments,
  submitKYBMemberDocuments,
  submitKYBUser,
  syncCrmRequest,
  unlinkEmployerToCrmRequest,
  unlockUser,
  updateBillingContact,
  updateBillingType,
  updateCompanyInfo as updateCompanyInfoNonKyb,
  updateCompanyKYBInfo,
  updateEntityLimitRequest,
  uploadEmployeesDocumentForEmployerRequest,
} from "services/adminService";
import ActionCreator from "utils/actionHandler";

export const registerKYBUser = (client, kybUser) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(adminConstants.REGISTER_KYB_USER);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      submitKYBUser(client, kybUser)
    );
  };
};

export const registerKYB = (client, kybEmployer) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(adminConstants.REGISTER_KYB);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      submitKYB(client, kybEmployer)
    );
  };
};

export const getApproval = (client, approvalId) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(adminConstants.GET_APPROVAL);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      getApprovalById(client, approvalId)
    );
  };
};

export const setApprovalStatus = (client, approvalParams) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(adminConstants.SET_APPROVAL_STATUS);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      setApproval(client, approvalParams)
    );
  };
};

export const getReports = (client) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      adminConstants.GET_COMPLIANCE_REPORTS
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      getComplianceReports(client)
    );
  };
};

export const getPendingApprovals = (client) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      adminConstants.GET_PENDING_APPROVALS
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      getApprovalRequests(client, "PENDING")
    );
  };
};

export const getUserAccountReviewDocuments = (client, userId) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      adminConstants.GET_USER_ACCOUNT_REVIEW_DOCUMENTS
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      getUserReviewDocuments(client, userId)
    );
  };
};

export const enableFeature = (client, params) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(adminConstants.ENABLE_FEATURE);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      enableFeatureForCompany(client, params)
    );
  };
};

export const getEmployerGroupsByCompanyId = (client, companyId) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      adminConstants.GET_GROUPS_BY_COMPANY_ID
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      getEmployerGroupsByCompanyIdRequest(client, companyId)
    );
  };
};

export const getAllProductFeatures = (client) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      adminConstants.GET_PRODUCT_FEATURES
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      getProductFeatures(client)
    );
  };
};

export const enableProductFeatures = (client, params) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      adminConstants.ENABLE_PRODUCT_FEATURES
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      enableFeaturesForCompany(client, params)
    );
  };
};

export const uploadEmployeesDocumentForEmployer = (
  client,
  groupId,
  file,
  companyId
) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      employerConstants.UPLOAD_EMPLOYEES_DOCUMENT
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      uploadEmployeesDocumentForEmployerRequest(
        client,
        groupId,
        file,
        companyId
      )
    );
  };
};

export const getPaginatedCompanies = (client, params) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      adminConstants.GET_PAGINATED_COMPANIES
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      getCompaniesSet(client, params)
    );
  };
};

export const linkCompanyBank = (client, params) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(adminConstants.LINK_BANK);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      linkBank(client, params)
    );
  };
};

export const updateCompanyKybInfo = (client, params) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      adminConstants.REGISTER_KYB_COMPANY
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      updateCompanyKYBInfo(client, params)
    );
  };
};

export const updateCompanyInfo = (client, params) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(adminConstants.UPDATE_COMPANY_INFO);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      updateCompanyInfoNonKyb(client, params)
    );
  };
};

export const createOrganizationForCompany = (client, params) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(adminConstants.CREATE_ORGANIZATION);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      createOrganization(client, params)
    );
  };
};

export const getOrganizationForCompany = (client, companyId) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      adminConstants.GET_ORGANIZATION_FOR_COMPANY
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      getOrgForCompany(client, companyId)
    );
  };
};

export const disableCompany = (client, companyId) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(adminConstants.DISABLE_COMPANY);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      disableCompanyRequest(client, companyId)
    );
  };
};

export const getOriginations = (client, params) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(adminConstants.GET_ORIGINATIONS);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      getAllOriginations(client, params)
    );
  };
};

export const submitBusinessDocuments = (client, params) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      adminConstants.SUBMIT_BUSINESS_KYB_DOCUMENTS
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      submitKYBBusinessDocuments(client, params)
    );
  };
};

export const submitBusinessMemberDocuments = (client, params) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      adminConstants.SUBMIT_BUSINESS_MEMBERS_KYB_DOCUMENTS
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      submitKYBMemberDocuments(client, params)
    );
  };
};

export const getBanksAndGroups = (client, companyId) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      adminConstants.GET_BANKS_AND_GROUPS
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      getGroupsAndBanks(client, companyId)
    );
  };
};

export const getPaginatedPayrolls = (client, params) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(adminConstants.GET_PAYROLLS);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      getPayrolls(client, params)
    );
  };
};

export const getKybStatusAndInfo = (client, companyId) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(adminConstants.GET_KYB_STATUS);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      getKybStatusForCompany(client, companyId)
    );
  };
};

export const searchAllUsers = (client, params) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(adminConstants.SEARCH_USERS);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      searchUsers(client, params)
    );
  };
};

export const getUser = (client, userId) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(adminConstants.GET_USER);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      getUserDetails(client, userId)
    );
  };
};

export const getTransactionsForUser = (client, userId) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      adminConstants.GET_USER_TRANSACTIONS
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      getUserTransactions(client, userId)
    );
  };
};

export const lockTargetUser = (client, userId) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(adminConstants.LOCK_USER);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      lockUser(client, userId)
    );
  };
};

export const unlockTargetUser = (client, userId) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(adminConstants.UNLOCK_USER);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      unlockUser(client, userId)
    );
  };
};

export const requirePortfolioUpdate = (client, userId) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      adminConstants.REQUIRE_PORTFOLIO_UPDATE
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      requirePortfolioUpdateRequest(client, userId)
    );
  };
};

export const getPricingData = (client) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(adminConstants.GET_PRICING_SHEET);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      getPricingSheet(client)
    );
  };
};

export const getBillingDetails = (client, companyId) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(adminConstants.GET_BILLING_DETAILS);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      getBillingDetailsForCompany(client, companyId)
    );
  };
};

export const createInvoiceForCustomer = (client, params) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      adminConstants.CREATE_BILLING_INVOICE
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      createInvoiceForCompany(client, params)
    );
  };
};

export const getPendingLinkedEmployers = (client, userId) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      adminConstants.GET_PENDING_LINKED_EMPLOYERS
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      getPendingAndLinkedEmployers(client, userId)
    );
  };
};
export const liquidateAccount = (client, params) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(adminConstants.LIQUIDATE_ACCOUNT);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      liquidateUserAccount(client, params)
    );
  };
};

export const resetUserOnboardingState = (client, userId) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(adminConstants.RESET_USER_STATE);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      resetUserState(client, userId)
    );
  };
};

export const disburseUserAccount = (client, params) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      adminConstants.DISPERSE_USER_ACCOUNT
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      partiallyDisburseUserAccount(client, params)
    );
  };
};

export const reopenUserAccountRequest = (client, params) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(adminConstants.REOPEN_USER_ACCOUNT);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      reopenUserAccount(client, params)
    );
  };
};

export const getEntityLimits = (client, entityType, entityId) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(adminConstants.GET_ENTITY_LIMITS);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      getEntityLimitsRequest(client, entityType, entityId)
    );
  };
};

export const updateEntityLimit = (client, params) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(adminConstants.UPDATE_ENTITY_LIMIT);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      updateEntityLimitRequest(client, params)
    );
  };
};

export const linkEmployerToCrm = (client, params) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(adminConstants.LINK_COMPANY_TO_CRM);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      linkEmployerToCrmRequest(client, params)
    );
  };
};

export const unlinkEmployerToCrm = (client, params) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      adminConstants.UNLINK_COMPANY_TO_CRM
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      unlinkEmployerToCrmRequest(client, params)
    );
  };
};

export const getEmployerCrmLink = (client, params) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      adminConstants.GET_COMPANY_CRM_LINK
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      getEmployerCrmLinkRequest(client, params)
    );
  };
};

export const syncCrm = (client, params) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(adminConstants.SYNC_CRM_DATA);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      syncCrmRequest(client, params)
    );
  };
};

export const getPaginatedMissedPayrolls = (client, params) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      adminConstants.GET_UNPROCESSED_PAYROLLS
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      getAllUnprocessedPayrollsRequest(client, params)
    );
  };
};

export const changeBillingType = (client, params) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      adminConstants.CHANGE_BILLING_TYPES
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      updateBillingType(client, params)
    );
  };
};

export const getPaginatedWorkflows = (client, params) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(adminConstants.GET_WORKFLOWS);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      getWorkflows(client, params)
    );
  };
};

export const getLastWorkflowStepForWorkflow = (client, params) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(adminConstants.GET_WORKFLOW_STEPS);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      getLastWorkflowStep(client, params)
    );
  };
};

export const getPaginatedCustomReports = (client, params) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(adminConstants.GET_CUSTOM_REPORTS);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      getCustomReports(client, params)
    );
  };
};

export const getCustomReportTypes = (client) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      adminConstants.GET_CUSTOM_REPORT_TYPES
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      getReportTypes(client)
    );
  };
};

export const createCustomReport = (client, params) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      adminConstants.CREATE_CUSTOM_REPORT
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      createCustomReportRequest(client, params)
    );
  };
};

export const updateBillingEmail = (client, params) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      adminConstants.UPDATE_BILLING_CONTACT
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      updateBillingContact(client, params)
    );
  };
};

export const approvePayrollIntegration = (client, params) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      adminConstants.APPROVE_PAYROLL_INTEGRATION
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      approvePayrollIntegrationSetup(client, params)
    );
  };
};

export const getKycStatus = (client, params) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(adminConstants.GET_KYC_STATUS);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      getKybStatus(client, params)
    );
  };
};

export const getReportsStatus = (client, params) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(adminConstants.GET_REPORTS_STATUS);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      getReportsStatuses(client, params)
    );
  };
};

export const migrateEmployees = (client, params) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      adminConstants.ADMIN_MIGRATE_EMPLOYEES
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      migrateEmployeesForCompany(client, params)
    );
  };
};
