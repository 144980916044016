import React from "react";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import PropTypes from "prop-types";
import { push } from "connected-react-router";
import { fetchOwners } from "actions/employerActions";

import OwnerFullInfoForm from "pages/signUp/employer/OwnerFullInfoForm";
import IconSpinner from "components/IconSpinner";

class EmployerSelfServeController extends React.PureComponent {
  static propTypes = {
    fetchOwners: PropTypes.func,
    push: PropTypes.func,
    client: PropTypes.object,
  };

  constructor() {
    super();

    this.state = {
      fetchingOwners: true,
      owners: [],
    };
  }

  async componentDidMount() {
    const { data: owners } = await this.props.fetchOwners(this.props.client);

    this.setState({ fetchingOwners: false, owners });
  }

  render() {
    if (this.state.fetchingOwners) return <IconSpinner centered />;

    return <OwnerFullInfoForm owner={this.state.owners[0]} />;
  }
}

const mapDispatchToProps = {
  fetchOwners,
  push,
};
export default connect(
  null,
  mapDispatchToProps
)(withApollo(EmployerSelfServeController));
