import React from "react";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import PropTypes from "prop-types";
import { includes, isEmpty } from "lodash";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { createErrorSelector } from "store/selectors";
import { employerConstants } from "actions/types";
import {
  createSetupIntent,
  getSetupIntent,
  setInvoicePaymentMethod,
  skipBilling,
} from "actions/employerActions";
import {
  planCodeSelector,
  requiresBillingDetails,
} from "store/selectors/employer";

import ProgressBar from "components/ProgressBar";
import IconSpinner from "components/IconSpinner";
import StripeForm from "components/StripeForm";
import env from "@beam-australia/react-env";
import { getUserState } from "actions/userActions";
import PlanCard from "./PlanCard";
import { BILLING_INFO } from "statics/onboardingSteps";
import { push } from "connected-react-router";

class EmployerBilling extends React.PureComponent {
  static propTypes = {
    client: PropTypes.object,
    isMultiAccount: PropTypes.bool,
    getSetupIntent: PropTypes.func,
    createSetupIntent: PropTypes.func,
    setInvoicePaymentMethod: PropTypes.func,
    push: PropTypes.func,
    getUserState: PropTypes.func,
    onSetupSuccess: PropTypes.func,
    skipBilling: PropTypes.func,
    showTerms: PropTypes.func,
    error: PropTypes.string,
    planCode: PropTypes.string,
    isDashboard: PropTypes.bool,
    requiresBillingDetails: PropTypes.bool,
    onSuccess: PropTypes.func,
  };
  stripePromise = loadStripe(env("STRIPE_PUBLIC_KEY"));

  constructor() {
    super();

    this.state = {
      initialFetching: true,
      stripeClientSecret: null,
    };
  }

  async componentDidMount() {
    let stripeClientSecret;
    if (!this.props.requiresBillingDetails) {
      this.props.push("/");
    }
    const { data } = await this.props.getSetupIntent(this.props.client);

    /* if the intent has a status of succeeded it means the customer has provided a valid payment method and we should
     progress them forward. This acts as a catch if they submit billing info but refresh the page before we can make all
     the necessary api calls
     */
    const moveForwardStatuses = ["succeeded", "requires_action"];
    if (data && includes(moveForwardStatuses, data.status)) {
      await this._onSuccess(data);
    } else if (isEmpty(data)) {
      const { data: setupIntent } = await this.props.createSetupIntent(
        this.props.client
      );
      stripeClientSecret = setupIntent.client_secret;
    } else {
      stripeClientSecret = data.client_secret;
    }

    // if no setupIntent returned then we need to create one before starting the stripe process

    this.setState({
      stripeClientSecret,
      initialFetching: false,
    });
  }

  _onSuccess = async ({ payment_method: paymentMethod, ...setupIntent }) => {
    const requiresMicroDepositVerification =
      setupIntent.status === "requires_action";
    if (requiresMicroDepositVerification) {
      // fire state machine and deal with microdeposits later so user can get through KYB
      if (!this.props.isDashboard) {
        await this.props.skipBilling(this.props.client);
      }
    } else {
      await this.props.setInvoicePaymentMethod(
        this.props.client,
        paymentMethod
      );
    }
    if (!this.props.error) {
      if (this.props.isDashboard) {
        this.props.onSuccess();
        await this.props.getSetupIntent(this.props.client);
      } else {
        await this.props.getUserState(this.props.client);
      }
    }
  };

  _getDashboardView = () => {
    return (
      <div id="employer-billing">
        <Elements
          stripe={this.stripePromise}
          options={{
            clientSecret: this.state.stripeClientSecret,
            appearance: {
              theme: "stripe",
              variables: {
                spacingUnit: "4px",
                colorPrimary: "#1b9aa9",
              },
            },
          }}
        >
          <StripeForm onSuccess={this._onSuccess} />
        </Elements>
      </div>
    );
  };

  render() {
    if (this.state.initialFetching) {
      return <IconSpinner centered />;
    }

    if (this.props.isDashboard) return this._getDashboardView();

    return (
      <div id="employer-billing">
        <ProgressBar
          isEmployer={true}
          activeStepId={BILLING_INFO.id}
          progressPercent={"90"}
          isMultiAccount={this.props.isMultiAccount}
          showBillingRoute={true}
        />
        <div className="mega-container">
          <div className="step-container is-active" data-circle-percent="100">
            {this.state.initialFetching && <IconSpinner centered />}
            {!this.state.initialFetching && (
              <section className="form-sec-2col">
                <section className="page-title-wrap">
                  <article className="text-cell">
                    <h1 className="page-title">Billing Details</h1>
                    <p className="page-subtext">
                      Please provide your billing details, you will not be
                      charged until your account has been fully opened.
                    </p>
                    <Elements
                      stripe={this.stripePromise}
                      options={{
                        clientSecret: this.state.stripeClientSecret,
                        appearance: {
                          theme: "stripe",
                          variables: {
                            spacingUnit: "4px",
                            colorPrimary: "#1b9aa9",
                          },
                        },
                      }}
                    >
                      <StripeForm onSuccess={this._onSuccess} />
                    </Elements>
                  </article>
                </section>
                <section>
                  <article className="plan-card-bottom">
                    <PlanCard planCode={this.props.planCode} />
                  </article>
                </section>
              </section>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const errorSelector = createErrorSelector([
  employerConstants.GET_SETUP_INTENT,
  employerConstants.CREATE_SETUP_INTENT,
  employerConstants.SKIP_BILLING,
]);

const mapStateToProps = (state) => {
  return {
    error: errorSelector(state),
    isMultiAccount: state.employer.isMultiAccount,
    planCode: planCodeSelector(state),
    requiresBillingDetails: requiresBillingDetails(state),
  };
};

const mapDispatchToProps = {
  getSetupIntent,
  createSetupIntent,
  setInvoicePaymentMethod,
  getUserState,
  skipBilling,
  push,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(EmployerBilling));
