import React from "react";
import PropTypes from "prop-types";
import Button from "components/Button";
import Alert from "components/Alert";

import { connect } from "react-redux";
import { get, map } from "lodash";
import { withApollo } from "@apollo/client/react/hoc";
import { push } from "connected-react-router";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { employerConstants } from "actions/types";
import { postEmployerAgent, validateSelfServe } from "actions/employerActions";
import { booleanValue } from "utils/fieldValidators";
import { getUserState } from "actions/userActions";
import {
  getError,
  getValue,
  isInvalid,
  isValid,
  ScrollToFieldError,
} from "utils/form";

import { Col, Form } from "react-bootstrap";
import { FieldArray, Formik } from "formik";

import "./EmployerEnroll.scss";
import { planCodeSelector } from "store/selectors/employer";

/* eslint-disable react/no-children-prop */

const blankBeneficiary = {
  familyName: "",
  givenName: "",
  email: "",
};

let yup = require("yup");

const schema = yup.object().shape({
  firstName: yup
    .string()
    .label("First")
    .required()
    .min(1, "Must be at least one character.")
    .max(30),
  lastName: yup
    .string()
    .label("Last")
    .required()
    .min(1, "Must be at least one character.")
    .max(40),
  email: yup
    .string()
    .label("Email")
    .email("You must enter a valid email address.")
    .max(200)
    .required(),
  beneficialOwner: yup.boolean().label("Beneficial Owners"),
  controllingOfficerAndBeneficialOwner: yup
    .boolean()
    .label("Controlling Officer and Beneficial Owner")
    .required(),
  beneficiaries: yup.array().of(
    yup.object().shape({
      firstName: yup
        .string()
        .label("First Name")
        .required()
        .min(1, "Must be at least one character.")
        .max(20),
      lastName: yup
        .string()
        .label("Last Name")
        .required()
        .min(1, "Must be at least one character.")
        .max(20),

      email: yup
        .string()
        .label("Email")
        .email("You must enter a valid email address.")
        .required()
        .max(200),
    })
  ),
});

class Controller extends React.Component {
  static propTypes = {
    client: PropTypes.shape({}),
    memberId: PropTypes.string,
    companyId: PropTypes.string,
    planCode: PropTypes.string,
    getUserState: PropTypes.func,
    postEmployerAgent: PropTypes.func,
    validateSelfServe: PropTypes.func,
    push: PropTypes.func,
    error: PropTypes.string,
    isFetching: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      identity: {
        firstName: "",
        lastName: "",
        email: "",
        role: "",
      },
      beneficiaries: [],
      beneficialOwner: false,
      controllingOfficerAndBeneficialOwner: "",
      loading: false,
      fetching: false,
      member: null,
      isEditMode: true,
    };
  }

  submitOwner = async (values) => {
    let identity = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      role: "",
    };

    const controllingOfficer = "ControllingOfficer";
    const beneficialOwner = "BeneficialOwner";
    const controllingOfficerAndBeneficialOwner =
      "ControllingOfficerAndBeneficialOwner";

    const promises = [];

    if (values.controllingOfficerAndBeneficialOwner) {
      promises.push(
        this.props.postEmployerAgent(this.props.client, {
          ...identity,
          role: controllingOfficerAndBeneficialOwner,
        })
      );
    } else {
      promises.push(
        this.props.postEmployerAgent(this.props.client, {
          ...identity,
          role: controllingOfficer,
        })
      );
    }

    if (values.beneficialOwner) {
      map(values.beneficiaries, (beneficiary) =>
        promises.push(
          this.props.postEmployerAgent(this.props.client, {
            firstName: beneficiary.firstName,
            lastName: beneficiary.lastName,
            email: beneficiary.email,
            role: beneficialOwner,
          })
        )
      );
    }

    await Promise.all(promises).then(() => {
      if (!this.props.error) {
        this.props.validateSelfServe(this.props.client).then(() => {
          this.props.getUserState(this.props.client);
        });
      }
    });
  };

  render() {
    const {
      identity,
      beneficiaries,
      beneficialOwner,
      controllingOfficerAndBeneficialOwner,
    } = this.state;

    var articleStyle = {
      paddingBottom: 0,
    };

    return (
      <div>
        <Formik
          validateOnChange={false}
          validationSchema={schema}
          onSubmit={(values) => {
            this.submitOwner(values);
          }}
          enableReinitialize={true}
          initialValues={{
            ...identity,
            beneficiaries,
            beneficialOwner,
            controllingOfficerAndBeneficialOwner,
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
            values,
            touched,
            errors,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <ScrollToFieldError />
              <div className="mega-container">
                <div
                  className="step-container is-active"
                  data-circle-percent="30"
                >
                  <section className="page-title-wrap">
                    <article className="text-cell">
                      <p className="page-title">Contact Information</p>
                    </article>
                  </section>

                  <div id="form-employer-company-profile">
                    <section className="form-sec-2col">
                      <article className="col-form" style={articleStyle}>
                        <article className="text-cell">
                          <p className="page-label">Controller</p>
                          <p className="page-subtext">
                            A company controller is typically an officer or
                            someone with signing authority who can act on behalf
                            of the company for plan administration.
                          </p>
                        </article>

                        <Form.Row>
                          <Form.Group as={Col} controlId="formBasicFirstName">
                            <Form.Control
                              name="firstName"
                              placeholder="First Name"
                              value={values.firstName}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              isInvalid={
                                touched.firstName && !!errors.firstName
                              }
                              isValid={touched.firstName && !errors.firstName}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.firstName}
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group as={Col} controlId="formBasicLastName">
                            <Form.Control
                              name="lastName"
                              placeholder="Last Name"
                              value={values.lastName}
                              onChange={handleChange}
                              isInvalid={touched.lastName && !!errors.lastName}
                              isValid={touched.lastName && !errors.lastName}
                              onBlur={handleBlur}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.lastName}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Form.Row>
                        <Form.Row>
                          <Form.Group
                            as={Col}
                            sm={6}
                            controlId="formBasicEmail"
                          >
                            <Form.Control
                              type="email"
                              name="email"
                              placeholder="Email Address"
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={touched.email && !!errors.email}
                              isValid={touched.email && !errors.email}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.email}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Form.Row>

                        <Form.Row className="control-checkbox-owner">
                          <Form.Group
                            as={Col}
                            sm={6}
                            controlId="formBasicControllingOfficerAndBeneficialOwner"
                          >
                            <Form.Label>
                              This controller is also a beneficial owner.
                            </Form.Label>
                            <Form.Control
                              as="select"
                              sm={4}
                              name="controllingOfficerAndBeneficialOwner"
                              value={
                                values.controllingOfficerAndBeneficialOwner
                              }
                              onChange={(e) => {
                                if (e.target.value === "true") {
                                  setFieldValue(
                                    "controllingOfficerAndBeneficialOwner",
                                    true
                                  );
                                } else if (e.target.value === "false") {
                                  setFieldValue(
                                    "controllingOfficerAndBeneficialOwner",
                                    false
                                  );
                                }
                              }}
                              onBlur={handleBlur}
                              isInvalid={
                                touched.controllingOfficerAndBeneficialOwner &&
                                !!errors.controllingOfficerAndBeneficialOwner
                              }
                              isValid={
                                touched.controllingOfficerAndBeneficialOwner &&
                                !errors.controllingOfficerAndBeneficialOwner
                              }
                            >
                              <option value="" disabled>
                                Select Option
                              </option>
                              <option value={"true"} key={"true"}>
                                Yes
                              </option>
                              <option value={"false"} key={"false"}>
                                No
                              </option>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                              {errors.controllingOfficerAndBeneficialOwner}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Form.Row>

                        <article className="text-cell-Benificial">
                          <p className="page-label">Beneficial Owners</p>
                          <p className="page-subtext">
                            We are required by law to verify the company’s
                            beneficial owners to create your Icon account. A
                            beneficial owner is any individual who owns 25% or
                            more of the company.
                          </p>
                        </article>
                        <section className="form-sec-col">
                          <article className="col-form" style={articleStyle}>
                            {this.state.isEditMode && (
                              <FieldArray
                                name="beneficiaries"
                                render={(arrayHelpers) => (
                                  <div>
                                    {values.beneficiaries &&
                                    values.beneficialOwner === true ? (
                                      values.beneficiaries.map(
                                        (beneficiary, index) => (
                                          <div
                                            className="owner-box"
                                            key={index}
                                          >
                                            <div className="owner-actions">
                                              <p className="owner-label">
                                                Beneficiary: {index + 1}
                                              </p>
                                              <div className="actions">
                                                {!values.controllingOfficerAndBeneficialOwner && (
                                                  <div>
                                                    {values.beneficiaries
                                                      .length <= 3 ? (
                                                      <div>
                                                        <Button
                                                          size="sm"
                                                          name="action"
                                                          color="action"
                                                          btnLabel="Add"
                                                          onClick={() =>
                                                            arrayHelpers.push({
                                                              ...blankBeneficiary,
                                                            })
                                                          }
                                                        />
                                                      </div>
                                                    ) : (
                                                      <div></div>
                                                    )}
                                                  </div>
                                                )}
                                                <div>
                                                  {values.controllingOfficerAndBeneficialOwner && (
                                                    <span>
                                                      {values.beneficiaries
                                                        .length <= 2 ? (
                                                        <div>
                                                          <Button
                                                            size="sm"
                                                            color="action"
                                                            name="action"
                                                            btnLabel="Add"
                                                            onClick={() =>
                                                              arrayHelpers.push(
                                                                {
                                                                  ...blankBeneficiary,
                                                                }
                                                              )
                                                            }
                                                          />
                                                        </div>
                                                      ) : (
                                                        <div></div>
                                                      )}
                                                    </span>
                                                  )}
                                                </div>
                                                <div>
                                                  {values.beneficiaries.length >
                                                  0 ? (
                                                    <div>
                                                      <div className="remove">
                                                        <Button
                                                          size="sm"
                                                          color="red"
                                                          name="action"
                                                          btnLabel="Remove"
                                                          onClick={() => {
                                                            const isLastBeneficiary =
                                                              values
                                                                .beneficiaries
                                                                .length === 1;
                                                            arrayHelpers.remove(
                                                              index
                                                            );
                                                            if (
                                                              isLastBeneficiary
                                                            ) {
                                                              setFieldValue(
                                                                "beneficialOwner",
                                                                false
                                                              );
                                                            }
                                                          }}
                                                        />
                                                      </div>
                                                    </div>
                                                  ) : (
                                                    <div></div>
                                                  )}
                                                </div>
                                              </div>
                                            </div>

                                            <Form.Row>
                                              <Form.Group
                                                as={Col}
                                                sm={9}
                                                controlId={`formBasicbeneficiaries${index}firstName`}
                                              >
                                                <Form.Control
                                                  name={`beneficiaries.${index}.firstName`}
                                                  placeholder="First Name"
                                                  value={getValue(
                                                    values,
                                                    `beneficiaries.${index}.firstName`
                                                  )}
                                                  onBlur={handleBlur}
                                                  onChange={handleChange}
                                                  isInvalid={isInvalid(
                                                    touched,
                                                    errors,
                                                    `beneficiaries.${index}.firstName`
                                                  )}
                                                  isValid={isValid(
                                                    touched,
                                                    errors,
                                                    `beneficiaries.${index}.firstName`
                                                  )}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                  {getError(
                                                    errors,
                                                    `beneficiaries.${index}.firstName`
                                                  )}
                                                </Form.Control.Feedback>
                                              </Form.Group>
                                              <Form.Group
                                                as={Col}
                                                sm={9}
                                                controlId={`formBasicbeneficiaries${index}lastName`}
                                              >
                                                <Form.Control
                                                  name={`beneficiaries.${index}.lastName`}
                                                  placeholder="Last Name"
                                                  value={getValue(
                                                    values,
                                                    `beneficiaries.${index}.lastName`
                                                  )}
                                                  onChange={handleChange}
                                                  isInvalid={isInvalid(
                                                    touched,
                                                    errors,
                                                    `beneficiaries.${index}.lastName`
                                                  )}
                                                  isValid={isValid(
                                                    touched,
                                                    errors,
                                                    `beneficiaries.${index}.lastName`
                                                  )}
                                                  onBlur={handleBlur}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                  {getError(
                                                    errors,
                                                    `beneficiaries.${index}.lastName`
                                                  )}
                                                </Form.Control.Feedback>
                                              </Form.Group>
                                            </Form.Row>
                                            <Form.Row>
                                              <Form.Group
                                                as={Col}
                                                sm={9}
                                                controlId={`formBasicbeneficiaries${index}email`}
                                              >
                                                <Form.Control
                                                  name={`beneficiaries.${index}.email`}
                                                  type="email"
                                                  placeholder="Email Address"
                                                  value={getValue(
                                                    values,
                                                    `beneficiaries.${index}.email`
                                                  )}
                                                  onChange={handleChange}
                                                  isInvalid={isInvalid(
                                                    touched,
                                                    errors,
                                                    `beneficiaries.${index}.email`
                                                  )}
                                                  isValid={isValid(
                                                    touched,
                                                    errors,
                                                    `beneficiaries.${index}.email`
                                                  )}
                                                  onBlur={handleBlur}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                  {getError(
                                                    errors,
                                                    `beneficiaries.${index}.email`
                                                  )}
                                                </Form.Control.Feedback>
                                              </Form.Group>
                                            </Form.Row>
                                          </div>
                                        )
                                      )
                                    ) : (
                                      <div className="submit-row">
                                        <div className="buttons">
                                          <div
                                            className="
                                            add-beneficiary"
                                          >
                                            {!values.controllingOfficerAndBeneficialOwner && (
                                              <Form.Label>
                                                Would you like to add a
                                                Beneficial Owner?
                                              </Form.Label>
                                            )}
                                            {values.controllingOfficerAndBeneficialOwner && (
                                              <Form.Label>
                                                Would you like to add another
                                                Beneficial Owner?
                                              </Form.Label>
                                            )}
                                            <Form.Row>
                                              <Form.Group
                                                as={Col}
                                                sm={3}
                                                controlId="beneficialOwner"
                                              >
                                                <Form.Control
                                                  as="select"
                                                  name="beneficialOwner"
                                                  value={values.beneficialOwner}
                                                  onChange={(e) => {
                                                    setFieldValue(
                                                      "beneficialOwner",
                                                      booleanValue(
                                                        e.target.value,
                                                        arrayHelpers.push({
                                                          ...blankBeneficiary,
                                                        })
                                                      )
                                                    );
                                                  }}
                                                  onBlur={handleBlur}
                                                  isInvalid={
                                                    touched.beneficialOwner &&
                                                    !!errors.beneficialOwner
                                                  }
                                                  isValid={
                                                    touched.beneficialOwner &&
                                                    !errors.beneficialOwner
                                                  }
                                                >
                                                  <option value="false">
                                                    No
                                                  </option>
                                                  <option value="true">
                                                    Yes
                                                  </option>
                                                </Form.Control>
                                                <Form.Control.Feedback type="invalid">
                                                  {errors.beneficialOwner}
                                                </Form.Control.Feedback>
                                              </Form.Group>
                                            </Form.Row>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )}
                              />
                            )}
                          </article>
                        </section>

                        <div className="submit-row">
                          {this.props.error && (
                            <Alert type="error" msg={this.props.error} />
                          )}
                          <Button
                            name="submit"
                            withArrow={true}
                            loading={this.props.isFetching}
                          />
                        </div>
                      </article>
                    </section>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

const actions = [
  employerConstants.REGISTER_AGENT,
  employerConstants.VALIDATE_SELF_SERVE,
];
const loadingSelector = createLoadingSelector(actions);
const errorSelector = createErrorSelector(actions);

const mapStateToProps = (state, ownProps) => {
  const { user } = state;

  return {
    user,
    ownProps,
    companyId: get(ownProps.match, "params.id"),
    memberId: get(ownProps.match, "query.memberId"),
    error: errorSelector(state),
    isFetching: loadingSelector(state),
    planCode: planCodeSelector(state),
  };
};

const mapDispatchToProps = {
  getUserState,
  postEmployerAgent,
  push,
  validateSelfServe,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(Controller));
