import React from "react";
import PropTypes from "prop-types";
import { withApollo } from "@apollo/client/react/hoc";
import { filter, get, isEmpty } from "lodash";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { Col, Row } from "react-bootstrap";
import { setTab } from "actions/navigationActions";
import AddBankCard from "pages/dashboards/AddBankCard";
import UpdateGroupCard from "./UpdateGroupCard";
import CurrentPayrollTable from "./CurrentPayrollTable";
import EmployeePlanParticipation from "./EmployeePlanParticipation";
import TotalsTable from "./TotalsTable";

import { createErrorSelector } from "store/selectors";
import {
  finchHasSyncError,
  hasEnabledFinchFeature,
  hasFinchConnectionAuthError,
  hasSuccessfullyConnectedFinch,
} from "store/selectors/employer";
import { groupType } from "statics/propTypes";
import { hydrateDashboard } from "actions/employerActions";
import {
  invalidBankIdsLinkedToGroupsSelector,
  isBankVerifiedSelector,
} from "store/selectors/bank";
import { bankConstants } from "actions/types";

import "./EmployerDashboard.scss";
import IconHeader from "components/IconHeader";
import IconSubheader from "components/IconSubheader";
import FinchConnect from "pages/dashboards/employerDashboard/dashboard/FinchConnect";
import RosterApprovalTable from "pages/dashboards/employerDashboard/dashboard/RosterApprovalTable";

import PageHeading from "components/PageHeading";
import IconSpinner from "components/IconSpinner";

class EmployerDashboard extends React.Component {
  static propTypes = {
    setTab: PropTypes.func,
    push: PropTypes.func,
    groupCreationHide: PropTypes.func,

    show: PropTypes.func,
    onCancel: PropTypes.func,
    onSuccess: PropTypes.func,
    onClick: PropTypes.func,
    hydrateDashboard: PropTypes.func,

    client: PropTypes.object,

    linkedEmployees: PropTypes.number,
    numberOfPendingInvites: PropTypes.number,
    numberOfLinkRequests: PropTypes.number,
    totalAmount: PropTypes.number,

    hasEnabledFinch: PropTypes.bool,
    hasSuccessfullyConnectedFinch: PropTypes.bool,
    hasBank: PropTypes.bool,
    hasGroup: PropTypes.bool,
    hasConnectionAuthError: PropTypes.bool,
    finchHasSyncError: PropTypes.bool,

    companyState: PropTypes.string,
    error: PropTypes.string,
    invalidBankIdsLinkedToGroups: PropTypes.arrayOf(PropTypes.string),
    groups: PropTypes.arrayOf(groupType),
    company: PropTypes.shape({
      name: PropTypes.string,
    }),
  };

  constructor() {
    super();

    this.state = {
      initialFetching: true,
    };
  }

  componentDidMount() {
    this.props.hydrateDashboard(this.props.client).then(() => {
      this.setState({ initialFetching: false });
    });
  }

  _getFullOnboardedEmployerDashboard = () => {
    return (
      <>
        <Row>
          <Col md={12}>
            <TotalsTable
              numOfPendingApprovals={this.props.numberOfLinkRequests}
              totalAmount={this.props.totalAmount}
              linkedEmployees={this.props.linkedEmployees}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <EmployeePlanParticipation />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <CurrentPayrollTable groups={this.props.groups} />
          </Col>
        </Row>
      </>
    );
  };

  _getOutdatedGroupsDashboard = () => {
    return (
      <>
        <Row>
          <Col md={6}>
            <UpdateGroupCard
              numOfInvalidBanks={this.props.invalidBankIdsLinkedToGroups.length}
            />
          </Col>
        </Row>
      </>
    );
  };

  _getAllBanksRemovedDashboard = () => {
    return (
      <>
        <Row>
          <Col md={6}>
            <AddBankCard />
          </Col>
        </Row>
      </>
    );
  };

  _getFinchOnboardingFlow = () => {
    if (this.state.showRosterTable) {
      return (
        <RosterApprovalTable
          onClose={() => this.setState({ showRosterTable: false })}
          isFullyOnboarded={true}
        />
      );
    }

    return (
      <div className="accordian">
        <div className="accordian-intro">
          <IconHeader variant="cardHeader" headerText="Link Payroll" />
          <IconSubheader subheader="Complete these steps..." />
        </div>
        <FinchConnect
          hasBank={this.props.hasBank}
          showRosterTable={() => this.setState({ showRosterTable: true })}
          isReauthFlow={this.props.hasConnectionAuthError}
        />
      </div>
    );
  };

  _getContent = () => {
    let content = null;
    let subtext = null;
    let title;

    if (this.state.initialFetching) {
      content = <IconSpinner centered />;
      return { content, subtext, title };
    }

    const removedAllBankAccounts = !this.props.hasBank && this.props.hasGroup;

    const onboardedButMissingFinch =
      this.props.hasEnabledFinch &&
      !this.props.finchHasSyncError &&
      (!this.props.hasSuccessfullyConnectedFinch ||
        this.props.hasConnectionAuthError);

    if (removedAllBankAccounts) {
      title = "Add a Bank Account";
      subtext =
        "It looks like there are no bank accounts linked to your account. Let's link your bank account again " +
        "before updating your groups!";
      content = this._getAllBanksRemovedDashboard();
    } else if (this.props.invalidBankIdsLinkedToGroups.length > 0) {
      title = "Update Your Groups";
      subtext =
        "There are some groups with an invalid bank attached. Update your groups with a new bank to" +
        " start processing contributions again!";
      content = this._getOutdatedGroupsDashboard();
    } else if (onboardedButMissingFinch) {
      content = this._getFinchOnboardingFlow();
      subtext = this.props.hasConnectionAuthError
        ? "It looks like we need to reauthenticate with your payroll provider."
        : "Let's connect with your payroll provider.";
      title = this.props.company.name;
    } else {
      content = this._getFullOnboardedEmployerDashboard();
      subtext = "Here’s up to date information on your plan.";
      title = this.props.company.name;
    }

    return { content, subtext, title };
  };

  render() {
    const { content, subtext, title } = this._getContent();
    return (
      <div>
        <PageHeading title={title} subtext={subtext} />
        <div className="main-content">{content}</div>
      </div>
    );
  }
}

const errorSelector = createErrorSelector([
  bankConstants.OAUTH_PLAID_LINK_TOKEN,
]);

const mapStateToProps = (state) => {
  const pendingLinks = filter(
    state.employer.employerLinkRequests,
    (link) => link.status === "REQUEST_ACCESS"
  );

  const unacceptedInvites = filter(
    state.employer.employeeInvitations,
    (invite) => {
      return invite.status !== "ACCEPTED";
    }
  );
  const company = state.employer.company;

  const totalPlanParticipants = state.employer.linkedEmployees.length;

  return {
    companyState: get(state.user.companyState, "state", ""),
    groups: state.employer.groups,
    numberOfPendingInvites: unacceptedInvites.length,
    numberOfLinkRequests: pendingLinks.length,
    invalidBankIdsLinkedToGroups: invalidBankIdsLinkedToGroupsSelector(state),
    company,
    totalAmount: state.payroll.totalAmounts.totalAmount,
    linkedEmployees: totalPlanParticipants,
    error: errorSelector(state),
    //OnBoardingSelectors
    hasConnectionAuthError: hasFinchConnectionAuthError(state),
    hasBank: isBankVerifiedSelector(state),
    hasGroup: !isEmpty(state.employer.groups),
    hasEnabledFinch: hasEnabledFinchFeature(state),
    hasSuccessfullyConnectedFinch: hasSuccessfullyConnectedFinch(state),
    finchHasSyncError: finchHasSyncError(state),
  };
};

const mapDispatchToProps = {
  push,
  setTab,
  hydrateDashboard,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(EmployerDashboard));
