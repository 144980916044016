import React from "react";
import classnames from "classnames";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { push } from "connected-react-router";
import { withApollo } from "@apollo/client/react/hoc";
import { auth0AccountSessionSelector } from "store/selectors/auth0";
import { NavDropdown } from "react-bootstrap";
import { get, map, size } from "lodash";

import { accountConstants } from "actions/types";
import { createLoadingSelector } from "store/selectors";
import { setAccountSession } from "actions/accountActions";
import { accountTypesToEnglishMapping } from "statics/accountTypes";
import {
  isIndividualAccountOverview,
  activeAccountType,
} from "store/selectors/user";
import { FiChevronDown, FiCheck } from "react-icons/fi";

import "./AccountSwitcher.scss";

class AccountSwitcher extends React.PureComponent {
  static propTypes = {
    push: PropTypes.func,
    setAccountSession: PropTypes.func,
    client: PropTypes.object,
    accounts: PropTypes.arrayOf(PropTypes.object),
    isAccountOverview: PropTypes.bool,
    pathname: PropTypes.string,
    userState: PropTypes.string,
    activeAccountId: PropTypes.string,
    activeAccountType: PropTypes.string,
    isFetching: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      rotateChevron: false,
    };
  }

  _handleRotate = () => {
    this.setState({ rotateChevron: !this.state.rotateChevron });
  };

  _activeAccountName = () => {
    const activeAccount = this.props.isAccountOverview
      ? "OVERVIEW"
      : this.props.activeAccountType;

    const showDropdown = size(this.props.accounts) >= 2;
    return (
      <>
        <div className="account-switcher-target-box">
          <p className="active-account-name">
            {accountTypesToEnglishMapping[activeAccount]}
            {showDropdown && (
              <span className="down-arrow">
                <FiChevronDown
                  size={14}
                  className={classnames(
                    `nav-chevron  ${this.state.rotateChevron ? "rotate" : ""}`
                  )}
                />
              </span>
            )}
          </p>
        </div>
      </>
    );
  };

  _displayOverview = () => {
    return (
      <>
        <NavDropdown.Item
          className={classnames("action-item ", {
            ["is-selected"]: this.props.isAccountOverview,
          })}
          onClick={async () => {
            await this.props.setAccountSession(this.props.client, "");
            window.location.search = `?redirect_to=/dashboard`;
          }}
        >
          Overview
          {this.props.isAccountOverview && (
            <FiCheck stroke={"#0A2540"} size={14} />
          )}
        </NavDropdown.Item>
      </>
    );
  };

  _getAccountTypes = () => {
    return (
      <div className="action-list-popover account-switcher-dropdown">
        <div className="actions-list">
          {size(this.props.accounts) >= 2 && this._displayOverview()}
          {map(this.props.accounts, (account) => {
            return (
              <NavDropdown.Item
                key={account.id}
                className={classnames("action-item", {
                  ["is-selected"]: this.props.activeAccountId === account.id,
                })}
                onClick={async () => {
                  await this.props.setAccountSession(
                    this.props.client,
                    account.id
                  );
                  window.location.search = `?redirect_to=/dashboard`;
                }}
              >
                {accountTypesToEnglishMapping[account.accountType]}{" "}
                {this.props.activeAccountId === account.id && (
                  <FiCheck stroke={"#0A2540"} size={14} />
                )}
              </NavDropdown.Item>
            );
          })}
        </div>
      </div>
    );
  };

  render() {
    return (
      <>
        <div className="account-switcher">
          <NavDropdown
            disabled={size(this.props.accounts) === 1}
            title={this._activeAccountName()}
            onClick={this.props.isAccountOverview ? this._handleRotate : null}
            id="nav-dropdown"
          >
            {this._getAccountTypes()}
          </NavDropdown>
        </div>
      </>
    );
  }
}

const loadingSelector = createLoadingSelector(
  accountConstants.SET_ACCOUNT_SESSION
);

const mapStateToProps = (state) => {
  const userState = state.user.userState.state;
  const accounts = state.accounts.iraAccounts;
  return {
    pathname: get(state.router, "location.pathname"),
    isFetching: loadingSelector(state),
    activeAccountId: auth0AccountSessionSelector(state),
    isAccountOverview: isIndividualAccountOverview(state),
    activeAccountType: activeAccountType(state),
    userState,
    accounts,
  };
};

const mapDispatchToProps = {
  setAccountSession,
  push,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(AccountSwitcher));
