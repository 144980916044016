import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { connect } from "react-redux";
import classnames from "classnames";
import { FaBusinessTime } from "react-icons/fa";
import { withApollo } from "@apollo/client/react/hoc";
import { Card } from "react-bootstrap";
import Button from "components/Button";
import { getSetupIntent } from "actions/employerActions";
import { requiresBillingPaymentUpdate } from "store/selectors/employer";
import EmployerBilling from "pages/signUp/employer/EmployerBilling";

class BillingCard extends React.PureComponent {
  static propTypes = {
    client: PropTypes.object,
    verificationUrl: PropTypes.string,
    requiresPaymentMethodUpdate: PropTypes.bool,
  };

  constructor() {
    super();

    this.state = {
      showPaymentForm: false,
    };
  }

  _getVerificationBody = () => {
    return (
      <Card.Body>
        <Card.Title style={{ color: "#0a2540" }}>
          Finish verifying your ACH billing account
        </Card.Title>
        <Card.Text style={{ color: "#858c9c", fontSize: "14px" }}>
          In order to bill your bank account, our billing provider requires you
          to verify your bank account.
        </Card.Text>
        <a
          className={"icon-link"}
          href={this.props.verificationUrl}
          target={"_blank"}
          rel="noreferrer"
        >
          Verify Bank Account
        </a>
      </Card.Body>
    );
  };

  _getNeedsPaymentUpdateBody = () => {
    return (
      <Card.Body>
        <Card.Title style={{ color: "#0a2540" }}>
          Payment method needs updated
        </Card.Title>
        <Card.Text style={{ color: "#858c9c", fontSize: "14px" }}>
          There was a problem verifying your bank account. Please update your
          payment method to continue.
        </Card.Text>
        {!this.state.showPaymentForm && (
          <Button
            name="action"
            btnLabel={"Add PaymentMethod"}
            onClick={() => this.setState({ showPaymentForm: true })}
          />
        )}
        {this.state.showPaymentForm && (
          <EmployerBilling
            isDashboard
            onSuccess={() => this.setState({ showPaymentForm: false })}
          />
        )}
      </Card.Body>
    );
  };

  render() {
    const content = this.props.requiresPaymentMethodUpdate
      ? this._getNeedsPaymentUpdateBody()
      : this._getVerificationBody();
    return (
      <>
        <div className={classnames("kyb-card")}>
          <Card className="text-center">
            <div className="alert-icon-container">
              <FaBusinessTime size={42} color="#009baa" />
            </div>
            {content}
          </Card>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    verificationUrl: get(
      state,
      "employer.setupIntent.next_action.verify_with_microdeposits.hosted_verification_url",
      ""
    ),
    requiresPaymentMethodUpdate: requiresBillingPaymentUpdate(state),
  };
};

const mapDispatchToProps = {
  getSetupIntent,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(BillingCard));
