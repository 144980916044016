import React from "react";
import PropTypes from "prop-types";
import { isEqual, pick } from "lodash";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import ProfileInfoCard from "./ProfileInfoCard";

import { Col, Form } from "react-bootstrap";
import { Formik } from "formik";
import { withApollo } from "@apollo/client/react/hoc";

import StateSelect from "components/StateSelect";
import Button from "components/Button";
import AddressValidator from "components/AddressValidator";
import Alert from "components/Alert";
import { FiEdit } from "react-icons/fi";
import IconSpinner from "components/IconSpinner";

import {
  address2Validator,
  IndividualAddress1Validator,
  NAME_MESSAGE,
  postalCodeValidator,
  PRINTABLE_ASCII_MESSAGE,
  testName,
  testPhoneNumber,
  testPrintableAsciiCharacters,
  validateUsersAddress,
} from "utils/fieldValidators";
import { userService } from "services/userService";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { accountConstants, userConstants } from "actions/types";
import { updateUserProfile } from "actions/userActions";
import "./ProfileInfo.scss";
import { ScrollToFieldError } from "utils/form";

let yup = require("yup");

const schema = yup.object({
  firstName: yup
    .string()
    .label("First Name")
    .required()
    .test("is-valid-name", NAME_MESSAGE, testName)
    .min(1, "Must be at least one character.")
    .max(20),
  lastName: yup
    .string()
    .label("Last Name")
    .required()
    .test("is-valid-name", NAME_MESSAGE, testName)
    .min(1, "Must be at least one character.")
    .max(20),
  address1: IndividualAddress1Validator,
  address2: address2Validator,
  city: yup
    .string()
    .label("City")
    .required()
    .test("city", PRINTABLE_ASCII_MESSAGE, testPrintableAsciiCharacters)
    .max(50),
  state: yup
    .string()
    .label("State")
    .required()
    .test("state", PRINTABLE_ASCII_MESSAGE, testPrintableAsciiCharacters)
    .max(14),
  postalCode: postalCodeValidator,
  phoneNumber: yup
    .string()
    .label("Phone number")
    .required()
    .test(
      "us-phone",
      "${path} is not a valid phone number. Ex: (123) 456-7890 or 1234567890",
      testPhoneNumber
    )
    .max(15),
});

class ProfileInfo extends React.Component {
  static propTypes = {
    userInfo: PropTypes.object,
    fetchingProfile: PropTypes.bool,
    client: PropTypes.object,
    updateUserProfile: PropTypes.func,
    error: PropTypes.string,
    isUpdatingProfile: PropTypes.bool,
    iraAccounts: PropTypes.array,
  };

  constructor(props) {
    super(props);
    // TODO map user info props to component state
    this.state = {
      showAddressModal: false,
      fetchingAddress: false,
      isEditMode: false,
      userInfo: {
        ...props.userInfo,
      },
    };
  }

  componentDidUpdate(prevProps) {
    const finishedFetching =
      prevProps.fetchingProfile && !this.props.fetchingProfile;
    const userInfoFromPropsChanged = prevProps.userInfo !== this.props.userInfo;
    if (finishedFetching && userInfoFromPropsChanged) {
      this.setState({
        userInfo: this.props.userInfo,
      });
    }
  }

  _newAddressEqualsOldAddress = (userInfo) => {
    const addressFields = [
      "address1",
      "address2",
      "state",
      "city",
      "postalCode",
    ];

    const currentAddress = pick(this.props.userInfo, addressFields);
    const newAddress = pick(userInfo, addressFields);
    return isEqual(currentAddress, newAddress);
  };

  _updateUserInfo = async (values, skipAddressValidation) => {
    // call the update action once it exists
    const addressesAreEqual = this._newAddressEqualsOldAddress(values);
    if (!skipAddressValidation && !addressesAreEqual) {
      try {
        this.setState({ fetchingAddress: true });

        const userAddress = {
          address1: values.address1,
          address2: values.address2,
          state: values.state,
          postalCode: values.postalCode,
          city: values.city,
        };

        const validatedAddress = await userService.getValidatedAddress(
          this.props.client,
          userAddress
        );
        this.setState({ fetchingAddress: false });

        const userAddressIsValid = validateUsersAddress(
          userAddress,
          validatedAddress
        );

        if (!userAddressIsValid) {
          this.setState({
            showAddressModal: true,
            validatedAddress,
          });

          return;
        }
      } catch (error) {
        // if the validation endpoint fails we dont want to prevent user from saving so continue on and log error
        console.log(error);
      }
    }
    const updatedProfile = {
      ...this.state.userInfo,
      ...values,
    };
    const payload = pick(updatedProfile, [
      "legalName",
      "firstName",
      "lastName",
      "address1",
      "address2",
      "city",
      "state",
      "postalCode",
      "phoneNumber",
      "email",
    ]);

    // graphql adds this property and it will cause argument not accepted errors on server if included
    delete updatedProfile.__typename;

    this.props.updateUserProfile(this.props.client, payload).then(() => {
      this.setState({ isEditMode: false, userInfo: payload });
    });
  };

  _closeModal = () => {
    this.setState({ showAddressModal: false });
  };

  render() {
    return (
      <div
        className="tab-pane"
        id="account"
        role="tabpanel"
        aria-labelledby="account_tab"
      >
        {this.props.fetchingProfile && <IconSpinner centered />}
        {this.props.error && <Alert type="error" msg={this.props.error} />}
        {!this.props.fetchingProfile && !this.state.isEditMode && (
          <>
            <ProfileInfoCard
              userInfo={this.props.userInfo}
              iraAccounts={this.props.iraAccounts}
            />
            <div className="edit-button">
              <FiEdit
                className="edit"
                size={"24px"}
                color={"#FFFFFF"}
                fill={"#FFFFFF"}
                stroke={"#6b7c93"}
                strokeWidth={"1.5"}
                onClick={() => {
                  this.setState({ isEditMode: true });
                }}
              />
            </div>
          </>
        )}
        {!this.props.fetchingProfile && this.state.isEditMode && (
          <Formik
            validateOnChange={false}
            validationSchema={schema}
            onSubmit={(values) => {
              this._updateUserInfo(values);
            }}
            enableReinitialize={true}
            initialValues={this.state.userInfo}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              setFieldValue,
              values,
              touched,
              errors,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <ScrollToFieldError />
                {this.state.showAddressModal && (
                  <AddressValidator
                    show={true}
                    onSubmit={this._updateUserInfo}
                    setFieldValue={setFieldValue}
                    validatedAddress={this.state.validatedAddress}
                    onClose={this._closeModal}
                    values={values}
                  />
                )}
                <div className="mega-container">
                  <div
                    className="step-container is-active"
                    data-circle-percent="30"
                  >
                    <div id="form-individual-info">
                      <section>
                        <article
                          className="col-form"
                          style={{ paddingBottom: 0 }}
                        >
                          <Form.Label>Legal Name</Form.Label>
                          <Form.Row>
                            <Form.Group
                              as={Col}
                              md={6}
                              controlId="formBasicFirstName"
                            >
                              <Form.Control
                                name="firstName"
                                placeholder="First Name"
                                value={values.firstName}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                isInvalid={
                                  touched.firstName && !!errors.firstName
                                }
                                isValid={touched.firstName && !errors.firstName}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.firstName}
                              </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group
                              as={Col}
                              md={6}
                              controlId="formBasicLastName"
                            >
                              <Form.Control
                                name="lastName"
                                placeholder="Last Name"
                                value={values.lastName}
                                onChange={handleChange}
                                isInvalid={
                                  touched.lastName && !!errors.lastName
                                }
                                isValid={touched.lastName && !errors.lastName}
                                onBlur={handleBlur}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.lastName}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Form.Row>

                          <Form.Label>U.S. Address</Form.Label>
                          <Form.Row>
                            <Form.Group
                              as={Col}
                              md={12}
                              controlId="formBasicaddress1"
                            >
                              <Form.Control
                                name="address1"
                                placeholder="Address 1"
                                value={values.address1}
                                onChange={handleChange}
                                isInvalid={
                                  touched.address1 && !!errors.address1
                                }
                                isValid={touched.address1 && !errors.address1}
                                onBlur={handleBlur}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.address1}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Form.Row>
                          <Form.Row>
                            <Form.Group
                              as={Col}
                              md={12}
                              controlId="formBasicaddress2"
                            >
                              <Form.Control
                                name="address2"
                                placeholder="Address 2"
                                value={values.address2}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                isInvalid={
                                  touched.address2 && !!errors.address2
                                }
                                isValid={touched.address2 && !errors.address2}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.address2}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Form.Row>
                          <Form.Row>
                            <Form.Group
                              as={Col}
                              md={4}
                              controlId="formBasicCity"
                            >
                              <Form.Control
                                name="city"
                                placeholder="City"
                                value={values.city}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={touched.city && !!errors.city}
                                isValid={touched.city && !errors.city}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.city}
                              </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group
                              as={Col}
                              md={4}
                              controlId="formBasicState"
                            >
                              <StateSelect
                                value={values.state}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                touched={touched}
                                errors={errors}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.state}
                              </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group
                              as={Col}
                              md={4}
                              controlId="formBasicPostalCode"
                            >
                              <Form.Control
                                name="postalCode"
                                placeholder="Zip"
                                value={values.postalCode}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={
                                  touched.postalCode && !!errors.postalCode
                                }
                                isValid={
                                  touched.postalCode && !errors.postalCode
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.postalCode}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Form.Row>
                          <Form.Row>
                            <Form.Group
                              as={Col}
                              sm={5}
                              controlId="formBasicPhone"
                            >
                              <Form.Control
                                name="phoneNumber"
                                placeholder="Phone"
                                value={values.phoneNumber}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                isInvalid={
                                  touched.phoneNumber && !!errors.phoneNumber
                                }
                                isValid={
                                  touched.phoneNumber && !errors.phoneNumber
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.phoneNumber}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Form.Row>
                        </article>
                      </section>
                      <section className="form-sec-2col">
                        <article className="col-form">
                          <div className="submit-row">
                            <div className="buttons">
                              <Button
                                size="sm"
                                className="cancel-button"
                                type="button"
                                color="cancel"
                                name="cancel"
                                btnLabel="Cancel"
                                onClick={() => {
                                  this.setState({ isEditMode: false });
                                }}
                              />
                              {this.state.isEditMode &&
                                !this.props.fetchingProfile && (
                                  <div className="save-button">
                                    <Button
                                      size="sm"
                                      withArrow={true}
                                      name="submit"
                                      btnLabel="Save"
                                      loading={
                                        this.props.isUpdatingProfile ||
                                        this.state.fetchingAddress
                                      }
                                    />
                                  </div>
                                )}
                            </div>
                          </div>
                        </article>
                      </section>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </div>
    );
  }
}

const profileLoadSelector = createLoadingSelector(userConstants.USER_PROFILE);
const profileUpdateSelector = createLoadingSelector(
  userConstants.USER_PROFILE_UPDATE,
  accountConstants.GET_ACCOUNTS
);
const profileUpdateErrorSelector = createErrorSelector(
  userConstants.USER_PROFILE_UPDATE,
  accountConstants.GET_ACCOUNTS,
  userConstants.USER_PROFILE
);
const mapStateToProps = (state, ownProps) => {
  const { id, profile: userInfo } = state.user;
  const iraAccounts = state.accounts.iraAccounts;

  return {
    id,
    userInfo,
    ownProps,
    isUpdatingProfile: profileUpdateSelector(state),
    fetchingProfile: profileLoadSelector(state),
    error: profileUpdateErrorSelector(state),
    iraAccounts,
  };
};

const mapDispatchToProps = { push, updateUserProfile };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(ProfileInfo));
