import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { withApollo } from "@apollo/client/react/hoc";
import { FiClock } from "react-icons/fi";
import { Card, Col, Row } from "react-bootstrap";
import { formatCurrency } from "utils/number";
import { hasAutoApprovalLinkRequestsFeature } from "store/selectors/employer";

import Button from "components/Button";

import "./EmployerDashboard.scss";

class TotalsTable extends React.PureComponent {
  static propTypes = {
    push: PropTypes.func,
    numOfPendingApprovals: PropTypes.number,
    totalAmount: PropTypes.number,
    linkedEmployees: PropTypes.number,
    hasAutoApprovalFeature: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <>
        <Card className="dashboard-widget">
          <div
            className="widget-header"
            style={{
              paddingTop: 10,
              paddingBottom: 10,
            }}
          >
            <span>
              <Card.Title>Totals</Card.Title>
            </span>
            {!this.props.hasAutoApprovalFeature && (
              <Button
                size="sm"
                name="action"
                icon={{
                  icon: <FiClock size={14} color={"#fffff"} />,
                  position: "left",
                }}
                onClick={() => {
                  this.props.push(`/dashboard/company/group`);
                }}
                btnLabel="See pending approvals"
              />
            )}
          </div>
          <div className="enrollment-table">
            <Row>
              <Col className="enrollment">
                <span className="enrollment-description">
                  <p className="enrollment-label">
                    Total Participant Contributions
                  </p>
                  <p className="enrollment-value">
                    {formatCurrency(this.props.totalAmount)}
                  </p>
                </span>
              </Col>

              <Col className="enrollment">
                <span className="enrollment-description">
                  <p className="enrollment-label">Current Participants</p>
                  <p className="enrollment-value">
                    {this.props.linkedEmployees}
                  </p>
                </span>
              </Col>

              <Col className="enrollment">
                <span className="enrollment-description">
                  <p className="enrollment-label">New Participant Approvals</p>
                  <p className="enrollment-value">
                    {this.props.numOfPendingApprovals}
                  </p>
                </span>
              </Col>
            </Row>
          </div>
        </Card>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    hasAutoApprovalFeature: hasAutoApprovalLinkRequestsFeature(state),
  };
};

const mapDispatchToProps = { push };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(TotalsTable));
