export const userConstants = {
  REGISTER_USER_REQUEST: "REGISTER_USER_REQUEST",
  REGISTER_USER_SUCCESS: "REGISTER_USER_SUCCESS",
  REGISTER_USER_FAILURE: "REGISTER_USER_FAILURE",
  REGISTER_USER: "REGISTER_USER",

  REGISTER_ADMIN_USER_REQUEST: "REGISTER_ADMIN_USER_REQUEST",
  REGISTER_ADMIN_USER_SUCCESS: "REGISTER_ADMIN_USER_SUCCESS",
  REGISTER_ADMIN_USER_FAILURE: "REGISTER_ADMIN_USER_FAILURE",
  REGISTER_ADMIN_USER: "REGISTER_ADMIN_USER",

  USER_STATE_REQUEST: "USER_STATE_REQUEST",
  USER_STATE_SUCCESS: "USER_STATE_SUCCESS",
  USER_STATE_FAILURE: "USER_STATE_FAILURE",
  USER_STATE: "USER_STATE",

  USER_PROFILE_REQUEST: "USER_PROFILE_REQUEST",
  USER_PROFILE_SUCCESS: "USER_PROFILE_SUCCESS",
  USER_PROFILE_FAILURE: "USER_PROFILE_FAILURE",
  USER_PROFILE: "USER_PROFILE",

  USER_CLOSE_ACCOUNT_REQUEST: "USER_CLOSE_ACCOUNT_REQUEST",
  USER_CLOSE_ACCOUNT_SUCCESS: "USER_CLOSE_ACCOUNT_SUCCESS",
  USER_CLOSE_ACCOUNT_FAILURE: "USER_CLOSE_ACCOUNT_FAILURE",
  USER_CLOSE_ACCOUNT: "USER_PROFILE",

  USER_PROFILE_UPDATE_REQUEST: "USER_PROFILE_UPDATE_REQUEST",
  USER_PROFILE_UPDATE_SUCCESS: "USER_PROFILE_UPDATE_SUCCESS",
  USER_PROFILE_UPDATE_FAILURE: "USER_PROFILE_UPDATE_FAILURE",
  USER_PROFILE_UPDATE: "USER_PROFILE_UPDATE",

  USER_INVESTMENT_PROFILE_UPDATE_REQUEST:
    "USER_INVESTMENT_PROFILE_UPDATE_REQUEST",
  USER_INVESTMENT_PROFILE_UPDATE_SUCCESS:
    "USER_INVESTMENT_PROFILE_UPDATE_SUCCESS",
  USER_INVESTMENT_PROFILE_UPDATE_FAILURE:
    "USER_INVESTMENT_PROFILE_UPDATE_FAILURE",
  USER_INVESTMENT_PROFILE_UPDATE: "USER_INVESTMENT_PROFILE_UPDATE",

  USER_UPLOADED_DOCUMENT: "USER_UPLOADED_DOCUMENT",
  USER_UPLOADED_DOCUMENT_REQUEST: "USER_UPLOADED_DOCUMENT_REQUEST",
  USER_UPLOADED_DOCUMENT_SUCCESS: "USER_UPLOADED_DOCUMENT_SUCCESS",
  USER_UPLOADED_DOCUMENT_FAILURE: "USER_UPLOADED_DOCUMENT_FAILURE",

  USER_LOGOUT: "USER_LOGOUT",
  USER_LOGOUT_REQUEST: "USER_LOGOUT_REQUEST",
  USER_LOGOUT_SUCCESS: "USER_LOGOUT_SUCCESS",
  USER_LOGOUT_FAILURE: "USER_LOGOUT_FAILURE",

  CONFIRM_EMAIL: "CONFIRM_EMAIL",
  CONFIRM_EMAIL_REQUEST: "CONFIRM_EMAIL_REQUEST",
  CONFIRM_EMAIL_SUCCESS: "CONFIRM_EMAIL_SUCCESS",
  CONFIRM_EMAIL_FAILURE: "CONFIRM_EMAIL_FAILURE",

  EMAIL_CHANGE_REQUEST: "EMAIL_CHANGE_REQUEST",
  EMAIL_CHANGE_SUCCESS: "EMAIL_CHANGE_SUCCESS",
  EMAIL_CHANGE_FAILURE: "EMAIL_CHANGE_FAILURE",
  EMAIL_CHANGE: "EMAIL_CHANGE",

  VERIFY_EMAIL_CHANGE_REQUEST: "VERIFY_EMAIL_CHANGE_REQUEST",
  VERIFY_EMAIL_CHANGE_SUCCESS: "VERIFY_EMAIL_CHANGE_SUCCESS",
  VERIFY_EMAIL_CHANGE_FAILURE: "VERIFY_EMAIL_CHANGE_FAILURE",
  VERIFY_EMAIL_CHANGE: "VERIFY_EMAIL_CHANGE",

  GET_EMAIL_CHANGE_REQUEST: "GET_EMAIL_CHANGE_REQUEST",
  GET_EMAIL_CHANGE_SUCCESS: "GET_EMAIL_CHANGE_SUCCESS",
  GET_EMAIL_CHANGE_FAILURE: "GET_EMAIL_CHANGE_FAILURE",
  GET_EMAIL_CHANGE: "GET_EMAIL_CHANGE",

  DELETE_EMAIL_CHANGE_REQUEST: "DELETE_EMAIL_CHANGE_REQUEST",
  DELETE_EMAIL_CHANGE_SUCCESS: "DELETE_EMAIL_CHANGE_SUCCESS",
  DELETE_EMAIL_CHANGE_FAILURE: "DELETE_EMAIL_CHANGE_FAILURE",
  DELETE_EMAIL_CHANGE: "DELETE_EMAIL_CHANGE",

  USER_ON_BOARDING_IDENTITY_UPDATE_REQUEST:
    "USER_ON_BOARDING_IDENTITY_UPDATE_REQUEST",
  USER_ON_BOARDING_IDENTITY_UPDATE_SUCCESS:
    "USER_ON_BOARDING_IDENTITY_UPDATE_SUCCESS",
  USER_ON_BOARDING_IDENTITY_UPDATE_FAILURE:
    "USER_ON_BOARDING_IDENTITY_UPDATE_FAILURE",
  USER_ON_BOARDING_IDENTITY_UPDATE: "USER_ON_BOARDING_IDENTITY_UPDATE",

  USER_ON_BOARDING_TRUSTED_CONTACT_UPDATE_REQUEST:
    "USER_ON_BOARDING_TRUSTED_CONTACT_UPDATE_REQUEST",
  USER_ON_BOARDING_TRUSTED_CONTACT_UPDATE_SUCCESS:
    "USER_ON_BOARDING_TRUSTED_CONTACT_UPDATE_SUCCESS",
  USER_ON_BOARDING_TRUSTED_CONTACT_UPDATE_FAILURE:
    "USER_ON_BOARDING_TRUSTED_CONTACT_UPDATE_FAILURE",
  USER_ON_BOARDING_TRUSTED_CONTACT_UPDATE:
    "USER_ON_BOARDING_TRUSTED_CONTACT_UPDATE",

  USER_ON_BOARDING_EMPLOYMENT_STATUS_UPDATE_REQUEST:
    "USER_ON_BOARDING_EMPLOYMENT_STATUS_UPDATE_REQUEST",
  USER_ON_BOARDING_EMPLOYMENT_STATUS_UPDATE_SUCCESS:
    "USER_ON_BOARDING_EMPLOYMENT_STATUS_UPDATE_SUCCESS",
  USER_ON_BOARDING_EMPLOYMENT_STATUS_UPDATE_FAILURE:
    "USER_ON_BOARDING_EMPLOYMENT_STATUS_UPDATE_FAILURE",
  USER_ON_BOARDING_EMPLOYMENT_STATUS_UPDATE:
    "USER_ON_BOARDING_EMPLOYMENT_STATUS_UPDATE",

  USER_ON_BOARDING_EMPLOYMENT_POSITION_UPDATE_REQUEST:
    "USER_ON_BOARDING_EMPLOYMENT_POSITION_UPDATE_REQUEST",
  USER_ON_BOARDING_EMPLOYMENT_POSITION_UPDATE_SUCCESS:
    "USER_ON_BOARDING_EMPLOYMENT_POSITION_UPDATE_SUCCESS",
  USER_ON_BOARDING_EMPLOYMENT_POSITION_UPDATE_FAILURE:
    "USER_ON_BOARDING_EMPLOYMENT_POSITION_UPDATE_FAILURE",
  USER_ON_BOARDING_EMPLOYMENT_POSITION_UPDATE_UPDATE:
    "USER_ON_BOARDING_EMPLOYMENT_POSITION_UPDATE",

  USER_ON_BOARDING_CITIZENSHIP_UPDATE_REQUEST:
    "USER_ON_BOARDING_CITIZENSHIP_UPDATE_REQUEST",
  USER_ON_BOARDING_CITIZENSHIP_UPDATE_SUCCESS:
    "USER_ON_BOARDING_CITIZENSHIP_UPDATE_SUCCESS",
  USER_ON_BOARDING_CITIZENSHIP_UPDATE_FAILURE:
    "USER_ON_BOARDING_CITIZENSHIP_UPDATE_FAILURE",
  USER_ON_BOARDING_CITIZENSHIP_UPDATE_UPDATE:
    "USER_ON_BOARDING_CITIZENSHIP_UPDATE_UPDATE",

  USER_ON_BOARDING_DISCLOSURE_UPDATE_REQUEST:
    "USER_ON_BOARDING_DISCLOSURE_UPDATE_REQUEST",
  USER_ON_BOARDING_DISCLOSURE_UPDATE_SUCCESS:
    "USER_ON_BOARDING_DISCLOSURE_UPDATE_SUCCESS",
  USER_ON_BOARDING_DISCLOSURE_UPDATE_FAILURE:
    "USER_ON_BOARDING_DISCLOSURE_UPDATE_FAILURE",
  USER_ON_BOARDING_DISCLOSURE_UPDATE: "USER_ON_BOARDING_DISCLOSURE_UPDATE",

  USER_ON_BOARDING_INVESTMENT_PROFILE_UPDATE_REQUEST:
    "USER_ON_BOARDING_INVESTMENT_PROFILE_UPDATE_REQUEST",
  USER_ON_BOARDING_INVESTMENT_PROFILE_UPDATE_SUCCESS:
    "USER_ON_BOARDING_INVESTMENT_PROFILE_UPDATE_SUCCESS",
  USER_ON_BOARDING_INVESTMENT_PROFILE_UPDATE_FAILURE:
    "USER_ON_BOARDING_DISCLOSURE_UPDATE_FAILURE",
  USER_ON_BOARDING_INVESTMENT_PROFILE_UPDATE:
    "USER_ON_BOARDING_INVESTMENT_PROFILE_UPDATE",

  USER_ON_BOARDING_INVESTMENT_SUITABILITY_UPDATE_REQUEST:
    "USER_ON_BOARDING_INVESTMENT_SUITABILITY_UPDATE_REQUEST",
  USER_ON_BOARDING_INVESTMENT_SUITABILITY_UPDATE_SUCCESS:
    "USER_ON_BOARDING_INVESTMENT_SUITABILITY_UPDATE_SUCCESS",
  USER_ON_BOARDING_INVESTMENT_SUITABILITY_UPDATE_FAILURE:
    "USER_ON_BOARDING_INVESTMENT_SUITABILITY_UPDATE_FAILURE",
  USER_ON_BOARDING_INVESTMENT_SUITABILITY_UPDATE:
    "USER_ON_BOARDING_INVESTMENT_SUITABILITY_UPDATE",

  GET_INVESTMENT_PROFILE_REQUEST: "GET_INVESTMENT_PROFILE_REQUEST",
  GET_INVESTMENT_PROFILE_SUCCESS: "GET_INVESTMENT_PROFILE_SUCCESS",
  GET_INVESTMENT_PROFILE_FAILURE: "GET_INVESTMENT_PROFILE_FAILURE",
  GET_INVESTMENT_PROFILE: "GET_INVESTMENT_PROFILE",

  USER_ON_BOARDING_INVESTMENT_TERMS_UPDATE_REQUEST:
    "USER_ON_BOARDING_INVESTMENT_TERMS_UPDATE_REQUEST",
  USER_ON_BOARDING_INVESTMENT_TERMS_UPDATE_SUCCESS:
    "USER_ON_BOARDING_INVESTMENT_TERMS_UPDATE_SUCCESS",
  USER_ON_BOARDING_INVESTMENT_TERMS_UPDATE_FAILURE:
    "USER_ON_BOARDING_INVESTMENT_TERMS_UPDATE_FAILURE",
  USER_ON_BOARDING_INVESTMENT_TERMS_UPDATE:
    "USER_ON_BOARDING_INVESTMENT_TERMS_UPDATE",

  USER_ON_BOARDING_COMPLETED_REQUEST: "USER_ON_BOARDING_COMPLETED_REQUEST",
  USER_ON_BOARDING_COMPLETED_SUCCESS: "USER_ON_BOARDING_COMPLETED_SUCCESS",
  USER_ON_BOARDING_COMPLETED_FAILURE: "USER_ON_BOARDING_COMPLETED_FAILURE",
  USER_ON_BOARDING_COMPLETED: "USER_ON_BOARDING_COMPLETED",

  USER_KYC_COMPLETED_REQUEST: "USER_KYC_COMPLETED_REQUEST",
  USER_KYC_COMPLETED_SUCCESS: "USER_KYC_COMPLETED_SUCCESS",
  USER_KYC_COMPLETED_FAILURE: "USER_KYC_COMPLETED_FAILURE",
  USER_KYC_COMPLETED: "USER_KYC_COMPLETED",

  USER_SUBMIT_YELLOWPATH_DOCS_REQUEST: "USER_SUBMIT_YELLOWPATH_DOCS_REQUEST",
  USER_SUBMIT_YELLOWPATH_DOCS_SUCCESS: "USER_SUBMIT_YELLOWPATH_DOCS_SUCCESS",
  USER_SUBMIT_YELLOWPATH_DOCS_FAILURE: "USER_SUBMIT_YELLOWPATH_DOCS_FAILURE",
  USER_SUBMIT_YELLOWPATH_DOCS: "USER_SUBMIT_YELLOWPATH_DOCS",

  USER_NEEDED_YELLOWPATH_DOCS_REQUEST: "USER_NEEDED_YELLOWPATH_DOCS_REQUEST",
  USER_NEEDED_YELLOWPATH_DOCS_SUCCESS: "USER_NEEDED_YELLOWPATH_DOCS_SUCCESS",
  USER_NEEDED_YELLOWPATH_DOCS_FAILURE: "USER_NEEDED_YELLOWPATH_DOCS_FAILURE",
  USER_NEEDED_YELLOWPATH_DOCS: "USER_NEEDED_YELLOWPATH_DOCS",

  USER_EMPLOYER_TERMS_UPDATE_REQUEST: "USER_EMPLOYER_TERMS_UPDATE_REQUEST",
  USER_EMPLOYER_TERMS_UPDATE_SUCCESS: "USER_EMPLOYER_TERMS_UPDATE_SUCCESS",
  USER_EMPLOYER_TERMS_UPDATE_FAILURE: "USER_EMPLOYER_TERMS_UPDATE_FAILURE",
  USER_EMPLOYER_TERMS_UPDATE: "USER_EMPLOYER_TERMS_UPDATE",
  SET_INITIAL_USER_LOADING: "SET_INITIAL_USER_LOADING",

  USER_ACCEPT_INVITATION_REQUEST: "USER_ACCEPT_INVITATION_REQUEST",
  USER_ACCEPT_INVITATION_SUCCESS: "USER_ACCEPT_INVITATION_SUCCESS",
  USER_ACCEPT_INVITATION_FAILURE: "USER_ACCEPT_INVITATION_FAILURE",
  USER_ACCEPT_INVITATION: "USER_ACCEPT_INVITATION",

  OPT_OUT_REQUEST: "OPT_OUT_REQUEST",
  OPT_OUT_SUCCESS: "OPT_OUT_SUCCESS",
  OPT_OUT_FAILURE: "OPT_OUT_FAILURE",
  OPT_OUT: "OPT_OUT",

  USER_EMPLOYER_LINK_REQUESTS_REQUEST: "USER_EMPLOYER_LINK_REQUESTS_REQUEST",
  USER_EMPLOYER_LINK_REQUESTS_SUCCESS: "USER_EMPLOYER_LINK_REQUESTS_SUCCESS",
  USER_EMPLOYER_LINK_REQUESTS_FAILURE: "USER_EMPLOYER_LINK_REQUESTS_FAILURE",
  USER_EMPLOYER_LINK_REQUESTS: "USER_EMPLOYER_LINK_REQUESTS",

  USER_EMPLOYER_LINK_REQUEST: "USER_EMPLOYER_LINK_REQUEST",
  USER_EMPLOYER_LINK_SUCCESS: "USER_EMPLOYER_LINK_SUCCESS",
  USER_EMPLOYER_LINK_FAILURE: "USER_EMPLOYER_LINK_FAILURE",
  USER_EMPLOYER_LINK: "USER_EMPLOYER_LINK",

  DELETE_USER_EMPLOYER_LINK_REQUEST: "DELETE_USER_EMPLOYER_LINK_REQUEST",
  DELETE_USER_EMPLOYER_LINK_SUCCESS: "DELETE_USER_EMPLOYER_LINK_SUCCESS",
  DELETE_USER_EMPLOYER_LINK_FAILURE: "DELETE_USER_EMPLOYER_LINK_FAILURE",
  DELETE_USER_EMPLOYER_LINK: "DELETE_USER_EMPLOYER_LINK",

  DELETE_USER_EMPLOYER_LINK_REQ_REQUEST:
    "DELETE_USER_EMPLOYER_LINK_REQ_REQUEST",
  DELETE_USER_EMPLOYER_LINK_REQ_SUCCESS:
    "DELETE_USER_EMPLOYER_LINK_REQ_SUCCESS",
  DELETE_USER_EMPLOYER_LINK_REQ_FAILURE:
    "DELETE_USER_EMPLOYER_LINK_REQ_FAILURE",
  DELETE_USER_EMPLOYER_LINK_REQ: "DELETE_USER_EMPLOYER_LINK_REQ",

  SUBMIT_SSN_REQUEST: "SUBMIT_SSN_REQUEST",
  SUBMIT_SSN_SUCCESS: "SUBMIT_SSN_SUCCESS",
  SUBMIT_SSN_FAILURE: "SUBMIT_SSN_FAILURE",
  SUBMIT_SSN: "SUBMIT_SSN",

  SUBMIT_EIN_REQUEST: "SUBMIT_EIN_REQUEST",
  SUBMIT_EIN_SUCCESS: "SUBMIT_EIN_SUCCESS",
  SUBMIT_EIN_FAILURE: "SUBMIT_EIN_FAILURE",
  SUBMIT_EIN: "SUBMIT_EIN",

  VALIDATE_EIN_REQUEST: "VALIDATE_EIN_REQUEST",
  VALIDATE_EIN_SUCCESS: "VALIDATE_EIN_SUCCESS",
  VALIDATE_EIN_FAILURE: "VALIDATE_EIN_FAILURE",
  VALIDATE_EIN: "VALIDATE_EIN",

  CREATE_REQUEST_REQUEST: "CREATE_REQUEST_REQUEST",
  CREATE_REQUEST_SUCCESS: "CREATE_REQUEST_SUCCESS",
  CREATE_REQUEST_FAILURE: "CREATE_REQUEST_FAILURE",
  CREATE_REQUEST: "CREATE_REQUEST",

  CLEAR_USER_UPLOADED_DOCUMENT_REQUEST: "CLEAR_USER_UPLOADED_DOCUMENT_REQUEST",
  CLEAR_USER_UPLOADED_DOCUMENT_SUCCESS: "CLEAR_USER_UPLOADED_DOCUMENT_SUCCESS",
  CLEAR_USER_UPLOADED_DOCUMENT_FAILURE: "CLEAR_USER_UPLOADED_DOCUMENT_FAILURE",
  CLEAR_USER_UPLOADED_DOCUMENT: "CLEAR_USER_UPLOADED_DOCUMENT",

  GET_USER_REQUIRED_STEPS_REQUEST: "GET_USER_REQUIRED_STEPS_REQUEST",
  GET_USER_REQUIRED_STEPS_SUCCESS: "GET_USER_REQUIRED_STEPS_SUCCESS",
  GET_USER_REQUIRED_STEPS_FAILURE: "GET_USER_REQUIRED_STEPS_FAILURE",
  GET_USER_REQUIRED_STEPS: "GET_USER_REQUIRED_STEPS",

  UPDATE_REQUIRED_DISCLOSURES_REQUEST: "UPDATE_REQUIRED_DISCLOSURES_REQUEST",
  UPDATE_REQUIRED_DISCLOSURES_SUCCESS: "UPDATE_REQUIRED_DISCLOSURES_SUCCESS",
  UPDATE_REQUIRED_DISCLOSURES_FAILURE: "UPDATE_REQUIRED_DISCLOSURES_FAILURE",
  UPDATE_REQUIRED_DISCLOSURES: "UPDATE_REQUIRED_DISCLOSURES",

  UPDATE_SELECTED_ACCOUNT_TYPE_REQUEST: "UPDATE_SELECTED_ACCOUNT_TYPE_REQUEST",
  UPDATE_SELECTED_ACCOUNT_TYPE_SUCCESS: "UPDATE_SELECTED_ACCOUNT_TYPE_SUCCESS",
  UPDATE_SELECTED_ACCOUNT_TYPE_FAILURE: "UPDATE_SELECTED_ACCOUNT_TYPE_FAILURE",
  UPDATE_SELECTED_ACCOUNT_TYPE: "UPDATE_SELECTED_ACCOUNT_TYPE",

  INITIALIZE_ROLLOVER_REQUEST: "INITIALIZE_ROLLOVER_REQUEST",
  INITIALIZE_ROLLOVER_SUCCESS: "INITIALIZE_ROLLOVER_SUCCESS",
  INITIALIZE_ROLLOVER_FAILURE: "INITIALIZE_ROLLOVER_FAILURE",
  INITIALIZE_ROLLOVER: "INITIALIZE_ROLLOVER",

  SUBMIT_ROLLOVER_INFO_REQUEST: " SUBMIT_ROLLOVER_INFO_REQUEST",
  SUBMIT_ROLLOVER_INFO_SUCCESS: " SUBMIT_ROLLOVER_INFO_SUCCESS",
  SUBMIT_ROLLOVER_INFO_FAILURE: " SUBMIT_ROLLOVER_INFO_FAILURE",
  SUBMIT_ROLLOVER_INFO: " SUBMIT_ROLLOVER_INFO",

  ADD_ROLLOVER_ACCOUNT_DISCLOSURE_REQUEST:
    "ADD_ROLLOVER_ACCOUNT_DISCLOSURE_REQUEST",
  ADD_ROLLOVER_ACCOUNT_DISCLOSURE_SUCCESS:
    "ADD_ROLLOVER_ACCOUNT_DISCLOSURE_SUCCESS",
  ADD_ROLLOVER_ACCOUNT_DISCLOSURE_FAILURE:
    "ADD_ROLLOVER_ACCOUNT_DISCLOSURE_FAILURE",
  ADD_ROLLOVER_ACCOUNT_DISCLOSURE: "ADD_ROLLOVER_ACCOUNT_DISCLOSURE",

  USER_ROLLOVER_INVESTMENT_PROFILE_UPDATE_REQUEST:
    "USER_ROLLOVER_INVESTMENT_PROFILE_UPDATE_REQUEST",
  USER_ROLLOVER_INVESTMENT_PROFILE_UPDATE_SUCCESS:
    "USER_ROLLOVER_INVESTMENT_PROFILE_UPDATE_SUCCESS",
  USER_ROLLOVER_INVESTMENT_PROFILE_UPDATE_FAILURE:
    "USER_ROLLOVER_INVESTMENT_PROFILE_UPDATE_FAILURE",
  USER_ROLLOVER_INVESTMENT_PROFILE_UPDATE:
    "USER_ROLLOVER_INVESTMENT_PROFILE_UPDATE",

  UPDATE_ROLLOVER_ACCOUNT_TERMS_SUBMIT_REQUEST:
    "UPDATE_ROLLOVER_ACCOUNT_TERMS_SUBMIT_REQUEST",
  UPDATE_ROLLOVER_ACCOUNT_TERMS_SUBMIT_SUCCESS:
    "UPDATE_ROLLOVER_ACCOUNT_TERMS_SUBMIT_SUCCESS",
  UPDATE_ROLLOVER_ACCOUNT_TERMS_SUBMIT_FAILURE:
    "UPDATE_ROLLOVER_ACCOUNT_TERMS_SUBMIT_FAILURE",
  UPDATE_ROLLOVER_ACCOUNT_TERMS_SUBMIT: "UPDATE_ROLLOVER_ACCOUNT_TERMS_SUBMIT",

  GET_USER_TERMS_DATE_REQUEST: "GET_USER_TERMS_DATE_REQUEST",
  GET_USER_TERMS_DATE_SUCCESS: "GET_USER_TERMS_DATE_SUCCESS",
  GET_USER_TERMS_DATE_FAILURE: "GET_USER_TERMS_DATE_FAILURE",
  GET_USER_TERMS_DATE: "GET_USER_TERMS_DATE",

  GET_ROLLOVER_INFO_REQUEST: "GET_ROLLOVER_INFO_REQUEST",
  GET_ROLLOVER_INFO_SUCCESS: "GET_ROLLOVER_INFO_SUCCESS",
  GET_ROLLOVER_INFO_FAILURE: "GET_ROLLOVER_INFO_FAILURE",
  GET_ROLLOVER_INFO: "GET_ROLLOVER_INFO",

  CANCEL_ROLLOVER_REQUEST: "CANCEL_ROLLOVER_REQUEST",
  CANCEL_ROLLOVER_SUCCESS: "CANCEL_ROLLOVER_SUCCESS",
  CANCEL_ROLLOVER_FAILURE: "CANCEL_ROLLOVER_FAILURE",
  CANCEL_ROLLOVER: "CANCEL_ROLLOVER",
};

export const employerConstants = {
  REGISTER_EMPLOYER_REQUEST: "REGISTER_EMPLOYER_REQUEST",
  REGISTER_EMPLOYER_SUCCESS: "REGISTER_EMPLOYER_SUCCESS",
  REGISTER_EMPLOYER_FAILURE: "REGISTER_EMPLOYER_FAILURE",
  REGISTER_EMPLOYER: "REGISTER_EMPLOYER",

  REGISTER_AGENT_REQUEST: "REGISTER_AGENT_REQUEST",
  REGISTER_AGENT_SUCCESS: "REGISTER_AGENT_SUCCESS",
  REGISTER_AGENT_FAILURE: "REGISTER_AGENT_FAILURE",
  REGISTER_AGENT: "REGISTER_AGENT",

  GET_COMPANY: "GET_COMPANY",
  GET_COMPANY_REQUEST: "GET_COMPANY_REQUEST",
  GET_COMPANY_SUCCESS: "GET_COMPANY_SUCCESS",
  GET_COMPANY_FAILURE: "GET_COMPANY_FAILURE",

  GET_EMPLOYER_ACCOUNTS_REQUEST: "GET_EMPLOYER_ACCOUNTS_REQUEST",
  GET_EMPLOYER_ACCOUNTS_SUCCESS: "GET_EMPLOYER_ACCOUNTS_SUCCESS",
  GET_EMPLOYER_ACCOUNTS_FAILURE: "GET_EMPLOYER_ACCOUNTS_FAILURE",
  GET_EMPLOYER_ACCOUNTS: "GET_EMPLOYER_ACCOUNTS",

  GET_USERS_FOR_GROUP_REQUEST: "GET_USERS_FOR_GROUP_REQUEST",
  GET_USERS_FOR_GROUP_SUCCESS: "GET_USERS_FOR_GROUP_SUCCESS",
  GET_USERS_FOR_GROUP_FAILURE: "GET_USERS_FOR_GROUP_FAILURE",
  GET_USERS_FOR_GROUP: "GET_USERS_FOR_GROUP",

  GET_EMPLOYER_GROUPS_REQUEST: "GET_EMPLOYER_GROUPS_REQUEST",
  GET_EMPLOYER_GROUPS_SUCCESS: "GET_EMPLOYER_GROUPS_SUCCESS",
  GET_EMPLOYER_GROUPS_FAILURE: "GET_EMPLOYER_GROUPS_FAILURE",
  GET_EMPLOYER_GROUPS: "GET_EMPLOYER_GROUPS",

  GET_EMPLOYER_GROUP_REQUEST: "GET_EMPLOYER_GROUP_REQUEST",
  GET_EMPLOYER_GROUP_SUCCESS: "GET_EMPLOYER_GROUP_SUCCESS",
  GET_EMPLOYER_GROUP_FAILURE: "GET_EMPLOYER_GROUP_FAILURE",
  GET_EMPLOYER_GROUP: "GET_EMPLOYER_GROUP",

  ADD_EMPLOYER_GROUPS_REQUEST: "ADD_EMPLOYER_GROUPS_REQUEST",
  ADD_EMPLOYER_GROUPS_SUCCESS: "ADD_EMPLOYER_GROUPS_SUCCESS",
  ADD_EMPLOYER_GROUPS_FAILURE: "ADD_EMPLOYER_GROUPS_FAILURE",
  ADD_EMPLOYER_GROUPS: "ADD_EMPLOYER_GROUPS",

  UPDATE_EMPLOYER_GROUPS_REQUEST: "UPDATE_EMPLOYER_GROUPS_REQUEST",
  UPDATE_EMPLOYER_GROUPS_SUCCESS: "UPDATE_EMPLOYER_GROUPS_SUCCESS",
  UPDATE_EMPLOYER_GROUPS_FAILURE: "UPDATE_EMPLOYER_GROUPS_FAILURE",
  UPDATE_EMPLOYER_GROUPS: "UPDATE_EMPLOYER_GROUPS",

  DELETE_EMPLOYER_GROUPS_REQUEST: "DELETE_EMPLOYER_GROUPS_REQUEST",
  DELETE_EMPLOYER_GROUPS_SUCCESS: "DELETE_EMPLOYER_GROUPS_SUCCESS",
  DELETE_EMPLOYER_GROUPS_FAILURE: "DELETE_EMPLOYER_GROUPS_FAILURE",
  DELETE_EMPLOYER_GROUPS: "DELETE_EMPLOYER_GROUPS",

  INVITE_EMPLOYEE_REQUEST: "INVITE_EMPLOYEE_REQUEST",
  INVITE_EMPLOYEE_SUCCESS: "INVITE_EMPLOYEE_SUCCESS",
  INVITE_EMPLOYEE_FAILURE: "INVITE_EMPLOYEE_FAILURE",
  INVITE_EMPLOYEE: "INVITE_EMPLOYEE",

  STAGE_EMPLOYEE_INVITE_REQUEST: "STAGE_EMPLOYEE_INVITE_REQUEST",
  STAGE_EMPLOYEE_INVITES_SUCCESS: "STAGE_EMPLOYEE_INVITE_SUCCESS",
  STAGE_EMPLOYEE_INVITE_FAILURE: "STAGE_EMPLOYEE_INVITE_FAILURE",
  STAGE_EMPLOYEE_INVITE: "STAGE_EMPLOYEE_INVITE",

  REVOKE_INVITE_EMPLOYEE_REQUEST: "REVOKE_INVITE_EMPLOYEE_REQUEST",
  REVOKE_INVITE_EMPLOYEE_SUCCESS: "REVOKE_INVITE_EMPLOYEE_SUCCESS",
  REVOKE_INVITE_EMPLOYEE_FAILURE: "REVOKE_INVITE_EMPLOYEE_FAILURE",
  REVOKE_INVITE_EMPLOYEE: "REVOKE_INVITE_EMPLOYEE",

  RESEND_INVITE_EMPLOYEE_REQUEST: "RESEND_INVITE_EMPLOYEE_REQUEST",
  RESEND_INVITE_EMPLOYEE_SUCCESS: "RESEND_INVITE_EMPLOYEE_SUCCESS",
  RESEND_INVITE_EMPLOYEE_FAILURE: "RESEND_INVITE_EMPLOYEE_FAILURE",
  RESEND_INVITE_EMPLOYEE: "RESEND_INVITE_EMPLOYEE",

  GET_EMPLOYEE_INVITES_REQUEST: "GET_EMPLOYEE_INVITES_REQUEST",
  GET_EMPLOYEE_INVITES_SUCCESS: "GET_EMPLOYEE_INVITES_SUCCESS",
  GET_EMPLOYEE_INVITES_FAILURE: "GET_EMPLOYEE_INVITES_FAILURE",
  GET_EMPLOYEE_INVITES: "GET_EMPLOYEE_INVITES",

  GET_LINKED_EMPLOYEES_REQUEST: "GET_LINKED_EMPLOYEES_REQUEST",
  GET_LINKED_EMPLOYEES_SUCCESS: "GET_LINKED_EMPLOYEES_SUCCESS",
  GET_LINKED_EMPLOYEES_FAILURE: "GET_LINKED_EMPLOYEES_FAILURE",
  GET_LINKED_EMPLOYEES: "GET_LINKED_EMPLOYEES",

  GET_PENDING_LINKED_EMPLOYEES_REQUEST: "GET_PENDING_LINKED_EMPLOYEES_REQUEST",
  GET_PENDING_LINKED_EMPLOYEES_SUCCESS: "GET_PENDING_LINKED_EMPLOYEES_SUCCESS",
  GET_PENDING_LINKED_EMPLOYEES_FAILURE: "GET_PENDING_LINKED_EMPLOYEES_FAILURE",
  GET_PENDING_LINKED_EMPLOYEES: "GET_PENDING_LINKED_EMPLOYEES",

  GET_TAKE_RATE_EMPLOYEES_REQUEST: "GET_TAKE_RATE_EMPLOYEES_REQUEST",
  GET_TAKE_RATE_EMPLOYEES_SUCCESS: "GET_TAKE_RATE_EMPLOYEES_SUCCESS",
  GET_TAKE_RATE_EMPLOYEES_FAILURE: "GET_TAKE_RATE_EMPLOYEES_FAILURE",
  GET_TAKE_RATE_EMPLOYEES: "GET_TAKE_RATE_EMPLOYEES",

  APPROVE_PENDING_LINKED_EMPLOYEE_REQUEST:
    "APPROVE_PENDING_LINKED_EMPLOYEE_REQUEST",
  APPROVE_PENDING_LINKED_EMPLOYEE_SUCCESS:
    "APPROVE_PENDING_LINKED_EMPLOYEE_SUCCESS",
  APPROVE_PENDING_LINKED_EMPLOYEE_FAILURE:
    "APPROVE_PENDING_LINKED_EMPLOYEE_FAILURE",
  APPROVE_PENDING_LINKED_EMPLOYEE: "APPROVE_PENDING_LINKED_EMPLOYEE",

  REJECT_PENDING_LINKED_EMPLOYEE_REQUEST:
    "REJECT_PENDING_LINKED_EMPLOYEE_REQUEST",
  REJECT_PENDING_LINKED_EMPLOYEE_SUCCESS:
    "REJECT_PENDING_LINKED_EMPLOYEE_SUCCESS",
  REJECT_PENDING_LINKED_EMPLOYEE_FAILURE:
    "REJECT_PENDING_LINKED_EMPLOYEE_FAILURE",
  REJECT_PENDING_LINKED_EMPLOYEE: "REJECT_PENDING_LINKED_EMPLOYEE",

  PROCESS_PAYROLL_REQUEST: "PROCESS_PAYROLL_REQUEST",
  PROCESS_PAYROLL_SUCCESS: "PROCESS_PAYROLL_SUCCESS",
  PROCESS_PAYROLL_FAILURE: "PROCESS_PAYROLL_FAILURE",
  PROCESS_PAYROLL: "PROCESS_PAYROLL",

  APPROVE_CONTRIBUTION_CHANGES_REQUEST: "APPROVE_CONTRIBUTION_CHANGES_REQUEST",
  APPROVE_CONTRIBUTION_CHANGES_SUCCESS: "APPROVE_CONTRIBUTION_CHANGES_SUCCESS",
  APPROVE_CONTRIBUTION_CHANGES_FAILURE: "APPROVE_CONTRIBUTION_CHANGES_FAILURE",
  APPROVE_CONTRIBUTION_CHANGES: "APPROVE_CONTRIBUTION_CHANGES",

  REJECT_CONTRIBUTION_CHANGES_REQUEST: "REJECT_CONTRIBUTION_CHANGES_REQUEST",
  REJECT_CONTRIBUTION_CHANGES_SUCCESS: "REJECT_CONTRIBUTION_CHANGES_SUCCESS",
  REJECT_CONTRIBUTION_CHANGES_FAILURE: "REJECT_CONTRIBUTION_CHANGES_FAILURE",
  REJECT_CONTRIBUTION_CHANGES: "REJECT_CONTRIBUTION_CHANGES",

  DELETE_EMPLOYER_LINKS_REQUEST: "DELETE_EMPLOYER_LINKS_REQUEST",
  DELETE_EMPLOYER_LINKS_SUCCESS: "DELETE_EMPLOYER_LINKS_SUCCESS",
  DELETE_EMPLOYER_LINKS_FAILURE: "DELETE_EMPLOYER_LINKS_FAILURE",
  DELETE_EMPLOYER_LINKS: "DELETE_EMPLOYER_LINKS",

  GET_ADMINISTRATORS: "GET_ADMINISTRATORS",
  GET_ADMINISTRATORS_REQUEST: "GET_ADMINISTRATORS_REQUEST",
  GET_ADMINISTRATORS_SUCCESS: "GET_ADMINISTRATORS_SUCCESS",
  GET_ADMINISTRATORS_FAILURE: "GET_ADMINISTRATORS_FAILURE",

  INVITE_ADMINISTRATOR_REQUEST: "INVITE_ADMINISTRATOR_REQUEST",
  INVITE_ADMINISTRATOR_SUCCESS: "INVITE_ADMINISTRATOR_SUCCESS",
  INVITE_ADMINISTRATOR_FAILURE: "INVITE_ADMINISTRATOR_FAILURE",
  INVITE_ADMINISTRATOR: "INVITE_ADMINISTRATOR",

  RESEND_INVITE_ADMINISTRATOR_REQUEST: "RESEND_INVITE_ADMINISTRATOR_REQUEST",
  RESEND_INVITE_ADMINISTRATOR_SUCCESS: "RESEND_INVITE_ADMINISTRATOR_SUCCESS",
  RESEND_INVITE_ADMINISTRATOR_FAILURE: "RESEND_INVITE_ADMINISTRATOR_FAILURE",
  RESEND_INVITE_ADMINISTRATOR: "RESEND_INVITE_ADMINISTRATOR",

  LOCK_ADMINISTRATOR_REQUEST: "LOCK_ADMINISTRATOR_REQUEST",
  LOCK_ADMINISTRATOR_SUCCESS: "LOCK_ADMINISTRATOR_SUCCESS",
  LOCK_ADMINISTRATOR_FAILURE: "LOCK_ADMINISTRATOR_FAILURE",
  LOCK_ADMINISTRATOR: "LOCK_ADMINISTRATOR",

  UNLOCK_ADMINISTRATOR_REQUEST: "UNLOCK_ADMINISTRATOR_REQUEST",
  UNLOCK_ADMINISTRATOR_SUCCESS: "UNLOCK_ADMINISTRATOR_SUCCESS",
  UNLOCK_ADMINISTRATOR_FAILURE: "UNLOCK_ADMINISTRATOR_FAILURE",
  UNLOCK_ADMINISTRATOR: "UNLOCK_ADMINISTRATOR",

  UPLOAD_EMPLOYEES_DOCUMENT_REQUEST: "UPLOAD_EMPLOYEES_DOCUMENT_REQUEST",
  UPLOAD_EMPLOYEES_DOCUMENT_SUCCESS: "UPLOAD_EMPLOYEES_DOCUMENT_SUCCESS",
  UPLOAD_EMPLOYEES_DOCUMENT_FAILURE: "UPLOAD_EMPLOYEES_DOCUMENT_FAILURE",
  UPLOAD_EMPLOYEES_DOCUMENT: "UPLOAD_EMPLOYEES_DOCUMENT",

  GET_EMPLOYEE_DOCUMENT_UPLOAD_JOBS_REQUEST:
    "GET_EMPLOYEE_DOCUMENT_UPLOAD_JOBS_REQUEST",
  GET_EMPLOYEE_DOCUMENT_UPLOAD_JOBS_SUCCESS:
    "GET_EMPLOYEE_DOCUMENT_UPLOAD_JOBS_SUCCESS",
  GET_EMPLOYEE_DOCUMENT_UPLOAD_JOBS_FAILURE:
    "GET_EMPLOYEE_DOCUMENT_UPLOAD_JOBS_FAILURE",
  GET_EMPLOYEE_DOCUMENT_UPLOAD_JOBS: "GET_EMPLOYEE_DOCUMENT_UPLOAD_JOBS",

  RESEND_INVITES_EMPLOYEE_REQUEST: "RESEND_INVITES_EMPLOYEE_REQUEST",
  RESEND_INVITES_EMPLOYEE_SUCCESS: "RESEND_INVITES_EMPLOYEE_SUCCESS",
  RESEND_INVITES_EMPLOYEE_FAILURE: "RESEND_INVITES_EMPLOYEE_FAILURE",
  RESEND_INVITES_EMPLOYEE: "RESEND_INVITES_EMPLOYEE",

  GET_PAGINATED_EMPLOYEES_REQUEST: "GET_PAGINATED_EMPLOYEES_REQUEST",
  GET_PAGINATED_EMPLOYEES_SUCCESS: "GET_PAGINATED_EMPLOYEES_SUCCESS",
  GET_PAGINATED_EMPLOYEES_FAILURE: "GET_PAGINATED_EMPLOYEES_FAILURE",
  GET_PAGINATED_EMPLOYEES: "GET_PAGINATED_EMPLOYEES",

  SET_EMPLOYER_SESSION_REQUEST: "SET_EMPLOYER_SESSION_REQUEST",
  SET_EMPLOYER_SESSION_SUCCESS: "SET_EMPLOYER_SESSION_SUCCESS",
  SET_EMPLOYER_SESSION_FAILURE: "SET_EMPLOYER_SESSION_FAILURE",
  SET_EMPLOYER_SESSION: "SET_EMPLOYER_SESSION",

  GET_LINKED_COMPANIES_REQUEST: "GET_LINKED_COMPANIES_REQUEST",
  GET_LINKED_COMPANIES_SUCCESS: "GET_LINKED_COMPANIES_SUCCESS",
  GET_LINKED_COMPANIES_FAILURE: "GET_LINKED_COMPANIES_FAILURE",
  GET_LINKED_COMPANIES: "GET_LINKED_COMPANIES",

  GET_PAGINATED_INVITES_REQUEST: "GET_PAGINATED_INVITES_REQUEST",
  GET_PAGINATED_INVITES_SUCCESS: "GET_PAGINATED_INVITES_SUCCESS",
  GET_PAGINATED_INVITES_FAILURE: "GET_PAGINATED_INVITES_FAILURE",
  GET_PAGINATED_INVITES: "GET_PAGINATED_INVITES",

  CHANGE_ADMIN_ROLE_REQUEST: "CHANGE_ADMIN_ROLE_REQUEST",
  CHANGE_ADMIN_ROLE_SUCCESS: "CHANGE_ADMIN_ROLE_SUCCESS",
  CHANGE_ADMIN_ROLE: "CHANGE_ADMIN_ROLE",
  CHANGE_ADMIN_ROLE_FAILURE: "CHANGE_ADMIN_ROLE_FAILURE",

  GET_OWNERS_REQUEST: "GET_OWNERS_REQUEST",
  GET_OWNERS_SUCCESS: "GET_OWNERS_SUCCESS",
  GET_OWNERS: "GET_OWNERS",
  GET_OWNERS_FAILURE: "GET_OWNERS_FAILURE",

  VALIDATE_SELF_SERVE_REQUEST: "VALIDATE_SELF_SERVE_REQUEST",
  VALIDATE_SELF_SERVE_SUCCESS: "VALIDATE_SELF_SERVE_SUCCESS",
  VALIDATE_SELF_SERVE: "VALIDATE_SELF_SERVE",
  VALIDATE_SELF_SERVE_FAILURE: "VALIDATE_SELF_SERVE_FAILURE",

  GET_SETUP_INTENT_REQUEST: "GET_SETUP_INTENT_REQUEST",
  GET_SETUP_INTENT_SUCCESS: "GET_SETUP_INTENT_SUCCESS",
  GET_SETUP_INTENT_FAILURE: "GET_SETUP_INTENT_FAILURE",
  GET_SETUP_INTENT: "GET_SETUP_INTENT",

  CREATE_SETUP_INTENT_REQUEST: "CREATE_SETUP_INTENT_REQUEST",
  CREATE_SETUP_INTENT_SUCCESS: "CREATE_SETUP_INTENT_SUCCESS",
  CREATE_SETUP_INTENT_FAILURE: "CREATE_SETUP_INTENT_FAILURE",
  CREATE_SETUP_INTENT: "CREATE_SETUP_INTENT",

  SET_INVOICE_PAYMENT_METHOD_REQUEST: "SET_INVOICE_PAYMENT_METHOD_REQUEST",
  SET_INVOICE_PAYMENT_METHOD_SUCCESS: "SET_INVOICE_PAYMENT_METHOD_SUCCESS",
  SET_INVOICE_PAYMENT_METHOD_FAILURE: "SET_INVOICE_PAYMENT_METHOD_FAILURE",
  SET_INVOICE_PAYMENT_METHOD: "SET_INVOICE_PAYMENT_METHOD",

  GET_BILLING_PORTAL_URL_REQUEST: "GET_BILLING_PORTAL_URL_REQUEST",
  GET_BILLING_PORTAL_URL_SUCCESS: "GET_BILLING_PORTAL_URL_SUCCESS",
  GET_BILLING_PORTAL_URL_FAILURE: "GET_BILLING_PORTAL_URL_FAILURE",
  GET_BILLING_PORTAL_URL: "GET_BILLING_PORTAL_URL",

  GET_PROGRESSIVE_ONBOARDING_REQUEST: "GET_PROGRESSIVE_ONBOARDING_REQUEST",
  GET_PROGRESSIVE_ONBOARDING_SUCCESS: "GET_PROGRESSIVE_ONBOARDING_SUCCESS",
  GET_PROGRESSIVE_ONBOARDING_FAILURE: "GET_PROGRESSIVE_ONBOARDING_FAILURE",
  GET_PROGRESSIVE_ONBOARDING: "GET_PROGRESSIVE_ONBOARDING",

  UPDATE_PROGRESSIVE_ONBOARDING_REQUEST:
    "UPDATE_PROGRESSIVE_ONBOARDING_REQUEST",
  UPDATE_PROGRESSIVE_ONBOARDING_SUCCESS:
    "UPDATE_PROGRESSIVE_ONBOARDING_SUCCESS",
  UPDATE_PROGRESSIVE_ONBOARDING_FAILURE:
    "UPDATE_PROGRESSIVE_ONBOARDING_FAILURE",
  UPDATE_PROGRESSIVE_ONBOARDING: "UPDATE_PROGRESSIVE_ONBOARDING",

  UPLOAD_ROSTER_REQUEST: "UPLOAD_ROSTER_REQUEST",
  UPLOAD_ROSTER_SUCCESS: "UPLOAD_ROSTER_SUCCESS",
  UPLOAD_ROSTER_FAILURE: "UPLOAD_ROSTER_FAILURE",
  UPLOAD_ROSTER: "UPLOAD_ROSTER",

  CONNECT_FINCH_REQUEST: "CONNECT_FINCH_REQUEST",
  CONNECT_FINCH_SUCCESS: "CONNECT_FINCH_SUCCESS",
  CONNECT_FINCH_FAILURE: "CONNECT_FINCH_FAILURE",
  CONNECT_FINCH: "CONNECT_FINCH",

  GET_FINCH_STATUS_REQUEST: "GET_FINCH_STATUS_REQUEST",
  GET_FINCH_STATUS_SUCCESS: "GET_FINCH_STATUS_SUCCESS",
  GET_FINCH_STATUS_FAILURE: "GET_FINCH_STATUS_FAILURE",
  GET_FINCH_STATUS: "GET_FINCH_STATUS",

  GET_EMPLOYEE_ROSTER_REQUEST: "GET_EMPLOYEE_ROSTER_REQUEST",
  GET_EMPLOYEE_ROSTER_SUCCESS: "GET_EMPLOYEE_ROSTER_SUCCESS",
  GET_EMPLOYEE_ROSTER_FAILURE: "GET_EMPLOYEE_ROSTER_FAILURE",
  GET_EMPLOYEE_ROSTER: "GET_EMPLOYEE_ROSTER",

  RESYNC_ROSTER_REQUEST: "RESYNC_ROSTER_REQUEST",
  RESYNC_ROSTER_SUCCESS: "RESYNC_ROSTER_SUCCESS",
  RESYNC_ROSTER_FAILURE: "RESYNC_ROSTER_FAILURE",
  RESYNC_ROSTER: "RESYNC_ROSTER",

  PROCESS_ROSTER_REQUEST: "PROCESS_ROSTER_REQUEST",
  PROCESS_ROSTER_SUCCESS: "PROCESS_ROSTER_SUCCESS",
  PROCESS_ROSTER_FAILURE: "PROCESS_ROSTER_FAILURE",
  PROCESS_ROSTER: "PROCESS_ROSTER",

  APPROVE_ROSTER_REQUEST: "APPROVE_ROSTER_REQUEST",
  APPROVE_ROSTER_SUCCESS: "APPROVE_ROSTER_SUCCESS",
  APPROVE_ROSTER_FAILURE: "APPROVE_ROSTER_FAILURE",
  APPROVE_ROSTER: "APPROVE_ROSTER",

  RECONNECT_FINCH_REQUEST: "RECONNECT_FINCH_REQUEST",
  RECONNECT_FINCH_SUCCESS: "RECONNECT_FINCH_SUCCESS",
  RECONNECT_FINCH_FAILURE: "RECONNECT_FINCH_FAILURE",
  RECONNECT_FINCH: "RECONNECT_FINCH",

  REFER_COMPANY_REQUEST: "REFER_COMPANY_REQUEST",
  REFER_COMPANY_SUCCESS: "REFER_COMPANY_SUCCESS",
  REFER_COMPANY_FAILURE: "REFER_COMPANY_FAILURE",
  REFER_COMPANY: "REFER_COMPANY",

  GET_PAYROLL_GROUP_CONTRIBUTION_ABILITY_REQUEST:
    "GET_PAYROLL_GROUP_CONTRIBUTION_ABILITY_REQUEST",
  GET_PAYROLL_GROUP_CONTRIBUTION_ABILITY_SUCCESS:
    "GET_PAYROLL_GROUP_CONTRIBUTION_ABILITY_SUCCESS",
  GET_PAYROLL_GROUP_CONTRIBUTION_ABILITY_FAILURE:
    "GET_PAYROLL_GROUP_CONTRIBUTION_ABILITY_FAILURE",
  GET_PAYROLL_GROUP_CONTRIBUTION_ABILITY:
    "GET_PAYROLL_GROUP_CONTRIBUTION_ABILITY",

  CURRENT_PAYROLLS_REQUEST: "CURRENT_PAYROLLS_REQUEST",
  CURRENT_PAYROLLS_SUCCESS: "CURRENT_PAYROLLS_SUCCESS",
  CURRENT_PAYROLLS_FAILURE: "CURRENT_PAYROLLS_FAILURE",
  CURRENT_PAYROLLS: "CURRENT_PAYROLLS",

  HYDRATE_DASHBOARD_REQUEST: "HYDRATE_DASHBOARD_REQUEST",
  HYDRATE_DASHBOARD_SUCCESS: "HYDRATE_DASHBOARD_SUCCESS",
  HYDRATE_DASHBOARD_FAILURE: "HYDRATE_DASHBOARD_FAILURE",
  HYDRATE_DASHBOARD: "HYDRATE_DASHBOARD",

  SKIP_BILLING_REQUEST: "SKIP_BILLING_REQUEST",
  SKIP_BILLING_SUCCESS: "SKIP_BILLING_SUCCESS",
  SKIP_BILLING_FAILURE: "SKIP_BILLING_FAILURE",
  SKIP_BILLING: "SKIP_BILLING",

  GET_EMPLOYER_REQUIRED_STEPS_REQUEST: "GET_EMPLOYER_REQUIRED_STEPS_REQUEST",
  GET_EMPLOYER_REQUIRED_STEPS_SUCCESS: "GET_EMPLOYER_REQUIRED_STEPS_SUCCESS",
  GET_EMPLOYER_REQUIRED_STEPS_FAILURE: "GET_EMPLOYER_REQUIRED_STEPS_FAILURE",
  GET_EMPLOYER_REQUIRED_STEPS: "GET_EMPLOYER_REQUIRED_STEPS",

  UPDATE_EMPLOYER_REQUIRED_ORDER_FORM_REQUEST:
    "UPDATE_EMPLOYER_REQUIRED_ORDER_FORM_REQUEST",
  UPDATE_EMPLOYER_REQUIRED_ORDER_FORM_SUCCESS:
    "UPDATE_EMPLOYER_REQUIRED_ORDER_FORM_SUCCESS",
  UPDATE_EMPLOYER_REQUIRED_ORDER_FORM_FAILURE:
    "UPDATE_EMPLOYER_REQUIRED_ORDER_FORM_FAILURE",
  UPDATE_EMPLOYER_REQUIRED_ORDER_FORM: "UPDATE_EMPLOYER_REQUIRED_ORDER_FORM",

  GET_PAYROLL_INTEGRATION_STATUS_REQUEST:
    "GET_PAYROLL_INTEGRATION_STATUS_REQUEST",
  GET_PAYROLL_INTEGRATION_STATUS_SUCCESS:
    "GET_PAYROLL_INTEGRATION_STATUS_SUCCESS",
  GET_PAYROLL_INTEGRATION_STATUS_FAILURE:
    "GET_PAYROLL_INTEGRATION_STATUS_FAILURE",
  GET_PAYROLL_INTEGRATION_STATUS: "GET_PAYROLL_INTEGRATION_STATUS",

  GET_EMPLOYER_TERMS_DATE_REQUEST: "GET_EMPLOYER_TERMS_DATE_REQUEST",
  GET_EMPLOYER_TERMS_DATE_SUCCESS: "GET_EMPLOYER_TERMS_DATE_SUCCESS",
  GET_EMPLOYER_TERMS_DATE_FAILURE: "GET_EMPLOYER_TERMS_DATE_FAILURE",
  GET_EMPLOYER_TERMS_DATE: "GET_EMPLOYER_TERMS_DATE",

  GET_SALES_QUOTE_REQUEST: "GET_SALES_QUOTE_REQUEST",
  GET_SALES_QUOTE_SUCCESS: "GET_SALES_QUOTE_SUCCESS",
  GET_SALES_QUOTE_FAILURE: "GET_SALES_QUOTE_FAILURE",
  GET_SALES_QUOTE: "GET_SALES_QUOTE",
};

export const navigationConstants = {
  NAVIGATION_SET_TAB: "NAVIGATION_SET_TAB",
  NAVIGATION_SET_TAB_REQUEST: "NAVIGATION_SET_TAB_REQUEST",
  NAVIGATION_SET_TAB_SUCCESS: "NAVIGATION_SET_TAB_SUCCESS",
  NAVIGATION_SET_TAB_FAILURE: "NAVIGATION_SET_TAB_FAILURE",

  NAVIGATION_SET_COMPANY: "NAVIGATION_SET_COMPANY",
  NAVIGATION_SET_COMPANY_REQUEST: "NAVIGATION_SET_COMPANY_REQUEST",
  NAVIGATION_SET_COMPANY_SUCCESS: "NAVIGATION_SET_COMPANY_SUCCESS",
  NAVIGATION_SET_COMPANY_FAILURE: "NAVIGATION_SET_COMPANY_FAILURE",
};

export const bankConstants = {
  LINK_BANK_WITH_ICON_REQUEST: "LINK_BANK_WITH_ICON_REQUEST",
  LINK_BANK_WITH_ICON_SUCCESS: "LINK_BANK_WITH_ICON_SUCCESS",
  LINK_BANK_WITH_ICON_FAILURE: "LINK_BANK_WITH_ICON_FAILURE",
  LINK_BANK_WITH_ICON: "LINK_BANK_WITH_ICON",

  DELETE_BANK_REQUEST: "DELETE_BANK_REQUEST",
  DELETE_BANK_SUCCESS: "DELETE_BANK_SUCCESS",
  DELETE_BANK_FAILURE: "DELETE_BANK_FAILURE",
  DELETE_BANK: "DELETE_BANK",

  VERIFY_BANK_REQUEST: "VERIFY_BANK_REQUEST",
  VERIFY_BANK_SUCCESS: "VERIFY_BANK_SUCCESS",
  VERIFY_BANK_FAILURE: "VERIFY_BANK_FAILURE",
  VERIFY_BANK: "VERIFY_BANK",

  SET_RECURRING_SCHEDULE: "SET_RECURRING_SCHEDULE",

  GET_PLAID_LINK_TOKEN_REQUEST: "GET_PLAID_LINK_TOKEN_REQUEST",
  GET_PLAID_LINK_TOKEN_SUCCESS: "GET_PLAID_LINK_TOKEN_SUCCESS",
  GET_PLAID_LINK_TOKEN_FAILURE: "GET_PLAID_LINK_TOKEN_FAILURE",
  GET_PLAID_LINK_TOKEN: "GET_PLAID_LINK_TOKEN",

  OAUTH_PLAID_LINK_TOKEN_REQUEST: "OAUTH_PLAID_LINK_TOKEN_REQUEST",
  OAUTH_PLAID_LINK_TOKEN_SUCCESS: "OAUTH_PLAID_LINK_TOKEN_SUCCESS",
  OAUTH_PLAID_LINK_TOKEN_FAILURE: "OAUTH_PLAID_LINK_TOKEN_FAILURE",
  OAUTH_PLAID_LINK_TOKEN: "OAUTH_PLAID_LINK_TOKEN",
};

export const contributionConstants = {
  CONTRIBUTION_REQUEST: "CONTRIBUTION_REQUEST",
  CONTRIBUTION_SUCCESS: "CONTRIBUTION_SUCCESS",
  CONTRIBUTION_FAILURE: "CONTRIBUTION_FAILURE",
  CONTRIBUTION: "CONTRIBUTION",

  PAYCHECK_CONTRIBUTION_REQUEST: "PAYCHECK_CONTRIBUTION_REQUEST",
  PAYCHECK_CONTRIBUTION_SUCCESS: "PAYCHECK_CONTRIBUTION_SUCCESS",
  PAYCHECK_CONTRIBUTION_FAILURE: "PAYCHECK_CONTRIBUTION_FAILURE",
  PAYCHECK_CONTRIBUTION: "PAYCHECK_CONTRIBUTION",

  EDIT_PAYCHECK_CONTRIBUTION_REQUEST: "EDIT_PAYCHECK_CONTRIBUTION_REQUEST",
  EDIT_PAYCHECK_CONTRIBUTION_SUCCESS: "EDIT_PAYCHECK_CONTRIBUTION_SUCCESS",
  EDIT_PAYCHECK_CONTRIBUTION_FAILURE: "EDIT_PAYCHECK_CONTRIBUTION_FAILURE",
  EDIT_PAYCHECK_CONTRIBUTION: "EDIT_PAYCHECK_CONTRIBUTION",

  ONBOARDING_PAYCHECK_CONTRIBUTION_REQUEST:
    "ONBOARDING_PAYCHECK_CONTRIBUTION_REQUEST",
  ONBOARDING_PAYCHECK_CONTRIBUTION_SUCCESS:
    "ONBOARDING_PAYCHECK_CONTRIBUTION_SUCCESS",
  ONBOARDING_PAYCHECK_CONTRIBUTION_FAILURE:
    "ONBOARDING_PAYCHECK_CONTRIBUTION_FAILURE",
  ONBOARDING_PAYCHECK_CONTRIBUTION: "ONBOARDING_PAYCHECK_CONTRIBUTION",

  ONBOARDING_RECURRING_CONTRIBUTION_REQUEST:
    "ONBOARDING_RECURRING_CONTRIBUTION_REQUEST",
  ONBOARDING_RECURRING_CONTRIBUTION_SUCCESS:
    "ONBOARDING_RECURRING_CONTRIBUTION_SUCCESS",
  ONBOARDING_RECURRING_CONTRIBUTION_FAILURE:
    "ONBOARDING_RECURRING_CONTRIBUTION_FAILURE",
  ONBOARDING_RECURRING_CONTRIBUTION: "ONBOARDING_RECURRING_CONTRIBUTION",

  ADD_RECURRING_CONTRIBUTION_REQUEST: "ADD_RECURRING_CONTRIBUTION_REQUEST",
  ADD_RECURRING_CONTRIBUTION_SUCCESS: "ADD_RECURRING_CONTRIBUTION_SUCCESS",
  ADD_RECURRING_CONTRIBUTION_FAILURE: "ADD_RECURRING_CONTRIBUTION_FAILURE",
  ADD_RECURRING_CONTRIBUTION: "ADD_RECURRING_CONTRIBUTION",

  GET_CONTRIBUTION_RECOMMENDATIONS_REQUEST:
    "GET_CONTRIBUTION_RECOMMENDATIONS_REQUEST",
  GET_CONTRIBUTION_RECOMMENDATIONS_SUCCESS:
    "GET_CONTRIBUTION_RECOMMENDATIONS_SUCCESS",
  GET_CONTRIBUTION_RECOMMENDATIONS_FAILURE:
    "GET_CONTRIBUTION_RECOMMENDATIONS_FAILURE",
  GET_CONTRIBUTION_RECOMMENDATIONS: "GET_CONTRIBUTION_RECOMMENDATIONS",

  GET_CONTRIBUTION_ABILITY: "GET_CONTRIBUTION_ABILITY",
  GET_CONTRIBUTION_ABILITY_REQUEST: "GET_CONTRIBUTION_ABILITY_REQUEST",
  GET_CONTRIBUTION_ABILITY_SUCCESS: "GET_CONTRIBUTION_ABILITY_SUCCESS",
  GET_CONTRIBUTION_ABILITY_FAILURE: "GET_CONTRIBUTION_ABILITY_FAILURE",

  GET_CONTRIBUTION_ESTIMATES: "GET_CONTRIBUTION_ESTIMATES",
  GET_CONTRIBUTION_ESTIMATES_REQUEST: "GET_CONTRIBUTION_ESTIMATES_REQUEST",
  GET_CONTRIBUTION_ESTIMATES_SUCCESS: "GET_CONTRIBUTION_ESTIMATES_SUCCESS",
  GET_CONTRIBUTION_ESTIMATES_FAILURE: "GET_CONTRIBUTION_ESTIMATES_FAILURE",

  GET_RECURRING_CONTRIBUTIONS: "GET_RECURRING_CONTRIBUTIONS",
  GET_RECURRING_CONTRIBUTIONS_REQUEST: "GET_RECURRING_CONTRIBUTIONS_REQUEST",
  GET_RECURRING_CONTRIBUTIONS_SUCCESS: "GET_RECURRING_CONTRIBUTIONS_SUCCESS",
  GET_RECURRING_CONTRIBUTIONS_FAILURE: "GET_RECURRING_CONTRIBUTIONS_FAILURE",
};

export const withdrawalConstants = {
  WITHDRAWAL_REQUEST: "WITHDRAWAL_REQUEST",
  WITHDRAWAL_SUCCESS: "WITHDRAWAL_SUCCESS",
  WITHDRAWAL_FAILURE: "WITHDRAWAL_FAILURE",
  WITHDRAWAL: "WITHDRAWAL",
};

export const accountConstants = {
  GET_ACCOUNTS_REQUEST: "GET_ACCOUNTS_REQUEST",
  GET_ACCOUNTS_SUCCESS: "GET_ACCOUNTS_SUCCESS",
  GET_ACCOUNTS_FAILURE: "GET_ACCOUNTS_FAILURE",
  GET_ACCOUNTS: "GET_ACCOUNTS",

  GET_IRA_BALANCE: "GET_IRA_BALANCE",
  GET_IRA_BALANCE_SUCCESS: "GET_IRA_BALANCE_SUCCESS",
  GET_IRA_BALANCE_FAILURE: "GET_IRA_BALANCE_FAILURE",
  GET_IRA_BALANCE_REQUEST: "GET_IRA_BALANCE_REQUEST",

  SET_ACCOUNT_SESSION_REQUEST: "SET_ACCOUNT_SESSION_REQUEST",
  SET_ACCOUNT_SESSION_SUCCESS: "SET_ACCOUNT_SESSION_SUCCESS",
  SET_ACCOUNT_SESSION_FAILURE: "SET_ACCOUNT_SESSION_FAILURE",
  SET_ACCOUNT_SESSION: "SET_ACCOUNT_SESSION",

  UPDATE_ACCOUNT_REQUEST: "UPDATE_ACCOUNT_REQUEST",
  UPDATE_ACCOUNT_SUCCESS: "UPDATE_ACCOUNT_SUCCESS",
  UPDATE_ACCOUNT_FAILURE: "UPDATE_ACCOUNT_FAILURE",
  UPDATE_ACCOUNT: "UPDATE_ACCOUNT",
};

export const transactionConstants = {
  GET_TRANSACTIONS_REQUEST: "GET_TRANSACTIONS_REQUEST",
  GET_TRANSACTIONS_SUCCESS: "GET_TRANSACTIONS_SUCCESS",
  GET_TRANSACTIONS_FAILURE: "GET_TRANSACTIONS_FAILURE",
  GET_TRANSACTIONS: "GET_TRANSACTIONS",

  CANCEL_TRANSACTION_REQUEST: "CANCEL_TRANSACTION_REQUEST",
  CANCEL_TRANSACTION_SUCCESS: "CANCEL_TRANSACTION_SUCCESS",
  CANCEL_TRANSACTION_FAILURE: "CANCEL_TRANSACTION_FAILURE",
  CANCEL_TRANSACTION: "CANCEL_TRANSACTION",
};

export const portfolioConstants = {
  GET_PORTFOLIOS_REQUEST: "GET_PORTFOLIOS_REQUEST",
  GET_PORTFOLIOS_SUCCESS: "GET_PORTFOLIOS_SUCCESS",
  GET_PORTFOLIOS_FAILURE: "GET_PORTFOLIOS_FAILURE",
  GET_PORTFOLIOS: "GET_PORTFOLIOS",

  GET_USER_PORTFOLIO_REQUEST: "GET_USER_PORTFOLIO_REQUEST",
  GET_USER_PORTFOLIO_SUCCESS: "GET_USER_PORTFOLIO_SUCCESS",
  GET_USER_PORTFOLIO_FAILURE: "GET_USER_PORTFOLIO_FAILURE",
  GET_USER_PORTFOLIO: "GET_USER_PORTFOLIO",

  GET_POSITIONS_REQUEST: "GET_POSITIONS_REQUEST",
  GET_POSITIONS_SUCCESS: "GET_POSITIONS_SUCCESS",
  GET_POSITIONS_FAILURE: "GET_POSITIONS_FAILURE",
  GET_POSITIONS: "GET_POSITIONS",

  GET_PERFORMANCE_REQUEST: "GET_PERFORMANCE_REQUEST",
  GET_PERFORMANCE_SUCCESS: "GET_PERFORMANCE_SUCCESS",
  GET_PERFORMANCE_FAILURE: "GET_PERFORMANCE_FAILURE",
  GET_PERFORMANCE: "GET_PERFORMANCE",

  UPDATE_USER_PORTFOLIO_REQUEST: "UPDATE_USER_PORTFOLIO_REQUEST",
  UPDATE_USER_PORTFOLIO_SUCCESS: "UPDATE_USER_PORTFOLIO_SUCCESS",
  UPDATE_USER_PORTFOLIO_FAILURE: "UPDATE_USER_PORTFOLIO_FAILURE",
  UPDATE_USER_PORTFOLIO: "UPDATE_USER_PORTFOLIO",

  SELECT_USER_ROLLOVER_PORTFOLIO_REQUEST:
    "SELECT_USER_ROLLOVER_PORTFOLIO_REQUEST",
  SELECT_USER_ROLLOVER_PORTFOLIO_SUCCESS:
    "SELECT_USER_ROLLOVER_PORTFOLIO_SUCCESS",
  SELECT_USER_ROLLOVER_PORTFOLIO_FAILURE:
    "SELECT_USER_ROLLOVER_PORTFOLIO_FAILURE",
  SELECT_USER_ROLLOVER_PORTFOLIO: "SELECT_USER_ROLLOVER_PORTFOLIO",
};

export const documentConstants = {
  GET_DOCUMENTS_REQUEST: "GET_DOCUMENTS_REQUEST",
  GET_DOCUMENTS_SUCCESS: "GET_DOCUMENTS_SUCCESS",
  GET_DOCUMENTS_FAILURE: "GET_DOCUMENTS_FAILURE",
  GET_DOCUMENTS: "GET_DOCUMENTS",

  GET_DOCUMENT_URL_REQUEST: "GET_DOCUMENT_URL_REQUEST",
  GET_DOCUMENT_URL_SUCCESS: "GET_DOCUMENT_URL_SUCCESS",
  GET_DOCUMENT_URL_FAILURE: "GET_DOCUMENT_URL_FAILURE",
  GET_DOCUMENT_URL: "GET_DOCUMENT_URL",
};

export const adminConstants = {
  GET_APPROVAL_REQUEST: "GET_APPROVAL_REQUEST",
  GET_APPROVAL_SUCCESS: "GET_APPROVAL_SUCCESS",
  GET_APPROVAL_FAILURE: "GET_APPROVAL_FAILURE",
  GET_APPROVAL: "GET_APPROVAL",

  GET_PAGINATED_COMPANIES_REQUEST: "GET_PAGINATED_COMPANIES_REQUEST",
  GET_PAGINATED_COMPANIES_SUCCESS: "GET_PAGINATED_COMPANIES_SUCCESS",
  GET_PAGINATED_COMPANIES_FAILURE: "GET_PAGINATED_COMPANIES_FAILURE",
  GET_PAGINATED_COMPANIES: "GET_PAGINATED_COMPANIES",

  GET_PENDING_APPROVALS_REQUEST: "GET_PENDING_APPROVALS_REQUEST",
  GET_PENDING_APPROVALS_SUCCESS: "GET_PENDING_APPROVALS_SUCCESS",
  GET_PENDING_APPROVALS_FAILURE: "GET_PENDING_APPROVALS_FAILURE",
  GET_PENDING_APPROVALS: "GET_PENDING_APPROVALS",

  GET_COMPLIANCE_REPORTS_REQUEST: "GET_COMPLIANCE_REPORTS_REQUEST",
  GET_COMPLIANCE_REPORTS_SUCCESS: "GET_COMPLIANCE_REPORTS_SUCCESS",
  GET_COMPLIANCE_REPORTS_FAILURE: "GET_COMPLIANCE_REPORTS_FAILURE",
  GET_COMPLIANCE_REPORTS: "GET_COMPLIANCE_REPORTS",

  SET_APPROVAL_STATUS_REQUEST: "SET_APPROVAL_STATUS_REQUEST",
  SET_APPROVAL_STATUS_SUCCESS: "SET_APPROVAL_STATUS_SUCCESS",
  SET_APPROVAL_STATUS_FAILURE: "SET_APPROVAL_STATUS_FAILURE",
  SET_APPROVAL_STATUS: "SET_APPROVAL_STATUS",

  REGISTER_KYB_USER_REQUEST: "REGISTER_KYB_USER_REQUEST",
  REGISTER_KYB_USER_SUCCESS: "REGISTER_KYB_USER_SUCCESS",
  REGISTER_KYB_USER_FAILURE: "REGISTER_KYB_USER_FAILURE",
  REGISTER_KYB_USER: "REGISTER_KYB_USER",

  REGISTER_KYB_COMPANY_REQUEST: "REGISTER_KYB_COMPANY_REQUEST",
  REGISTER_KYB_COMPANY_SUCCESS: "REGISTER_KYB_COMPANY_SUCCESS",
  REGISTER_KYB_COMPANY_FAILURE: "REGISTER_KYB_COMPANY_FAILURE",
  REGISTER_KYB_COMPANY: "REGISTER_KYB_COMPANY",

  REGISTER_KYB_REQUEST: "REGISTER_KYB_REQUEST",
  REGISTER_KYB_SUCCESS: "REGISTER_KYB_SUCCESS",
  REGISTER_KYB_FAILURE: "REGISTER_KYB_FAILURE",
  REGISTER_KYB: "REGISTER_KYB",

  ENABLE_FEATURE_REQUEST: "ENABLE_FEATURE_REQUEST",
  ENABLE_FEATURE_SUCCESS: "ENABLE_FEATURE_SUCCESS",
  ENABLE_FEATURE_FAILURE: "ENABLE_FEATURE_FAILURE",
  ENABLE_FEATURE: "ENABLE_FEATURE",

  ENABLE_PRODUCT_FEATURES_REQUEST: "ENABLE_PRODUCT_FEATURES_REQUEST",
  ENABLE_PRODUCT_FEATURES_SUCCESS: "ENABLE_PRODUCT_FEATURES_SUCCESS",
  ENABLE_PRODUCT_FEATURES_FAILURE: "ENABLE_PRODUCT_FEATURES_FAILURE",
  ENABLE_PRODUCT_FEATURES: "ENABLE_PRODUCT_FEATURES",

  GET_USER_ACCOUNT_REVIEW_DOCUMENTS: "GET_USER_ACCOUNT_REVIEW_DOCUMENTS",
  GET_USER_ACCOUNT_REVIEW_DOCUMENTS_SUCCESS:
    "GET_USER_ACCOUNT_REVIEW_DOCUMENTS_SUCCESS",
  GET_USER_ACCOUNT_REVIEW_DOCUMENTS_REQUEST:
    "GET_USER_ACCOUNT_REVIEW_DOCUMENTS_REQUEST",
  GET_USER_ACCOUNT_REVIEW_DOCUMENTS_FAILURE:
    "GET_USER_ACCOUNT_REVIEW_DOCUMENTS_FAILURE",

  GET_GROUPS_BY_COMPANY_ID_REQUEST: "GET_GROUPS_BY_COMPANY_ID_REQUEST",
  GET_GROUPS_BY_COMPANY_ID_SUCCESS: "GET_GROUPS_BY_COMPANY_ID_SUCCESS",
  GET_GROUPS_BY_COMPANY_ID_FAILURE: "GET_GROUPS_BY_COMPANY_ID_FAILURE",
  GET_GROUPS_BY_COMPANY_ID: "GET_GROUPS_BY_COMPANY_ID",

  LINK_BANK_REQUEST: "LINK_BANK_REQUEST",
  LINK_BANK_SUCCESS: "LINK_BANK_SUCCESS",
  LINK_BANK_FAILURE: "LINK_BANK_FAILURE",
  LINK_BANK: "LINK_BANK",

  UPLOAD_EMPLOYEES_DOCUMENT_FOR_EMPLOYER_REQUEST:
    "UPLOAD_EMPLOYEES_DOCUMENT_FOR_EMPLOYER_REQUEST",
  UPLOAD_EMPLOYEES_DOCUMENT_FOR_EMPLOYER_SUCCESS:
    "UPLOAD_EMPLOYEES_DOCUMENT_FOR_EMPLOYER_SUCCESS",
  UPLOAD_EMPLOYEES_DOCUMENT_FOR_EMPLOYER_FAILURE:
    "UPLOAD_EMPLOYEES_DOCUMENT_FOR_EMPLOYER_FAILURE",
  UPLOAD_EMPLOYEES_DOCUMENT_FOR_EMPLOYER:
    "UPLOAD_EMPLOYEES_DOCUMENT_FOR_EMPLOYER",

  GET_PRODUCT_FEATURES: "GET_PRODUCT_FEATURES",
  GET_PRODUCT_FEATURES_REQUEST: "GET_PRODUCT_FEATURES_REQUEST",
  GET_PRODUCT_FEATURES_SUCCESS: "GET_PRODUCT_FEATURES_SUCCESS",
  GET_PRODUCT_FEATURES_FAILURE: "GET_PRODUCT_FEATURES_FAILURE",

  GET_COMPANY_MEMBERS: "GET_COMPANY_MEMBERS",
  GET_COMPANY_MEMBERS_REQUEST: "GET_COMPANY_MEMBERS_REQUEST",
  GET_COMPANY_MEMBERS_SUCCESS: "GET_COMPANY_MEMBERS_SUCCESS",
  GET_COMPANY_MEMBERS_FAILURE: "GET_COMPANY_MEMBERS_FAILURE",

  GET_MEMBER: "GET_MEMBER",
  GET_MEMBER_REQUEST: "GET_MEMBER_REQUEST",
  GET_MEMBER_SUCCESS: "GET_MEMBER_SUCCESS",
  GET_MEMBER_FAILURE: "GET_MEMBER_FAILURE",

  DELETE_MEMBER: "DELETE_MEMBER",
  DELETE_MEMBER_REQUEST: "DELETE_MEMBER_REQUEST",
  DELETE_MEMBER_SUCCESS: "DELETE_MEMBER_SUCCESS",
  DELETE_MEMBER_FAILURE: "DELETE_MEMBER_FAILURE",

  CREATE_ORGANIZATION: "CREATE_ORGANIZATION",
  CREATE_ORGANIZATION_REQUEST: "CREATE_ORGANIZATION_REQUEST",
  CREATE_ORGANIZATION_SUCCESS: "CREATE_ORGANIZATION_SUCCESS",
  CREATE_ORGANIZATION_FAILURE: "CREATE_ORGANIZATION_FAILURE",

  GET_ORGANIZATION_FOR_COMPANY: "GET_ORGANIZATION_FOR_COMPANY",
  GET_ORGANIZATION_FOR_COMPANY_REQUEST: "GET_ORGANIZATION_FOR_COMPANY_REQUEST",
  GET_ORGANIZATION_FOR_COMPANY_SUCCESS: "GET_ORGANIZATION_FOR_COMPANY_SUCCESS",
  GET_ORGANIZATION_FOR_COMPANY_FAILURE: "GET_ORGANIZATION_FOR_COMPANY_FAILURE",

  DISABLE_COMPANY: "DISABLE_COMPANY",
  DISABLE_COMPANY_REQUEST: "DISABLE_COMPANY_REQUEST",
  DISABLE_COMPANY_SUCCESS: "DISABLE_COMPANY_SUCCESS",
  DISABLE_COMPANY_FAILURE: "DISABLE_COMPANY_FAILURE",

  GET_ORIGINATIONS_REQUEST: "GET_ORIGINATIONS_REQUEST",
  GET_ORIGINATIONS_SUCCESS: "GET_ORIGINATIONS_SUCCESS",
  GET_ORIGINATIONS_FAILURE: "GET_ORIGINATIONS_FAILURE",
  GET_ORIGINATIONS: "GET_ORIGINATIONS",

  SUBMIT_BUSINESS_KYB_DOCUMENTS_REQUEST:
    "SUBMIT_BUSINESS_KYB_DOCUMENTS_REQUEST",
  SUBMIT_BUSINESS_KYB_DOCUMENTS_SUCCESS:
    "SUBMIT_BUSINESS_KYB_DOCUMENTS_SUCCESS",
  SUBMIT_BUSINESS_KYB_DOCUMENTS_FAILURE:
    "SUBMIT_BUSINESS_KYB_DOCUMENTS_FAILURE",
  SUBMIT_BUSINESS_KYB_DOCUMENTS: "SUBMIT_BUSINESS_KYB_DOCUMENTS",

  SUBMIT_BUSINESS_MEMBERS_KYB_DOCUMENTS_REQUEST:
    "SUBMIT_BUSINESS_MEMBERS_KYB_DOCUMENTS_REQUEST",
  SUBMIT_BUSINESS_MEMBERS_KYB_DOCUMENTS_SUCCESS:
    "SUBMIT_BUSINESS_MEMBERS_KYB_DOCUMENTS_SUCCESS",
  SUBMIT_BUSINESS_MEMBERS_KYB_DOCUMENTS_FAILURE:
    "SUBMIT_BUSINESS_MEMBERS_KYB_DOCUMENTS_FAILURE",
  SUBMIT_BUSINESS_MEMBERS_KYB_DOCUMENTS:
    "SUBMIT_BUSINESS_MEMBERS_KYB_DOCUMENTS",

  GET_BANKS_AND_GROUPS_REQUEST: "GET_BANKS_AND_GROUPS_REQUEST",
  GET_BANKS_AND_GROUPS_SUCCESS: "GET_BANKS_AND_GROUPS_SUCCESS",
  GET_BANKS_AND_GROUPS_FAILURE: "GET_BANKS_AND_GROUPS_FAILURE",
  GET_BANKS_AND_GROUPS: "GET_BANKS_AND_GROUPS",

  GET_PAYROLLS_REQUEST: "GET_PAYROLLS_REQUEST",
  GET_PAYROLLS_SUCCESS: "GET_PAYROLLS_SUCCESS",
  GET_PAYROLLS_FAILURE: "GET_PAYROLLS_FAILURE",
  GET_PAYROLLS: "GET_PAYROLLS",

  GET_KYB_STATUS_REQUEST: "GET_KYB_STATUS_REQUEST",
  GET_KYB_STATUS_SUCCESS: "GET_KYB_STATUS_SUCCESS",
  GET_KYB_STATUS_FAILURE: "GET_KYB_STATUS_FAILURE",
  GET_KYB_STATUS: "GET_KYB_STATUS",

  SEARCH_USERS_REQUEST: "SEARCH_USERS_REQUEST",
  SEARCH_USERS_SUCCESS: "SEARCH_USERS_SUCCESS",
  SEARCH_USERS_FAILURE: "SEARCH_USERS_FAILURE",
  SEARCH_USERS: "SEARCH_USERS",

  GET_USER_REQUEST: "GET_USER_REQUEST",
  GET_USER_SUCCESS: "GET_USER_SUCCESS",
  GET_USER_FAILURE: "GET_USER_FAILURE",
  GET_USER: "GET_USER",

  LOCK_USER_REQUEST: "LOCK_USER_REQUEST",
  LOCK_USER_SUCCESS: "LOCK_USER_SUCCESS",
  LOCK_USER_FAILURE: "LOCK_USER_FAILURE",
  LOCK_USER: "LOCK_USER",

  UNLOCK_USER_REQUEST: "UNLOCK_USER_REQUEST",
  UNLOCK_USER_SUCCESS: "UNLOCK_USER_SUCCESS",
  UNLOCK_USER_FAILURE: "UNLOCK_USER_FAILURE",
  UNLOCK_USER: "UNLOCK_USER",

  REQUIRE_PORTFOLIO_UPDATE_REQUEST: "REQUIRE_PORTFOLIO_UPDATE_REQUEST",
  REQUIRE_PORTFOLIO_UPDATE_SUCCESS: "REQUIRE_PORTFOLIO_UPDATE_SUCCESS",
  REQUIRE_PORTFOLIO_UPDATE_FAILURE: "REQUIRE_PORTFOLIO_UPDATE_FAILURE",
  REQUIRE_PORTFOLIO_UPDATE: "REQUIRE_PORTFOLIO_UPDATE",

  GET_USER_TRANSACTIONS_REQUEST: "GET_USER_TRANSACTIONS_REQUEST",
  GET_USER_TRANSACTIONS_SUCCESS: "GET_USER_TRANSACTIONS_SUCCESS",
  GET_USER_TRANSACTIONS_FAILURE: "GET_USER_TRANSACTIONS_FAILURE",
  GET_USER_TRANSACTIONS: "GET_USER_TRANSACTIONS",

  GET_PRICING_SHEET_REQUEST: "GET_PRICING_SHEET_REQUEST",
  GET_PRICING_SHEET_SUCCESS: "GET_PRICING_SHEET_SUCCESS",
  GET_PRICING_SHEET_FAILURE: "GET_PRICING_SHEET_FAILURE",
  GET_PRICING_SHEET: "GET_PRICING_SHEET",

  GET_BILLING_DETAILS_REQUEST: "GET_BILLING_DETAILS_REQUEST",
  GET_BILLING_DETAILS_SUCCESS: "GET_BILLING_DETAILS_SUCCESS",
  GET_BILLING_DETAILS_FAILURE: "GET_BILLING_DETAILS_FAILURE",
  GET_BILLING_DETAILS: "GET_BILLING_DETAILS",

  CREATE_BILLING_INVOICE_REQUEST: "CREATE_BILLING_INVOICE_REQUEST",
  CREATE_BILLING_INVOICE_SUCCESS: "CREATE_BILLING_INVOICE_SUCCESS",
  CREATE_BILLING_INVOICE_FAILURE: "CREATE_BILLING_INVOICE_FAILURE",
  CREATE_BILLING_INVOICE: "CREATE_BILLING_INVOICE",

  GET_PENDING_LINKED_EMPLOYERS_REQUEST: "GET_PENDING_LINKED_EMPLOYERS_REQUEST",
  GET_PENDING_LINKED_EMPLOYERS_SUCCESS: "GET_PENDING_LINKED_EMPLOYERS_SUCCESS",
  GET_PENDING_LINKED_EMPLOYERS_FAILURE: "GET_PENDING_LINKED_EMPLOYERS_FAILURE",
  GET_PENDING_LINKED_EMPLOYERS: "GET_PENDING_LINKED_EMPLOYERS",

  UPDATE_COMPANY_INFO_REQUEST: "UPDATE_COMPANY_INFO_REQUEST",
  UPDATE_COMPANY_INFO_SUCCESS: "UPDATE_COMPANY_INFO_SUCCESS",
  UPDATE_COMPANY_INFO_FAILURE: "UPDATE_COMPANY_INFO_FAILURE",
  UPDATE_COMPANY_INFO: "UPDATE_COMPANY_INFO",

  LIQUIDATE_ACCOUNT_REQUEST: "LIQUIDATE_ACCOUNT_REQUEST",
  LIQUIDATE_ACCOUNT_SUCCESS: "LIQUIDATE_ACCOUNT_SUCCESS",
  LIQUIDATE_ACCOUNT_FAILURE: "LIQUIDATE_ACCOUNT_FAILURE",
  LIQUIDATE_ACCOUNT: "LIQUIDATE_ACCOUNT",

  RESET_USER_STATE_REQUEST: "RESET_USER_STATE_REQUEST",
  RESET_USER_STATE_SUCCESS: "RESET_USER_STATE_SUCCESS",
  RESET_USER_STATE_FAILURE: "RESET_USER_STATE_FAILURE",
  RESET_USER_STATE: "RESET_USER_STATE",

  DISPERSE_USER_ACCOUNT_REQUEST: "DISPERSE_USER_ACCOUNT_REQUEST",
  DISPERSE_USER_ACCOUNT_SUCCESS: "DISPERSE_USER_ACCOUNT_SUCCESS",
  DISPERSE_USER_ACCOUNT_FAILURE: "DISPERSE_USER_ACCOUNT_FAILURE",
  DISPERSE_USER_ACCOUNT: "DISPERSE_USER_ACCOUNT",

  REOPEN_USER_ACCOUNT_REQUEST: "REOPEN_USER_ACCOUNT_REQUEST",
  REOPEN_USER_ACCOUNT_SUCCESS: "REOPEN_USER_ACCOUNT_SUCCESS",
  REOPEN_USER_ACCOUNT_FAILURE: "REOPEN_USER_ACCOUNT_FAILURE",
  REOPEN_USER_ACCOUNT: "REOPEN_USER_ACCOUNT",

  GET_ENTITY_LIMITS_REQUEST: "GET_ENTITY_LIMITS_REQUEST",
  GET_ENTITY_LIMITS_SUCCESS: "GET_ENTITY_LIMITS_SUCCESS",
  GET_ENTITY_LIMITS_FAILURE: "GET_ENTITY_LIMITS_FAILURE",
  GET_ENTITY_LIMITS: "GET_ENTITY_LIMITS",

  UPDATE_ENTITY_LIMIT_REQUEST: "UPDATE_ENTITY_LIMIT_REQUEST",
  UPDATE_ENTITY_LIMIT_SUCCESS: "UPDATE_ENTITY_LIMIT_SUCCESS",
  UPDATE_ENTITY_LIMIT_FAILURE: "UPDATE_ENTITY_LIMIT_FAILURE",
  UPDATE_ENTITY_LIMIT: "UPDATE_ENTITY_LIMIT",

  UNLINK_COMPANY_TO_CRM_REQUEST: "UNLINK_COMPANY_TO_CRM_REQUEST",
  UNLINK_COMPANY_TO_CRM_SUCCESS: "UNLINK_COMPANY_TO_CRM_SUCCESS",
  UNLINK_COMPANY_TO_CRM_FAILURE: "UNLINK_COMPANY_TO_CRM_FAILURE",
  UNLINK_COMPANY_TO_CRM: "UNLINK_COMPANY_TO_CRM",

  LINK_COMPANY_TO_CRM_REQUEST: "LINK_COMPANY_TO_CRM_REQUEST",
  LINK_COMPANY_TO_CRM_SUCCESS: "LINK_COMPANY_TO_CRM_SUCCESS",
  LINK_COMPANY_TO_CRM_FAILURE: "LINK_COMPANY_TO_CRM_FAILURE",
  LINK_COMPANY_TO_CRM: "LINK_COMPANY_TO_CRM",

  GET_COMPANY_CRM_LINK_REQUEST: "GET_COMPANY_CRM_LINK_REQUEST",
  GET_COMPANY_CRM_LINK_SUCCESS: "GET_COMPANY_CRM_LINK_SUCCESS",
  GET_COMPANY_CRM_LINK_FAILURE: "GET_COMPANY_CRM_LINK_FAILURE",
  GET_COMPANY_CRM_LINK: "GET_COMPANY_CRM_LINK",

  SYNC_CRM_DATA_REQUEST: "SYNC_CRM_DATA_REQUEST",
  SYNC_CRM_DATA_SUCCESS: "SYNC_CRM_DATA_SUCCESS",
  SYNC_CRM_DATA_FAILURE: "SYNC_CRM_DATA_FAILURE",
  SYNC_CRM_DATA: "SYNC_CRM_DATA",

  GET_UNPROCESSED_PAYROLLS_REQUEST: "GET_UNPROCESSED_PAYROLLS_REQUEST",
  GET_UNPROCESSED_PAYROLLS_SUCCESS: "GET_UNPROCESSED_PAYROLLS_SUCCESS",
  GET_UNPROCESSED_PAYROLLS_FAILURE: "GET_UNPROCESSED_PAYROLLS_FAILURE",
  GET_UNPROCESSED_PAYROLLS: "GET_UNPROCESSED_PAYROLLS",

  CHANGE_BILLING_TYPES_REQUEST: "CHANGE_BILLING_TYPES_REQUEST",
  CHANGE_BILLING_TYPES_SUCCESS: "CHANGE_BILLING_TYPES_SUCCESS",
  CHANGE_BILLING_TYPES_FAILURE: "CHANGE_BILLING_TYPES_FAILURE",
  CHANGE_BILLING_TYPES: "CHANGE_BILLING_TYPES",

  GET_WORKFLOWS_REQUEST: "GET_WORKFLOWS_REQUEST",
  GET_WORKFLOWS_SUCCESS: "GET_WORKFLOWS_SUCCESS",
  GET_WORKFLOWS_FAILURE: "GET_WORKFLOWS_FAILURE",
  GET_WORKFLOWS: "GET_WORKFLOWS",

  GET_WORKFLOW_STEPS_REQUEST: "GET_WORKFLOW_STEPS_REQUEST",
  GET_WORKFLOW_STEPS_SUCCESS: "GET_WORKFLOW_STEPS_SUCCESS",
  GET_WORKFLOW_STEPS_FAILURE: "GET_WORKFLOW_STEPS_FAILURE",
  GET_WORKFLOW_STEPS: "GET_WORKFLOW_STEPS",

  GET_CUSTOM_REPORT_TYPES_REQUEST: "GET_CUSTOM_REPORT_TYPES_REQUEST",
  GET_CUSTOM_REPORT_TYPES_SUCCESS: "GET_CUSTOM_REPORT_TYPES_SUCCESS",
  GET_CUSTOM_REPORT_TYPES_FAILURE: "GET_CUSTOM_REPORT_TYPES_FAILURE",
  GET_CUSTOM_REPORT_TYPES: "GET_CUSTOM_REPORT_TYPES",

  GET_CUSTOM_REPORTS_REQUEST: "GET_CUSTOM_REPORTS_REQUEST",
  GET_CUSTOM_REPORTS_SUCCESS: "GET_CUSTOM_REPORTS_SUCCESS",
  GET_CUSTOM_REPORTS_FAILURE: "GET_CUSTOM_REPORTS_FAILURE",
  GET_CUSTOM_REPORTS: "GET_CUSTOM_REPORTS",

  CREATE_CUSTOM_REPORT_REQUEST: "CREATE_CUSTOM_REPORT_REQUEST",
  CREATE_CUSTOM_REPORT_SUCCESS: "CREATE_CUSTOM_REPORT_SUCCESS",
  CREATE_CUSTOM_REPORT_FAILURE: "CREATE_CUSTOM_REPORT_FAILURE",
  CREATE_CUSTOM_REPORT: "CREATE_CUSTOM_REPORT",

  UPDATE_BILLING_CONTACT_REQUEST: "UPDATE_BILLING_CONTACT_REQUEST",
  UPDATE_BILLING_CONTACT_SUCCESS: "UPDATE_BILLING_CONTACT_SUCCESS",
  UPDATE_BILLING_CONTACT_FAILURE: "UPDATE_BILLING_CONTACT_FAILURE",
  UPDATE_BILLING_CONTACT: "UPDATE_BILLING_CONTACT",

  APPROVE_PAYROLL_INTEGRATION_REQUEST: "APPROVE_PAYROLL_INTEGRATION_REQUEST",
  APPROVE_PAYROLL_INTEGRATION_SUCCESS: "APPROVE_PAYROLL_INTEGRATION_SUCCESS",
  APPROVE_PAYROLL_INTEGRATION_FAILURE: "APPROVE_PAYROLL_INTEGRATION_FAILURE",
  APPROVE_PAYROLL_INTEGRATION: "APPROVE_PAYROLL_INTEGRATION",

  GET_KYC_STATUS_REQUEST: "GET_KYC_STATUS_REQUEST",
  GET_KYC_STATUS_SUCCESS: "GET_KYC_STATUS_SUCCESS",
  GET_KYC_STATUS_FAILURE: "GET_KYC_STATUS_FAILURE",
  GET_KYC_STATUS: "GET_KYC_STATUS",

  GET_REPORTS_STATUS_REQUEST: "GET_REPORTS_STATUS_REQUEST",
  GET_REPORTS_STATUS_SUCCESS: "GET_REPORTS_STATUS_SUCCESS",
  GET_REPORTS_STATUS_FAILURE: "GET_REPORTS_STATUS_FAILURE",
  GET_REPORTS_STATUS: "GET_REPORTS_STATUS",

  ADMIN_MIGRATE_EMPLOYEES_REQUEST: "ADMIN_MIGRATE_EMPLOYEES_REQUEST",
  ADMIN_MIGRATE_EMPLOYEES_SUCCESS: "ADMIN_MIGRATE_EMPLOYEES_SUCCESS",
  ADMIN_MIGRATE_EMPLOYEES_FAILURE: "ADMIN_MIGRATE_EMPLOYEES_FAILURE",
  ADMIN_MIGRATE_EMPLOYEES: "ADMIN_MIGRATE_EMPLOYEES",

  GET_SALES_QUOTES_REQUEST: "GET_SALES_QUOTES_REQUEST",
  GET_SALES_QUOTES_SUCCESS: "GET_SALES_QUOTES_SUCCESS",
  GET_SALES_QUOTES_FAILURE: "GET_SALES_QUOTES_FAILURE",
  GET_SALES_QUOTES: "GET_SALES_QUOTES",

  CREATE_SALES_QUOTE_REQUEST: "CREATE_SALES_QUOTE_REQUEST",
  CREATE_SALES_QUOTE_SUCCESS: "CREATE_SALES_QUOTE_SUCCESS",
  CREATE_SALES_QUOTE_FAILURE: "CREATE_SALES_QUOTE_FAILURE",
  CREATE_SALES_QUOTE: "CREATE_SALES_QUOTE",

  DELETE_SALES_QUOTE_REQUEST: "DELETE_SALES_QUOTE_REQUEST",
  DELETE_SALES_QUOTE_SUCCESS: "DELETE_SALES_QUOTE_SUCCESS",
  DELETE_SALES_QUOTE_FAILURE: "DELETE_SALES_QUOTE_FAILURE",
  DELETE_SALES_QUOTE: "DELETE_SALES_QUOTE",
};

export const beneficiaryConstants = {
  GET_BENEFICIARIES_REQUEST: "GET_BENEFICIARIES_REQUEST",
  GET_BENEFICIARIES_SUCCESS: "GET_BENEFICIARIES_SUCCESS",
  GET_BENEFICIARIES_FAILURE: "GET_BENEFICIARIES_FAILURE",
  GET_BENEFICIARIES: "GET_BENEFICIARIES",

  UPDATE_BENEFICIARIES_REQUEST: "UPDATE_BENEFICIARIES_REQUEST",
  UPDATE_BENEFICIARIES_SUCCESS: "UPDATE_BENEFICIARIES_SUCCESS",
  UPDATE_BENEFICIARIES_FAILURE: "UPDATE_BENEFICIARIES_FAILURE",
  UPDATE_BENEFICIARIES: "UPDATE_BENEFICIARIES",

  DELETE_BENEFICIARIES_REQUEST: "DELETE_BENEFICIARIES_REQUEST",
  DELETE_BENEFICIARIES_SUCCESS: "DELETE_BENEFICIARIES_SUCCESS",
  DELETE_BENEFICIARIES_FAILURE: "DELETE_BENEFICIARIES_FAILURE",
  DELETE_BENEFICIARIES: "DELETE_BENEFICIARIES",
};

export const payrollConstants = {
  GET_PAYROLL_LINE_ITEMS_REQUEST: "GET_PAYROLL_LINE_ITEMS_REQUEST",
  GET_PAYROLL_LINE_ITEMS_SUCCESS: "GET_PAYROLL_LINE_ITEMS_SUCCESS",
  GET_PAYROLL_LINE_ITEMS_FAILURE: "GET_PAYROLL_LINE_ITEMS_FAILURE",
  GET_PAYROLL_LINE_ITEMS: "GET_PAYROLL_LINE_ITEMS",

  GET_PAGINATED_CHANGE_REQUESTS_REQUEST:
    "GET_PAGINATED_CHANGE_REQUESTS_REQUEST",
  GET_PAGINATED_CHANGE_REQUESTS_SUCCESS:
    "GET_PAGINATED_CHANGE_REQUESTS_SUCCESS",
  GET_PAGINATED_CHANGE_REQUESTS_FAILURE:
    "GET_PAGINATED_CHANGE_REQUESTS_FAILURE",
  GET_PAGINATED_CHANGE_REQUESTS: "GET_PAGINATED_CHANGE_REQUESTS",

  GET_PAGINATED_CONTRIBUTIONS_REQUEST: "GET_PAGINATED_CONTRIBUTIONS_REQUEST",
  GET_PAGINATED_CONTRIBUTIONS_SUCCESS: "GET_PAGINATED_CONTRIBUTIONS_SUCCESS",
  GET_PAGINATED_CONTRIBUTIONS_FAILURE: "GET_PAGINATED_CONTRIBUTIONS_FAILURE",
  GET_PAGINATED_CONTRIBUTIONS: "GET_PAGINATED_CONTRIBUTIONS",

  APPROVE_PAYROLL_REQUEST: "APPROVE_PAYROLL_REQUEST",
  APPROVE_PAYROLL_SUCCESS: "APPROVE_PAYROLL_SUCCESS",
  APPROVE_PAYROLL_FAILURE: "APPROVE_PAYROLL_FAILURE",
  APPROVE_PAYROLL: "APPROVE_PAYROLL",

  GET_PAYROLL_HISTORY: "GET_PAYROLL_HISTORY",
  GET_PAYROLL_HISTORY_REQUEST: "GET_PAYROLL_HISTORY_REQUEST",
  GET_PAYROLL_HISTORY_SUCCESS: "GET_PAYROLL_HISTORY_SUCCESS",
  GET_PAYROLL_HISTORY_FAILURE: "GET_PAYROLL_HISTORY_FAILURE",

  CANCEL_PAYROLLS: "CANCEL_PAYROLLS",
  CANCEL_PAYROLLS_REQUEST: "CANCEL_PAYROLLS_REQUEST",
  CANCEL_PAYROLLS_SUCCESS: "CANCEL_PAYROLLS_SUCCESS",
  CANCEL_PAYROLLS_FAILURE: "CANCEL_PAYROLLS_FAILURE",

  GET_TOTAL_AMOUNT_SAVED: "GET_TOTAL_AMOUNT_SAVED",
  GET_TOTAL_AMOUNT_SAVED_REQUEST: "GET_TOTAL_AMOUNT_SAVED_REQUEST",
  GET_TOTAL_AMOUNT_SAVED_SUCCESS: "GET_TOTAL_AMOUNT_SAVED_SUCCESS",
  GET_TOTAL_AMOUNT_SAVED_FAILURE: "GET_TOTAL_AMOUNT_SAVED_FAILURE",

  CREATE_TOP_OFF_CONTRIBUTIONS: "CREATE_TOP_OFF_CONTRIBUTIONS",
  CREATE_TOP_OFF_CONTRIBUTIONS_REQUEST: "CREATE_TOP_OFF_CONTRIBUTIONS_REQUEST",
  CREATE_TOP_OFF_CONTRIBUTIONS_SUCCESS: "CREATE_TOP_OFF_CONTRIBUTIONS_SUCCESS",
  CREATE_TOP_OFF_CONTRIBUTIONS_FAILURE: "CREATE_TOP_OFF_CONTRIBUTIONS_FAILURE",

  CANCEL_PAYROLL: "CANCEL_PAYROLL",
  CANCEL_PAYROLL_REQUEST: "CANCEL_PAYROLL_REQUEST",
  CANCEL_PAYROLL_SUCCESS: "CANCEL_PAYROLL_SUCCESS",
  CANCEL_PAYROLL_FAILURE: "CANCEL_PAYROLL_FAILURE",

  RECHECK_PAYROLL_BALANCE: "RECHECK_PAYROLL_BALANCE",
  RECHECK_PAYROLL_BALANCE_REQUEST: "RECHECK_PAYROLL_BALANCE_REQUEST",
  RECHECK_PAYROLL_BALANCE_SUCCESS: "RECHECK_PAYROLL_BALANCE_SUCCESS",
  RECHECK_PAYROLL_BALANCE_FAILURE: "RECHECK_PAYROLL_BALANCE_FAILURE",

  DISMISS_PAYROLL_REQUEST: "DISMISS_PAYROLL_REQUEST",
  DISMISS_PAYROLL_SUCCESS: "DISMISS_PAYROLL_SUCCESS",
  DISMISS_PAYROLL_FAILURE: "DISMISS_PAYROLL_FAILURE",
  DISMISS_PAYROLL: "DISMISS_PAYROLL",

  APPROVE_SESSION_TOKEN_PAYROLL_REQUEST:
    "APPROVE_SESSION_TOKEN_PAYROLL_REQUEST",
  APPROVE_SESSION_TOKEN_PAYROLL_SUCCESS:
    "APPROVE_SESSION_TOKEN_PAYROLL_SUCCESS",
  APPROVE_SESSION_TOKEN_PAYROLL_FAILURE:
    "APPROVE_SESSION_TOKEN_PAYROLL_FAILURE",
  APPROVE_SESSION_TOKEN_PAYROLL: "APPROVE_SESSION_TOKEN_PAYROLL",
};

export const errorConstants = {
  FLUSH_ERRORS: "FLUSH_ERRORS",
};

export const sidebarConstants = {
  SIDEBAR_SHOW: "SIDEBAR_SHOW",
  SIDEBAR_HIDE: "SIDEBAR_HIDE",
};

export const groupCreationConstants = {
  GROUP_CREATION_SHOW: "GROUP_CREATION_SHOW",
  GROUP_CREATION_HIDE: "GROUP_CREATION_HIDE",
};
