import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { get, includes } from "lodash";
import { Switch } from "react-router-dom";
import { connect } from "react-redux";
import {
  filterAdminRoutesToRole,
  getAccountClosedRoutes,
  getEmployerRoutes,
  getLockedUserRoutes,
  getOnboardingEmployerRoutes,
  getResponsiveSideBarRoutes,
  individualRoutes,
} from "./Routes";
import PrivateRoute from "components/PrivateRoute";
import {
  getAllEmployerAccounts,
  getAllEmployerGroups,
  getBillingPortalURL,
  getCompanyInfo,
} from "actions/employerActions";
import { withApollo } from "@apollo/client/react/hoc";
import { getUserProfile } from "actions/userActions";
import { getAllAccounts } from "actions/accountActions";
import { individualSite } from "utils/determineSite";
import { getAdminRoles } from "store/selectors/admin";
import {
  employerOnboardingStates,
  IndividualClosed,
  IndividualClosePending,
  IndividualContributionPending,
  IndividualInvestmentProfileUpdate,
  IndividualPortfolioPending,
  IndividualPortfolioUpdate,
  IndividualRequiredUpdatesPending,
  lifecycleStates,
} from "statics/states";
import {
  isIndividualAccountOverview,
  userIsLockedSelector,
} from "store/selectors/user";
import {
  hasEnabledPayrollIntegration,
  hasRunFirstPayroll,
  hasStatePlanSelector,
  hasVirtualBankEnabled,
} from "store/selectors/employer";

import PageNotFound from "pages/PageNotFound";
import SideBar from "components/SideBar";
import SubNav from "components/navbar/SubNav";
import IconSpinner from "components/IconSpinner";

class DashboardLayout extends React.Component {
  static propTypes = {
    getCompanyInfo: PropTypes.func,
    getBillingPortalURL: PropTypes.func,
    client: PropTypes.object,
    shouldHideSideBar: PropTypes.bool,
    isEmployerDashboard: PropTypes.bool,
    accountClosed: PropTypes.bool,
    accountPendingClose: PropTypes.bool,
    isAdminDashboard: PropTypes.bool,
    getUserProfile: PropTypes.func,
    getAllEmployerAccounts: PropTypes.func,
    getAllEmployerGroups: PropTypes.func,
    getAllAccounts: PropTypes.func,
    color: PropTypes.string,
    companyState: PropTypes.shape({
      state: PropTypes.string,
    }),
    isAdministrator: PropTypes.bool,
    userIsLocked: PropTypes.bool,
    isSmallScreen: PropTypes.bool,
    hasStatePlan: PropTypes.bool,
    hasVirtualBank: PropTypes.bool,
    hasPayrollIntegration: PropTypes.bool,
    hasRunFirstPayroll: PropTypes.bool,
    userRoles: PropTypes.array,
    isIndividualDashboard: PropTypes.bool,
    shouldHideSubNav: PropTypes.bool,
  };

  static defaultProps = {
    color: "azure",
  };

  constructor(props) {
    super(props);

    this.state = {
      image: null,
      hasImage: false,
      initialFetching: true,
    };
  }

  async componentDidMount() {
    if (this.props.isAdminDashboard || this.props.userIsLocked) {
      this.setState({ initialFetching: false });
      return;
    }
    if (this.props.isEmployerDashboard) {
      await Promise.all([
        this.props.getCompanyInfo(this.props.client),
        this.props.getBillingPortalURL(
          this.props.client,
          window.location.origin + "/dashboard"
        ),
        this.props.getAllEmployerGroups(this.props.client),
        this.props.getAllEmployerAccounts(this.props.client),
      ]);
      this.setState({ initialFetching: false });
    } else {
      // hydrate user profile as various sub components rely on user profile data
      await Promise.all([
        this.props.getUserProfile(this.props.client),
        this.props.getAllAccounts(this.props.client),
      ]);
      this.setState({ initialFetching: false });
    }
  }

  componentDidUpdate(e) {
    if (e.history.action === "PUSH" && this.mainPanel) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.mainPanel.scrollTop = 0;
    }
    if (
      // window.innerWidth < 993 &&
      e.history.action === "PUSH" &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
    }
  }

  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse || prop.hiddenChildRoutes) {
        return this.getRoutes(prop.views);
      }
      if (
        includes(
          ["/employer", "/workflow", "/individual", "/admin"],
          prop.layout
        )
      ) {
        return (
          <PrivateRoute
            path={prop.path}
            exact
            key={key}
            component={(props) => <prop.component {...props} />}
          />
        );
      } else {
        return null;
      }
    });
  };

  getRoutesToUse = () => {
    const isOnboardingEmployer = includes(
      employerOnboardingStates,
      get(this.props, "companyState.state", false)
    );

    if (this.props.accountClosed || this.props.accountPendingClose) {
      return getAccountClosedRoutes();
    } else if (this.props.isEmployerDashboard && isOnboardingEmployer) {
      return getOnboardingEmployerRoutes();
    } else if (this.props.isEmployerDashboard) {
      return getEmployerRoutes({
        hasRanFirstPayroll: this.props.hasRunFirstPayroll,
        hasStatePlan: this.props.hasStatePlan,
        isAdministrator: this.props.isAdministrator,
        hasPayrollIntegration: this.props.hasPayrollIntegration,
        hasVirtualBank: this.props.hasVirtualBank,
      });
    } else if (this.props.isAdminDashboard) {
      return filterAdminRoutesToRole(this.props.userRoles);
    } else if (this.props.userIsLocked) {
      return getLockedUserRoutes();
    } else if (this.props.isSmallScreen) {
      return getResponsiveSideBarRoutes();
    } else {
      return individualRoutes;
    }
  };

  render() {
    if (this.state.initialFetching) {
      return <IconSpinner centered />;
    }
    const routesToUse = this.getRoutesToUse();

    const component = (
      <Switch>
        {this.getRoutes(routesToUse)}
        <PrivateRoute component={PageNotFound} />
      </Switch>
    );

    if (
      this.props.isIndividualDashboard &&
      !this.props.isSmallScreen &&
      !this.props.shouldHideSubNav
    ) {
      return (
        <div>
          <SubNav {...this.props} routes={routesToUse}></SubNav>
          <div ref={(ref) => (this.mainPanel = ref)}>{component}</div>
        </div>
      );
    } else if (this.props.isSmallScreen) {
      return (
        <div>
          <div className="portfolio-sm-screen">
            <SideBar
              {...this.props}
              routes={routesToUse}
              image={this.state.image}
              color={this.props.color}
              hasImage={this.state.hasImage}
              isEmployer={this.props.isEmployerDashboard}
            ></SideBar>
            <div ref={(ref) => (this.mainPanel = ref)}>{component}</div>
          </div>
        </div>
      );
    }
    return (
      <div>
        <div className="portfolio-lg-screen">
          {!this.props.shouldHideSideBar && (
            <SideBar
              {...this.props}
              routes={routesToUse}
              image={this.state.image}
              color={this.props.color}
              hasImage={this.state.hasImage}
              isEmployer={this.props.isEmployerDashboard}
            />
          )}

          <div
            className={classnames("main-panel", {
              ["no-sidebar"]:
                this.props.shouldHideSideBar &&
                this.props.isIndividualDashboard,
            })}
            ref={(ref) => (this.mainPanel = ref)}
          >
            {component}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const userState = get(state.user, "userState.state", "");

  const statesToHideSideBar = [
    IndividualPortfolioPending,
    IndividualContributionPending,
    IndividualInvestmentProfileUpdate,
    IndividualPortfolioUpdate,
    IndividualRequiredUpdatesPending,
  ];

  /*
   * As this is a very high level component of our app that renders routing logic
   * we must be very careful what state vals we are mapping here, we should avoid
   * anything that is dynamic/fetched by multiple components (groups, company info, etc) to avoid
   * recursive mounting loops in children components.
   * */
  return {
    ownProps,
    companyState: state.user.companyState,
    accountClosed: userState === IndividualClosed,
    accountPendingClose: userState === IndividualClosePending,
    isAdministrator: get(state.user, "userRoleId", "") === "2",
    shouldHideSideBar:
      statesToHideSideBar.includes(userState) &&
      lifecycleStates.includes(userState) &&
      individualSite() &&
      !isIndividualAccountOverview(state),
    shouldHideSubNav: statesToHideSideBar.includes(userState),
    userIsLocked: userIsLockedSelector(state),
    hasStatePlan: hasStatePlanSelector(state),
    hasVirtualBank: hasVirtualBankEnabled(state),
    hasPayrollIntegration: hasEnabledPayrollIntegration(state),
    hasRunFirstPayroll: hasRunFirstPayroll(state),
    userRoles: getAdminRoles(state),
    isIndividualDashboard:
      lifecycleStates.includes(userState) && individualSite(),
  };
};
const mapDispatchToProps = {
  getCompanyInfo,
  getUserProfile,
  getAllAccounts,
  getAllEmployerGroups,
  getAllEmployerAccounts,
  getBillingPortalURL,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(DashboardLayout));
