import React from "react";
import PropTypes from "prop-types";
import ProgressBar from "components/ProgressBar";

import { userService } from "services/userService";
import { Col, Form } from "react-bootstrap";
import { Formik } from "formik";

import { registerEmployer } from "actions/employerActions";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import {
  every,
  get,
  includes,
  intersectionBy,
  isEmpty,
  isNull,
  pick,
  replace,
  some,
  toLower,
} from "lodash";
import { toast } from "react-toastify";

import {
  businessTypes,
  employeeRange,
  naicsCategories,
  naicsValues,
} from "statics/businessCodes";
import {
  address2Validator,
  noWhitespaceRegex,
  postalCodeValidator,
  testEIN,
  testPhoneNumber,
  urlValidator,
  validateUsersAddress,
  whiteSpaceError,
} from "utils/fieldValidators";
import { employerConstants, userConstants } from "actions/types";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import Button from "components/Button";
import Alert from "components/Alert";
import queryString from "query-string";
import InputMask from "react-input-mask";
import StateSelect from "components/StateSelect";
import AddressValidator from "components/AddressValidator";
import IconSpinner from "components/IconSpinner";
import EmployerOwnerInfo from "./EmployerOwnerInfo";

import "./EmployerEnroll.scss";
import { getUserState } from "actions/userActions";
import {
  auth0PlanCodeSelector,
  auth0ReferralCodeSelector,
} from "store/selectors/auth0";
import { ENTERPRISE, findPlan } from "statics/planCodes";
import PlanCard from "./PlanCard";
import { planCodeSelector } from "store/selectors/employer";
import { COMPANY_INFO } from "statics/onboardingSteps";
import {
  alphabeticalPayrollProviders,
  isolvedPartnerReferralCodes,
  isolvedProviders,
  wholesaleBillingProviders,
  wholesaleBillingReferralCodes,
} from "statics/payrollProviders";
import { ScrollToFieldError } from "utils/form";

/* eslint-disable react/no-children-prop */

let yup = require("yup");

function testNaicsCode(value) {
  const regex = /99999/;
  const match = regex.test(value);
  if (match) {
    return false;
  } else {
    return true;
  }
}

const schema = yup.object({
  businessName: yup
    .string()
    .label("Company Legal Name")
    .required()
    .min(3, "Must be at least three characters.")
    .max(200, "Must be less than 200 characters.")
    .matches(noWhitespaceRegex, whiteSpaceError),
  businessDBA: yup
    .string()
    .label("Company DBA")
    .min(1, "Must be at least one character.")
    .max(200),
  ein: yup
    .string()
    .label("EIN")
    .required()
    .max(10)
    .test("ein", "${path} is not a valid format. Ex: 12-3456789", testEIN),
  confirmEin: yup
    .string()
    .label("Confirm EIN")
    .required()
    .max(10)
    .test("eins-match", `EIN must match`, function (value) {
      return this.parent.ein === value;
    }),
  businessType: yup.string().label("Incorporation Type").required(),
  naicsCode: yup.string().label("Industry Type").required(),
  businessSize: yup.string().label("Company size").required(),
  businessSizeDisplayValue: yup
    .number()
    .label("Company size")
    .required()
    .min(1),
  businessURL: yup
    .string()
    .label("Website URL")
    .max(200)
    .test("businessURL", "Enter a valid website URL", urlValidator),
  naicsCodeCategory: yup
    .string()
    .label("Industry Category")
    .required()
    .test("naicsCat", "Please choose an Industry Category", testNaicsCode),
  address1: yup
    .string()
    .label("Address 1")
    .required()
    .min(3, "Must be at least three characters.")
    .max(40),
  address2: address2Validator,
  city: yup.string().label("City").required().max(40),
  state: yup.string().label("State").required().max(2),
  postalCode: postalCodeValidator,
  phone: yup
    .string()
    .label("Phone")
    .required()
    .test(
      "us-phone",
      "${path} is not a valid phone number. Ex: 123-456-7890",
      testPhoneNumber
    )
    .max(30),
  phoneExt: yup.string().label("Phone Ext").max(10),
  referralCode: yup.string().label("Referral Code").max(30),
  needsStatePlan: yup.string().label("Satisfy State Mandate").required(),
  payrollProvider: yup.string().label("Payroll Provider").required(),
});

const verifyCompany = (company) => {
  const userCompany = pick(company, [
    "id",
    "name",
    "ein",
    "type",
    "naicsCode",
    "size",
    "address.address1",
    "address.city",
    "address.state",
    "address.postalCode",
    "address.phone",
  ]);
  return every(userCompany, (val) => !isNull(val) && !isEmpty(val));
};

class EmployerEnroll extends React.Component {
  static propTypes = {
    client: PropTypes.shape({}),
    registerEmployer: PropTypes.func,
    company: PropTypes.object,
    getUserState: PropTypes.func,
    error: PropTypes.string,
    isFetching: PropTypes.bool,
    isMultiAccount: PropTypes.bool,
    userState: PropTypes.string,
    companyId: PropTypes.string,
    organizationId: PropTypes.string,
    initialPlanCode: PropTypes.string,
    savedPlanCode: PropTypes.string,
    companyState: PropTypes.string,
    referralCode: PropTypes.string,
  };

  _isMounted = false;

  constructor(props) {
    super(props);

    const referralCode =
      queryString.parse(window.location.search).referralCode || "";

    this.state = {
      company: {
        businessName: "",
        businessDBA: "",
        ein: "",
        businessType: "",
        naicsCodeCategory: "",
        naicsCode: "",
        businessSize: null,
        referralCode,
        businessURL: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        postalCode: "",
        phone: "",
        phoneExt: "",
        payrollProvider: "",
      },
      disableIndustryType: true,
      submitted: false,
      loading: true,
      showAddressModal: false,
      fetchingAddress: false,
      urlHadInviteCode: !!referralCode,
      showOwnerInfoPage: false,
    };
  }

  async componentDidMount() {
    window.analytics.page("Employer Company Info");
    this._isMounted = true;

    const isAddingEmp =
      (this.props.isMultiAccount &&
        this.props.companyState === "EmployerApplicationApproved") ||
      this.props.companyState === "EmployerRegistrationComplete";

    const identityIsVerified =
      !isAddingEmp &&
      !isEmpty(this.props.company) &&
      verifyCompany(this.props.company);

    this.setState({
      submitted: false,
      loading: false,
      showOwnerInfoPage: identityIsVerified,
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  submitEmployer = async (values, skipAddressValidation = false) => {
    if (!skipAddressValidation) {
      try {
        this.setState({ fetchingAddress: true });

        const companyAddress = {
          address1: values.address1,
          address2: values.address2,
          state: values.state,
          postalCode: values.postalCode,
          city: values.city,
        };

        const validatedAddress = await userService.getValidatedAddress(
          this.props.client,
          companyAddress
        );

        this.setState({ fetchingAddress: false });

        const userAddressIsValid = validateUsersAddress(
          companyAddress,
          validatedAddress
        );

        if (!userAddressIsValid) {
          this.setState({
            showAddressModal: true,
            validatedAddress,
          });

          return;
        }
      } catch (error) {
        // if the validation endpoint fails we dont want to prevent user from saving so continue on and log error
        console.log(error);
      }
    }

    let company = {
      name: values.businessName,
      dba: values.businessDBA,
      ein: values.ein,
      type: values.businessType,
      naicsCode: values.naicsCode,
      size: values.businessSize,
      referralCode: values.referralCode || "",
      url: values.businessURL,
      address1: values.address1,
      address2: values.address2,
      city: values.city,
      state: values.state,
      postalCode: values.postalCode,
      phone: values.phone,
      phoneExt: values.phoneExt,
      payrollProvider: values.payrollProvider,
      planCode: this._getPlanCode(values),
    };

    await this.props.registerEmployer(this.props.client, {
      company,
      organizationId: this.props.organizationId,
      needsStatePlan: values.needsStatePlan === "yes",
    });

    if (!this.props.error) {
      await this.props.getUserState(this.props.client);
      toast.success("Successfully registered company.");

      this.setState({
        showOwnerInfoPage: true,
      });
    }

    window.analytics.track("Submitted Company Information", {});

    if (this._isMounted) {
      this.setState({
        submitted: true,
      });
    }
  };

  _closeModal = () => {
    this.setState({ showAddressModal: false });
  };

  _getPlanCode = (values) => {
    const isWholesaleBillingReferral = some(
      wholesaleBillingReferralCodes,
      (code) => {
        return toLower(code) === toLower(this.state.company.referralCode);
      }
    );

    const isWholesaleBillingProvider = includes(
      wholesaleBillingProviders,
      values.payrollProvider
    );
    if (isWholesaleBillingProvider || isWholesaleBillingReferral) {
      return null;
    }

    if (this.props.initialPlanCode && !values.businessSizeDisplayValue) {
      return this.props.initialPlanCode;
    }

    const matchingPlan = findPlan(
      values.businessSizeDisplayValue,
      this.props.initialPlanCode
    );

    if (matchingPlan && this.props.initialPlanCode) return matchingPlan.id;

    return null;
  };

  _getPayrollProvidersToUse = () => {
    const isIsolvedReferral = some(
      isolvedPartnerReferralCodes,
      (code) => toLower(code) === toLower(this.props.referralCode)
    );

    // only show isolved payroll providers if they came in with isolved referral code
    if (isIsolvedReferral) {
      return intersectionBy(
        alphabeticalPayrollProviders,
        isolvedProviders,
        (val) => {
          return val.id || val;
        }
      );
    }

    return alphabeticalPayrollProviders;
  };

  renderEmployerForm = () => {
    const { company } = this.state;

    var articleStyle = {
      paddingBottom: 0,
    };

    const payrollProvidersToUse = this._getPayrollProvidersToUse();

    return (
      <div>
        <Formik
          validateOnChange={false}
          validationSchema={schema}
          onSubmit={(values) => {
            this.submitEmployer(values);
          }}
          enableReinitialize={true}
          initialValues={{
            ...company,
            needsStatePlan: "",
            businessSizeDisplayValue: "",
            confirmEin: "",
            ein: "",
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
            setFieldTouched,
            values,
            touched,
            errors,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <ScrollToFieldError />
              {this.state.showAddressModal && (
                <AddressValidator
                  show={this.state.showAddressModal}
                  onSubmit={this.submitEmployer}
                  setFieldValue={setFieldValue}
                  validatedAddress={this.state.validatedAddress}
                  onClose={this._closeModal}
                  values={values}
                  allowPoBox={true}
                />
              )}
              <div className="mega-container">
                <div
                  className="step-container is-active"
                  data-circle-percent="30"
                >
                  <section className="page-title-wrap">
                    <article className="text-cell">
                      <p className="page-title">Company Verification</p>
                    </article>
                  </section>
                  <div id="form-employer-company-profile">
                    <section className="form-sec-2col">
                      <article className="col-form" style={articleStyle}>
                        <Form.Row>
                          <Form.Group
                            as={Col}
                            md={10}
                            controlId="formBasicBusinessName"
                          >
                            <p className="page-label">Company Information</p>
                            <p className="page-subtext">
                              We are required by law to identify and verify your
                              business to create your Icon account.
                            </p>
                            <Form.Control
                              name="businessName"
                              placeholder="Company Legal Name"
                              value={values.businessName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={
                                touched.businessName && !!errors.businessName
                              }
                              isValid={
                                touched.businessName && !errors.businessName
                              }
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.businessName}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Form.Row>

                        <Form.Row>
                          <Form.Group
                            as={Col}
                            md={10}
                            controlId="formBasicBusinessDBA"
                          >
                            <Form.Control
                              name="businessDBA"
                              placeholder="Company DBA"
                              value={values.businessDBA}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={
                                touched.businessDBA && !!errors.businessDBA
                              }
                              isValid={
                                touched.businessDBA && !errors.businessDBA
                              }
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.businessDBA}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Form.Row>

                        <Form.Row>
                          <Form.Group
                            as={Col}
                            sm={4}
                            controlId="formBasicBusinessSize"
                          >
                            <Form.Control
                              name="businessSizeDisplayValue"
                              placeholder="Number of employees"
                              value={values.businessSizeDisplayValue}
                              onBlur={handleBlur}
                              onChange={(e) => {
                                const rawValueFromInputString = +replace(
                                  e.target.value,
                                  /\D/g,
                                  ""
                                );
                                setFieldValue(
                                  "businessSizeDisplayValue",
                                  rawValueFromInputString
                                );
                                setFieldValue(
                                  "businessSize",
                                  employeeRange(rawValueFromInputString)
                                );
                              }}
                              isInvalid={
                                touched.businessSizeDisplayValue &&
                                !!errors.businessSizeDisplayValue
                              }
                              isValid={
                                touched.businessSizeDisplayValue &&
                                !errors.businessSizeDisplayValue
                              }
                            />

                            <Form.Control.Feedback type="invalid">
                              {errors.businessSizeDisplayValue}
                            </Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group
                            as={Col}
                            sm={6}
                            controlId="formBasicBusinessType"
                          >
                            <Form.Control
                              sm={4}
                              as="select"
                              name="businessType"
                              value={values.businessType}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={
                                touched.businessType && !!errors.businessType
                              }
                              isValid={
                                touched.businessType && !errors.businessType
                              }
                            >
                              <option value="" disabled>
                                Incorporation Type
                              </option>
                              {businessTypes.map((item) => (
                                <option value={item.value} key={item.value}>
                                  {item.description}
                                </option>
                              ))}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                              {errors.businessType}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Form.Row>

                        <Form.Row style={{ marginBottom: 12 }}>
                          <Form.Group
                            as={Col}
                            sm={4}
                            controlId="formBasicBusinessEIN"
                          >
                            <InputMask
                              mask="99-9999999"
                              name="ein"
                              placeholder="EIN"
                              value={values.ein}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={touched.ein && !!errors.ein}
                              isValid={touched.ein && !errors.ein}
                              children={(props) => <Form.Control {...props} />}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.ein}
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group
                            as={Col}
                            sm={4}
                            controlId="formBusinessConfirmEIN"
                          >
                            <InputMask
                              mask="99-9999999"
                              name="confirmEin"
                              placeholder="Confirm EIN"
                              value={values.confirmEin}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={
                                touched.confirmEin && !!errors.confirmEin
                              }
                              isValid={touched.confirmEin && !errors.confirmEin}
                              children={(props) => <Form.Control {...props} />}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.confirmEin}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Form.Row>
                      </article>
                      <PlanCard
                        planCode={(() => this._getPlanCode(values))()}
                      />
                    </section>

                    <section className="form-sec-2col">
                      <article className="col-form">
                        <p className="page-label">Company Setup</p>
                        <Form.Row>
                          <Form.Group
                            as={Col}
                            sm={6}
                            controlId="formBasicPayrollProvider"
                          >
                            <Form.Label>
                              What payroll service do you use?
                            </Form.Label>
                            <Form.Control
                              sm={6}
                              as="select"
                              name="payrollProvider"
                              value={values.payrollProvider}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={
                                touched.payrollProvider &&
                                !!errors.payrollProvider
                              }
                              isValid={
                                touched.payrollProvider &&
                                !errors.payrollProvider
                              }
                            >
                              <option value="" disabled>
                                Payroll Provider
                              </option>
                              {payrollProvidersToUse.map((item) => (
                                <option value={item.id} key={item.id}>
                                  {item.label}
                                </option>
                              ))}
                            </Form.Control>

                            <Form.Control.Feedback type="invalid">
                              {errors.payrollProvider}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Form.Row>
                        <Form.Row>
                          <Form.Group
                            as={Col}
                            controlId="formBasicNeedStatePlan"
                          >
                            <Form.Label>
                              Are you going to be using Icon to satisfy a state
                              mandate (e.g. CalSavers)?
                            </Form.Label>

                            <Form.Control
                              sm={2}
                              as="select"
                              name="needsStatePlan"
                              value={values.needsStatePlan}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={
                                touched.needsStatePlan &&
                                !!errors.needsStatePlan
                              }
                              isValid={
                                touched.needsStatePlan && !errors.needsStatePlan
                              }
                              style={{ width: 140 }}
                            >
                              <option
                                value={""}
                                key={"blank-state-label"}
                                disabled
                              >
                                Select an option
                              </option>
                              <option value={"yes"} key={"yes"}>
                                Yes
                              </option>
                              <option value={"no"} key={"no"}>
                                No
                              </option>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                              {errors.needsStatePlan}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Form.Row>
                      </article>
                    </section>
                    <section className="form-sec-2col">
                      <article className="col-form" style={articleStyle}>
                        <Form.Row>
                          <Form.Group
                            as={Col}
                            md={10}
                            controlId="formBasicBusinessNAICSCategory"
                          >
                            <p className="page-label">Company Industry </p>
                            <p className="page-subtext">
                              Choosing a category and type helps us verify your
                              information. It doesn{`'`}t have to be an exact
                              match, just pick the best one you see.
                            </p>
                            <Form.Control
                              sm={6}
                              as="select"
                              name="naicsCodeCategory"
                              value={values.naicsCodeCategory}
                              onChange={(e) => {
                                handleChange(e);
                                this.setState({
                                  disableIndustryType: false,
                                });
                                // reset the code values if the category changes
                                setFieldValue("naicsCode", "");
                                setFieldTouched("naicsCode", false, false);
                              }}
                              onBlur={handleBlur}
                              isInvalid={
                                touched.naicsCodeCategory &&
                                !!errors.naicsCodeCategory
                              }
                              isValid={
                                touched.naicsCodeCategory &&
                                !errors.naicsCodeCategory
                              }
                            >
                              <option value="" disabled>
                                Industry Category
                              </option>
                              {naicsCategories.map((item, index) => (
                                <option value={item.value} key={index}>
                                  {item.value}
                                </option>
                              ))}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                              {errors.naicsCodeCategory}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Form.Row>
                        <Form.Row>
                          <Form.Group
                            as={Col}
                            md={12}
                            controlId="formBasicBusinessNAICS"
                          >
                            <Form.Control
                              sm={6}
                              as="select"
                              name="naicsCode"
                              value={values.naicsCode}
                              onChange={(e) => {
                                setFieldTouched("naicsCode", false, false);
                                handleChange(e);
                              }}
                              onBlur={handleBlur}
                              isInvalid={
                                touched.naicsCode && !!errors.naicsCode
                              }
                              isValid={touched.naicsCode && !errors.naicsCode}
                              disabled={this.state.disableIndustryType}
                            >
                              <option value="" disabled>
                                Industry Type
                              </option>
                              {naicsValues
                                .filter(
                                  (i) => i.category === values.naicsCodeCategory
                                )
                                .map((item, index) => (
                                  <option value={item.value} key={index}>
                                    {item.description}
                                  </option>
                                ))}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                              {errors.naicsCode}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Form.Row>
                      </article>
                    </section>

                    <section className="form-sec-2col">
                      <article className="col-form">
                        <Form.Group controlId="formBasicAddress1">
                          <p className="page-label">Company Address</p>
                          <Form.Control
                            name="address1"
                            placeholder="Address 1"
                            value={values.address1}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.address1 && !!errors.address1}
                            isValid={touched.address1 && !errors.address1}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.address1}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="formBasicAddress2">
                          <Form.Control
                            name="address2"
                            placeholder="Address 2"
                            value={values.address2}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.address2 && !!errors.address2}
                            isValid={touched.address2 && !errors.address2}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.address2}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Row>
                          <Form.Group as={Col} controlId="formBasicCity">
                            <Form.Control
                              name="city"
                              placeholder="City"
                              value={values.city}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={touched.city && !!errors.city}
                              isValid={touched.city && !errors.city}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.city}
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group
                            as={Col}
                            sm={4}
                            controlId="formBasicState"
                          >
                            <StateSelect
                              value={values.state}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              touched={touched}
                              errors={errors}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.state}
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group
                            as={Col}
                            sm={2}
                            controlId="formBasicPostalCode"
                          >
                            <Form.Control
                              name="postalCode"
                              placeholder="Zip"
                              value={values.postalCode}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={
                                touched.postalCode && !!errors.postalCode
                              }
                              isValid={touched.postalCode && !errors.postalCode}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.postalCode}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Form.Row>
                        <Form.Group controlId="formBasicBusinessURL">
                          <Form.Control
                            name="businessURL"
                            placeholder="Website"
                            value={values.businessURL}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={
                              touched.businessURL && !!errors.businessURL
                            }
                            isValid={touched.businessURL && !errors.businessURL}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.businessURL}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Row>
                          <Form.Group
                            as={Col}
                            sm={4}
                            controlId="formBasicPhone"
                          >
                            <InputMask
                              mask="999-999-9999"
                              name="phone"
                              placeholder="Phone"
                              value={values.phone}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              isInvalid={touched.phone && !!errors.phone}
                              isValid={touched.phone && !errors.phone}
                            >
                              {(props) => <Form.Control {...props} />}
                            </InputMask>
                            <Form.Control.Feedback type="invalid">
                              {errors.phone}
                            </Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group
                            as={Col}
                            sm={2}
                            controlId="formBasicPhoneExt"
                          >
                            <Form.Control
                              name="phoneExt"
                              placeholder="Ext"
                              value={values.phoneExt}
                              onChange={handleChange}
                              isInvalid={touched.phoneExt && !!errors.phoneExt}
                              onBlur={handleBlur}
                              isValid={touched.phoneExt && !errors.phoneExt}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.phoneExt}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Form.Row>
                      </article>
                    </section>

                    <section className="form-sec-2col">
                      <article className="col-form">
                        <div className="submit-row">
                          {this.props.error && (
                            <Alert type="error" msg={this.props.error} />
                          )}
                          <Button
                            name="submit"
                            withArrow={true}
                            loading={
                              this.props.isFetching ||
                              this.state.fetchingAddress
                            }
                          />
                        </div>
                      </article>
                    </section>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  };

  render() {
    let content;

    if (this.state.loading) {
      content = <IconSpinner centered />;
    } else if (this.state.showOwnerInfoPage) {
      content = <EmployerOwnerInfo />;
    } else {
      content = this.renderEmployerForm();
    }

    const progPercent = this.props.isMultiAccount ? "25" : "75";
    const savedPlanCode = this.props.savedPlanCode;
    const initialPlanCode = this.props.initialPlanCode;

    const showBillingRoute = this.state.showOwnerInfoPage
      ? savedPlanCode && savedPlanCode !== ENTERPRISE
      : !!initialPlanCode;
    return (
      <div className="employer-enroll">
        <ProgressBar
          isEmployer={true}
          activeStepId={COMPANY_INFO.id}
          progressPercent={progPercent}
          isMultiAccount={this.props.isMultiAccount}
          showBillingRoute={showBillingRoute}
        />
        <div>{content}</div>
      </div>
    );
  }
}

const actions = [employerConstants.REGISTER_EMPLOYER, userConstants.USER_STATE];
const loadingSelector = createLoadingSelector(actions);
const errorSelector = createErrorSelector(actions);

const mapStateToProps = (state, ownProps) => {
  const {
    employer: { company },
  } = state;
  return {
    company,
    ownProps,
    isMultiAccount: state.employer.isMultiAccount,
    organizationId: get(state.employer, "organization.id"),
    error: errorSelector(state),
    isFetching: loadingSelector(state),
    companyState: get(state.user, "companyState.state"),
    initialPlanCode: auth0PlanCodeSelector(state),
    savedPlanCode: planCodeSelector(state),
    referralCode: auth0ReferralCodeSelector(state),
  };
};

const mapDispatchToProps = {
  registerEmployer,
  getUserState,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(EmployerEnroll));
