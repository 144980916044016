export function simulateLinkClickNewTab(url, newTab = true) {
  const link = document.createElement("a");

  document.body.appendChild(link);
  link.href = url;
  if (newTab) {
    link.target = "_blank";
  }
  link.click();
  document.body.removeChild(link);
}

export function scrollToTop() {
  window.scrollTo(0, 0);
}

export default {
  simulateLinkClickNewTab,
  scrollToTop,
};

export function scrollIntoView(node) {
  if (node) {
    node.scrollIntoView();
  }
  return;
}

export function downloadCSVFromString(csvString, fileName) {
  // Convert the CSV string to a Blob
  var blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });

  // Create a link element
  var link = document.createElement("a");

  // Set the link's href attribute to a URL representing the Blob
  link.href = URL.createObjectURL(blob);

  // Set the download attribute with the desired file name
  link.download = fileName;

  // Append the link to the document
  document.body.appendChild(link);

  // Trigger a click on the link to start the download
  link.click();

  // Remove the link from the document
  document.body.removeChild(link);
}
