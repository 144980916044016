import { lowerCase, sortBy } from "lodash";

export const payrollProviders = [
  {
    label: "AAP",
    id: "aap_isolved",
  },
  {
    label: "Abacus HCM",
    id: "abacus_isolved",
  },
  {
    label: "Absolute Paytrust",
    id: "absolute_isolved",
  },
  {
    label: "AccountantsWorld",
    id: "accountantsworld",
  },
  {
    label: "Accupay Systems",
    id: "accupay_isolved",
  },
  {
    label: "Ace Workforce Technologies",
    id: "ace_isolved",
  },
  {
    label: "Adams Keegan",
    id: "adams_keegan",
  },
  {
    label: "ADP Comprehensive Services",
    id: "adp_comprehensive",
  },
  {
    label: "ADP TotalSource",
    id: "adp_totalsource",
  },
  {
    label: "ADP Workforce Now",
    id: "adp_workforce_now",
  },
  {
    label: "Advantage Payroll Services",
    id: "advantage_payroll_services",
  },
  {
    label: "Affiliated HR Payroll Services Evolution",
    id: "affiliated_hr_payroll_services_evolution",
  },
  {
    label: "Affiliated Payroll",
    id: "affiliated_hr_payroll_services_isolved",
  },
  {
    label: "Ahola",
    id: "ahola",
  },
  {
    label: "AlphaStaff",
    id: "alphastaff",
  },
  {
    label: "Amplify HR",
    id: "amplify_hr",
  },
  {
    label: "Apdata",
    id: "apdata",
  },
  {
    label: "APS Payroll",
    id: "aps_payroll",
  },
  {
    label: "Asset HR",
    id: "asset_hr",
  },
  {
    label: "Asure Software",
    id: "asure_software",
  },
  {
    label: "Balance Point",
    id: "balance_point",
  },
  {
    label: "BambooHR",
    id: "bamboo_hr",
  },
  {
    label: "Baron Payroll",
    id: "baron_isolved",
  },
  {
    label: "BASIC",
    id: "basic",
  },
  {
    label: "BBCS Payroll",
    id: "bbcs",
  },
  {
    label: "BBSI",
    id: "bbsi",
  },
  {
    label: "BCN Services",
    id: "bcn_services",
  },
  {
    label: "Bene-Care",
    id: "benecare",
  },
  {
    label: "Big Fish Employer Services",
    id: "big_fish_employer_services",
  },
  {
    label: "Bizchecks Payroll",
    id: "bizchecks_payroll",
  },
  {
    label: "bob",
    id: "bob",
  },
  {
    label: "Business Online Payroll",
    id: "business_online_payroll",
  },
  {
    label: "Ceridian Dayforce",
    id: "ceridian_dayforce",
  },
  {
    label: "Ceridian Powerpay",
    id: "ceridian_powerpay",
  },
  {
    label: "Cezanne HR",
    id: "cezannehr",
  },
  {
    label: "CharlieHR",
    id: "charlie_hr",
  },
  {
    label: "ClickUp",
    id: "clickup",
  },
  {
    label: "CoAdvantage",
    id: "coadvantage",
  },
  {
    label: "Coastal Payroll",
    id: "coastal_payroll",
  },
  {
    label: "CognosHR",
    id: "cognos_hr",
  },
  {
    label: "Collage",
    id: "collage",
  },
  {
    label: "Commonwealth Payroll & HR",
    id: "commonwealth_payroll_hr",
  },
  {
    label: "ConnectPay",
    id: "connectpay",
  },
  {
    label: "CPM Employer Solutions",
    id: "cpm_isolved",
  },
  {
    label: "Crescent Payroll Solutions",
    id: "crescent_payroll_solutions",
  },
  {
    label: "CSC Paymaster",
    id: "csc_paymaster",
  },
  {
    label: "Darwinbox",
    id: "darwinbox",
  },
  {
    label: "Decent",
    id: "decent",
  },
  {
    label: "Deel",
    id: "deel",
  },
  {
    label: "Deltek",
    id: "deltek",
  },
  {
    label: "Deluxe",
    id: "deluxe",
  },
  {
    label: "DM Payroll Solutions",
    id: "dm_payroll_solutions",
  },
  {
    label: "Dominion Payroll",
    id: "dominion_payroll",
  },
  {
    label: "Doyle",
    id: "doyle_hcm",
  },
  {
    label: "Eddy",
    id: "eddyhr",
  },
  {
    label: "EmPower HR",
    id: "empower_hr",
  },
  {
    label: "Emplicity",
    id: "emplicity",
  },
  {
    label: "Employdrive",
    id: "employdrive",
  },
  {
    label: "Employer Flexible",
    id: "employer_flexible",
  },
  {
    label: "Employer Services Corporation (ESC)",
    id: "esc",
  },
  {
    label: "Engage PEO",
    id: "engage_peo",
  },
  {
    label: "Everee",
    id: "everee",
  },
  {
    label: "Evolution Payroll Services",
    id: "evolution_payroll_services",
  },
  {
    label: "Excel Resources",
    id: "excel_resources",
  },
  {
    label: "ExtensisHR",
    id: "extensis_hr",
  },
  {
    label: "Flock",
    id: "flock",
  },
  {
    label: "Freshteam",
    id: "freshteam",
  },
  {
    label: "FullStack PEO",
    id: "fullstack_peo",
  },
  {
    label: "G&A Partners",
    id: "gna_partners",
  },
  {
    label: "Gig Wage",
    id: "gigwage",
  },
  {
    label: "Goco",
    id: "goco",
  },
  {
    label: "greytHR",
    id: "greyt_hr",
  },
  {
    label: "GTM Payroll Services Evolution",
    id: "gtm_payroll_services_evolution",
  },
  {
    label: "GTM Payroll Services",
    id: "gtm_payroll_services_isolved",
  },
  {
    label: "Gusto",
    id: "gusto",
  },
  {
    label: "Heartland",
    id: "heartland",
  },
  {
    label: "Helpside",
    id: "helpside",
  },
  {
    label: "Highflyer HR",
    id: "highflyer_hr",
  },
  {
    label: "Horizons",
    id: "horizons",
  },
  {
    label: "HR Cloud",
    id: "hrcloud",
  },
  {
    label: "HROne",
    id: "hrone",
  },
  {
    label: "HRO",
    id: "hro",
  },
  {
    label: "Hubstaff",
    id: "hubstaff",
  },
  {
    label: "Humaans",
    id: "humaans",
  },
  {
    label: "Humi",
    id: "humi",
  },
  {
    label: "iSolved",
    id: "isolved",
  },
  {
    label: "INFINITI HR",
    id: "infiniti_hr",
  },
  {
    label: "Infor",
    id: "infor",
  },
  {
    label: "Insperity",
    id: "insperity",
  },
  {
    label: "Iris HR",
    id: "iris_hr",
  },
  {
    label: "Justworks",
    id: "justworks",
  },
  {
    label: "Kenjo",
    id: "kenjo",
  },
  {
    label: "Keystone Payroll",
    id: "keystone_isolved",
  },
  {
    label: "mp",
    id: "mp",
  },
  {
    label: "MPAY",
    id: "mpay",
  },
  {
    label: "Multiplier",
    id: "multiplier",
  },
  {
    label: "Namely",
    id: "namely",
  },
  {
    label: "NaturalHR",
    id: "natural_hr",
  },
  {
    label: "Netchex",
    id: "netchex",
  },
  {
    label: "Newtek",
    id: "newtek",
  },
  {
    label: "Nextep",
    id: "nextep",
  },
  {
    label: "Oasis",
    id: "oasis",
  },
  {
    label: "Obsidian HR",
    id: "obsidianhr_isolved",
  },
  {
    label: "odoo",
    id: "odoo",
  },
  {
    label: "OnPay",
    id: "onpay",
  },
  {
    label: "OneSource Payroll",
    id: "onesource_payroll",
  },
  {
    label: "Opolis",
    id: "opolis",
  },
  {
    label: "Oracle Fusion",
    id: "oracle_fusion",
  },
  {
    label: "Oracle PeopleSoft",
    id: "oracle_peoplesoft",
  },
  {
    label: "Panther",
    id: "panther",
  },
  {
    label: "Paragon Payroll",
    id: "paragon_payroll_isolved",
  },
  {
    label: "Patriot",
    id: "patriot",
  },
  {
    label: "PayFit",
    id: "payfit",
  },
  {
    label: "PayNorthwest",
    id: "paynw",
  },
  {
    label: "PayPro HCS",
    id: "paypro_hcs_isolved",
  },
  {
    label: "PayUSA",
    id: "pay_usa_inc",
  },
  {
    label: "Paychex Flex",
    id: "paychex_flex",
  },
  {
    label: "Paycom",
    id: "paycom",
  },
  {
    label: "Paycor",
    id: "paycor",
  },
  {
    label: "Payday Workforce Solutions",
    id: "payday_workforce_solutions",
  },
  {
    label: "Paylocity",
    id: "paylocity",
  },
  {
    label: "Payroll Network",
    id: "payroll_network",
  },
  {
    label: "Payroll Office of America",
    id: "payroll_office_of_america",
  },
  {
    label: "Payroll Plus HCM Evolution",
    id: "payroll_plus_hcm_evolution",
  },
  {
    label: "Payroll Plus HCM",
    id: "payroll_plus_hcm_isolved",
  },
  {
    label: "Payroll Solutions",
    id: "payroll_solutions",
  },
  {
    label: "Paytime",
    id: "paytime",
  },
  {
    label: "PCS HCM",
    id: "pcs_hcm",
  },
  {
    label: "PeopleForce",
    id: "peopleforce",
  },
  {
    label: "PeopleHum",
    id: "peoplehum",
  },
  {
    label: "People Lease",
    id: "people_lease",
  },
  {
    label: "Personio",
    id: "personio",
  },
  {
    label: "Platinum Group",
    id: "platinum_group",
  },
  {
    label: "PrestigePEO",
    id: "prestige_peo",
  },
  {
    label: "PrimePay",
    id: "primepay",
  },
  {
    label: "PrismHR",
    id: "prism_hr",
  },
  {
    label: "ProSoftware",
    id: "prosoftware",
  },
  {
    label: "Proliant",
    id: "proliant",
  },
  {
    label: "PropelHR",
    id: "propel_hr",
  },
  {
    label: "Proxus HR",
    id: "proxus_hr",
  },
  {
    label: "Pushpinder Tax Services",
    id: "pushpinder_tax_services",
  },
  {
    label: "QuickBooks Payroll",
    id: "quickbooks",
  },
  {
    label: "Remote",
    id: "remote",
  },
  {
    label: "Resourcing Edge",
    id: "resourcing_edge",
  },
  {
    label: "Rippling",
    id: "rippling",
  },
  {
    label: "RMI PEO",
    id: "rmi_peo",
  },
  {
    label: "Run Powered by ADP",
    id: "adp_run",
  },
  {
    label: "Sage HR",
    id: "sage_hr",
  },
  {
    label: "Sage Payroll",
    id: "sage_payroll",
  },
  {
    label: "SAP SuccessFactors",
    id: "successfactors",
  },
  {
    label: "Sapling",
    id: "sapling",
  },
  {
    label: "Savant HCM Evolution",
    id: "savant_hcm_evolution",
  },
  {
    label: "Sequoia One",
    id: "sequoia_one",
  },
  {
    label: "Sheakley",
    id: "sheakley",
  },
  {
    label: "Simploy",
    id: "simploy",
  },
  {
    label: "Skuad",
    id: "skuad",
  },
  {
    label: "Solution Services",
    id: "solution_services",
  },
  {
    label: "SourceOne",
    id: "sourceone",
  },
  {
    label: "Southeast Personnel Leasing",
    id: "southeastpersonnel",
  },
  {
    label: "Square Payroll",
    id: "square_payroll",
  },
  {
    label: "Strategic Payroll Solutions",
    id: "strategic_payroll_solutions_isolved",
  },
  {
    label: "SuitePeople (Oracle NetSuite)",
    id: "suite_people",
  },
  {
    label: "SurePayroll",
    id: "surepayroll",
  },
  {
    label: "SyncHR",
    id: "sync_hr",
  },
  {
    label: "Teampay by ADP Run",
    id: "adp_teampay",
  },
  {
    label: "Teamworks Group",
    id: "teamworks",
  },
  {
    label: "The Payroll Company",
    id: "the_payroll_company",
  },
  {
    label: "Thread HCM",
    id: "thread_hcm",
  },
  {
    label: "Toast Payroll",
    id: "toast_payroll",
  },
  {
    label: "Trakstar",
    id: "trakstar",
  },
  {
    label: "TriNet",
    id: "trinet",
  },
  {
    label: "UKG Ready (Kronos)",
    id: "ukg_ready",
  },
  {
    label: "UltiPro (UKG Pro)",
    id: "ulti_pro",
  },
  {
    label: "Uzio",
    id: "uzio",
  },
  {
    label: "Velocity Global",
    id: "velocity_global",
  },
  {
    label: "VensureHR",
    id: "vensure_hr",
  },
  {
    label: "Vfficient",
    id: "vfficient",
  },
  {
    label: "Viewpoint HR Management Spectrum",
    id: "viewpoint_hr_management_spectrum",
  },
  {
    label: "Viewpoint HR Management Vista",
    id: "viewpoint_hr_management_vista",
  },
  {
    label: "Viventium",
    id: "viventium",
  },
  {
    label: "Wagepoint",
    id: "wagepoint",
  },
  {
    label: "Wave",
    id: "wave",
  },
  {
    label: "WebHR",
    id: "web_hr",
  },
  {
    label: "whirks",
    id: "whirks",
  },
  {
    label: "Workday",
    id: "workday",
  },
  {
    label: "Workforce Junction",
    id: "workforce_junction",
  },
  {
    label: "Worklio",
    id: "worklio",
  },
  {
    label: "Workplace HCM",
    id: "workplace_hcm",
  },
  {
    label: "Workzoom",
    id: "workzoom",
  },
  {
    label: "wurk",
    id: "wurk",
  },
  {
    label: "Xenium HR",
    id: "xenium_hr",
  },
  {
    label: "Xero",
    id: "xero",
  },
  {
    label: "Zenefits",
    id: "zenefits",
  },
  {
    label: "Zoho Payroll",
    id: "zoho_payroll",
  },
  {
    label: "Other",
    id: "other",
  },
  {
    id: "asap_accounting_and_payroll_inc",
    label: "ASAP ACCOUNTING AND PAYROLL INC",
  },
  {
    id: "asap_pay_solutions",
    label: "ASAP PAY SOLUTIONS",
  },
  {
    id: "adva_pay_systems",
    label: "AdvaPay Systems",
  },
  {
    id: "ahola_hr_solutions_payroll",
    label: "Ahola HR Solutions | Payroll",
  },
  {
    id: "am_check_las_vegas",
    label: "AmCheck Las Vegas",
  },
  {
    id: "candoor_hcm",
    label: "Candoor hcm",
  },
  {
    id: "columbia_edp",
    label: "Columbia EDP",
  },
  {
    id: "combined_hcm",
    label: "Combined HCM",
  },
  {
    id: "complete_payroll",
    label: "Complete Payroll",
  },
  {
    id: "complete_payroll_solutions",
    label: "Complete Payroll Solutions",
  },
  {
    id: "concept_hr",
    label: "Concept HR",
  },
  {
    id: "contempo_hcm",
    label: "Contempo HCM",
  },
  {
    id: "dynamic_hr",
    label: "DynamicHR",
  },
  {
    id: "envision_payroll",
    label: "Envision Payroll",
  },
  {
    id: "greenlink_hcm",
    label: "Greenlink HCM",
  },
  {
    id: "higher_up_hcm",
    label: "HigherUp HCM",
  },
  {
    id: "idilus_hr",
    label: "Idilus HR",
  },
  {
    id: "infinium_staffing",
    label: "Infinium Staffing",
  },
  {
    id: "integrated_payroll_services",
    label: "Integrated Payroll Services",
  },
  {
    id: "lift_hcm",
    label: "Lift HCM",
  },
  {
    id: "mp_hr_solutions",
    label: "MP HR solutions",
  },
  {
    id: "managed_pay",
    label: "ManagedPAY",
  },
  {
    id: "mercantile_bank_corp",
    label: "Mercantile Bank Corp.",
  },
  {
    id: "my_office_pro",
    label: "My Office Pro",
  },
  {
    id: "ncr_voyix",
    label: "NCR VOYIX",
  },
  {
    id: "optimum_employer_solutions",
    label: "Optimum Employer Solutions",
  },
  {
    id: "pay_pros_inc",
    label: "PAY PROS INC",
  },
  {
    id: "pni_hcm",
    label: "PNI HCM",
  },
  {
    id: "pacific_payroll_group",
    label: "Pacific Payroll Group",
  },
  {
    id: "pacific_payroll",
    label: "Pacific Payroll",
  },
  {
    id: "paper_trails",
    label: "Paper Trails",
  },
  {
    id: "pay_serv_systems",
    label: "PayServ Systems",
  },
  {
    id: "payday_hcm",
    label: "Payday HCM",
  },
  {
    id: "payfluence_hcm",
    label: "Payfluence HCM",
  },
  {
    id: "payroll_people_inc",
    label: "Payroll People Inc.",
  },
  {
    id: "payroll_vault",
    label: "Payroll Vault",
  },
  {
    id: "peopleworx",
    label: "Peopleworx",
  },
  {
    id: "pink_payroll",
    label: "PinkPayroll",
  },
  {
    id: "premier_payroll_services",
    label: "Premier Payroll Services",
  },
  {
    id: "press_gold_group",
    label: "Press Gold Group",
  },
  {
    id: "pro_service_hawaii",
    label: "ProService Hawaii",
  },
  {
    id: "snf_payroll_hr",
    label: "SNF Payroll & HR",
  },
  {
    id: "savant_payroll_solutions",
    label: "Savant Payroll Solutions",
  },
  {
    id: "solex_hcm",
    label: "Solex HCM",
  },
  {
    id: "true_payroll_integration",
    label: "TRUE PAYROLL INTEGRATION",
  },
  {
    id: "the_payroll_department_inc",
    label: "The Payroll Department, Inc",
  },
  {
    id: "unity_hr",
    label: "Unity HR",
  },
  {
    id: "vision_payroll",
    label: "Vision Payroll",
  },
  {
    id: "worx_payroll",
    label: "Worx Payroll",
  },
  {
    id: "zuma_pay",
    label: "Zuma Pay",
  },
];

export const isolvedProviders = [
  "isolved",
  "aap_isolved",
  "abacus_isolved",
  "absolute_isolved",
  "accupay_isolved",
  "ace_isolved",
  "affiliated_hr_payroll_services_isolved",
  "baron_isolved",
  "cpm_isolved",
  "gtm_payroll_services_isolved",
  "keystone_isolved",
  "obsidianhr_isolved",
  "paragon_payroll_isolved",
  "paypro_hcs_isolved",
  "payroll_plus_hcm_isolved",
  "strategic_payroll_solutions_isolved",
  "proxus_hr",
  "highflyer_hr",
  "pay_usa_inc",
  "the_payroll_company",
  "whirks",
  "asap_accounting_and_payroll_inc",
  "asap_pay_solutions",
  "adva_pay_systems",
  "ahola_hr_solutions_payroll",
  "am_check_las_vegas",
  "candoor_hcm",
  "columbia_edp",
  "combined_hcm",
  "complete_payroll",
  "complete_payroll_solutions",
  "concept_hr",
  "contempo_hcm",
  "dynamic_hr",
  "envision_payroll",
  "greenlink_hcm",
  "higher_up_hcm",
  "idilus_hr",
  "infinium_staffing",
  "integrated_payroll_services",
  "lift_hcm",
  "mp_hr_solutions",
  "managed_pay",
  "mercantile_bank_corp",
  "my_office_pro",
  "ncr_voyix",
  "optimum_employer_solutions",
  "pay_pros_inc",
  "pni_hcm",
  "pacific_payroll",
  "paper_trails",
  "pay_serv_systems",
  "payday_hcm",
  "payfluence_hcm",
  "payroll_people_inc",
  "payroll_vault",
  "peopleworx",
  "pink_payroll",
  "premier_payroll_services",
  "press_gold_group",
  "pro_service_hawaii",
  "snf_payroll_hr",
  "savant_payroll_solutions",
  "solex_hcm",
  "true_payroll_integration",
  "the_payroll_department_inc",
  "unity_hr",
  "vision_payroll",
  "worx_payroll",
  "zuma_pay",
];

export const isolvedPartnerReferralCodes = [
  "ISOLVED",
  "ISOLVEDZUMAPAY",
  "ISOLVEDTHREADHCM",
  "ISOLVEDPARAGONPAYROLL",
  "ISOLVEDPINKPAYROLL",
  "ISOLVEDPACIFICPAYROLL",
  "ISOLVEDSELECTHCM",
  "ISOLVEDPAYMASTERS",
  "ISOLVEDCOASTALPAYROLL",
];

// list of all payroll providers that are linked to a wholesale billing partner
export const wholesaleBillingProviders = [...isolvedProviders];
export const wholesaleBillingReferralCodes = [...isolvedPartnerReferralCodes];

export const alphabeticalPayrollProviders = sortBy(
  payrollProviders,
  (provider) => lowerCase(provider.label)
);
