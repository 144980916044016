import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { wrapError } from "utils/errorHelper";
import { Modal, Row, Col } from "react-bootstrap";
import { simulateLinkClickNewTab } from "utils/dom";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { getUrlForDocument } from "services/documentService";
import { auth0AccountSessionSelector } from "store/selectors/auth0";
import { FiDownload } from "react-icons/fi";
import { getRolloverInfo } from "actions/userActions";
import { userConstants } from "actions/types";
import { some, isEmpty, find } from "lodash";

import Button from "components/Button";
import IconHeader from "components/IconHeader";
import IconSubheader from "components/IconSubheader";

import "./RolloverInstructionsModal.scss";

const Steps = ({ steps }) => {
  return (
    <div className="rollover-step-container">
      {steps.map((step, index) => (
        <Row key={index} style={{ paddingBottom: 45 }}>
          <Col sm={1}>
            <div className="rollover-step-circle">{step.index}</div>
          </Col>

          <Col>
            <div>
              <p className="rollover-step-label">{step.label}</p>
              <p className="rollover-step-description">{step.description}</p>
              {step.customComponent && (
                <div className="rollover-custom-component">
                  {step.customComponent({ ...step })}
                </div>
              )}
            </div>
          </Col>
        </Row>
      ))}
    </div>
  );
};

Steps.propTypes = {
  label: PropTypes.string,
  description: PropTypes.string,
  index: PropTypes.number,
  steps: PropTypes.array,
};

class RolloverInstructionsModal extends React.PureComponent {
  static propTypes = {
    onClose: PropTypes.func,
    onSuccess: PropTypes.func,
    show: PropTypes.bool,
    client: PropTypes.object.isRequired,
    getRolloverInfo: PropTypes.func,
    error: PropTypes.string,
    rolloverInfo: PropTypes.object,
    accountId: PropTypes.string,
    isProcessingRollover: PropTypes.bool,
  };

  _promises = [];

  constructor() {
    super();

    this.state = {
      rolloverInfo: null,
      loading: true,
      error: null,
      documentId: "",
      accountId: "",
    };
  }

  componentDidMount() {
    if (this.props.isProcessingRollover) {
      this.props
        .getRolloverInfo(this.props.client, this.props.accountId)
        .then((response) => {
          const data = response.data;
          const { docId: documentId, accountId: accountId } = data;
          this.setState({ documentId, accountId });
        })
        .catch((error) => {
          console.error("Error fetching rollover info:", error);
        });
    }
  }

  _downloadDoc() {
    const { documentId, accountId } = this.state;
    getUrlForDocument(this.props.client, accountId, documentId)
      .then((response) => {
        simulateLinkClickNewTab(response.url);
      })
      .catch((error) => {
        this.setState({ error: wrapError(error) });
      });
  }

  _buildRolloverSteps = () => {
    const steps = [
      {
        index: 1,
        label: "Download and print a copy of your rollover form.",
        description:
          "Please ensure you sign the bottom of the form in black ink. This signed form, along with your checks, will need to be mailed together.",
        customComponent: () => {
          return this.props.isProcessingRollover ? (
            <a className="rollover-link" onClick={() => this._downloadDoc()}>
              <FiDownload
                className="icon"
                color="white"
                stroke="#60A4BF"
                size={16}
              />
              <p className="link-text">Icon Rollover Form</p>
            </a>
          ) : (
            <p className="doc-text">
              Your document is being generated. You will receive an email
              notification when it is ready!
            </p>
          );
        },
      },
      {
        index: 2,
        label: "Make check payable to:",
        description: "Apex Clearing Corporation, FBO <your name>",
      },
      {
        index: 3,
        label: "Send the check to the address below:",
        customComponent: () => (
          <>
            <p className="rollover-step-description">
              Attn. Banking Department
            </p>
            <p className="rollover-step-description">
              Apex Clearing Corporation
            </p>
            <p className="rollover-step-description">
              350 North St. Paul Street, Suite 1300 Dallas, Texas 75201
            </p>
          </>
        ),
      },
    ];
    return steps;
  };

  render() {
    const steps = this._buildRolloverSteps();

    if (this.props.show) {
      return (
        <Modal
          centered
          scrollable
          show={this.props.show}
          className="rollover-modal"
        >
          <Modal.Header className="modal-header">
            <IconHeader
              variant="cardHeader"
              headerText="Complete your rollover! "
            />
            <IconSubheader subheader="To finish rolling over your account, contact your plan provider and request a rollover. Try calling the phone number on your statement and provide them the instructions below." />
          </Modal.Header>
          <Modal.Body>
            <Steps steps={steps} />
          </Modal.Body>
          <Modal.Footer>
            <Button
              name="cancel"
              btnLabel="Done"
              onClick={() => this.props.onClose()}
            />
          </Modal.Footer>
        </Modal>
      );
    }
    return null;
  }
}

const actions = [userConstants.GET_ROLLOVER_INFO];

const errorSelector = createErrorSelector(actions);
const loadingSelector = createLoadingSelector(actions);

const mapStateToProps = (state) => {
  const accounts = state.accounts.iraAccounts;
  const activeAccountId = auth0AccountSessionSelector(state);
  const account = find(accounts, { isProcessingRollover: true });
  const id = account ? account.id : null;
  const accountId = isEmpty(activeAccountId) ? id : activeAccountId;
  const isProcessingRollover = some(accounts, {
    isProcessingRollover: true,
    state: "AccountActive",
  });

  return {
    accountId,
    isProcessingRollover,
    activeAccountId,
    error: errorSelector(state),
    loading: loadingSelector(state),
  };
};

const mapDispatchToProps = {
  getRolloverInfo,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(RolloverInstructionsModal));
