import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import Button from "components/Button";
import { Card } from "react-bootstrap";
import { noop } from "lodash";

import { FaCheckCircle } from "react-icons/fa";
import IconSpinner from "components/IconSpinner";

import "./ActionCard.scss";
export const ActionCard = ({
  onClick,
  icon,
  title,
  description,
  actionText,
  completedAction,
  loading,
  disabled,
}) => {
  return (
    <Card>
      <Card.Body>
        <div>
          <div className="action-card-details">
            {icon && (
              <div className="action-icon-container">
                <div className="action-icon-circle">
                  <div className="action-icon">{icon}</div>
                </div>
              </div>
            )}
            <h5 className="action-card-title">{title}</h5>
            <p className="action-card-description">{description}</p>
          </div>
          {!completedAction && !loading && (
            <div className={classnames("action-card-action", { disabled })}>
              <Button
                name="action"
                size="sm"
                onClick={disabled ? noop : onClick}
              >
                {actionText}
              </Button>
            </div>
          )}
          {completedAction && (
            <div className="action-card-action completed-action">
              <div className="success-icon">
                <FaCheckCircle size={24} color="#4BB543" />
              </div>
            </div>
          )}
          {loading && (
            <div className="action-card-action action-card-loading">
              <IconSpinner />
            </div>
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

ActionCard.propTypes = {
  onClick: PropTypes.func,
  icon: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  actionText: PropTypes.string,
  completedAction: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default ActionCard;
