import { get, findIndex, isNil, isEmpty } from "lodash";
import {
  bankConstants,
  employerConstants,
  userConstants,
  portfolioConstants,
  accountConstants,
} from "actions/types";

const initialState = {
  iraAccounts: [],
  bankAccounts: [],
};

const updateAccountList = (accounts, updatedAccount) => {
  //Find account with same id
  const index = accounts.findIndex(
    (account) => account.id === updatedAccount.id
  );
  //Checking if account exists
  const accountExists = index !== -1;

  //Use the map to create a new array where the account with the matching id is replaced by updatedAccount otherwise gives original account
  if (accountExists) {
    return accounts.map((account) =>
      account.id === updatedAccount.id ? updatedAccount : account
    );
  }
  //If the account does not exist use spread to create new array with updatedAccount added to the end of accounts.
  return [...accounts, updatedAccount];
};

export function accounts(state = initialState, action) {
  switch (action.type) {
    case accountConstants.GET_ACCOUNTS_SUCCESS: {
      const { iraAccounts, bankAccounts } = action.data;
      const iraAccountIds = iraAccounts.map((account) => account.id);
      const bankAccountIds = bankAccounts.map((account) => account.id);
      return {
        ...state,
        iraAccounts: [
          // only keep accounts (IRA and Banks) that aren't being changed by the new payload
          ...state.iraAccounts.filter(
            (account) => !iraAccountIds.includes(account.id)
          ),
          ...iraAccounts,
        ],
        bankAccounts: [
          ...state.bankAccounts.filter(
            (account) => !bankAccountIds.includes(account.id)
          ),
          ...bankAccounts,
        ],
      };
    }
    case bankConstants.LINK_BANK_WITH_ICON_SUCCESS: {
      const { userBank } = action.data;
      return {
        ...state,
        bankAccounts: [...state.bankAccounts, userBank],
      };
    }
    case bankConstants.VERIFY_BANK_SUCCESS: {
      const accounts = state.bankAccounts.filter(
        (a) => a.id !== action.data.id
      );
      return {
        ...state,
        bankAccounts: [...accounts, action.data],
      };
    }
    case employerConstants.GET_EMPLOYER_ACCOUNTS_SUCCESS: {
      const bankAccounts = get(action, "data");
      const bankAccountIds = bankAccounts.map((bank) => get(bank, "id"));
      const oldAccounts = state.bankAccounts.filter(
        (account) => !bankAccountIds.includes(account.id)
      );
      if (isNil(bankAccounts) || isEmpty(bankAccounts)) {
        return state;
      }
      return {
        ...state,
        bankAccounts: [...oldAccounts, ...bankAccounts],
      };
    }
    case bankConstants.DELETE_BANK_SUCCESS:
      return {
        ...state,
        bankAccounts: state.bankAccounts.filter(
          (account) => account.id !== action.data
        ),
      };
    case accountConstants.GET_IRA_BALANCE_SUCCESS: {
      const { iraAccounts } = action.data;
      const accountsWithNewBalance = iraAccounts.reduce((accum, account) => {
        return {
          ...accum,
          [account.id]: account,
        };
      }, {});
      const newIraAccounts = state.iraAccounts.map((account) => {
        if (Object.keys(accountsWithNewBalance).includes(account.id)) {
          return {
            ...account,
            balances: accountsWithNewBalance[account.id].balances,
          };
        }
        return account;
      });
      return {
        ...state,
        iraAccounts: newIraAccounts,
      };
    }
    case bankConstants.SET_RECURRING_SCHEDULE: {
      const idxOfBank = findIndex(state.bankAccounts, {
        id: action.data.bankId,
      });
      if (idxOfBank < 0) {
        return state;
      }
      const banks = [...state.bankAccounts];
      const bankClone = { ...banks[idxOfBank] };
      bankClone.recurringContribution = action.data.recurringContribution;
      banks[idxOfBank] = bankClone;
      return {
        ...state,
        bankAccounts: banks,
      };
    }
    case userConstants.SUBMIT_ROLLOVER_INFO_SUCCESS:
    case userConstants.ADD_ROLLOVER_ACCOUNT_DISCLOSURE_SUCCESS:
    case userConstants.USER_ROLLOVER_INVESTMENT_PROFILE_UPDATE_SUCCESS:
    case portfolioConstants.SELECT_USER_ROLLOVER_PORTFOLIO_SUCCESS:
    case userConstants.UPDATE_ROLLOVER_ACCOUNT_TERMS_SUBMIT_SUCCESS: {
      const key = [
        "submitRolloverAccountInfo",
        "addRolloverAccountDisclosure",
        "updateRolloverInvestmentProfileAndSuitability",
        "addRolloverAccountPortfolio",
        "updateRolloverAccountTermsAndSubmit",
      ].find((key) => get(action.data, key));

      const updatedAccount = get(action.data, key);

      if (updatedAccount) {
        const newState = {
          ...state,
          iraAccounts: updateAccountList(state.iraAccounts, updatedAccount),
        };

        return newState;
      }
      return state;
    }

    default:
      return state;
  }
}
