import React from "react";
import PropTypes from "prop-types";
import { withApollo } from "@apollo/client/react/hoc";
import { connect } from "react-redux";
import { Card, Col, Row } from "react-bootstrap";
import { createErrorSelector } from "store/selectors";
import { linkBank, verifyBankAccount } from "actions/bankActions";
import { bankConstants } from "actions/types";
import {
  activeBanksSelector,
  banksSelector,
  pendingBanksSelector,
} from "store/selectors/bank";

import Alert from "components/Alert";

import AddBankCard from "pages/dashboards/AddBankCard";
import BankCard from "pages/dashboards/individualDashboard/contributions/overview/BankCard";
import { progressiveOnboardingEvents } from "statics/states";
import { updateProgressiveOnboarding } from "actions/employerActions";
import { getLimitByType, hasAddedBank } from "store/selectors/employer";

class BankAccounts extends React.Component {
  static propTypes = {
    linkBank: PropTypes.func,
    client: PropTypes.object,
    error: PropTypes.string,
    bankAccounts: PropTypes.arrayOf(PropTypes.object),
    pendingBanks: PropTypes.arrayOf(PropTypes.object),
    activeBanks: PropTypes.arrayOf(PropTypes.object),
    verifyBankAccount: PropTypes.func,
    updateProgressiveOnboarding: PropTypes.func,
    hasAddedBank: PropTypes.bool,
    bankAccountLimit: PropTypes.string,
  };
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      submitted: false,
    };
  }

  render() {
    return (
      <div className="download-employee-template">
        <div className="main-content">
          <Card>
            <Card.Title>
              <p className="page-header">Linked Accounts</p>
              <p className="subtext">
                Securely connect your business bank account to Icon in order to
                facilitate your employee{"'"}s payroll deductions.
              </p>
            </Card.Title>
            <Card.Body>
              {this.props.error && (
                <Alert type="error" msg={this.props.error} />
              )}
              <Row id="add-banks">
                {this.props.activeBanks.map((b) => {
                  return (
                    <Col key={b.id} md={6}>
                      <BankCard
                        allowContribution={false}
                        key={b.id}
                        bankAccount={b}
                      />
                    </Col>
                  );
                })}
                {this.props.pendingBanks.map((b) => {
                  return (
                    <Col key={b.id} md={6}>
                      <AddBankCard key={b.id} bankAccount={b} />
                    </Col>
                  );
                })}
                {this.props.bankAccounts.length <
                  this.props.bankAccountLimit && (
                  <Col md={6}>
                    <AddBankCard
                      insertOnlyMode
                      onSuccess={() => {
                        if (!this.props.hasAddedBank) {
                          this.props.updateProgressiveOnboarding(
                            this.props.client,
                            progressiveOnboardingEvents.AddBank
                          );
                        }
                      }}
                    />
                  </Col>
                )}
              </Row>
            </Card.Body>
          </Card>
        </div>
      </div>
    );
  }
}

const errorSelector = createErrorSelector([
  bankConstants.LINK_BANK_WITH_ICON,
  bankConstants.OAUTH_PLAID_LINK_TOKEN,
]);
const mapStateToProps = (state) => {
  return {
    bankAccounts: banksSelector(state),
    activeBanks: activeBanksSelector(state),
    pendingBanks: pendingBanksSelector(state),
    error: errorSelector(state),
    hasAddedBank: hasAddedBank(state),
    bankAccountLimit: getLimitByType(state, "EmployerBankSizeLimit"),
  };
};

const mapDispatchToProps = {
  linkBank,
  verifyBankAccount,
  updateProgressiveOnboarding,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(BankAccounts));
