import React from "react";
import PropTypes from "prop-types";
import queryString from "query-string";
import moment from "moment";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { toast } from "react-toastify";
import {
  filter,
  get,
  groupBy,
  includes,
  isEmpty,
  keyBy,
  map,
  size,
  some,
} from "lodash";
import { withApollo } from "@apollo/client/react/hoc";
import { Card, Form } from "react-bootstrap";
import { processedStatuses } from "statics/payroll";
import { groupType, payrollTypes } from "statics/propTypes";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { employerConstants, payrollConstants } from "actions/types";
import { invalidBankIdsLinkedToGroupsSelector } from "store/selectors/bank";
import { FiCalendar, FiZap } from "react-icons/fi";
import {
  cancelPayroll,
  dismissPayroll,
  recheckPayrollBalance,
} from "actions/payrollActions";
import {
  currentPayrolls,
  getAllEmployerGroups,
  getLinkedEmployees,
} from "actions/employerActions";
import {
  hasEnabled360PayrollIntegration,
  hasEnabledPayrollIntegration,
} from "store/selectors/employer";

import DismissPayrollModal from "./DismissPayrollModal";
import IconTable from "../../../../components/IconTable";
import IconSpinner from "components/IconSpinner";
import PayrollStatus from "components/PayrollStatus";
import Button from "components/Button";
import Alert from "components/Alert";

import "./ProcessGroupsTable.scss";

const commonDateFormat = "MMM Do YYYY";

const allGroups = {
  id: "",
  value: "ALL_GROUPS",
  name: "All Groups",
};

export class PayrollTable extends React.PureComponent {
  static propTypes = {
    // Redux props
    push: PropTypes.func,
    client: PropTypes.object,
    linkedEmployeesByGroup: PropTypes.object,
    employerLinkRequestsByGroup: PropTypes.object,
    invalidBankIdsLinkedToGroups: PropTypes.arrayOf(PropTypes.string),
    error: PropTypes.string,
    groups: PropTypes.arrayOf(groupType),
    isFetching: PropTypes.bool,
    onSubmit: PropTypes.func,
    onClose: PropTypes.func,
    groupId: PropTypes.string,
    groupsById: PropTypes.objectOf(groupType),
    payrolls: PropTypes.arrayOf(payrollTypes),

    // Action Creators
    getLinkedEmployees: PropTypes.func.isRequired,
    getAllEmployerGroups: PropTypes.func.isRequired,
    currentPayrolls: PropTypes.func,
    cancelPayroll: PropTypes.func.isRequired,
    dismissPayroll: PropTypes.func.isRequired,
    recheckPayrollBalance: PropTypes.func.isRequired,
    has360PayrollIntegration: PropTypes.bool,
    hasPayrollIntegration: PropTypes.bool,
    onClick: PropTypes.func,
  };

  constructor(props) {
    super(props);

    const payPeriodId = queryString.parse(window.location.search).payPeriodId;

    this.state = {
      initialFetching: true,
      showConfirmationModal: false,
      pendingCancellationId: null,
      showDismissModal: false,
      showOneTimePayroll: false,
      payrollData: null,
      selectedPayrollGroup: "ALL_GROUPS",
      payPeriodId: payPeriodId || "",
    };
  }

  componentDidMount() {
    this.props.currentPayrolls(this.props.client);
  }

  _payrollGroupFilter = () => {
    const groups = this.props.groups.map((group) => {
      return { value: group.name, name: group.name };
    });
    return [allGroups, ...groups];
  };

  _filteredPayrollGroups = () => {
    const allGroups = this.props.payrolls;
    if (this.state.selectedPayrollGroup === "ALL_GROUPS") return allGroups;

    return allGroups.filter((payroll) => {
      const groupName = get(this.props.groupsById[payroll.groupId], "name");
      return groupName === this.state.selectedPayrollGroup;
    });
  };

  _buildColumns = () => {
    const columns = [
      {
        label: "Group Name",

        customComponent: (props) => {
          const groupName = get(this.props.groupsById[props.groupId], "name");
          return groupName;
        },
      },

      {
        label: "Payroll Period",
        customComponent: (props) => {
          const start = props.payPeriodStart;
          const end = props.payPeriodEnd;
          const period = `${moment(start).format(commonDateFormat)} - ${moment(
            end
          ).format(commonDateFormat)}`;
          return period;
        },
      },
      {
        label: "Processing Date",
        customComponent: (props) => {
          const payrollProcessingDate = props.payrollProcessingDate;
          return moment(payrollProcessingDate).format(commonDateFormat);
        },
      },
      {
        label: "Users",
        customComponent: (props) => {
          const groupId = props.groupId;
          const linkedEmployees = get(
            this.props.linkedEmployeesByGroup,
            groupId,
            []
          );

          if (props.payrollType === "ADHOC") {
            return props.userCount;
          }
          return size(linkedEmployees);
        },
      },
      {
        label: "Type",
        customComponent: (props) => {
          if (props.payrollType === "ADHOC") {
            return (
              <div className="payroll-type">
                <span className="payroll-icon">
                  <FiZap
                    size={"12px"}
                    color={"#FFFFFF"}
                    fill={"#FFFFFF"}
                    stroke={"#009baa"}
                    strokeWidth={"2"}
                  />
                </span>
                <p className="payroll-label-one-time">One-Time</p>
              </div>
            );
          }
          return (
            <div className="payroll-type">
              <span className="payroll-icon">
                <FiCalendar
                  size={"12px"}
                  color={"#FFFFFF"}
                  fill={"#FFFFFF"}
                  stroke={"#0a2540"}
                  strokeWidth={"2"}
                />
              </span>
              <p className="payroll-label-scheduled">Scheduled</p>
            </div>
          );
        },
      },
      {
        label: "Status",
        customComponent: (props) => {
          return (
            <PayrollStatus
              periodState={props.periodState}
              status={props.status}
            />
          );
        },
      },
      {
        customComponent: (props) => {
          return (
            <div data-testid="payroll-actions" className="payroll-actions">
              {this._getPayrollAction(props)}
            </div>
          );
        },
      },
    ];
    return columns;
  };

  _getPayrollAction = (payroll) => {
    const runPayrollStatuses = ["WAITING_FOR_APPROVAL", "ERROR", "CANCELED"];
    const groupId = payroll.groupId;

    const group = this.props.groupsById[groupId];

    const associatedBankAccountId = get(group, "associatedBankAccount.id");
    const groupHasPendingEmployee =
      this.props.employerLinkRequestsByGroup[groupId];

    const status = get(payroll, "status");

    const shouldUpdateBank = includes(
      this.props.invalidBankIdsLinkedToGroups,
      associatedBankAccountId
    );

    const readyToApprove =
      includes(runPayrollStatuses, status) && !groupHasPendingEmployee;

    const linkedEmployees = get(this.props.linkedEmployeesByGroup, groupId, []);

    const zeroLinkedEmployees = isEmpty(linkedEmployees);

    const payPeriodId = payroll.payPeriodId;

    const isProcessed = processedStatuses.includes(status);
    const isMissedPayroll = payroll.periodState === "MISSED" && !isProcessed;
    const groupHasUnprocessedLatePayroll = some(
      this.props.payrolls,
      (payroll) =>
        payroll.periodState === "LATE" &&
        payroll.groupId === groupId &&
        !processedStatuses.includes(payroll.status)
    );
    const isCurrent = payroll.periodState === "CURRENT";
    const isUpcoming = payroll.periodState === "UPCOMING";
    const isWaitingForAction = status === "WAITING_FOR_ACTION";
    const isApproved = status === "APPROVED";

    let shouldDisplayAction = true;
    if (this.props.has360PayrollIntegration) {
      shouldDisplayAction = false;
    } else if (isMissedPayroll) {
      shouldDisplayAction = false;
    } else if (isCurrent && groupHasUnprocessedLatePayroll) {
      shouldDisplayAction = false;
    } else if (isUpcoming) {
      shouldDisplayAction = false;
    }

    if (isWaitingForAction) {
      return (
        <Button
          className="table-btn"
          btnLabel="Restart"
          color="action"
          name="action"
          size="sm"
          onClick={() => {
            this.props
              .recheckPayrollBalance(this.props.client, payroll.payrollId)
              .then(() => this.props.getAllEmployerGroups(this.props.client));
          }}
        />
      );
    } else if (shouldUpdateBank) {
      return (
        <Button
          className="table-btn"
          color="action"
          name="action"
          size="sm"
          btnLabel="Update Bank"
          data-testid="update-bank-button"
          onClick={() => {
            this.props.push("/dashboard/company/group");
          }}
        />
      );
    } else if (
      !this.props.has360PayrollIntegration &&
      groupHasPendingEmployee
    ) {
      return (
        <Button
          className="table-btn"
          color="action"
          name="action"
          size="sm"
          btnLabel="Review Pending Employees"
          onClick={() => this.props.push("/dashboard/company/group")}
        />
      );
    } else if (zeroLinkedEmployees && !this.props.hasPayrollIntegration) {
      return (
        <Button
          className="table-btn"
          color="action"
          name="action"
          size="sm"
          btnLabel="Invite Employees"
          onClick={() => this.props.push("/dashboard/users/employees")}
        />
      );
    } else if (readyToApprove) {
      return (
        <>
          {shouldDisplayAction && (
            <Button
              className="table-btn"
              color="action"
              name="action"
              size="sm"
              btnLabel="Run Payroll"
              onClick={() => {
                this.props.push({
                  pathname: `/dashboard/contributions/${groupId}/approval`,
                  search: `payPeriodId=${payPeriodId}`,
                });
              }}
            />
          )}
          {!this.props.has360PayrollIntegration && isMissedPayroll && (
            <>
              <span>
                <Button
                  className="table-btn"
                  color="dismiss"
                  name="action"
                  size="sm"
                  btnLabel="Dismiss"
                  onClick={() =>
                    this.setState({
                      payPeriodId,
                      showDismissModal: true,
                      payrollData: payroll,
                    })
                  }
                />
              </span>
              <span style={{ paddingLeft: 3 }}>
                <Button
                  className="table-btn"
                  color="action"
                  name="action"
                  size="sm"
                  btnLabel="Run"
                  onClick={() => {
                    this.props.push({
                      pathname: `/dashboard/contributions/${groupId}/one-time`,
                      search: `startDate=${payroll.payPeriodStart}&endDate=${payroll.payPeriodEnd}&payPeriodId=${payPeriodId}`,
                    });
                  }}
                />
              </span>
            </>
          )}
        </>
      );
    } else if (isApproved && !isEmpty(payroll.payrollId)) {
      return (
        <Button
          className="table-btn"
          name="action"
          size="sm"
          btnLabel="Cancel Payroll"
          onClick={() => this._cancelPayroll(groupId, payroll.payrollId)}
          color="red"
        />
      );
    }
    return null;
  };

  _cancelPayroll = (groupId, payrollId) => {
    this.props
      .cancelPayroll(this.props.client, groupId, payrollId)
      .then(() => this.props.currentPayrolls(this.props.client))
      .then((data) => {
        if (!data.error) {
          toast.success("Successfully canceled approved payroll.");
        }
      });
  };

  render() {
    const columns = this._buildColumns();

    const data = this._filteredPayrollGroups();

    return (
      <>
        {this.props.isFetching && <IconSpinner centered />}
        {this.state.showDismissModal && (
          <DismissPayrollModal
            payPeriodId={this.state.payPeriodId}
            payrollData={this.state.payrollData}
            onClose={() =>
              this.setState({
                showDismissModal: false,
                payrollData: null,
              })
            }
            onSuccess={() => {
              this.props.currentPayrolls(this.props.client);
              this.setState({ showDismissModal: false });
            }}
          />
        )}
        <Card className="process-contributions">
          <div className="widget-header">
            <span>
              <Card.Title>Payrolls</Card.Title>

              <Card.Text>
                <span>
                  <Form.Control
                    className="group-filter"
                    as="select"
                    id="inlineFormCustomSelect"
                    value={this.state.selectedPayrollGroup}
                    onChange={(e) => {
                      this.setState({
                        selectedPayrollGroup: e.target.value,
                      });
                    }}
                  >
                    {map(this._payrollGroupFilter(), (group) => (
                      <option value={group.value} key={group.id}>
                        {group.name}
                      </option>
                    ))}
                  </Form.Control>
                </span>
              </Card.Text>
            </span>
          </div>
          {this.props.error && <Alert type="error" msg={this.props.error} />}{" "}
          <IconTable columns={columns} data={data} />
        </Card>
      </>
    );
  }
}

const actions = [
  employerConstants.PROCESS_PAYROLL,
  employerConstants.GET_LINKED_EMPLOYEES,
  employerConstants.GET_EMPLOYER_GROUPS,
  employerConstants.CURRENT_PAYROLLS,
  payrollConstants.CANCEL_PAYROLL,
  payrollConstants.DISMISS_PAYROLL,
];

const isLoadingSelector = createLoadingSelector(actions);
const errorSelector = createErrorSelector(actions);

const mapStateToProps = (state) => {
  const linkedEmployees = state.employer.linkedEmployees;

  const employerLinkRequests = filter(
    state.employer.employerLinkRequests,
    (request) => request.status === "REQUEST_ACCESS"
  );

  const linkedEmployeesByGroup = groupBy(
    linkedEmployees,
    "groupWithSchedule.group.id"
  );

  const employerLinkRequestsByGroup = groupBy(employerLinkRequests, "group.id");

  const groupsToShow = filter(state.employer.groups, (group) => {
    // only show groups that have linkRequests or linkedEmployees
    const hasLinkRequest = !isEmpty(employerLinkRequestsByGroup[group.id]);
    const hasLinkedEmployees = !isEmpty(linkedEmployeesByGroup[group.id]);
    return hasLinkRequest || hasLinkedEmployees;
  });

  const groupsById = keyBy(groupsToShow, "id");

  // only show payrolls that are associated with groups that have linkRequests or linkedEmployees
  const payrollsToShow = filter(state.employer.currentPayrolls, (payroll) => {
    return groupsById[payroll.groupId];
  });

  return {
    groupsById,
    groups: groupsToShow,
    linkedEmployeesByGroup,
    employerLinkRequestsByGroup,
    error: errorSelector(state),
    isFetching: isLoadingSelector(state),
    invalidBankIdsLinkedToGroups: invalidBankIdsLinkedToGroupsSelector(state),
    has360PayrollIntegration: hasEnabled360PayrollIntegration(state),
    hasPayrollIntegration: hasEnabledPayrollIntegration(state),
    payrolls: payrollsToShow,
  };
};

const mapDispatchToProps = {
  push,
  getLinkedEmployees,
  getAllEmployerGroups,
  cancelPayroll,
  recheckPayrollBalance,
  currentPayrolls,
  dismissPayroll,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(PayrollTable));
