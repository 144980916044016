import React from "react";
import { get, map } from "lodash";
import { Card, Col, Form } from "react-bootstrap";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { userService } from "services/userService";
import { toast } from "react-toastify";
import { push } from "connected-react-router";
import { Formik } from "formik";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { hasFullyOnboarded } from "store/selectors/employer";
import { employerConstants } from "actions/types";
import {
  getPaginatedInvites,
  resendInvitation,
  uploadRoster,
} from "actions/employerActions";
import {
  IndividualAddress1Validator,
  individualAddress2Validator,
  NAME_MESSAGE,
  noWhitespaceRegex,
  PRINTABLE_ASCII_MESSAGE,
  testName,
  testPhoneNumber,
  testPrintableAsciiCharacters,
  testSsn,
  validateUsersAddress,
  whiteSpaceError,
} from "utils/fieldValidators";

import moment from "moment";
import InputMask from "react-input-mask";
import StateSelect from "components/StateSelect";
import Button from "components/Button";
import Alert from "components/Alert";
import PropTypes from "prop-types";
import AddressValidator from "components/AddressValidator";
import UploadProcessing from "pages/dashboards/employerDashboard/userManagement/UploadProcessing";
import { ScrollToFieldError } from "utils/form";

const yup = require("yup");

const schema = yup.object({
  firstName: yup
    .string()
    .label("First")
    .matches(noWhitespaceRegex, whiteSpaceError)
    .required()
    .test("is-valid-name", NAME_MESSAGE, testName)
    .min(1, "Must be at least one character.")
    .max(20),
  lastName: yup
    .string()
    .label("Last")
    .matches(noWhitespaceRegex, whiteSpaceError)
    .required()
    .test("is-valid-name", NAME_MESSAGE, testName)
    .min(1, "Must be at least one character.")
    .max(20, "Must be less than 20 characters."),
  email: yup.string().email().required(),
  dob: yup
    .date()
    .label("Date Of Birth")
    .required()
    .test(
      "dob",
      "Employee must be at least 18 to create an account.",
      (value) => {
        return moment().diff(moment(value), "years") >= 18;
      }
    )
    .test(
      "dob",
      "Employee must be under 100 years old to create an account.",
      (value) => {
        return moment().diff(moment(value), "years") < 100;
      }
    ),
  confirmDob: yup
    .string()
    .required()
    .label("Confirm Date of Birth")
    .test("dob-match", "Date of births must match", function (value) {
      return moment(this.parent.dob).isSame(moment(value));
    }),
  ssn: yup
    .string()
    .trim()
    .required()
    .label("Social Security Number")
    .test("ssn", "${path} must be of format 111-11-1111", testSsn),
  confirmSsn: yup
    .string()
    .trim()
    .required()
    .label("Confirm Social Security Number")
    .test("ssn-match", "Social Security Numbers must match", function (value) {
      return this.parent.ssn === value;
    }),
  address1: IndividualAddress1Validator,
  address2: individualAddress2Validator,
  city: yup
    .string()
    .label("City")
    .required()
    .test("city", PRINTABLE_ASCII_MESSAGE, testPrintableAsciiCharacters)
    .max(50)
    .matches(noWhitespaceRegex, whiteSpaceError),
  state: yup
    .string()
    .label("State")
    .required()
    .test("state", PRINTABLE_ASCII_MESSAGE, testPrintableAsciiCharacters)
    .max(14),
  postalCode: yup
    .string()
    .label("Zip")
    .required()
    .test("postal-code", PRINTABLE_ASCII_MESSAGE, testPrintableAsciiCharacters)
    .max(5)
    .matches(noWhitespaceRegex, whiteSpaceError),
  phoneNumber: yup
    .string()
    .label("Phone")
    .required()
    .test("phone", PRINTABLE_ASCII_MESSAGE, testPrintableAsciiCharacters)
    .test(
      "us-phone",
      "${path} is not a valid phone number. Ex: (123) 456-7890 or 1234567890",
      testPhoneNumber
    )
    .max(15),
});

class UploadSingle extends React.PureComponent {
  static propTypes = {
    error: PropTypes.string,
    client: PropTypes.object,
    uploadRoster: PropTypes.func,
    push: PropTypes.func,
    getPaginatedInvites: PropTypes.func,
    resendInvitation: PropTypes.func,
    isLoading: PropTypes.bool,
    groups: PropTypes.arrayOf(PropTypes.object),
    hasFullyOnboarded: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    const defaultGroup = get(props.groups, "0.id");

    this.state = {
      fetchingAddress: false,
      showAddressModal: false,
      validatedAddress: {},
      isInviting: false,
      groupId: defaultGroup,
      jobError: "",
      employeeInfo: {
        dob: "",
        email: "",
        ssn: "",
        confirmSsn: "",
        firstName: "",
        lastName: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        postalCode: "",
        phoneNumber: "",
        location: "",
        department: "",
      },
    };
  }

  inviteEmployee = async (values, skipAddressValidation = false) => {
    // verify user address, if it matches USPS just continue on
    // if USPS endpoint fails continue on
    // if there is a mismatch then open modal to allow customer to decide which to use
    if (!skipAddressValidation) {
      try {
        this.setState({
          fetchingAddress: true,
        });

        const userAddress = {
          address1: values.address1,
          address2: values.address2,
          state: values.state,
          postalCode: values.postalCode,
          city: values.city,
        };

        const validatedAddress = await userService.getValidatedAddress(
          this.props.client,
          userAddress
        );
        this.setState({
          fetchingAddress: false,
        });

        const userAddressIsValid = validateUsersAddress(
          userAddress,
          validatedAddress
        );

        if (!userAddressIsValid) {
          this.setState({
            showAddressModal: true,
            validatedAddress,
          });

          return;
        }
      } catch (error) {
        // if the validation endpoint fails we dont want to prevent user from saving so continue on and log error
        console.log(error);
      }
    }

    let employeeInfo = {
      firstName: values.firstName,
      lastName: values.lastName,
      dob: values.dob,
      address1: values.address1,
      address2: values.address2,
      city: values.city,
      state: values.state,
      postalCode: values.postalCode,
      ssn: values.ssn,
      email: values.email,
      phoneNumber: values.phoneNumber,
      location: values.location,
      department: values.department,
    };

    this.setState({
      employeeInfo,
      groupId: values.groupId,
    });

    await this.props.uploadRoster(this.props.client, {
      rows: [employeeInfo],
      groupId: values.groupId,
    });

    this.setState({ isInviting: true });

    window.analytics.track("Invited single employee to state plan");
  };

  _closeModal = () => {
    this.setState({ showAddressModal: false });
  };

  _renderForm = () => {
    const errorMsg = this.props.error || this.state.jobError;
    return (
      <Card>
        <Card.Body>
          <Formik
            validateOnChange={false}
            validationSchema={schema}
            onSubmit={(values) => {
              this.inviteEmployee(values);
            }}
            enableReinitialize={false}
            initialValues={{
              ...this.state.employeeInfo,
              groupId: this.state.groupId,
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              errors,
              setFieldValue,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <ScrollToFieldError />
                {this.state.showAddressModal && (
                  <AddressValidator
                    show={this.state.showAddressModal}
                    onSubmit={this.inviteEmployee}
                    setFieldValue={setFieldValue}
                    validatedAddress={this.state.validatedAddress}
                    onClose={this._closeModal}
                    values={values}
                    allowPoBox={false}
                  />
                )}
                <div id="form-upload-single">
                  <article className="col-form">
                    <Form.Row>
                      <Form.Group as={Col} sm={6} controlId="formBasicGroupId">
                        <Form.Label>Payroll Group</Form.Label>
                        <Form.Control
                          as="select"
                          name="groupId"
                          value={values.groupId}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.groupId && !!errors.groupId}
                          isValid={touched.groupId && !errors.groupId}
                        >
                          {map(this.props.groups, (group) => (
                            <option key={group.id} value={group.id}>
                              {group.name}
                            </option>
                          ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {errors.groupId}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} controlId="formBasicFirstName">
                        <Form.Label>Employee First Name</Form.Label>
                        <Form.Control
                          name="firstName"
                          placeholder="Legal first name"
                          value={values.firstName}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          isInvalid={touched.firstName && !!errors.firstName}
                          isValid={touched.firstName && !errors.firstName}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.firstName}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group as={Col} controlId="formBasicLastName">
                        <Form.Label>Employee Last Name</Form.Label>
                        <Form.Control
                          name="lastName"
                          placeholder="Legal last name"
                          value={values.lastName}
                          onChange={handleChange}
                          isInvalid={touched.lastName && !!errors.lastName}
                          isValid={touched.lastName && !errors.lastName}
                          onBlur={handleBlur}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.lastName}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      {" "}
                      <Form.Group as={Col} controlId="formBasicSsnField">
                        <Form.Label>Employee Social Security Number</Form.Label>
                        <InputMask
                          name="ssn"
                          mask="999-99-9999"
                          placeholder="000-00-0000"
                          value={values.ssn}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.ssn && !!errors.ssn}
                          isValid={touched.ssn && !errors.ssn}
                        >
                          {(props) => <Form.Control {...props} />}
                        </InputMask>
                        <Form.Control.Feedback type="invalid">
                          {errors.ssn}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Col} controlId="formBasicSsnConfirmField">
                        <Form.Label>
                          Confirm Employee Social Security Number
                        </Form.Label>
                        <InputMask
                          name="confirmSsn"
                          mask="999-99-9999"
                          placeholder="000-00-0000"
                          value={values.confirmSsn}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.confirmSsn && !!errors.confirmSsn}
                          isValid={touched.confirmSsn && !errors.confirmSsn}
                        >
                          {(props) => <Form.Control {...props} />}
                        </InputMask>
                        <Form.Control.Feedback type="invalid">
                          {errors.confirmSsn}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>

                    <Form.Row>
                      <Form.Group as={Col} controlId="formBasicDateOfBirth">
                        <Form.Label>Date of Birth</Form.Label>
                        <InputMask
                          name="dob"
                          placeholder="MM/DD/YYYY"
                          mask="99/99/9999"
                          value={values.dob}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.dob && !!errors.dob}
                          isValid={touched.dob && !errors.dob}
                        >
                          {(props) => <Form.Control {...props} />}
                        </InputMask>
                        <Form.Control.Feedback>
                          {errors.dob}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        controlId="formBasicConfirmDateOfBirth"
                      >
                        <Form.Label>Confirm Date of Birth</Form.Label>
                        <InputMask
                          name="confirmDob"
                          placeholder="MM/DD/YYYY"
                          mask="99/99/9999"
                          value={values.confirmDob}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.confirmDob && !!errors.confirmDob}
                          isValid={touched.confirmDob && !errors.confirmDob}
                        >
                          {(props) => <Form.Control {...props} />}
                        </InputMask>
                        <Form.Control.Feedback type="invalid">
                          {errors.confirmDob}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group
                        as={Col}
                        md={4}
                        sm={12}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Employee Email</Form.Label>
                        <Form.Control
                          name="email"
                          placeholder="user@company.com"
                          value={values.email}
                          onChange={handleChange}
                          isInvalid={touched.email && !!errors.email}
                          isValid={touched.email && !errors.email}
                          onBlur={handleBlur}
                        />
                        <Form.Control.Feedback>
                          {errors.email}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group
                        as={Col}
                        md={4}
                        sm={12}
                        controlId="formBasicLocation"
                      >
                        <Form.Label>Employee Location</Form.Label>
                        <Form.Control
                          name="location"
                          placeholder=""
                          value={values.location}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group
                        as={Col}
                        md={4}
                        sm={12}
                        controlId="formBasicDepartment"
                      >
                        <Form.Label>Employee Department</Form.Label>
                        <Form.Control
                          name="department"
                          placeholder=""
                          value={values.department}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Form.Group>
                    </Form.Row>
                    <Form.Label>Employee Address</Form.Label>
                    <Form.Group controlId="formBasicaddress1">
                      <Form.Control
                        name="address1"
                        placeholder="Address 1"
                        value={values.address1}
                        onChange={handleChange}
                        isInvalid={touched.address1 && !!errors.address1}
                        isValid={touched.address1 && !errors.address1}
                        onBlur={handleBlur}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.address1}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="formBasicaddress2">
                      <Form.Control
                        name="address2"
                        placeholder="Address 2"
                        value={values.address2}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        isInvalid={touched.address2 && !!errors.address2}
                        isValid={touched.address2 && !errors.address2}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.address2}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Row>
                      <Form.Group as={Col} controlId="formBasicCity">
                        <Form.Control
                          name="city"
                          placeholder="City"
                          value={values.city}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.city && !!errors.city}
                          isValid={touched.city && !errors.city}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.city}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group as={Col} sm={4} controlId="formBasicState">
                        <StateSelect
                          value={values.state}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          touched={touched}
                          errors={errors}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.state}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        sm={3}
                        controlId="formBasicPostalCode"
                      >
                        <Form.Control
                          name="postalCode"
                          placeholder="Zip"
                          value={values.postalCode}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.postalCode && !!errors.postalCode}
                          isValid={touched.postalCode && !errors.postalCode}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.postalCode}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} sm={5} controlId="formBasicPhone">
                        <InputMask
                          maskChar=" "
                          mask="(999) 999-9999"
                          name="phoneNumber"
                          placeholder="Phone"
                          value={values.phoneNumber}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          isInvalid={
                            touched.phoneNumber && !!errors.phoneNumber
                          }
                          isValid={touched.phoneNumber && !errors.phoneNumber}
                        >
                          {(props) => <Form.Control {...props} />}
                        </InputMask>
                        <Form.Control.Feedback type="invalid">
                          {errors.phoneNumber}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>
                    {errorMsg && <Alert type="error" msg={errorMsg} />}
                    <div className="submit-row btn-row">
                      <Button
                        btnLabel={"Go Back"}
                        name="cancel"
                        color={"cancel"}
                        onClick={() =>
                          this.props.push("/dashboard/users/employees")
                        }
                      />
                      <Button
                        withArrow={true}
                        btnLabel={"Invite"}
                        name="submit"
                        loading={
                          this.props.isLoading || this.state.fetchingAddress
                        }
                      />
                    </div>
                  </article>
                </div>
              </Form>
            )}
          </Formik>
        </Card.Body>
      </Card>
    );
  };

  _onInviteJobCompletion = async (job, hasErrored) => {
    //if user is still onboarding and upload did not fail just go to dashboard
    if (!this.props.hasFullyOnboarded && !hasErrored) {
      toast.success("Successfully staged invite.");
      this.props.push("/dashboard");
      return;
    }

    if (!hasErrored) {
      const {
        data: { rows: invites },
      } = await this.props.getPaginatedInvites(this.props.client, {
        limit: 1,
        offset: 0,
        search: this.state.employeeInfo.email,
        groupId: this.state.groupId,
      });
      const invitation = invites[0];
      // if for some reason invite doesn't exist just go to employees page
      if (!invitation) {
        toast.error(
          "Unable to auto-invite employee, please send invitation manually."
        );
        this.props.push("/dashboard/users/employees");
        return;
      }

      await this.props.resendInvitation(this.props.client, invitation);
      toast.success("Successfully invited employee.");
      this.props.push("/dashboard/users/employees");
    } else {
      this.setState({
        isInviting: false,
        jobError: map(job.errors, "reason").join(", "),
      });
    }
  };

  render() {
    const content = this.state.isInviting ? (
      <UploadProcessing
        heading={"Processing Invite"}
        subheading={"Give us a moment while we process the invitation."}
        onFinished={this._onInviteJobCompletion}
      />
    ) : (
      this._renderForm()
    );
    return (
      <div className="upload-single">
        <section className="page-title-wrap">
          <article className="text-cell">
            <div className="step-heading">
              <h1 className="page-title">Invite Employee</h1>
              <p className="page-subtext">
                Select a payroll group and add their personal information.
              </p>
            </div>
            <div className="main-content">{content}</div>
          </article>
        </section>
      </div>
    );
  }
}

const isLoadingSelector = createLoadingSelector(
  employerConstants.UPLOAD_ROSTER
);

const errorSelector = createErrorSelector(employerConstants.UPLOAD_ROSTER);

const mapStateToProps = (state) => {
  return {
    groups: state.employer.groups,
    error: errorSelector(state),
    isLoading: isLoadingSelector(state),
    hasFullyOnboarded: hasFullyOnboarded(state),
  };
};

const mapDispatchToProps = {
  uploadRoster,
  push,
  getPaginatedInvites,
  resendInvitation,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(UploadSingle));
