import React from "react";
import classnames from "classnames";
import { PropTypes } from "prop-types";
import { push } from "connected-react-router";
import { withApollo } from "@apollo/client/react/hoc";
import { connect } from "react-redux";
import { FiArrowLeftCircle, FiChevronDown, FiPlusCircle } from "react-icons/fi";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { get, includes, isEmpty, map, size } from "lodash";

import { setEmployerSession } from "actions/employerActions";
import "./EmployerSwitcher.scss";
import { AGENT_ROLE_ID } from "statics/roles";

class EmployerSwitcher extends React.PureComponent {
  static propTypes = {
    setEmployerSession: PropTypes.func,
    client: PropTypes.object,
    activeCompany: PropTypes.object,
    companies: PropTypes.arrayOf(PropTypes.object),
    push: PropTypes.func,
    pathname: PropTypes.string,
    companyState: PropTypes.string,
    isEmployerAgent: PropTypes.bool,
    organizationCompanyLimit: PropTypes.number,
  };

  constructor() {
    super();

    this.state = {
      dropdownOpen: false,
    };
  }

  _getActionButton = () => {
    // I am adding an employer if I'm on onboarding route and my current company state is active.
    if (this.props.isEmployerAgent) return null;
    const onOnboardingRoute = includes(this.props.pathname, "employer/");
    const isAddingEmp =
      onOnboardingRoute &&
      this.props.companyState === "EmployerApplicationApproved";

    if (isAddingEmp) {
      return (
        <li
          className="action-item add-company-btn"
          onClick={() => {
            document.body.click();

            this.props.push("/dashboard");
          }}
        >
          <FiArrowLeftCircle size={14} fill="white" />
          Back to Dashboard
        </li>
      );
    }
    const lessThanLimit =
      size(this.props.companies) < this.props.organizationCompanyLimit;

    if (lessThanLimit) {
      return (
        <li
          className="action-item add-company-btn"
          onClick={() => {
            document.body.click();

            this.props.push("/employer/enroll");
          }}
        >
          <FiPlusCircle size={14} />
          Create Company
        </li>
      );
    }
  };

  _buildPopover = () => {
    return (
      <Popover className="action-list-popover employer-switcher-dropdown">
        <Popover.Content>
          <ul className="actions-list">
            {this._getActionButton()}
            {map(this.props.companies, (company) => {
              return (
                <li
                  key={company.id}
                  className={classnames("action-item", {
                    ["is-selected"]: this.props.activeCompany.id === company.id,
                  })}
                  onClick={async () => {
                    await this.props.setEmployerSession(
                      this.props.client,
                      company.id
                    );
                    window.location.search = `?redirect_to=/dashboard`;
                  }}
                >
                  {company.name}
                </li>
              );
            })}
          </ul>
        </Popover.Content>
      </Popover>
    );
  };

  _toggleDropdown = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  };

  render() {
    if (isEmpty(this.props.activeCompany)) return null;
    return (
      <div className="employer-switcher">
        <OverlayTrigger
          rootClose
          trigger="click"
          placement="bottom"
          overlay={this._buildPopover()}
        >
          <div className="target-box">
            <p className="active-company-name">
              {this.props.activeCompany.name}
              <span className="down-arrow">
                <FiChevronDown size={14} />
              </span>
            </p>
          </div>
        </OverlayTrigger>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companies: state.employer.linkedCompanies,
    activeCompany: state.employer.company,
    pathname: get(state.router, "location.pathname"),
    companyState: get(state.user, "companyState.state"),
    isEmployerAgent: state.user.userRoleId === AGENT_ROLE_ID,
    organizationCompanyLimit: state.employer.organization.companyLimit,
  };
};

const mapDispatchToProps = {
  setEmployerSession,
  push,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(EmployerSwitcher));
