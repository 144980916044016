import React from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import moment from "moment";
import { connect } from "react-redux";
import { Formik } from "formik";
import { withApollo } from "@apollo/client/react/hoc";
import { Card, Col, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { ScrollToFieldError } from "utils/form";
import { submitRolloverAccountInfo } from "actions/userActions";
import { userConstants, accountConstants } from "actions/types";
import { createLoadingSelector } from "store/selectors";
import { accountTypesToEnglishMapping } from "statics/accountTypes";
import { push } from "connected-react-router";
import { ROLLOVER_INFO } from "statics/onboardingSteps";
import { TRADITIONAL_IRA } from "statics/accountTypes";
import {
  isRolloverSelector,
  activeRolloverAccountSameAsCurrent,
  activeAccountType,
  activeRolloverIsMixed,
} from "store/selectors/user";

import Alert from "components/Alert";
import Button from "components/Button";
import IconHeader from "components/IconHeader";
import ProgressBar from "components/ProgressBar";
import IconSubheader from "components/IconSubheader";
import CancelRolloverModal from "../../dashboards/individualDashboard/dashboard/CancelRolloverModal";

/* eslint-disable react/no-children-prop */

let yup = require("yup");

const schema = yup.object().shape({
  amount: yup
    .number()
    .required()
    .label("Contribution Amount")
    .test("amount", `Amount must be greater than 0`, (value) => value > 0),
  date: yup.date().label("Contribution Date").required("Date is required"),
  rolloverAgreement: yup.boolean().label("Rollover Agreement"),
  rothAmount: yup.number().when("isRolloverIsMixed", {
    is: true,
    then: yup
      .number()
      .required("Roth Contribution Amount is required")
      .test(
        "rothAmount",
        "Roth Amount must be greater than 0",
        (value) => value > 0
      ),
    otherwise: yup.number(),
  }),
  rothDate: yup.date().when("isRolloverIsMixed", {
    is: true,
    then: yup.date().required("Roth Contribution Date is required"),
    otherwise: yup.date(),
  }),
});

const parseDate = (val) => {
  if (val) {
    const parsedDate = moment(val, "YYYY-MM-DD").toDate();
    if (!isNaN(parsedDate.getTime())) {
      return parsedDate;
    }
  }
  return null;
};

class IndividualRolloverForm extends React.Component {
  static propTypes = {
    push: PropTypes.func,
    submitRolloverAccountInfo: PropTypes.func,
    error: PropTypes.string,
    userState: PropTypes.string,
    isFetching: PropTypes.bool,
    client: PropTypes.object,
    accountType: PropTypes.string,
    accounts: PropTypes.arrayOf(PropTypes.object),
    isRolloverAccount: PropTypes.bool,
    rolloverAccountType: PropTypes.object,
    isRolloverSameAsCurrent: PropTypes.bool,
    isRolloverIsMixed: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      date: moment().format("YYYY-MM-DD"), // Set today's date as initial date,
      rothDate: moment().format("YYYY-MM-DD"),
      amount: undefined,
      rothAmount: undefined,
      loading: false,
      showCancelRolloverModal: false,
    };
  }
  _submitRequest = (values) => {
    let params;
    const { amount, date, rolloverAgreement, rothAmount, rothDate } = values;
    const { isRolloverIsMixed, rolloverAccountType } = this.props;

    if (isRolloverIsMixed) {
      params = [
        {
          amount,
          date,
          rolloverAgreement,
          accountType: "TRADITIONAL_IRA",
        },
        {
          amount: rothAmount,
          date: rothDate,
          rolloverAgreement,
          accountType: "ROTH_IRA",
        },
      ];
    } else {
      params = [
        {
          amount,
          date,
          rolloverAgreement,
          accountType: rolloverAccountType,
        },
      ];
    }
    this.props.submitRolloverAccountInfo(this.props.client, params).then(() => {
      if (!this.props.error) {
        toast.success("Your rollover information has been submitted.");
      } else {
        toast.error("There was a problem submitting information.");
      }
    });
  };

  _onAmountChange = (event, setFieldValue) => {
    const amount = +event.target.value;
    setFieldValue("amount", amount);
  };

  _onRothAmountChange = (event, setFieldValue) => {
    const rothAmount = +event.target.value;
    setFieldValue("rothAmount", rothAmount);
  };

  _onCancel = () => {
    this.setState({
      showCancelRolloverModal: true,
    });
  };

  render() {
    var articleStyle = {
      paddingBottom: 0,
    };

    const mixedAccountType = this.props.isRolloverIsMixed;

    const account = mixedAccountType
      ? TRADITIONAL_IRA
      : this.props.rolloverAccountType;

    const userHasMixedRollover = mixedAccountType
      ? "Usually two checks are issued when you roll over a mixed 401(k) into an IRA. One of these checks is for the pre-tax dollars (Traditional) from your mixed 401(k) and the other is for your post-tax (Roth) dollars. "
      : "Let us help you move a previous retirement account to Icon.";

    let progPercent;
    if (this.props.isRolloverSameAsCurrent) {
      progPercent = "40";
    } else {
      progPercent = "20";
    }

    return (
      <>
        <div className="transactions-mega-container">
          <ProgressBar
            isEmployer={false}
            activeStepId={ROLLOVER_INFO.id}
            isRolloverSameAsCurrent={this.props.isRolloverSameAsCurrent}
            progressPercent={progPercent}
          />

          <section className="form-sec-2col">
            <article className="col-form" style={articleStyle}>
              {this.state.showCancelRolloverModal && (
                <CancelRolloverModal
                  show={this.state.showCancelRolloverModal}
                  onClose={() =>
                    this.setState({
                      showCancelRolloverModal: false,
                    })
                  }
                  onSuccess={() =>
                    this.setState({
                      showCancelRolloverModal: false,
                    })
                  }
                />
              )}
              <div>
                <Card>
                  <Card.Title style={{ padding: 20, margin: 0 }}>
                    <IconHeader
                      variant="cardHeader"
                      headerText="Icon Rollover Form"
                    />
                    <IconSubheader subheader={userHasMixedRollover} />
                  </Card.Title>

                  <Card.Body>
                    <Formik
                      validateOnChange={false}
                      validationSchema={schema}
                      onSubmit={(values) => {
                        this._submitRequest(values);
                      }}
                      enableReinitialize={true}
                      initialValues={{
                        rolloverAgreement: false,
                        isRolloverIsMixed: this.props.isRolloverIsMixed,
                        date: this.state.date,
                        rothDate: this.state.rothDate,
                      }}
                    >
                      {({
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                        values,
                        touched,
                        errors,
                      }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                          <ScrollToFieldError />

                          <Form.Row>
                            <Form.Group
                              as={Col}
                              sm={6}
                              controlId="formAccountType"
                            >
                              <Form.Label style={{ fontWeight: 500 }}>
                                {accountTypesToEnglishMapping[account]}
                              </Form.Label>
                            </Form.Group>
                          </Form.Row>
                          <Form.Row>
                            <Form.Group
                              as={Col}
                              controlId="formContributionAmount"
                            >
                              <Form.Label>Contribution Amount</Form.Label>
                              <Form.Control
                                sm={4}
                                type="number"
                                name="amount"
                                placeholder="0.00"
                                value={values.amount}
                                onChange={(e) =>
                                  this._onAmountChange(e, setFieldValue)
                                }
                                onBlur={handleBlur}
                                isInvalid={touched.amount && !!errors.amount}
                                isValid={touched.amount && !errors.amount}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.amount}
                              </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group
                              as={Col}
                              sm={6}
                              controlId="formContributionDate"
                            >
                              <Form.Label>Date</Form.Label>
                              <DatePicker
                                className="form-control"
                                name="date"
                                autoComplete="off"
                                dateFormat="MM/dd/yyyy"
                                placeholderText="mm/dd/yyyy"
                                disabled
                                selected={
                                  values.date ? parseDate(values.date) : null
                                }
                                onChange={(val) => {
                                  const formattedDate = val
                                    ? moment(val).format("YYYY-MM-DD")
                                    : null;
                                  setFieldValue("date", formattedDate);
                                }}
                                onBlur={handleBlur}
                                isInvalid={touched.date && !!errors.date}
                                isValid={touched.date && !errors.date}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.date}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Form.Row>
                          {mixedAccountType && (
                            <>
                              <Form.Row>
                                <Form.Group
                                  as={Col}
                                  sm={6}
                                  controlId="formAccountType"
                                >
                                  <Form.Label style={{ fontWeight: 500 }}>
                                    Roth IRA
                                  </Form.Label>
                                </Form.Group>
                              </Form.Row>
                              <Form.Row>
                                <Form.Group
                                  as={Col}
                                  controlId="formContributionAmount"
                                >
                                  <Form.Label>Contribution Amount</Form.Label>
                                  <Form.Control
                                    sm={4}
                                    type="number"
                                    name="rothAmount"
                                    placeholder="0.00"
                                    value={values.rothAmount}
                                    onChange={(e) =>
                                      this._onRothAmountChange(e, setFieldValue)
                                    }
                                    onBlur={handleBlur}
                                    isInvalid={
                                      touched.rothAmount && !!errors.rothAmount
                                    }
                                    isValid={
                                      touched.rothAmount && !errors.rothAmount
                                    }
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {errors.rothAmount}
                                  </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group
                                  as={Col}
                                  sm={6}
                                  controlId="formContributionDate"
                                >
                                  <Form.Label>Date</Form.Label>
                                  <DatePicker
                                    className="form-control"
                                    name="rothDate"
                                    autoComplete="off"
                                    dateFormat="MM/dd/yyyy"
                                    placeholderText="mm/dd/yyyy"
                                    disabled
                                    selected={
                                      values.rothDate
                                        ? parseDate(values.rothDate)
                                        : null
                                    }
                                    onChange={(val) => {
                                      const formattedRothDate = val
                                        ? moment(val).format("YYYY-MM-DD")
                                        : null;
                                      setFieldValue(
                                        "rothDate",
                                        formattedRothDate
                                      );
                                    }}
                                    onBlur={handleBlur}
                                    isInvalid={
                                      touched.rothDate && !!errors.rothDate
                                    }
                                    isValid={
                                      touched.rothDate && !errors.rothDate
                                    }
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {errors.rothDate}
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Form.Row>
                            </>
                          )}

                          {this.props.error && (
                            <Alert type="error" msg={this.props.error} />
                          )}

                          <div
                            style={{
                              borderTop: "1px solid #E7E7E8",
                              marginTop: 20,
                              paddingBottom: 30,
                            }}
                          ></div>

                          <div className="terms-of-service">
                            <p className="terms-label">TRUSTED CONTACT</p>
                            <p>
                              Under FINRA Rule 4512 Apex Clearing Corporation is
                              required to disclose to you (the customer) that
                              Apex Clearing Corporation or an associated person
                              of Apex Clearing Corporation is authorized to
                              contact the trusted contact person and disclose
                              information about the customer’s account to
                              address possible financial exploitation, to
                              confirm the specifics of the customer’s current
                              contact information, health status, or the
                              identity of any legal guardian, executor, trustee
                              or holder of a power of attorney, or as otherwise
                              permitted by FINRA Rule 2165.
                            </p>

                            <p className="terms-label">
                              IRREVOCABLE ROLLOVER DESIGNATION
                            </p>
                            <p>
                              I attest and acknowledge that the funds deposited
                              are eligible to be contributed to this IRA, that
                              the assets are the same assets that were
                              distributed as a Direct Rollover or as an Indirect
                              Rollover less than 60 days ago, and that I am
                              allowed only one 60-day rollover distribution from
                              an IRA in a rolling 12-month period regardless of
                              the number of IRAs I own. Due to the important tax
                              consequences of rolling over a cash balance, or
                              securities, I have been advised to consult with a
                              tax professional and I attest that the custodian
                              has not provided any tax advice. By selecting this
                              option, I certify that no part of his contribution
                              contains amounts required to be distributed under
                              Internal Revenue Code Sections 408(a)(6) and
                              401(a)(9), and I acknowledge that I am making an
                              irrevocable election to designate this
                              contribution as a rollover contribution.
                            </p>
                          </div>

                          <Form.Row>
                            <Form.Group
                              as={Col}
                              sm={12}
                              controlId="formBasicRolloverAgreement"
                            >
                              <Form.Check
                                type="checkbox"
                                id="rolloverAgreement"
                                name="rolloverAgreement"
                                label="I Agree"
                                checked={values.rolloverAgreement}
                                value={values.rolloverAgreement}
                                onChange={(e) => {
                                  setFieldValue(
                                    "rolloverAgreement",
                                    e.target.checked
                                  );
                                }}
                              />
                              <p
                                style={{
                                  fontStyle: "italic",
                                  fontSize: 14,
                                  paddingTop: 10,
                                  paddingLeft: 4,
                                  color: "#0a2540",
                                }}
                              >
                                By checking {'"I Agree"'} you are confirming
                                that all information provided by me is true and
                                correct and may be relied upon by the custodian.
                                I assume full responsibility for these
                                transactions and release, indemnify and hold
                                Apex Clearing Corporation and its officers,
                                directors, employees, affiliates, assigns,
                                agents, employees or successors harmless from
                                and against any and all liabilities, damages,
                                losses, costs (including attorney’s fees),
                                claims or actions arising from or related to any
                                errors, improper instructions, or
                                misrepresentations in this request.
                              </p>
                            </Form.Group>
                          </Form.Row>
                          <div className="submit-row btn-row">
                            <Button
                              type="button"
                              btnLabel={"Cancel"}
                              color={"cancel"}
                              name="cancel"
                              onClick={this._onCancel}
                            />
                            <Button
                              name="submit"
                              btnLabel="Submit"
                              disabled={!values.rolloverAgreement}
                              withArrow={true}
                              loading={this.props.isFetching}
                            />
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </Card.Body>
                </Card>
              </div>
            </article>
          </section>
        </div>
      </>
    );
  }
}

const loadingSelector = createLoadingSelector([
  userConstants.SUBMIT_ROLLOVER_INFO,
  accountConstants.GET_ACCOUNTS,
]);

const mapStateToProps = (state) => {
  const userState = state.user.userState.state;
  const accounts = state.accounts.iraAccounts;

  return {
    userState,
    accounts,
    isFetching: loadingSelector(state),
    isRolloverAccount: isRolloverSelector(state),
    rolloverAccountType: activeAccountType(state),
    isRolloverSameAsCurrent: activeRolloverAccountSameAsCurrent(state),
    isRolloverIsMixed: activeRolloverIsMixed(state),
  };
};

const mapDispatchToProps = {
  push,
  submitRolloverAccountInfo,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(IndividualRolloverForm));
