import React from "react";
import PropTypes from "prop-types";
import { Card } from "react-bootstrap";

import { allPlanCodes, ENTERPRISE } from "statics/planCodes";
import "./PlanCard.scss";

function buildLabels(plan) {
  let sizeLabel, setupFeeLabel, noteLabel;

  const infoLabel = plan.label;

  if (plan.id === ENTERPRISE) {
    sizeLabel = "100+";
    setupFeeLabel = null;
    noteLabel = null;
  } else {
    const { min, max } = plan.range;
    sizeLabel = `${min}-${max}`;
    setupFeeLabel = `$${plan.price} one-time setup fee`;
    noteLabel = "That's all you pay. Period.";
  }
  return {
    sizeLabel,
    infoLabel,
    setupFeeLabel,
    noteLabel,
  };
}

const PlanCard = ({ planCode }) => {
  const matchingPlan = allPlanCodes[planCode];

  if (!matchingPlan) return null;

  const { sizeLabel, setupFeeLabel, noteLabel, infoLabel } =
    buildLabels(matchingPlan);

  return (
    <Card className="plan-card">
      <div className="size-heading">
        <p className="size">{sizeLabel}</p>
        <p className="employee-label">Employees</p>
      </div>
      <div className="pricing-section">
        <p className="pricing">{infoLabel}</p>
        {setupFeeLabel && <p className="setup-fee">{setupFeeLabel}</p>}
      </div>
      {noteLabel && <p className="plan-note">{noteLabel}</p>}
    </Card>
  );
};

PlanCard.propTypes = {
  planCode: PropTypes.string,
};

export default PlanCard;
