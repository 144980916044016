import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { debounce, get, groupBy, includes, isEmpty, map } from "lodash";
import {
  getLastWorkflowStepForWorkflow,
  getPaginatedWorkflows,
} from "actions/adminActions";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { Col, Form, Row } from "react-bootstrap";
import { adminConstants } from "actions/types";
import moment from "moment";
import Alert from "components/Alert";
import Paginator from "components/Paginator";
import IconTable from "components/IconTable";
import Button from "components/Button";
import IconSpinner from "components/IconSpinner";
import IconTableHeader from "components/IconTableHeader";
import { createDateFilterRange } from "utils/timeHelper";
import queryString from "query-string";
import IconEmptyState from "components/IconEmptyState";
import { FiDollarSign } from "react-icons/fi";
import WorkflowStepModal from "./WorkflowStepModal";
import { push } from "connected-react-router";
import WorkflowParamsModal from "pages/dashboards/adminDashboard/workflows/WorkflowParamsModal";

class Workflows extends React.PureComponent {
  static propTypes = {
    getPaginatedWorkflows: PropTypes.func,
    getLastWorkflowStep: PropTypes.func,
    push: PropTypes.func,
    client: PropTypes.object,
    isFetching: PropTypes.bool,
    workflowsCount: PropTypes.number,
    workflows: PropTypes.arrayOf(PropTypes.shape({})),
    workflowFilterValues: PropTypes.object,
    companyId: PropTypes.string,
    error: PropTypes.string,
  };
  _workflowStatuses = [
    // null value returns all workflows
    {
      label: "All",
      value: "ALL",
    },
    {
      label: "Complete",
      value: "COMPLETE",
    },
    {
      label: "Exit",
      value: "EXIT",
    },
    {
      label: "Failed",
      value: "FAILED",
    },
    {
      label: "Locked",
      value: "LOCKED",
    },
    {
      label: "New",
      value: "NEW",
    },
    {
      label: "Paused",
      value: "PAUSED",
    },
    {
      label: "Running",
      value: "RUNNING",
    },
  ];
  _dateFilters = [
    {
      label: "Past 1 day",
      value: 1,
    },
    {
      label: "Past 7 days",
      value: 7,
    },
    {
      label: "Past 30 days",
      value: 30,
    },
    {
      label: "Past 60 days",
      value: 60,
    },
  ];

  constructor(props) {
    super(props);
    const queryParams = queryString.parse(window.location.search);
    const defaultDaysAgoFilter = queryParams.selectedDaysAgoFilter || 1;
    const { startDate, endDate } = createDateFilterRange(defaultDaysAgoFilter);

    let filters = {
      startDate,
      endDate,
      entityId: queryParams.entityId || null,
      status: queryParams.status || null,
      name: queryParams.name || null,
      id: queryParams.id || null,
    };

    this.state = {
      initialFetching: true,
      page: 0,
      limit: 25,
      offset: 0,
      selectedDaysAgoFilter: defaultDaysAgoFilter,
      filters,
      showLastWorkflowStepModal: false,
      showWorkflowParamsModal: false,
      activeWorkflow: {},
    };
  }

  componentDidMount() {
    this._getPaginatedWorkflows().then(() => {
      this.setState({ initialFetching: false });
    });
  }

  _onPageChange = ({ selected }) => {
    const offset = selected * this.state.limit;

    this.setState({ page: selected, offset }, () =>
      this._getPaginatedWorkflows()
    );
  };

  _getPaginatedWorkflows = () => {
    const queryParams = {
      selectedDaysAgoFilter: this.state.selectedDaysAgoFilter,
      entityId: this.state.filters.entityId,
      status: this.state.filters.status,
      name: this.state.filters.name,
      id: this.state.filters.id,
    };
    // preserve filters in url so refreshes don't cause loss of data and can share links
    const newQueryString = queryString.stringify(queryParams);
    const newLocation = `${window.location.pathname}?${newQueryString}`;
    window.history.pushState({}, "", newLocation);

    return this.props.getPaginatedWorkflows(this.props.client, {
      limit: this.state.limit,
      offset: this.state.offset,
      updatedAtStart: this.state.filters.startDate,
      updatedAtEnd: this.state.filters.endDate,
      status: this.state.filters.status,
      entityId: this.state.filters.entityId,
      name: this.state.filters.name,
      id: this.state.filters.id,
    });
  };

  _buildWorkflowColumns = () => {
    const columns = [
      {
        label: "Workflow ID",
        key: "id",
      },
      {
        label: "Subject Id",
        key: "entityId",
      },
      {
        label: "Subject Type",
        key: "entityType",
      },
      {
        label: "Description",
        key: "description",
      },
      {
        label: "Name",
        key: "name",
      },
      {
        label: "Status",
        key: "status",
      },
      {
        label: "Updated At",
        customComponent: (props) => {
          const commonDateFormat = "MMM Do YYYY";
          return <>{moment(props.updatedAt).format(commonDateFormat)}</>;
        },
      },

      {
        label: "Paused Until",
        key: "pausedUntil",
      },
      {
        key: "actions",
        customComponent: (props) => {
          const params = get(props, "workflow.params", {});
          return (
            <div className={"btn-row"}>
              {!isEmpty(params) && (
                <Button
                  color="primary"
                  btnLabel="Params"
                  onClick={() => {
                    this.setState({
                      showWorkflowParamsModal: true,
                      activeWorkflow: props,
                    });
                  }}
                />
              )}
              <Button
                color="action"
                btnLabel="Last Step"
                onClick={() => {
                  this.setState({
                    showLastWorkflowStepModal: true,
                    activeWorkflow: props,
                  });
                }}
              />
            </div>
          );
        },
      },
    ];
    return columns;
  };

  _handleStatusFilterChange = (e) => {
    let status = e.target.value;
    if (status === "ALL") {
      status = null;
    }
    this.setState(
      {
        filters: { ...this.state.filters, status },
        offset: 0,
        page: 0,
      },
      () => this._getPaginatedWorkflows()
    );
  };

  _handleNameFilterChange = (e) => {
    let name = e.target.value;
    if (name === "ALL") {
      name = null;
    }

    this.setState(
      {
        filters: { ...this.state.filters, name },
        offset: 0,
        page: 0,
      },
      () => this._getPaginatedWorkflows()
    );
  };

  _handleDaysAgoFilterChange = (e) => {
    let daysAgo = e.target.value;
    const { startDate, endDate } = createDateFilterRange(daysAgo);
    this.setState(
      {
        filters: { ...this.state.filters, startDate, endDate },
        selectedDaysAgoFilter: daysAgo,
        offset: 0,
        page: 0,
      },
      () => this._getPaginatedWorkflows()
    );
  };

  _handleOrderFilterChange = (e) => {
    this.setState(
      {
        filters: { ...this.state.filters, order: e.target.value },
        offset: 0,
        page: 0,
      },
      () => this._getPaginatedWorkflows()
    );
  };

  _handleEntityIdFilterChange = (e) => {
    this.setState(
      {
        filters: { ...this.state.filters, [e.target.name]: e.target.value },
        offset: 0,
        page: 0,
      },
      () => this._getPaginatedWorkflows()
    );
  };

  _debounceOnChange = debounce(this._handleEntityIdFilterChange, 500);

  _getQueryParamWorkfowName = () => {
    const queryParams = queryString.parse(window.location.search);

    if (!isEmpty(queryParams.name)) {
      const filterValueAlreadyExists = includes(
        this.props.workflowFilterValues.name,
        queryParams.name
      );

      if (filterValueAlreadyExists) return null;

      return (
        <option key={queryParams.name} value={queryParams.name}>
          {queryParams.name}
        </option>
      );
    }

    return null;
  };

  render() {
    if (this.state.initialFetching) {
      return <IconSpinner centered />;
    }

    if (this.props.error) {
      return <Alert type={"error"} msg={this.props.error} />;
    }

    const columns = this._buildWorkflowColumns();
    const data = this.props.workflows;

    const showBlankSlate = isEmpty(data);

    return (
      <div className="mega-container">
        <section className="page-title-wrap">
          <article className="text-cell">
            <h1 className="page-title">Workflows</h1>
            <div className="main-content">
              <>
                {this.props.isFetching && <IconSpinner centered />}
                {this.state.showLastWorkflowStepModal && (
                  <WorkflowStepModal
                    show={this.state.showLastWorkflowStepModal}
                    workflowId={this.state.activeWorkflow.id}
                    onClose={() => {
                      this.setState({
                        showLastWorkflowStepModal: false,
                        activeWorkflow: {},
                      });
                    }}
                  />
                )}
                {this.state.showWorkflowParamsModal && (
                  <WorkflowParamsModal
                    show={this.state.showLastWorkflowStepModal}
                    workflowParams={get(
                      this.state.activeWorkflow,
                      "workflow.params",
                      {}
                    )}
                    onClose={() => {
                      this.setState({
                        showWorkflowParamsModal: false,
                        activeWorkflow: {},
                      });
                    }}
                  />
                )}
                <>
                  <div className="action-box">
                    <Row style={{ padding: 10 }}>
                      <Col md={4}>
                        <div className="state-filter">
                          <Form.Label>Filter by status</Form.Label>
                          <Form.Control
                            as="select"
                            name="statusFilter"
                            value={this.state.filters.status}
                            onChange={this._handleStatusFilterChange}
                          >
                            {map(this._workflowStatuses, (filter) => (
                              <option key={filter.label} value={filter.value}>
                                {filter.label}
                              </option>
                            ))}
                          </Form.Control>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="state-filter">
                          <Form.Label>Filter by workflow name</Form.Label>
                          <Form.Control
                            as="select"
                            name="statusFilter"
                            value={this.state.filters.name}
                            onChange={this._handleNameFilterChange}
                          >
                            <option key={"All"} value={"ALL"}>
                              All
                            </option>
                            {this._getQueryParamWorkfowName()}
                            {map(
                              this.props.workflowFilterValues.name,
                              (filter) => (
                                <option key={filter} value={filter}>
                                  {filter}
                                </option>
                              )
                            )}
                          </Form.Control>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="state-filter">
                          <Form.Label>Filter by time</Form.Label>
                          <Form.Control
                            as="select"
                            name="statusFilter"
                            value={this.state.selectedDaysAgoFilter}
                            onChange={this._handleDaysAgoFilterChange}
                          >
                            {map(this._dateFilters, (filter) => (
                              <option key={filter.label} value={filter.value}>
                                {filter.label}
                              </option>
                            ))}
                          </Form.Control>
                        </div>
                      </Col>
                    </Row>
                    <Row style={{ padding: 10 }}>
                      <Col md={6}>
                        <div className="state-filter">
                          <Form.Label>Filter by User/Company ID</Form.Label>
                          <Form.Control
                            name="entityId"
                            value={this.state.filters.entityId}
                            onChange={(e) => {
                              this.setState({
                                filters: {
                                  ...this.state.filters,
                                  entityId: e.target.value,
                                },
                              });
                              this._debounceOnChange(e);
                            }}
                          ></Form.Control>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="state-filter">
                          <Form.Label>Filter by Workflow ID</Form.Label>
                          <Form.Control
                            name="id"
                            value={this.state.filters.id}
                            onChange={(e) => {
                              this.setState({
                                filters: {
                                  ...this.state.filters,
                                  id: e.target.value,
                                },
                              });
                              this._debounceOnChange(e);
                            }}
                          ></Form.Control>
                        </div>
                      </Col>
                    </Row>
                    <IconTableHeader
                      tableHeader="Workflows"
                      tableCount={this.props.workflowsCount}
                    />
                    {showBlankSlate && (
                      <div>
                        <IconEmptyState
                          header={"No workflows"}
                          subheader="There are currently no workflows to display."
                          icon={
                            <FiDollarSign
                              color="white"
                              stroke="#60A4BF"
                              size={16}
                            />
                          }
                        />
                      </div>
                    )}
                    {!showBlankSlate && (
                      <>
                        <IconTable columns={columns} data={data} />
                        <Paginator
                          onChange={this._onPageChange}
                          pageCount={
                            this.props.workflowsCount / this.state.limit
                          }
                          page={this.state.page}
                        />
                      </>
                    )}
                  </div>
                </>
              </>
            </div>
          </article>
        </section>
      </div>
    );
  }
}

const isLoadingSelector = createLoadingSelector(adminConstants.GET_WORKFLOWS);
const errorSelector = createErrorSelector(adminConstants.GET_WORKFLOWS);

const mapStateToProps = (state) => {
  const routeName = get(state.router, "location.pathname");

  const workflowStepsById = groupBy(state.admin.workflowSteps, "id");
  const workflowResults = get(state.admin, "workflows.results", []);
  const filterValues = get(state.admin, "workflows.filterValues", {});
  return {
    routeName,
    workflowStepsById,
    getLastWorkflowStep: getLastWorkflowStepForWorkflow,
    isFetching: isLoadingSelector(state),
    workflows: workflowResults,
    workflowFilterValues: filterValues,
    workflowsCount: get(state.admin, "workflows.totalRows", 0),
    error: errorSelector(state),
  };
};

const mapDispatchToProps = {
  getPaginatedWorkflows,
  push,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(Workflows));
