import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withApollo } from "@apollo/client/react/hoc";
import { get, isNil } from "lodash";

import Button from "./Button";
import { bankConstants } from "actions/types";
import { createLoadingSelector } from "store/selectors";
import Alert from "components/Alert";
import PlaidLinkTokenStorage from "../utils/PlaidLinkTokenStorage";
import { getPlaidLinkToken } from "actions/bankActions";

class PlaidLink extends Component {
  static propTypes = {
    client: PropTypes.object.isRequired,
    label: PropTypes.string,
    isFetching: PropTypes.bool.isRequired,
    render: PropTypes.func,
    onSuccess: PropTypes.func,
    onExit: PropTypes.func,
    userId: PropTypes.string,
    bank: PropTypes.object,
    getPlaidLinkToken: PropTypes.func.isRequired,
    userLegalName: PropTypes.string,
  };
  static defaultProps = {
    label: "Link bank account",
    style: {
      padding: "6px 4px",
      outline: "none",
      background: "#FFFFFF",
      border: "2px solid #F1F1F1",
      borderRadius: "4px",
    },
  };

  _isMounted = false;
  plaidHandler = null;

  constructor(props) {
    super(props);
    this.state = {
      linkLoaded: false,
      error: null,
      isOpen: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (!this.state.isOpen) {
      PlaidLinkTokenStorage.clearTokenContext(this.props.userId);
    }
  }

  onExit = async (err, metadata) => {
    // handle the invalid link token error
    if (err != null && err.error_code === "INVALID_LINK_TOKEN") {
      this.plaidHandler.destroy();
      await this.createPlaidLink();
    }
    // handle general error
    if (err != null) {
      this.setState({ error: "Failed to initialize plaid" });
      console.error({ message: "Failed to initialize plaid", err });
    }
    PlaidLinkTokenStorage.clearTokenContext(this.props.userId);
    this.props.onExit && this.props.onExit(metadata);
  };

  createPlaidLink = async () => {
    try {
      const currentPath = window.location.pathname;
      const bankId = get(this.props, "bank.id");
      const plaidLinkToken = await this.props.getPlaidLinkToken(
        this.props.client,
        bankId
      );
      const token = get(plaidLinkToken, "data.linkToken");
      if (isNil(bankId)) {
        PlaidLinkTokenStorage.setTokenContext(
          this.props.userId,
          currentPath,
          token
        );
      }
      const plaidConfig = {
        token,
        onExit: (err, metadata) => this.onExit(err, metadata),
        onSuccess: (token, metadata) => {
          PlaidLinkTokenStorage.clearTokenContext(this.props.userId);
          this.props.onSuccess(token, metadata, this.props.userLegalName);
        },
      };

      this.plaidHandler = window.Plaid.create(plaidConfig);
      if (this._isMounted) {
        this.setState({ linkLoaded: true });
      }
    } catch (error) {
      this.setState({
        error: "Failed to load bank information.",
      });
      throw error;
    }
  };

  handleOnClick = async () => {
    await this.createPlaidLink();
    if (this.state.linkLoaded) {
      this.plaidHandler.open();
      this.setState({ isOpen: true });
    }
  };

  render() {
    return (
      <>
        {this.state.error && <Alert msg={this.state.error} type="error" />}
        {(this.props.render && this.props.render(this)) || (
          <Button
            name="submit"
            disabled={this.state.linkLoaded}
            onClick={this.handleOnClick}
            btnLabel={this.props.label}
            loading={this.props.isFetching}
            withArrow
          />
        )}
      </>
    );
  }
}

const loadingSelector = createLoadingSelector([
  bankConstants.LINK_BANK_WITH_ICON,
  bankConstants.GET_PLAID_LINK_TOKEN,
]);

const mapStateToProps = (state) => {
  const userLegalName = `${state.user.profile.firstName} ${state.user.profile.lastName}`;
  return {
    userId: state.user.id,
    isFetching: loadingSelector(state),
    userLegalName,
  };
};

const mapDispatchToProps = {
  getPlaidLinkToken,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(PlaidLink));
