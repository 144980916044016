import { find, keys } from "lodash";

export const ENTERPRISE = "ENTERPRISE";
const ENTERPRISE_PLAN = {
  id: ENTERPRISE,
  label:
    "A retirement specialist will be in touch to discuss pricing after signup.",
  range: {
    min: 101,
    max: 9999999999,
  },
};

export const planCodes = {
  VERSION_1: {
    WSPLN000140516221: {
      label: "$25/mo",
      price: 25,
      id: "WSPLN000140516221",
      range: {
        min: 1,
        max: 4,
      },
    },
    WSPLN005250516221: {
      label: "$50/mo",
      price: 50,
      id: "WSPLN005250516221",
      range: {
        min: 5,
        max: 25,
      },
    },
    WSPLN026500516221: {
      label: "$90/mo",
      price: 90,
      id: "WSPLN026500516221",
      range: {
        min: 26,
        max: 50,
      },
    },
    WSPLN511000516221: {
      label: "$140/mo",
      price: 140,
      id: "WSPLN511000516221",
      range: {
        min: 51,
        max: 100,
      },
    },
    ENTERPRISE: ENTERPRISE_PLAN,
  },
  VERSION_2: {
    WSPLN001100327224: {
      label: "$40/mo",
      price: 80,
      id: "WSPLN001100327224",
      range: {
        min: 1,
        max: 10,
      },
    },
    WSPLN011250327224: {
      label: "$80/mo",
      price: 160,
      id: "WSPLN011250327224",
      range: {
        min: 11,
        max: 25,
      },
    },
    WSPLN026500327224: {
      label: "$140/mo",
      price: 280,
      id: "WSPLN026500327224",
      range: {
        min: 26,
        max: 50,
      },
    },
    WSPLN511000327224: {
      label: "$200/mo",
      price: 400,
      id: "WSPLN511000327224",
      range: {
        min: 51,
        max: 100,
      },
    },
    ENTERPRISE: ENTERPRISE_PLAN,
  },
};

export const allPlanCodes = {
  ...planCodes.VERSION_1,
  ...planCodes.VERSION_2,
};

export const version1Keys = keys(planCodes.VERSION_1);

const maxSmallPlanEmployeeSize = 100;

export function findPlan(numOfEmployees, initialPlanCode) {
  if (numOfEmployees > maxSmallPlanEmployeeSize) return ENTERPRISE_PLAN;
  const planCodesToCheck = version1Keys.includes(initialPlanCode)
    ? planCodes.VERSION_1
    : planCodes.VERSION_2;

  const matchingPlan = find(planCodesToCheck, (code) => {
    return numOfEmployees >= code.range.min && numOfEmployees <= code.range.max;
  });

  return matchingPlan;
}

export default {
  planCodes,
};
