import React from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { Card } from "react-bootstrap";
import { map, get, reduce, isEmpty, size, has, chain, find } from "lodash";
import { push } from "connected-react-router";
import { FiChevronLeft } from "react-icons/fi";
import { getPaginatedContributions } from "actions/payrollActions";
import { createLoadingSelector, createErrorSelector } from "store/selectors";
import { payrollConstants, employerConstants } from "actions/types";
import { toast } from "react-toastify";
import { formatCurrency, formatAmount } from "utils/number";
import { activeBankSelector } from "store/selectors/bank";
import { approvePayroll } from "actions/payrollActions";
import { payrollTypes } from "statics/propTypes";
import { processedStatuses } from "statics/payroll";
import {
  usersWithNoContributionChangeSelector,
  usersWithPendingContributionsSelector,
} from "store/selectors/user";
import {
  approveContributionChanges,
  deleteEmployerLinks,
} from "actions/employerActions";

import moment from "moment";
import Button from "components/Button";
import Alert from "components/Alert";
import IconSpinner from "components/IconSpinner";
import Kpi from "components/Kpi";
import StatusBox from "components/StatusBox";
import Paginator from "components/Paginator";
import IconTable from "components/IconTable";
import PayrollStatementDownloader from "../PayrollStatementDownloader";

export class Summary extends React.PureComponent {
  static propTypes = {
    goToNamedStep: PropTypes.func,
    getPaginatedContributions: PropTypes.func,
    approvePayroll: PropTypes.func,
    approveContributionChanges: PropTypes.func,
    push: PropTypes.func,
    deleteEmployerLinks: PropTypes.func,
    isFetching: PropTypes.bool,
    isUpdating: PropTypes.bool,
    client: PropTypes.object,
    keptPriorContributions: PropTypes.object,
    approvedContributions: PropTypes.object,
    skippedContributions: PropTypes.object,
    removedEmployees: PropTypes.object,
    allContributions: PropTypes.object,
    group: PropTypes.object.isRequired,
    bankAccountNumber: PropTypes.string.isRequired,
    usersWhoLeftPlan: PropTypes.array,
    contributionsWithNoChange: PropTypes.object,
    contributionChanges: PropTypes.array,
    usersToExclude: PropTypes.array,
    linkedEmployees: PropTypes.array,
    contributionChangesCount: PropTypes.number,
    error: PropTypes.string,
    lockedUserContributions: PropTypes.array,

    currentPayrolls: PropTypes.func,
    payPeriodId: PropTypes.string,
    payroll: PropTypes.arrayOf(payrollTypes),
  };

  constructor(props) {
    super(props);

    const payPeriod = find(
      this.props.payroll,
      (payroll) => payroll.payPeriodId === props.payPeriodId
    );

    this.state = {
      limit: 25,
      offset: 0,
      initialFetching: true,
      enableAutoPayroll: false,
      page: 0,
      showAllLineItems: true,
      payPeriod,
    };
  }

  componentDidMount() {
    this._getPaginatedContributions().then(() => {
      this.setState({ initialFetching: false });
    });
  }

  _toggleShowAllLineItems = () => {
    const showAllLineItems = this.state.showAllLineItems;

    this.setState(
      { showAllLineItems: !showAllLineItems, offset: 0, page: 0 },
      () => {
        this._getPaginatedContributions();
      }
    );
  };

  _getPaginatedContributions = () => {
    return this.props.getPaginatedContributions(this.props.client, {
      groupId: this.props.group.id,
      limit: this.state.limit,
      offset: this.state.offset,
      excludedIds: this.state.showAllLineItems ? [] : this.props.usersToExclude,
      payPeriodId: this.props.payPeriodId,
    });
  };

  _getStatus = ({ employeeId, isUserWhoLeft, isLockedUser }) => {
    if (this.props.keptPriorContributions[employeeId]) {
      return <StatusBox status="Using Prior Amount" type="dark" />;
    } else if (this.props.skippedContributions[employeeId]) {
      return <StatusBox status="Skipped" type="dark" />;
    } else if (this.props.approvedContributions[employeeId]) {
      return <StatusBox status="Approved" type="success" />;
    } else if (isLockedUser) {
      return <StatusBox status="Paused" type="warning" />;
    } else if (isUserWhoLeft || this.props.removedEmployees[employeeId]) {
      return <StatusBox status="Removed" type="danger" />;
    } else {
      return <StatusBox status="Approved Prior" type="success" />;
    }
  };

  _getContribsWithNoChange = () => {
    const {
      skippedContributions,
      removedEmployees,
      contributionsWithNoChange,
    } = this.props;
    // a previously approved contribution can still be changed by being skipped or employee removed
    const contributionsWithTrulyNoChange = chain(contributionsWithNoChange)
      .filter((contrib) => {
        return (
          !skippedContributions[contrib.employeeId] &&
          !removedEmployees[contrib.employeeId]
        );
      })
      .map((contrib) => {
        return {
          employeeId: contrib.employeeId,
          amount: contrib.amount,
        };
      })
      .value();

    return contributionsWithTrulyNoChange;
  };

  _buildPayrollInput() {
    // all approved and existing contributions with no change
    const contribsWithNoChange = this._getContribsWithNoChange();
    const newApprovedContribs = map(
      this.props.approvedContributions,
      (contrib) => {
        return {
          employeeId: contrib.employeeId,
          amount: contrib.userNewContributionAmount,
        };
      }
    );
    const allApproved = [...contribsWithNoChange, ...newApprovedContribs];

    // all kept prior contributions
    const newKeptPrior = map(this.props.keptPriorContributions, (contrib) => {
      return {
        employeeId: contrib.employeeId,
        amount: contrib.userCurrentContributionAmount,
        skippedAmount: contrib.userNewContributionAmount,
      };
    });

    const newSkippedContributions = map(
      this.props.skippedContributions,
      ({ contribution = {}, employeeId }) => {
        return {
          employeeId,
          amount: 0,
          skippedAmount: contribution.amount,
        };
      }
    );

    // all remove from plan, by link id
    const linkIds = map(this.props.removedEmployees, (removedUser) => {
      return (
        find(this.props.linkedEmployees, {
          employeeId: removedUser.employeeId,
        }) || {}
      ).id;
    });

    return {
      contributionsToProcess: [...allApproved, ...newKeptPrior],
      approvedContributions: map(this.props.approvedContributions, "id"),
      skippedContributions: [...newKeptPrior, ...newSkippedContributions],
      usersRemovedFromPlan: linkIds,
      totalAmount: this._getApprovedAmount(),
      groupId: this.props.group.id,
      isNextPayPeriod: this._isNextPayPeriod(),
      payPeriodId: this.props.payPeriodId,
    };
  }

  _submitPayroll = () => {
    const promises = [];
    const payrollInput = this._buildPayrollInput();

    if (!isEmpty(this.props.approvedContributions)) {
      promises.push(
        this.props.approveContributionChanges(
          this.props.client,
          payrollInput.approvedContributions,
          this.props.group.id
        )
      );
    }

    if (!isEmpty(this.props.removedEmployees)) {
      promises.push(
        this.props.deleteEmployerLinks(
          this.props.client,
          payrollInput.usersRemovedFromPlan
        )
      );
    }

    Promise.all(promises).then(() => {
      if (!this.props.error) {
        this.props.approvePayroll(this.props.client, payrollInput).then(() => {
          if (!this.props.error) {
            toast.success("Successfully processed payroll.");
            this.props.push("/dashboard/contributions");
          }
        });
      }
    });
  };

  _getSummaryData = (changes) => {
    return map(changes, (change) => {
      const {
        userProfile: { firstName, lastName, lastFourOfSsn },
      } = change;
      let amount;

      // if skipped or removed employee new amount is 0 prev amount should be last current;
      if (
        this.props.removedEmployees[change.employeeId] ||
        this.props.skippedContributions[change.employeeId]
      ) {
        amount = 0;

        // if using prior contribution then amount and prev amount become the same
      } else if (this.props.keptPriorContributions[change.employeeId]) {
        amount = change.userCurrentContributionAmount;
      } else if (has(change, "amount")) {
        amount = change.amount;
      } else {
        amount = change.userNewContributionAmount;
      }

      const fullName = `${firstName} ${lastName}`;

      const status = this._getStatus(change);

      const contributionAmount = formatCurrency(amount);

      return {
        employeeName: fullName,
        lastFourOfSsn,
        contributionAmount,
        status,
        change,
      };
    });
  };

  _buildSummaryColumns = () => {
    const columns = [
      {
        label: "Employee Name",
        key: "employeeName",
      },

      {
        label: "Last 4 SSN",
        key: "lastFourOfSsn",
      },
      {
        label: "Contribution for Period	",
        key: "contributionAmount",
      },

      {
        label: "Status",
        key: "status",
      },
    ];
    return columns;
  };

  _onPageChange = ({ selected }) => {
    const offset = selected * this.state.limit;

    this.setState({ page: selected, offset }, () =>
      this._getPaginatedContributions()
    );
  };

  _isNextPayPeriod = () => {
    const currentStatus = this.state.payPeriod.status;
    return processedStatuses.includes(currentStatus);
  };

  _getApprovedAmount = () => {
    const {
      skippedContributions,
      removedEmployees,
      keptPriorContributions,
      approvedContributions,
    } = this.props;
    const approvedAmount = reduce(
      this.props.allContributions,
      (acc, contrib) => {
        if (
          skippedContributions[contrib.employeeId] ||
          removedEmployees[contrib.employeeId]
        ) {
          return acc;
        }

        if (keptPriorContributions[contrib.employeeId]) {
          acc += contrib.userCurrentContributionAmount;
          return acc;
        }

        // if amount property exist it means its a prior approved contribution
        if (has(contrib, "amount")) {
          acc += contrib.amount;
          return acc;
        }

        if (
          approvedContributions[contrib.employeeId] &&
          contrib.userNewContributionAmount
        ) {
          acc += contrib.userNewContributionAmount;
          return acc;
        }

        return acc;
      },
      0
    );

    return formatAmount(approvedAmount);
  };

  _getSummarySection = () => {
    const processingDate = this.state.payPeriod.onOrAfterPayrollProcessingDate
      ? moment().format("YYYY-MM-DD")
      : this.state.payPeriod.payrollProcessingDate;

    const newlyApprovedAmt = this._getApprovedAmount();
    return (
      <div className="summary-info-wrapper">
        <p className="summary-title">Just a reminder</p>
        <p className="summary-body">
          By clicking submit, you authorize Icon Financial Services to debit
          your account ending in {this.props.bankAccountNumber} the amount of{" "}
          {formatCurrency(newlyApprovedAmt)} on {processingDate}. If the
          processing date falls on a weekend, US federal holidays, or after 5:30
          PM Pacific, requests will be processed on the next business day.
        </p>
      </div>
    );
  };

  _getKpiSection = () => {
    const newlyApprovedAmt = this._getApprovedAmount();
    const numOfApproved = size(this.props.approvedContributions);
    const numOfSkipped = size(this.props.skippedContributions);

    return (
      <div className="kpis">
        <Kpi
          label="Total Contribution Amount"
          value={newlyApprovedAmt}
          format="currency"
        />
        <Kpi label="Approved Contributions" value={numOfApproved} />
        <Kpi label="Skipped Contributions" value={numOfSkipped} />
      </div>
    );
  };

  render() {
    if (this.state.initialFetching) {
      return <IconSpinner centered />;
    }

    const changes = [
      ...this.props.usersWhoLeftPlan,
      ...this.props.lockedUserContributions,
      ...this.props.contributionChanges,
    ];

    const noChanges = !this.state.showAllLineItems && isEmpty(changes);

    const showBtnLabel = this.state.showAllLineItems
      ? "Show Changes only"
      : "Show all";

    const payrollPeriod = this.state.payPeriod;

    const schedule = payrollPeriod;

    const columns = this._buildSummaryColumns(changes);
    const data = this._getSummaryData(changes);

    return (
      <div>
        {this._getSummarySection()}
        {this._getKpiSection()}
        <Card>
          <div className="workflow-widget">
            <div className="action-header">
              <span className="group-info">
                <span className="group-name">
                  {this.props.group.name}:{" "}
                  <span className="invite-number">
                    Total contributions{" "}
                    {this.state.showAllLineItems ? "" : "with changes"}
                    <span className="number">({changes.length})</span>
                  </span>
                </span>
              </span>

              <div className="action-btns">
                <PayrollStatementDownloader
                  endDate={schedule.payPeriodEnd}
                  startDate={schedule.payPeriodStart}
                  bankAccountNumber={this.props.bankAccountNumber}
                  group={this.props.group}
                  payrollInput={this._buildPayrollInput()}
                  payrollType={"SCHEDULED"}
                  totalAmount={this._getApprovedAmount()}
                  text={"Download Contributions"}
                />
                <span style={{ paddingLeft: "15px" }}>
                  <Button
                    color="secondary"
                    name="action"
                    onClick={this._toggleShowAllLineItems}
                    btnLabel={showBtnLabel}
                    size="sm"
                  />
                </span>
              </div>
            </div>
          </div>

          <span>
            {this.props.isFetching && <IconSpinner centered />}
            {!noChanges && (
              <>
                <IconTable columns={columns} data={data} />

                <div className="pagination-row">
                  <Paginator
                    onChange={this._onPageChange}
                    pageCount={
                      this.props.contributionChangesCount / this.state.limit
                    }
                    page={this.state.page}
                  />
                </div>
              </>
            )}
            {noChanges && (
              <p className="blank-slate-msg">
                There are no contribution changes for this pay period.
              </p>
            )}
          </span>
        </Card>
        {this.props.error && <Alert type="error" msg={this.props.error} />}
        <div className="process-payroll-button-row">
          <Button
            icon={{
              icon: <FiChevronLeft size={14} stroke={"#0cb7c4"} />,
              position: "left",
            }}
            onClick={() => this.props.goToNamedStep("approvals")}
            btnLabel="Go Back"
            name="cancel"
            color="cancel"
          />
          <Button
            name="submit"
            onClick={this._submitPayroll}
            withArrow
            loading={this.props.isUpdating}
          />
        </div>
      </div>
    );
  }
}

const updatingActions = [
  employerConstants.APPROVE_CONTRIBUTION_CHANGES,
  employerConstants.DELETE_EMPLOYER_LINKS,
  payrollConstants.APPROVE_PAYROLL,
];

const isLoadingSelector = createLoadingSelector(
  payrollConstants.GET_PAGINATED_CONTRIBUTIONS
);
const isErrorSelector = createErrorSelector([
  payrollConstants.GET_PAGINATED_CONTRIBUTIONS,
  ...updatingActions,
]);
const updatingSelector = createLoadingSelector(updatingActions);

const mapStateToProps = (state) => {
  const allPending = usersWithPendingContributionsSelector(state);
  const contributionsWithNoChange =
    usersWithNoContributionChangeSelector(state);
  const allContributions = {
    ...allPending,
    ...contributionsWithNoChange,
  };

  return {
    payroll: state.employer.currentPayrolls,
    isFetching: isLoadingSelector(state),
    pendingChanges: state.payroll.paginatedPendingContributionChanges,
    pendingChangesCount: state.payroll.count,
    error: isErrorSelector(state),
    bankAccountNumber: get(activeBankSelector(state), "accountId", ""),
    contributionChanges: state.payroll.paginatedContributions,
    contributionChangesCount: state.payroll.paginatedContributionsCount,
    linkedEmployees: state.employer.linkedEmployees,
    contributionsWithNoChange,
    isUpdating: updatingSelector(state),
    allContributions,
  };
};

const mapDispatchToProps = {
  getPaginatedContributions,
  approveContributionChanges,
  deleteEmployerLinks,
  approvePayroll,
  push,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(Summary));
