import React from "react";
import PropTypes from "prop-types";
import { Spinner } from "react-bootstrap";

const IconSpinner = ({ centered, size = "lg", type = "info" }) => {
  const spinner = (
    <Spinner animation="border" role="status" size={size} variant={type}>
      <span className="sr-only">Loading...</span>
    </Spinner>
  );
  if (centered) {
    return <div className="center">{spinner}</div>;
  }

  return spinner;
};

IconSpinner.propTypes = {
  centered: PropTypes.bool,
  size: PropTypes.string,
  type: PropTypes.string,
};

export default IconSpinner;
