import React from "react";
import PropTypes from "prop-types";
import Button from "components/Button";
import IconSpinner from "components/IconSpinner";
import IconHeader from "components/IconHeader";
import IconSubheader from "components/IconSubheader";
import IconTable from "components/IconTable";
import Alert from "components/Alert";
import GroupForm from "components/GroupForm";
import EditGroupForm from "components/EditGroupForm";
import BankLinkModal from "./BankLinkModal";
import IconTableHeader from "components/IconTableHeader";

import { OverlayTrigger, Popover } from "react-bootstrap";
import { filter, get, isEmpty, keyBy, map, size, some } from "lodash";
import { withApollo } from "@apollo/client/react/hoc";
import { connect } from "react-redux";
import { FiDollarSign, FiEdit3, FiUsers } from "react-icons/fi";
import { payrollPeriodsToEnglishMapping } from "utils/timeHelper";
import { getBanksAndGroups } from "actions/adminActions";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { daysBeforeDebitId, uniqueInviteCodesId } from "statics/featureIds";
import { toast } from "react-toastify";
import { BsTrash } from "react-icons/bs";
import {
  addEmployerGroup,
  currentPayrolls,
  deleteEmployerGroup,
  updateEmployerGroup,
} from "actions/employerActions";
import { adminConstants, employerConstants } from "actions/types";

import "./EmployerInfo.scss";
import GroupMigrationWorkflow from "components/groupMigrationWorkflow/GroupMigrationWorkflow";

class Groups extends React.PureComponent {
  static propTypes = {
    companyId: PropTypes.string,
    company: PropTypes.object,
    client: PropTypes.object,
    error: PropTypes.string,
    groupCreationError: PropTypes.string,
    groupEditError: PropTypes.string,
    getBanksAndGroups: PropTypes.func,
    deleteEmployerGroup: PropTypes.func,
    updateEmployerGroup: PropTypes.func,
    addEmployerGroup: PropTypes.func,
    isLoading: PropTypes.bool,
    currentPayrolls: PropTypes.func,
  };

  constructor() {
    super();

    this.state = {
      initialFetching: true,
      banks: [],
      groups: [],
      showGroupForm: false,
      groupForEdit: null,
      showGroupEditForm: false,
      showLinkModal: false,
      payPeriods: [],
    };
  }

  async componentDidMount() {
    const {
      data: { getCompanyBanks: banks, getCompanyGroups: groups },
    } = await this.props.getBanksAndGroups(
      this.props.client,
      this.props.companyId
    );
    const { data: payPeriods } = await this.props.currentPayrolls(
      this.props.client,
      this.props.companyId
    );
    this.setState({ initialFetching: false, banks, groups, payPeriods });
  }

  _submitGroup = (group) => {
    this.props
      .addEmployerGroup(this.props.client, this.props.companyId, group)
      .then(async () => {
        if (!this.props.groupCreationError) {
          toast.success("Successfully added group.");
          const {
            data: { getCompanyBanks: banks, getCompanyGroups: groups },
          } = await this.props.getBanksAndGroups(
            this.props.client,
            this.props.companyId
          );
          this.setState({
            banks,
            groups,
            showGroupForm: false,
          });
        }
      });
  };

  _submitGroupForEdit = (group) => {
    this.props
      .updateEmployerGroup(this.props.client, group, this.props.companyId)
      .then(async () => {
        if (!this.props.groupEditError) {
          const {
            data: { getCompanyBanks: banks, getCompanyGroups: groups },
          } = await this.props.getBanksAndGroups(
            this.props.client,
            this.props.companyId
          );
          this.setState({
            banks,
            groups,
            groupForEdit: null,
            showGroupEditForm: false,
          });
          toast.success("Successfully updated group.");
        }
      });
  };

  _showLinkModal = () => {
    this.setState({ showLinkModal: true });
  };

  _closeLinkModal = () => {
    this.setState({ showLinkModal: false });
  };

  _removeGroup = (id) => {
    this.props
      .deleteEmployerGroup(this.props.client, id, this.props.companyId)
      .then(() => {
        if (!this.props.error) {
          // closes the pop up <OverlayTrigger />
          document.body.click();
          toast.success("Successfully removed group.");
          const newGroups = filter(
            this.state.groups,
            (group) => group.id !== id
          );
          this.setState({ groups: newGroups });
        }
      });
  };

  _getGroupData = () => {
    const payrollsByGroupId = keyBy(this.state.payPeriods, "groupId");

    return map(this.state.groups, (group) => {
      const {
        name,
        id,
        associatedLinkRequests,
        planType,
        payrollFrequency,
        associatedBankAccount,
      } = group;

      const payrollFrequencyLabel =
        payrollPeriodsToEnglishMapping[payrollFrequency];
      const activeParticipants = filter(associatedLinkRequests, {
        status: "ACTIVE",
      });
      const pendingParticipants = filter(associatedLinkRequests, (request) => {
        return request.status !== "ACTIVE";
      });
      const numberOfParticipants = size(activeParticipants);
      const groupIsDeletable = isEmpty(associatedLinkRequests);

      const accountId = associatedBankAccount.accountId;

      const nextPayrollDate = get(payrollsByGroupId[id], "end");

      const processingDate = get(
        payrollsByGroupId[id],
        "payrollProcessingDate"
      );

      const approved = this.state.payPeriods.filter(
        (payroll) => payroll.status === "APPROVED"
      );
      const processing = this.state.payPeriods.filter(
        (payroll) => payroll.status === "PROCESSING"
      );
      const groupHasProcessingPayrolls = some(processing, {
        groupId: group.id,
      });
      const groupHasApprovedPayrolls = some(approved, { groupId: group.id });

      return {
        id,
        name,
        planType,
        processingDate,
        nextPayrollDate,
        payrollFrequencyLabel,
        numberOfParticipants,
        numberOfPendingParticipants: size(pendingParticipants),
        accountId,
        groupIsDeletable,
        group,
        groupId: group.id,
        companyGroupIndex: group.companyGroupIndex,
        groupHasProcessingPayrolls,
        groupHasApprovedPayrolls,
      };
    });
  };

  _buildGroupColumns = () => {
    const columns = [
      {
        label: "Group Name",
        key: "name",
      },

      {
        label: "Plan Type",
        key: "planType",
      },
      {
        label: "Plan Code",
        customComponent: (props) => {
          const planCode = `${this.props.company.planId}-${props.companyGroupIndex}`;
          return planCode;
        },
      },
      {
        label: "Internal ID",
        key: "id",
      },

      {
        label: "Next Payroll Processing Date",
        key: "processingDate",
      },

      {
        label: "Next Payroll Date",
        key: "nextPayrollDate",
      },

      {
        label: "Payroll Frequency",
        key: "payrollFrequencyLabel",
      },
      {
        label: "# of active participants",
        key: "numberOfParticipants",
      },
      {
        label: "# of pending participants",
        key: "numberOfPendingParticipants",
      },

      {
        label: "Associated Bank",
        key: "accountId",
      },
      {
        customComponent: (props) => {
          return (
            <td className="action-icons">
              <span
                className="action-icon"
                onClick={() =>
                  this.setState({
                    showGroupEditForm: true,
                    groupForEdit: props.group,
                  })
                }
              >
                <FiEdit3
                  size={18}
                  stroke="#96AAC4"
                  fill="white"
                  color="white"
                />
              </span>
              {props.groupIsDeletable && (
                <OverlayTrigger
                  rootClose
                  trigger="click"
                  placement="bottom"
                  overlay={
                    <Popover className="action-popover">
                      <Popover.Content>
                        {!props.groupHasApprovedPayrolls &&
                          !props.groupHasProcessingPayrolls && (
                            <p>Are you sure you want to remove this group?</p>
                          )}
                        {props.groupHasProcessingPayrolls && (
                          <div className="dismiss-payroll-info">
                            <p>
                              {"There are currently payroll contributions processing and you are unable to remove the group." +
                                " Come back in 1 business day, after contributions have processed, to remove the group."}
                            </p>
                          </div>
                        )}
                        {props.groupHasApprovedPayrolls &&
                          !props.groupHasProcessingPayrolls && (
                            <div className="dismiss-payroll-info">
                              <p>
                                {"There are currently payroll contributions approved for processing. By continuing and removing this group," +
                                  " the payroll contributions will be un-approved. Would you like to continue?"}
                              </p>
                            </div>
                          )}
                        <div>
                          <Button
                            type="button"
                            action="cancel"
                            color="cancel"
                            btnLabel="Cancel"
                            onClick={() => document.body.click()}
                            size="sm"
                          />
                          {!props.groupHasProcessingPayrolls && (
                            <Button
                              type="button"
                              name="submit"
                              color="red"
                              btnLabel="Remove"
                              withArrow={true}
                              loading={this.props.isLoading}
                              onClick={() => this._removeGroup(props.groupId)}
                              disabled={props.groupHasProcessingPayrolls}
                              size="sm"
                            />
                          )}
                        </div>
                      </Popover.Content>
                    </Popover>
                  }
                >
                  <span className="action-icon">
                    <BsTrash size={18} color="#B12121" />
                  </span>
                </OverlayTrigger>
              )}
            </td>
          );
        },
      },
    ];
    return columns;
  };

  _getBankData = () => {
    return map(this.state.banks, (bank) => {
      const {
        id,
        accountId,
        verificationStatus,
        state,
        bankName,
        accountSubtype,
      } = bank;

      return {
        id,
        accountId,
        verificationStatus,
        state,
        bankName,
        accountSubtype,
      };
    });
  };

  _buildBankColumns = () => {
    const columns = [
      {
        label: "Bank Name",
        key: "bankName",
      },

      {
        label: "Account Type",
        key: "accountSubtype",
      },
      {
        label: "Bank Account #",
        key: "accountId",
      },

      {
        label: "Internal ID",
        key: "id",
      },

      {
        label: "Status",
        key: "verificationStatus",
      },

      {
        label: "State",
        key: "state",
      },
    ];
    return columns;
  };

  _buildGroupsSection = () => {
    if (isEmpty(this.state.groups)) {
      return (
        <div className="action-box">
          <div className="employerInfo">
            <div className="employerInfo-body">
              <div>
                <div className="circle">
                  <span className="icon">
                    <FiUsers color="white" stroke="#60A4BF" size={16} />
                  </span>
                </div>
              </div>
              <div className="employerInfo-intro">
                <IconHeader variant="labelHeader" headerText="Groups" />
                <IconSubheader subheader="Employer has not created a payroll group yet." />
              </div>
            </div>
            <div className="employerInfo-action">
              <Button
                disabled={isEmpty(this.state.banks)}
                onClick={() => this.setState({ showGroupForm: true })}
                size={"sm"}
                btnLabel={"Add Group"}
                name="action"
              />
            </div>
          </div>
        </div>
      );
    }

    const columns = this._buildGroupColumns();
    const data = this._getGroupData();

    return (
      <div className="action-box">
        <IconTableHeader
          tableHeader="Groups"
          tableCount={size(this.state.groups)}
        ></IconTableHeader>
        <div className="btn-row group-migrate-btn-container">
          <Button
            onClick={() => this.setState({ showMigration: true })}
            color="primary"
            name="action"
            size="sm"
            btnLabel="Migrate Employees"
          />
        </div>
        <IconTable columns={columns} data={data} />
      </div>
    );
  };

  _buildBanksSection = () => {
    if (isEmpty(this.state.banks)) {
      return (
        <div className="action-box">
          <div className="employerInfo">
            <div className="employerInfo-body">
              <div>
                <div className="circle">
                  <span className="icon">
                    <FiDollarSign color="white" stroke="#60A4BF" size={16} />
                  </span>
                </div>
              </div>
              <div className="employerInfo-intro">
                <IconHeader variant="labelHeader" headerText="Bank" />
                <IconSubheader subheader="Employer has not linked a bank yet." />
              </div>
            </div>
          </div>
        </div>
      );
    }

    const columns = this._buildBankColumns();
    const data = this._getBankData();

    return (
      <div className="action-box">
        <IconTableHeader
          tableHeader="Bank"
          tableCount={size(this.state.banks)}
        />
        <IconTable columns={columns} data={data} />
      </div>
    );
  };

  _getGroupForm = () => {
    const hasExpiredInvites = some(
      this.props.company.enabledProductFeatures,
      (featureId) => featureId === uniqueInviteCodesId
    );
    const enabledDaysBeforeDebit = some(
      this.props.company.enabledProductFeatures,
      (featureId) => featureId === daysBeforeDebitId
    );
    return (
      <GroupForm
        onClose={() => this.setState({ showGroupForm: false })}
        onSubmit={this._submitGroup}
        companyId={this.props.companyId}
        error={this.props.groupCreationError}
        accounts={this.state.banks}
        isLoading={this.props.isLoading}
        planTypes={this.props.company.planTypes}
        hasExpiredInvites={hasExpiredInvites}
        enabledDaysBeforeDebit={enabledDaysBeforeDebit}
      />
    );
  };
  _getEditGroupForm = () => {
    const hasExpiredInvites = some(
      this.props.company.enabledProductFeatures,
      (featureId) => featureId === uniqueInviteCodesId
    );
    return (
      <EditGroupForm
        onClose={() => this.setState({ showGroupEditForm: false })}
        onSubmit={this._submitGroupForEdit}
        group={this.state.groupForEdit}
        payPeriods={this.state.payPeriods}
        error={this.props.groupEditError}
        accounts={this.state.banks}
        isLoading={this.props.isLoading}
        hasExpiredInvites={hasExpiredInvites}
      />
    );
  };

  render() {
    if (this.state.initialFetching) {
      return <IconSpinner centered />;
    }

    if (this.props.error) {
      return <Alert msg={this.props.error} type={"error"} />;
    }

    if (this.state.showMigration) {
      return (
        <GroupMigrationWorkflow
          onCancel={() => this.setState({ showMigration: false })}
          onSuccess={(groups) => {
            this.setState({ groups, showMigration: false });
          }}
          groups={this.state.groups}
          companyId={this.props.companyId}
        />
      );
    }

    if (this.state.showGroupForm) {
      return this._getGroupForm();
    }
    if (this.state.showGroupEditForm) {
      return this._getEditGroupForm();
    }
    if (this.state.showLinkModal) {
      return (
        <BankLinkModal
          companyId={this.props.companyId}
          onClose={this._closeLinkModal}
        />
      );
    }
    return (
      <div className="company-groups-banks">
        <div className="groups">{this._buildGroupsSection()}</div>
        <div className="banks">{this._buildBanksSection()}</div>
      </div>
    );
  }
}

const groupActions = [
  employerConstants.ADD_EMPLOYER_GROUPS,
  employerConstants.DELETE_EMPLOYER_GROUPS,
  employerConstants.UPDATE_EMPLOYER_GROUPS,
  employerConstants.CURRENT_PAYROLLS,
];
const errorSelector = createErrorSelector(
  adminConstants.GET_BANKS_AND_GROUPS,
  employerConstants.CURRENT_PAYROLLS
);
const groupEditErrorSelector = createErrorSelector(
  employerConstants.UPDATE_EMPLOYER_GROUPS
);

const loadingSelector = createLoadingSelector([
  ...groupActions,
  adminConstants.GET_BANKS_AND_GROUPS,
]);
const groupErrorSelector = createErrorSelector([
  employerConstants.ADD_EMPLOYER_GROUPS,
  employerConstants.DELETE_EMPLOYER_GROUPS,
  employerConstants.CURRENT_PAYROLLS,
]);

const mapStateToProps = (state) => {
  return {
    error: errorSelector(state),
    groupCreationError: groupErrorSelector(state),
    groupEditError: groupEditErrorSelector(state),
    isLoading: loadingSelector(state),
  };
};

const mapDispatchToProps = {
  getBanksAndGroups,
  addEmployerGroup,
  deleteEmployerGroup,
  updateEmployerGroup,
  currentPayrolls,
};

export default connect(mapStateToProps, mapDispatchToProps)(withApollo(Groups));
