import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { startCase } from "lodash";
import IconSpinner from "components/IconSpinner";

export default class Button extends React.PureComponent {
  static propTypes = {
    withArrow: PropTypes.bool,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    type: PropTypes.string,
    onClick: PropTypes.func,
    btnLabel: PropTypes.string,
    children: PropTypes.node,
    size: PropTypes.string,
    btnClass: PropTypes.string,
    icon: PropTypes.shape({
      icon: PropTypes.node,
      position: PropTypes.string,
    }),
    position: PropTypes.string,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "red",
      "cancel",
      "action",
      "dismiss",
    ]),
    name: PropTypes.oneOf(["submit", "cancel", "action"]).isRequired,
  };

  static defaultProps = {
    type: "submit",
    loading: false,
    btnLabel: "Continue",
    disabled: false,
  };

  render() {
    const btnClass = classnames("submit-button button", {
      "has-arrow": this.props.withArrow,
      [`button-${this.props.color}`]: !!this.props.color,
      disabled: this.props.disabled,
      loading: this.props.loading,
      [this.props.size]: this.props.size,
      [this.props.btnClass]: this.props.btnClass,
    });

    const btnLabel = startCase(this.props.btnLabel);

    return (
      <button
        className={btnClass}
        disabled={this.props.disabled || this.props.loading}
        name={this.props.name}
        type={this.props.type}
        onClick={this.props.onClick}
      >
        <span>
          {this.props.icon && this.props.icon.position === "left" && (
            <div
              className="btn-icon"
              style={{ paddingRight: 5, position: "relative", bottom: 1 }}
            >
              {this.props.icon.icon}
            </div>
          )}
          {this.props.children || btnLabel}
          {this.props.icon && this.props.icon.position === "right" && (
            <div
              className="btn-icon"
              style={{ paddingLeft: 3, position: "relative", bottom: 1 }}
            >
              {this.props.icon.icon}
            </div>
          )}
        </span>

        {this.props.loading && (
          <span className="btn-loading-spinner">
            <IconSpinner size="sm" type="light" />
          </span>
        )}

        {this.props.withArrow && !this.props.loading && !this.props.disabled && (
          <span className="btn-arrow">
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 60 60"
              style={{
                enableBackground: "new 0 0 60 60",
              }}
            >
              <path
                className="st0"
                d="M0,30.2c0.2,1,1.1,1.7,2.1,1.7h47.5c0.7,0,1.1,0.8,0.7,1.4l-6,5.4c-0.4,0.4-0.6,0.9-0.6,1.4
c0,0.6,0.2,1.1,0.7,1.5c0.8,0.7,2,0.6,2.8-0.2l12.2-10.1c0.1-0.1,0.2-0.2,0.3-0.3l0.1-0.1c0-0.1,0.1-0.1,0.1-0.2s0-0.1,0.1-0.2
c0-0.1,0-0.1,0.1-0.2C60,30,60,29.8,60,29.5c0-0.1,0-0.1-0.1-0.2c0-0.1,0-0.1-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2c0,0,0-0.1-0.1-0.1
c-0.1-0.1-0.2-0.2-0.3-0.3L47,18.4c-0.7-0.7-1.8-0.9-2.6-0.3c-1,0.7-1.1,2.2-0.3,3l6.2,5.4c0.5,0.6,0,1.4-0.7,1.4H2
C0.8,27.9-0.2,29,0,30.2z"
              ></path>
            </svg>
          </span>
        )}
      </button>
    );
  }
}
