import {
  adminConstants,
  employerConstants,
  userConstants,
} from "actions/types";
import { get, isEmpty, keyBy } from "lodash";
import { virtualBankId } from "statics/featureIds";
import { payrollConstants } from "../actions/types";

const initialState = {
  groupsLoaded: false,
  company: {},
  groups: [],
  payrolls: [],
  employeeInvitations: {},
  linkedEmployees: [],
  employerLinkRequests: [],
  administrators: [],
  userProfiles: {},
  employeeUploadJobs: [],
  hasVirtualBank: false,
  paginatedEmployees: [],
  employeeCount: 0,
  linkedCompanies: [],
  isMultiAccount: false,
  organization: {},
  paginatedEmployeeInvitations: [],
  paginatedEmployeeInvitationsCount: 0,
  beneficialOwners: [],
  userProfilesHasMetadata: false,
  finchStatus: "",
  paginatedEmployeeRoster: [],
  paginatedEmployeeRosterCount: 0,
  contributionAbility: {},
  employeeRosterFilterValues: {},
  currentPayrolls: [],
  crmLink: {
    id: "id",
    providerReferenceId: "",
  },
  setupIntent: {},
  requiredUpdates: {},
  billingType: "",
};

function updateAdministratorLink(id, state, updatedData) {
  const administrators = state.administrators.map((admin) => {
    if (admin.link.id === id) {
      return {
        ...admin,
        link: { ...admin.link, ...updatedData },
      };
    }
    return admin;
  });
  return {
    ...state,
    administrators,
  };
}

export function employer(state = initialState, action) {
  switch (action.type) {
    case employerConstants.REGISTER_EMPLOYER_SUCCESS:
      return {
        ...state,
        company: action.data,
        groups: [],
      };

    //GET COMPANY OBJECT
    case employerConstants.GET_COMPANY_SUCCESS: {
      const hasVirtualBank = (
        action.data.enabledProductFeatures || []
      ).includes(virtualBankId);
      return {
        ...state,
        company: action.data,
        hasVirtualBank,
        finchStatus: action.data.finchStatus,
        payrollIntegrationStatus: action.data.payrollIntegrationStatus,
        referralPlans: action.data.referralPlans,
      };
    }

    case userConstants.USER_STATE_SUCCESS: {
      const hasVirtualBank = (
        action.data.enabledProductFeatures || []
      ).includes(virtualBankId);
      const referralPlans = get(action.data.company, "referralPlans", []);
      return {
        ...state,
        company: action.data.company,
        referralPlans,
        hasVirtualBank,
      };
    }

    // UPDATE COMPANY OBJECT
    case employerConstants.UPDATE_COMPANY_SUCCESS:
      return {
        ...state,
        company: action.data.company,
      };

    // GET EMPLOYER GROUP
    case adminConstants.GET_GROUPS_BY_COMPANY_ID_SUCCESS:
    case employerConstants.GET_EMPLOYER_GROUPS_SUCCESS:
      return {
        ...state,
        groupsLoaded: true,
        groups: action.data,
      };

    // ADD EMPLOYER GROUP
    case employerConstants.ADD_EMPLOYER_GROUPS_SUCCESS:
      return { ...state, groups: [...state.groups, action.data] };

    // UPDATE EMPLOYER GROUP
    case employerConstants.UPDATE_EMPLOYER_GROUPS_SUCCESS: {
      const groupsCopy = [...state.groups];
      const objIndex = state.groups.findIndex(
        (group) => group.id == action.data.id
      );
      groupsCopy[objIndex] = action.data;
      return { ...state, groups: groupsCopy };
    }

    // DELETE EMPLOYER GROUP
    case employerConstants.DELETE_EMPLOYER_GROUPS_SUCCESS: {
      const groupsWithoutDeletedGroup = state.groups.filter(
        (group) => group.id != action.data
      );
      return { ...state, groups: groupsWithoutDeletedGroup };
    }
    case employerConstants.RESEND_INVITES_EMPLOYEE_SUCCESS:
    case employerConstants.GET_EMPLOYEE_INVITES_SUCCESS:
      return {
        ...state,
        employeeInvitations: keyBy(action.data, "userEmail"),
      };

    case employerConstants.RESEND_INVITE_EMPLOYEE_SUCCESS:
    case employerConstants.REVOKE_INVITE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        employeeInvitations: {
          ...state.employeeInvitations,
          [action.data.userEmail]: action.data,
        },
      };
    case employerConstants.INVITE_EMPLOYEE_SUCCESS: {
      const newInvites = keyBy(action.data, "userEmail");
      return {
        ...state,
        employeeInvitations: {
          ...state.employeeInvitations,
          ...newInvites,
        },
      };
    }
    case employerConstants.GET_LINKED_EMPLOYEES_SUCCESS:
      return {
        ...state,
        linkedEmployees: action.data,
      };
    case employerConstants.GET_PAGINATED_EMPLOYEES_SUCCESS:
      return {
        ...state,
        paginatedEmployees: action.data.rows,
        employeeCount: action.data.count,
      };
    case employerConstants.GET_PENDING_LINKED_EMPLOYEES_SUCCESS:
    case employerConstants.APPROVE_PENDING_LINKED_EMPLOYEE_SUCCESS:
    case employerConstants.REJECT_PENDING_LINKED_EMPLOYEE_SUCCESS:
      return {
        ...state,
        employerLinkRequests: action.data,
      };
    case employerConstants.GET_USERS_FOR_GROUP_SUCCESS:
      return {
        ...state,
        userProfiles: keyBy(action.data.employees, "id"),
        userProfilesHasMetadata: get(action.data, "hasMetadata", false),
      };

    case employerConstants.GET_ADMINISTRATORS_SUCCESS:
      return {
        ...state,
        administrators: action.data,
      };
    case employerConstants.RESEND_INVITE_ADMINISTRATOR_SUCCESS: {
      const { id } = action.data.link;
      return updateAdministratorLink(id, state, action.data.link);
    }
    case employerConstants.INVITE_ADMINISTRATOR_SUCCESS: {
      return {
        ...state,
        administrators: [...state.administrators, action.data],
      };
    }
    case employerConstants.CHANGE_ADMIN_ROLE_SUCCESS: {
      const { id } = action.data.link;

      return updateAdministratorLink(id, state, action.data.link);
    }
    case employerConstants.LOCK_ADMINISTRATOR_SUCCESS: {
      const { id } = action.data.link;
      return updateAdministratorLink(id, state, action.data.link);
    }
    case employerConstants.UNLOCK_ADMINISTRATOR_SUCCESS: {
      const { id } = action.data.link;
      return updateAdministratorLink(id, state, action.data.link);
    }

    case employerConstants.GET_EMPLOYEE_DOCUMENT_UPLOAD_JOBS_SUCCESS:
    case employerConstants.UPLOAD_ROSTER_SUCCESS:
    case employerConstants.UPLOAD_EMPLOYEES_DOCUMENT_SUCCESS:
      return {
        ...state,
        employeeUploadJobs: action.data,
      };

    case payrollConstants.CANCEL_PAYROLL_SUCCESS: {
      const { groupId, payrollId } = action.data;
      const group = state.groups.find((group) => group.id === groupId);
      const payroll = state.payrolls.find(
        (payroll) => payroll.id === payrollId
      );

      const updatedGroup = {
        ...group,
        ...payroll,
      };
      const groups = [
        ...state.groups.filter((group) => group.id !== groupId),
        updatedGroup,
      ];

      return {
        ...state,
        groups,
      };
    }

    case employerConstants.GET_LINKED_COMPANIES_SUCCESS: {
      if (!action.data) return state;

      const { organization = {}, companies = [] } = action.data;
      return {
        ...state,
        linkedCompanies: companies,
        isMultiAccount: !isEmpty(action.data),
        organization,
      };
    }

    case employerConstants.GET_PAGINATED_INVITES_SUCCESS: {
      return {
        ...state,
        paginatedEmployeeInvitations: action.data.rows,
        paginatedEmployeeInvitationsCount: action.data.count,
      };
    }
    case employerConstants.GET_OWNERS_SUCCESS: {
      return {
        ...state,
        beneficialOwners: action.data,
      };
    }
    case employerConstants.GET_BILLING_PORTAL_URL_SUCCESS: {
      return {
        ...state,
        billingPortalURL: action.data,
      };
    }
    case employerConstants.GET_PROGRESSIVE_ONBOARDING_SUCCESS: {
      return {
        ...state,
        company: {
          ...state.company,
          progressiveOnboardingState: action.data,
        },
      };
    }

    case employerConstants.UPDATE_PROGRESSIVE_ONBOARDING_SUCCESS: {
      return {
        ...state,
        company: {
          ...state.company,
          progressiveOnboardingState: action.data,
        },
      };
    }
    case employerConstants.CONNECT_FINCH_SUCCESS:
    case employerConstants.RESYNC_ROSTER_SUCCESS:
    case employerConstants.RECONNECT_FINCH_SUCCESS:
    case employerConstants.GET_FINCH_STATUS_SUCCESS: {
      return {
        ...state,
        finchStatus: action.data,
      };
    }

    case employerConstants.GET_EMPLOYEE_ROSTER_SUCCESS: {
      return {
        ...state,
        paginatedEmployeeRoster: action.data.results,
        paginatedEmployeeRosterCount: action.data.totalRows,
        employeeRosterFilterValues: action.data.filterValues,
      };
    }

    case employerConstants.GET_REFERRAL_PLANS_SUCCESS: {
      return {
        ...state,
        referralPlans: action.data,
      };
    }

    case employerConstants.REFER_COMPANY_SUCCESS: {
      return {
        ...state,
        referCompany: action.data,
      };
    }

    case employerConstants.GET_PAYROLL_GROUP_CONTRIBUTION_ABILITY_SUCCESS: {
      return {
        ...state,
        contributionAbility: action.data,
      };
    }
    case employerConstants.CURRENT_PAYROLLS_SUCCESS: {
      return {
        ...state,
        currentPayrolls: action.data,
      };
    }
    case adminConstants.GET_COMPANY_CRM_LINK_SUCCESS: {
      return {
        ...state,
        crmLink: isEmpty(action.data) ? initialState.crmLink : action.data,
      };
    }

    case employerConstants.GET_SETUP_INTENT_SUCCESS: {
      return {
        ...state,
        setupIntent: action.data,
      };
    }

    case employerConstants.GET_EMPLOYER_REQUIRED_STEPS_SUCCESS:
      return {
        ...state,
        requiredUpdates: action.data,
      };
    case employerConstants.GET_PAYROLL_INTEGRATION_STATUS_SUCCESS:
      return {
        ...state,
        payrollIntegrationStatus: action.data,
      };

    default:
      return state;
  }
}
