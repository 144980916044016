import React from "react";
import PropTypes from "prop-types";
import { withApollo } from "@apollo/client/react/hoc";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { RiExchangeDollarLine } from "react-icons/ri";
import { map } from "lodash";
import { employerConstants } from "actions/types";
import { categoryToColorMappingParticipationFunnel } from "utils/highcharts/config";
import { hasEnabledPayrollIntegration } from "store/selectors/employer";
import { groupType } from "statics/propTypes";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { getEmployerTakeRateDownloadUrl } from "services/documentService";
import { simulateLinkClickNewTab } from "utils/dom";
import { wrapError } from "utils/errorHelper";
import { Card, Col, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import {
  getAllEmployerGroups,
  getTakeRateEmployeesForCompany,
} from "actions/employerActions";
import {
  FiAlertCircle,
  FiClock,
  FiDownload,
  FiMail,
  FiTrendingUp,
} from "react-icons/fi";

import IconSpinner from "components/IconSpinner";
import Button from "components/Button";

import "./EmployerDashboard.scss";

const allGroups = {
  id: "",
  value: "ALL_GROUPS",
  name: "All Groups",
};

const dateFilter = [
  { value: "ALL_TIME", label: "All Time" },
  { value: "PAST_90_DAYS", label: "Past 90 Days" },
];

class EmployeePlanParticipation extends React.PureComponent {
  static propTypes = {
    planParticipationFunnel: PropTypes.arrayOf(
      PropTypes.shape({
        enrollmentIcon: PropTypes.string,
        enrollmentLabel: PropTypes.string,
      })
    ),
    getTakeRateEmployeesForCompany: PropTypes.func,
    getAllEmployerGroups: PropTypes.func,

    client: PropTypes.object,
    push: PropTypes.func,
    groups: PropTypes.arrayOf(groupType),
    isUpdating: PropTypes.bool,
    hasPayrollIntegration: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      error: "",
      initialFetching: true,
      selectedTakeRateGroup: "ALL_GROUPS",
      selectedDate: "ALL_TIME",
      result: { notStarted: 0, opening: 0, enrolled: 0 },
    };
  }

  async componentDidMount() {
    window.analytics.page("Employer Dashboard");

    const selectedDate = this.getSelectedDate(this.state.selectedDate);
    const groupId = this.getGroupId(this.state.selectedTakeRateGroup);

    const result = await this._getTakeRateNumbers(groupId, selectedDate);

    this.setState({ result });
  }

  _getTakeRateNumbers = async (groupId, selectedDate) => {
    const result = await this.props.getTakeRateEmployeesForCompany(
      this.props.client,
      groupId,
      selectedDate
    );
    return result.data;
  };

  _createTakeRateGroupFilter = () => {
    const groups = this.props.groups.map((group) => {
      return { id: group.id, value: group.name, name: group.name };
    });
    return [allGroups, ...groups];
  };

  _filterTakeRateGroups = (group) => {
    const allGroups = this.props.groups;
    if (group === "ALL_GROUPS") {
      return allGroups;
    }
    return this.props.groups.filter((groupId) => {
      return groupId.name === group;
    });
  };

  async _handleFilters(group, selectedDate) {
    const groupId = this.getGroupId(group);

    const filteredResult = await this._getTakeRateNumbers(
      groupId,
      this.getSelectedDate(selectedDate)
    );

    this.setState({ result: filteredResult });
  }

  _getPlanParticipationFunnel = () => {
    {
      return [
        {
          id: "notStarted",
          enrollmentIcon: (
            <FiClock color={"#009deb"} stroke={"#FFFFFF"} size={"20px"} />
          ),
          enrollmentLabel: "Invited",
        },
        {
          id: "opening",
          enrollmentIcon: (
            <FiTrendingUp color={"#1a59e6"} stroke={"#FFFFFF"} size={"20px"} />
          ),
          enrollmentLabel: "Enrolling",
        },
        {
          id: "enrolled",
          enrollmentIcon: (
            <RiExchangeDollarLine
              fill={"#FFFFFF"}
              color={"#FFFFFF"}
              stroke={"#002c59"}
              strokeWidth={"0.5"}
              size={"22px"}
            />
          ),
          enrollmentLabel: "Enrolled",
        },
      ];
    }
  };

  _buildFunnel = () => {
    return map(
      this._getPlanParticipationFunnel(),
      (planParticipationFunnel) => (
        <Col
          className="enrollment"
          id={planParticipationFunnel.id}
          key={planParticipationFunnel.id}
        >
          <span
            className="enrollment-icon"
            style={{
              backgroundColor:
                categoryToColorMappingParticipationFunnel[
                  planParticipationFunnel.id
                ],
            }}
          >
            {planParticipationFunnel.enrollmentIcon}
          </span>

          <span className="enrollment-description">
            <p className="enrollment-label">
              {planParticipationFunnel.enrollmentLabel}
            </p>

            <p className="enrollment-value">
              {this.state.result[planParticipationFunnel.id]}
            </p>
          </span>
        </Col>
      )
    );
  };

  _renderTooltip = (props) => (
    <Tooltip {...props}>
      <b>Document Download:</b>
      <br></br>
      The downloadable lists of employee emails will be updated daily.
    </Tooltip>
  );

  _fileDownload(status) {
    return (
      <>
        <a
          className="link"
          target="_blank"
          onClick={() =>
            this._downloadFile(
              this.state.selectedTakeRateGroup,
              status,
              this.state.selectedDate
            )
          }
        >
          <FiDownload
            fill={"#FFFFFF"}
            color={"#FFFFFF"}
            stroke={"#29b0c2"}
            strokeWidth={"1.5"}
            size={"16px"}
          />
        </a>
      </>
    );
  }

  getGroupId(groupName) {
    if (groupName === "ALL_GROUPS") {
      return null;
    } else {
      const groupId = this._filterTakeRateGroups(groupName).map((group) => {
        return group.id;
      });
      return groupId[0];
    }
  }

  getSelectedDate(selectedDate) {
    if (selectedDate === "Past 90 Days") return "PAST_90_DAYS";
    return "ALL_TIME";
  }

  _downloadFile(groupName, status, selectedDate) {
    const id = this.getGroupId(groupName);
    const date = this.getSelectedDate(selectedDate);

    getEmployerTakeRateDownloadUrl(this.props.client, {
      groupId: id,
      status,
      timeframe: date,
    })
      .then((response) => {
        simulateLinkClickNewTab(response.url);
      })
      .catch((error) => {
        this.setState({ error: wrapError(error) });
      });
  }

  render() {
    const content = this._buildFunnel();
    const isUpdating = this.props.isUpdating;
    return (
      <>
        <Card className="dashboard-widget">
          <div className="widget-header">
            <span>
              <Card.Title>Employee Plan Participation</Card.Title>
              <Card.Text>
                <span>
                  <Form.Control
                    className="group-filter"
                    as="select"
                    id="inlineFormCustomSelect"
                    value={this.state.selectedTakeRateGroup}
                    onChange={(e) => {
                      this._handleFilters(
                        e.target.value,
                        this.state.selectedDate
                      );

                      this.setState({
                        selectedTakeRateGroup: e.target.value,
                      });
                    }}
                  >
                    {map(this._createTakeRateGroupFilter(), (group) => (
                      <option id={group.id} value={group.value} key={group.id}>
                        {group.name}
                      </option>
                    ))}
                  </Form.Control>
                </span>
                <span>
                  <Form.Control
                    className="group-filter"
                    as="select"
                    id="inlineFormCustomSelect"
                    value={this.state.selectedDate}
                    onChange={(e) => {
                      this._handleFilters(
                        this.state.selectedTakeRateGroup,
                        e.target.value
                      );
                      this.setState({
                        selectedDate: e.target.value,
                      });
                    }}
                  >
                    {map(dateFilter, (time) => (
                      <option key={time.value}>{time.label}</option>
                    ))}
                  </Form.Control>
                </span>
                <span className="OverlayTrigger">
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 200 }}
                    overlay={this._renderTooltip}
                    containerPadding={20}
                    transition={true}
                  >
                    <FiAlertCircle
                      className="FiAlertCircle"
                      size={"16px"}
                      color={"#FFFFFF"}
                      stroke={"#29b0c2"}
                      strokeWidth={"1.5"}
                    />
                  </OverlayTrigger>
                </span>
              </Card.Text>
            </span>
            {!this.props.hasPayrollIntegration && (
              <Button
                size="sm"
                name="action"
                icon={{
                  icon: (
                    <FiMail size={14} color={"#29b0c2"} stroke={"#FFFFFF"} />
                  ),
                  position: "left",
                }}
                onClick={() => {
                  this.props.push(`/dashboard/users/employees`);
                }}
                btnLabel="Invite Employees"
              />
            )}
          </div>
          {isUpdating && <IconSpinner centered />}
          {!isUpdating && (
            <div className="enrollment-table">
              <Row>{content}</Row>
              <Row className="bottom">
                <Col className="download">
                  {this.state.result.notStarted === 0
                    ? null
                    : this._fileDownload("NOT_STARTED")}
                </Col>
                <Col className="download">
                  {this.state.result.opening === 0
                    ? null
                    : this._fileDownload("OPENING")}
                </Col>
                <Col className="download">
                  {this.state.result.enrolled === 0
                    ? null
                    : this._fileDownload("ENROLLED")}
                </Col>
              </Row>
            </div>
          )}
        </Card>
      </>
    );
  }
}

const updatingActions = [
  employerConstants.GET_EMPLOYER_GROUPS,
  employerConstants.GET_TAKE_RATE_EMPLOYEES,
];

const errorSelector = createErrorSelector(updatingActions);
const isUpdating = createLoadingSelector(updatingActions);

const mapStateToProps = (state) => {
  return {
    groups: state.employer.groups,
    isUpdating: isUpdating(state),
    error: errorSelector(state),
    hasPayrollIntegration: hasEnabledPayrollIntegration(state),
  };
};

const mapDispatchToProps = {
  push,
  getAllEmployerGroups,
  getTakeRateEmployeesForCompany,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(EmployeePlanParticipation));
