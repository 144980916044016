export const employerLinkRequestStatusToEnglish = {
  PENDING: "Pending",
  REQUEST_ACCESS: "Pending Approval",
  REJECTED: "Rejected By Employer",
};

export const payrollStatusToEnglish = {
  WAITING_FOR_APPROVAL: "Current",
  WAITING_FOR_ACTION: "Error",
  APPROVED: "Approved",
  PROCESSING: "Processing",
  ERROR: "Error",
  COMPLETE: "Complete",
  CANCELED: "Canceled",
  UPCOMING: "Upcoming",
  LATE: "Late",
  CURRENT: "Current",
  MISSED: "Missed",
  DISMISSED: "Dismissed",
};

export const payrollStatusToType = {
  WAITING_FOR_APPROVAL: "success",
  WAITING_FOR_ACTION: "danger",
  APPROVED: "success",
  PROCESSING: "pending",
  ERROR: "danger",
  COMPLETE: "success",
  CANCELED: "danger",
  LATE: "danger",
  CURRENT: "secondary",
  UPCOMING: "info",
  MISSED: "complete",
  DISMISSED: "complete",
};

export const paymentStatusToEnglish = {
  HOLD: "In Process",
  PENDING: "In Process",
  COMPLETE: "Complete",
};

export const inviteStatusToEnglish = {
  NEW: "New",
  PENDING: "Sent",
  ACCEPTED: "Accepted",
  EXPIRED: "Expired",
  REVOKED: "Revoked",
  PROCESSING: "Processing",
  ERROR: "Failed",
  PROCESSED: "Processed",
};

export const inviteStatusToType = {
  PENDING: "pending",
  ACCEPTED: "success",
  EXPIRED: "danger",
  NEW: "success",
  REVOKED: "dark",
  PROCESSING: "primary",
  ERROR: "danger",
  PROCESSED: "secondary",
};

export default employerLinkRequestStatusToEnglish;

export const playType = {
  PENDING: "pending",
  ACCEPTED: "success",
  EXPIRED: "danger",
  NEW: "success",
  REVOKED: "dark",
  PROCESSING: "primary",
  ERROR: "danger",
  PROCESSED: "secondary",
};

export const adminStatusToEnglish = {
  PENDING: "Pending",
  ACCEPTED: "Accepted",
  EXPIRED: "Expired",
  LOCKED: "Locked",
  NEW: "New",
};

export const adminStatus = {
  PENDING: "pending",
  ACCEPTED: "success",
  EXPIRED: "danger",
  LOCKED: "dark",
  NEW: "success",
};

export const groupStatusToEnglish = {
  REQUEST_ACCESS: "Pending",
  ACTIVE: "Active",
  LINK_BANK: "Link Bank",
  NOT_ACTIVE: "Invite Employees",
};

export const groupStatusType = {
  REQUEST_ACCESS: "pending",
  LINK_BANK: "danger",
  ACTIVE: "secondary",
  NOT_ACTIVE: "dark",
};
